import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { Box } from '../Box';
import { withStyles } from '../../styling';
import { flexBox } from '../../system/props';
import { StepperContext } from './StepperContext';
import { StepConnector } from './StepConnector';

const defaultConnector = <StepConnector />;

// TODO: fix verticalContent (and rename to something that isnt confusing with vertical orientation)
const Stepper = withStyles({
  root: (props) => ({
    ...flexBox({
      ...props,
    }),
  }),
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  vertical: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  verticalContent: {
    alignItems: 'flex-start',
  },
}, { filterProps: [...flexBox.filterProps], preserveStyleProp: true })
(React.forwardRef(function Stepper(props, ref) {
  const {
    activeStep = 0,
    nonLinear = false,
    vertical = false,
    verticalContent = false,
    // ^ only useful while horizontal and puts the step icon/line above the label,content
    connector = defaultConnector,
    hideIcon = false,
    styles,
    style,
    children,
    ...rest
  } = props;

  const childrenArray = React.Children.toArray(children);
  const steps = childrenArray.map((step, index) => {
    return React.cloneElement(step, {
      index,
      last: index + 1 === childrenArray.length,
      ...step.props,
    });
  });
  const context = useMemo(() => {
    return {
      activeStep,
      connector,
      nonLinear,
      vertical,
      verticalContent,
      hideIcon
    }
  }, [activeStep, connector, nonLinear, vertical, verticalContent, hideIcon]);

  return (
    <StepperContext.Provider value={context}>
      <Box
        ref={ref}
        style={[
          vertical ? styles.vertical : styles.horizontal,
          !vertical && verticalContent ? styles.verticalContent : null,
          styles.root,
          style,
        ]}
        {...rest}
      >
        {steps}
      </Box>
    </StepperContext.Provider>
  );
}))

export { Stepper };