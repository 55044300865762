import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { Box, Text, TextField, Spacing, Heading, Button, Modal, ModalBackdrop, Menu, MenuItem } from '../../../../ui';
import { isNull } from '../../../../ui/utils';
import { apiHelpers, formatMoney } from '../../../../utils';
import { useApi, useAppState, useAsync } from '../../../../hooks';
import { Dialog, LayoutBox, TextData } from '../../../../components';
import { QuoteStatuses } from '../../../../constants';
import { CollapseArrowIcon, ExpandArrowIcon } from '../../../../components/icons';
import { QuoteCoverages } from './QuoteCoverages';
import { useEventCallback } from '../../../../ui/hooks';
import { ApplicationView } from '../../../ApplicationPage';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';
import { useAdjustmentForm } from '../../context/QuoteAdjustment/AdjustmentFormContext';
import { useAdjustmentPreview } from '../../context/QuoteAdjustment/AdjustmentPreviewContext';
import { ShieldCheck } from '../../../../ui/icons'

const TextData2 = props => {
  return (
    <TextData
      {...props}
      invert
      labelProps={{ size: 'small' }}
    />
  )
}

export const QuoteDetails = React.forwardRef((props, ref) => {
  const { quote, productCoverages, macavity, concentrations, ...rest } = props;
  const [{ showPremium, showAdjustmentFactor, canAddQuoteDescription, canViewWorkbook }] = useAppState();
  const { generateWorkbook, getWorkbook, generateQuoteDocs } = useApi();
  const {
    adjustmentFactor,
    netPremiumTotal = 0,
    product: productData,
    application,
    targetPremium,
  } = quote;

  const [menuOpen, setMenuOpen] = React.useState(false);
  const btnRef = useRef(null);
  const [refreshSuccess, setRefreshSuccess] = useState(false);

  const handleGenerateQuoteDocs = async (quoteId) => {
    setRefreshSuccess(false);
    try {
      const response = await generateQuoteDocs(quoteId);
      if (response && !response.errorMessage) {
        setRefreshSuccess(true);
      } else {
        setRefreshSuccess(false);
      }
    } catch (error) {
      setRefreshSuccess(false);
    }
  };

  const handleDownloadItem = useCallback(async (itemType) => {
    let url;
    switch(itemType) {
      case 'workbook':
        url = quote.workbookUrl;
        break;
      case 'quote':
        url = quote.quoteDocumentUrl; 
        break;
      case 'draftPolicy':
        url = quote.draftPolicyUrl; 
        break;
      case 'binder':
        url = quote.binderUrl; 
        break;
      default:
        console.error('Invalid item type:', itemType);
        return;
    }
    window.open(url, '_blank');
  }, [quote]);  // quote object dependency
  
  const { execute: downloadItem, status: downloadStatus, error: downloadError } = useAsync(handleDownloadItem, { immediate: false });

  const applicationSmallDetails = (
    <LayoutBox
      flex="1"
      borderRadius={8}
      gap={12}
      layout="top-left"
      padding="$2"
      borderWidth={1}
      borderColor="$gray.200"
    >
      <Text medium>Customer Details</Text>
      <TextData
        label="Insured"
        data={quote.insured}
        invert
        labelProps={{ size: 'small' }}
        textProps={{ size: 'large' }}
      />
      <TextData
        label="Agent"
        data={typeof quote.agent.name === 'string' ? quote.agent.name.split('@')[0] : quote.agent.email ? quote.agent.email.split('@')[0] : 'Not listed'}
        invert
        size="small"
      />
      <TextData
        label="Applied at"
        data={moment.utc(application.appliedAt).format('MM-DD-YYYY')}
        invert
        size="small"
      />
      <TextData
        label="Target Date"
        data={moment.utc(application.targetEffectiveAt).format('MM-DD-YYYY')}
        invert
        labelProps={{ size: 'small' }}
        textProps={{ size: 'large'  }}
      />
      {canViewWorkbook ? (
        <Box pt="$1" alignItems="flex-end" width="100%" padX="$1" border={{ top: { width: 1, color: '$gray.200' }}}>
          <ViewApplicationButton quoteId={quote.id} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
              {refreshSuccess && <ShieldCheck/>}
              <Button
                text
                label="Refresh Documents"
                color="$primary"
                onPress={() => handleGenerateQuoteDocs(quote.id)}
              />
            </div>
            <Button color="$primary" ref={btnRef} onPress={() => setMenuOpen(c => !c)}>
              Download Options
            </Button>
            <Menu anchorNode={btnRef.current} open={menuOpen} onClose={() => setMenuOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
              <MenuItem onClick={() => downloadItem('workbook')}>Download Workbook</MenuItem>
              <MenuItem onClick={() => downloadItem('quote')}>Download Quote</MenuItem>
              <MenuItem onClick={() => downloadItem('draftPolicy')}>Download Draft Policy</MenuItem>
              <MenuItem onClick={() => downloadItem('binder')}>Download Binder</MenuItem>
            </Menu>
        </Box>
      ) : null}
    </LayoutBox>
  );

  const { adjusting } = useAdjustmentState();
  const { setValue } = useAdjustmentForm();
  const product = useMemo(() => apiHelpers.resolveProduct(productData));
  const [expanded, setExpanded] = useState(quote && quote.status === QuoteStatuses.prebound ? false : true);
  const [macavityExpanded, setMacavityExpanded] = useState(true);
  const [concentrationsExpanded, setConcentrationsExpanded] = useState(true);

  const grossPremiumTotal = netPremiumTotal + (quote.fees ? quote.fees.map(f => f.amount).reduce((a,b) => a+b, 0) : 0);

  if (macavity && macavity.quoteLocations && quote && quote.locations) {
    macavity.quoteLocations.forEach((q) => {
      const myQuote = quote.locations.find(l => l.id === q.locationId);
      if (myQuote) {
        q.riskId = myQuote.riskId;
        q.name = myQuote.name;
      } else {
        q.riskId = null;
        q.name = 'Unknown Location';
      }
    });
    macavity.quoteLocations.sort((a,b) => (a.riskId > b.riskId) ? 1 : ((a.riskId < b.riskId) ? -1 : 0));
  }

  var riskConcentrations = null;

  if (concentrations && quote && quote.locations) {
    const conKeys = Object.keys(concentrations);
    riskConcentrations = conKeys.map(k => {
      const myLocation = quote.locations.find(l => l.id === k);
      return {name: myLocation.name, riskId: myLocation.riskId, id: myLocation.id, concentrations: concentrations[k]};
    });
    riskConcentrations.sort((a,b) => (a.riskId > b.riskId) ? 1 : ((a.riskId < b.riskId) ? -1 : 0));
  }

  const quoteDetails = (
    <LayoutBox width="100%" row justifyContent="space-between" alignItems="flex-start" border={{ bottom: { width: 0, color: '$gray.200' } }} pb="$0">
      <LayoutBox flex={1.75}>
        <Box sx={{ border: { bottom: { width: 0, color: '$gray.200' } }, padBottom: '$2' }}>
          <Text medium>{`Product Details`}</Text>
          <Heading level={4}>{`${product.labels.name}`}</Heading>
          <LayoutBox row pt={4}>
          <Text small bold dim>{`${product.labels.type} Policy`}</Text>
            {/* <Text xSmall>Line of Business: <Text bold>Auto</Text></Text> */}
            {/* <Box height="4" width="4" bg="$gray.500" marginY="auto" marginX="$1" borderRadius="$circle" /> */}
            {/* <Text xSmall>Insurer: <Text bold>Skyward Insurance</Text></Text> */}
          </LayoutBox>
        </Box>
        <LayoutBox pb="$0.5" row layout="top-left" gap={24}>
          <TextData2
            label="Start Date"
            data={moment.utc(quote.coverageAt).format('MM-DD-YYYY')}
          />
          <Box height="30"  width={1} bg="$gray.200" alignSelf="center" mx="$0.5" opacity="0.6" />
          <TextData2
            label="End Date"
            data={moment.utc(quote.coverageUntil).format('MM-DD-YYYY')}
          />
          <Box height="30" width={1} bg="transparent" alignSelf="center" mx="$0.25" opacity="0.6" />
        </LayoutBox>
        <LayoutBox pt="$1" pb="$2.5" row layout="top-left" gap={24}>

          <TextData2
            label="Total Insured Value"
            data={formatMoney(quote.coverageTotal, { prefix: '$' })}
          />


        </LayoutBox>
        <LayoutBox pt="$1" pb="$2.5" row layout="top-left" gap={24}>
          {showPremium && showAdjustmentFactor ? (
            adjusting ? (
              <Controller
                name="quote.adjustmentFactor"
                rules={{ required: 'Required' }}
                render={({
                  field: { value, name, ref, onChange, onBlur },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <TextField
                      label="Adjustment Factor"
                      value={value}
                      variant="filled"
                      placeholder="0"
                      type="number"
                      error={error}
                      helperText={error ? error.message : null}
                      onChangeValue={v => onChange(v)}
                      onBlur={(e, valueError) => {
                        if (valueError && valueError.fixed) {
                          setValue('quote.adjustmentFactor', valueError.fixed, { shouldValidate: true });
                        } else if (value === null) {
                          setValue('quote.adjustmentFactor', 0, { shouldValidate: true });
                        }
                        onBlur(e);
                      }}
                      inputRef={ref}
                      LabelProps={{ weight: '$bold' }}
                      hideHelperText={false}
                    />
                  );
                }}
              />
            ) : (
              <TextData2
                label="Adjustment Factor"
                data={adjustmentFactor}
                textProps={{ size: 'large' }}
              />
            )
          ) : null}
            <Box style={{width: '2em'}} />
            <Box>
            {showPremium && showAdjustmentFactor ? (
            adjusting ? (
              <Controller
                name="quote.targetPremium"
                render={({
                  field: { value, name, ref, onChange, onBlur },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <TextField
                      label="Target Premium"
                      value={value}
                      variant="filled"
                      placeholder="0"
                      type="number"
                      error={error}
                      helperText={error ? error.message : null}
                      onChangeValue={v => onChange(v)}
                      onBlur={(e, valueError) => {
                        if (valueError && valueError.fixed) {
                          setValue('quote.targetPremium', valueError.fixed, { shouldValidate: true });
                        } else if (value === null) {
                          setValue('quote.targetPremium', null, { shouldValidate: true });
                        }
                        onBlur(e);
                      }}
                      inputRef={ref}
                      LabelProps={{ weight: '$bold', size: 'small'}}
                      hideHelperText={false}
                    />
                  );
                }}
              />
            ) : (
              <TextData2
                label="Target Premium"
                data={targetPremium}
                textProps={{ size: 'large' }}
              />
            )
          ) : null}
            </Box>
        </LayoutBox>
        <LayoutBox row pt={adjusting ? 0 : "$2"}>
            <Box>
              <Text>Net Premium</Text>
              {showPremium ? (
                <QuotePremium adjusting={adjusting} netPremiumTotal={netPremiumTotal} />
              ) : (
                <Text>Pricing not currently available</Text>
              )}
            </Box>
            <Box style={{width: '5em'}} />
            <Box>
              <Text>Gross Premium</Text>
              {showPremium ? (
                <QuoteGrossPremium adjusting={adjusting} netPremiumTotal={grossPremiumTotal} />
              ) : (
                <Text>Pricing not currently available</Text>
              )}
            </Box>
        </LayoutBox>
        <Box pt={adjusting ? 0 : "$2"}>
          <Fees fees={quote.fees} />
        </Box>
        {canAddQuoteDescription ?
          <AddQuoteDescription quoteId={quote.id} currentDescription={quote.description} alignSelf="flex-start" />
        : null}
        <LayoutBox row pt="$3.5" ty={expanded ? '$5' : 0}>
          <Text size={'large'}>Coverage(s) for <Text bold>{`${quote.locations.length} locations`}</Text></Text>
        </LayoutBox>
      </LayoutBox>
        <Box width={1} alignSelf="stretch" mx="$2"  bg="transparent" opacity="0.6" />
        {applicationSmallDetails}

      </LayoutBox>
  )
  return (
    <Box ref={ref}>
      {quoteDetails}
      <Box flex={1} alignItems="flex-start" width="100%" display={expanded ? 'flex' : 'none'} pt="$3">
        <QuoteCoverages quote={quote} productCoverages={productCoverages} />
      </Box>
      <LayoutBox row width="100%" layout="center-left" pt="$2">
        <Button
          variant="outlined"
          color="$primary"
          size="large"
          label={`${expanded ? 'Hide' : 'Expand'} Coverage Details`}
          endIcon={expanded ? <CollapseArrowIcon color="$primary" /> : <ExpandArrowIcon color="$primary" />}
          onPress={() => setExpanded(!expanded)}
        />
      </LayoutBox>
      {canViewWorkbook ? (
        <>
          <Box width="100%" display={macavityExpanded ? 'flex' : 'none'} pt="$3">
            {macavity && macavity.returnPeriods ? (
              <>
                <Text size="small" bold={true}>Total Quote Cat Model</Text>
                <table>
                  <thead>
                    <tr>
                      <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Average Annual Loss</Text></td>
                      <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Median Annual Loss</Text></td>
                      <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Average Largest Occurrence</Text></td>
                      <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Median Largest Occurrence</Text></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(macavity.annualMean)}</Text></td>
                      <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(macavity.annualMedian)}</Text></td>
                      <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(macavity.occurrenceMean)}</Text></td>
                      <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(macavity.occurrenceMedian)}</Text></td>
                    </tr>
                  </tbody>
                </table>
                <Spacing vertical={1} />
                <table style={{ maxWidth: '25em' }}>
                  <thead>
                    <tr>
                      <td style={{paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Return Period</Text></td>
                      <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Total Claims</Text></td>
                      <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Largest Claim</Text></td>
                    </tr>
                  </thead>
                  <tbody>
                    {macavity.returnPeriods.map((rp,i) => (
                      <tr key={`totalReturnPeriods.${i}`}>
                        <td style={{paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">{rp.returnPeriod} years</Text></td>
                        <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(rp.totalClaims)}</Text></td>
                        <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(rp.largestClaim)}</Text></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {macavity.quoteLocations ? (
                  <>
                    <Spacing vertical={2} />
                    <Text size="small" bold={true}>Location-Specific Cat Model</Text>
                    <table>
                      <thead>
                        <tr>
                          <td style={{paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Location</Text></td>
                          <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Average Annual Loss</Text></td>
                          <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Median Annual Loss</Text></td>
                          <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Average Largest Occurrence</Text></td>
                          <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Median Largest Occurrence</Text></td>
                        </tr>
                      </thead>
                      <tbody>
                        {macavity.quoteLocations.map((q,i) => (
                          <tr key={`quoteLocationsSummary.${i}`}>
                            <td style={{paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">{q.riskId ? `${q.riskId}. ${q.name}` : q.name}</Text></td>
                            <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(q.annualMean)}</Text></td>
                            <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(q.annualMedian)}</Text></td>
                            <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(q.occurrenceMean)}</Text></td>
                            <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(q.occurrenceMedian)}</Text></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {macavity.quoteLocations.map((q, i) => (
                      <Box key={q.locationId}>
                        <Spacing vertical={1} />
                        <Text size="small" bold={true}>{q.riskId ? `${q.riskId}. ${q.name}` : q.name}</Text>
                        <table key={`quoteLocations.${i}`} style={{ maxWidth: '25em' }}>
                          <thead>
                            <tr>
                              <td style={{paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Return Period</Text></td>
                              <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Total Claims</Text></td>
                              <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Largest Claim</Text></td>
                            </tr>
                          </thead>
                          <tbody>
                            {q.returnPeriods.map((rp,j) => (
                              <tr key={`quoteLocations.${i}.returnPeriods.${j}`}>
                                <td style={{paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">{rp.returnPeriod} years</Text></td>
                                <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(rp.totalClaims)}</Text></td>
                                <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(rp.largestClaim)}</Text></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Box>
                    ))}
                  </>
                ) : null}
              </>
            ) : <Text>No cat modeling info is available for this quote.</Text>}
          </Box>
          <LayoutBox row width="100%" layout="center-left" pt="$2">
            <Button
              variant="outlined"
              color="$primary"
              size="large"
              label={`${macavityExpanded ? 'Hide' : 'Expand'} Cat Model Details`}
              endIcon={macavityExpanded ? <CollapseArrowIcon color="$primary" /> : <ExpandArrowIcon color="$primary" />}
              onPress={() => setMacavityExpanded(!macavityExpanded)}
            />
          </LayoutBox>
          <Box width="100%" display={concentrationsExpanded ? 'flex' : 'none'} pt="$3">
            {riskConcentrations ? (
              <>
                <Spacing vertical={2} />
                <Text size="small" bold={true}>Risk Concentrations by Location</Text>
                {riskConcentrations.map(loc => (
                  <Box width="100%" key={loc.id}>
                    <Spacing vertical={1} />
                    <Text size="small" bold={true}>{loc.riskId ? `${loc.riskId}. ${loc.name}` : loc.name}</Text>
                    <table style={{ maxWidth: '35em' }}>
                      <thead>
                        <tr>
                          <td style={{paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Radius</Text></td>
                          <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Max Allowed</Text></td>
                          <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Concentration</Text></td>
                          <td style={{textAlign: 'center', paddingRight: '1em', paddingLeft: '1em'}}><Text bold={true} size="small">Flags</Text></td>
                        </tr>
                      </thead>
                      <tbody>
                        {loc.concentrations.map((con,i) => (
                          <tr style={{ backgroundColor: (con.local_coverage > con.local_max) ? '#F6BA5E' : 'none' }} key={`${loc.id}.${con.radius_mi}`}>
                            <td style={{paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">{con.radius_mi} miles</Text></td>
                            <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(con.local_max)}</Text></td>
                            <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">${formatMoney(con.local_coverage)}</Text></td>
                            <td style={{textAlign: 'right', paddingRight: '1em', paddingLeft: '1em'}}><Text size="small">{con.local_coverage > con.local_max ? "OVER" : ""}</Text></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                ))}
              </>
            ) : <Text>No risk concentration info is available for this quote</Text>}
          </Box>
          <LayoutBox row width="100%" layout="center-left" pt="$2">
            <Button
              variant="outlined"
              color="$primary"
              size="large"
              label={`${concentrationsExpanded ? 'Hide' : 'Expand'} Risk Concentration Details`}
              endIcon={concentrationsExpanded ? <CollapseArrowIcon color="$primary" /> : <ExpandArrowIcon color="$primary" />}
              onPress={() => setConcentrationsExpanded(!concentrationsExpanded)}
            />
          </LayoutBox>
        </>
      ) : null}
    </Box>
  );
})

const ViewApplicationButton = ({ quoteId }) => {
  const [open, setOpen] = useState(false);
  const closeModal = useEventCallback(() => setOpen(false));
  return (
    <>
      <Button
        label="Open Application"
        color="$primary"
        variant="text"
        onPress={() => setOpen(!open)}
      />
      <Modal
        open={open}
        onClose={closeModal}
        hideBackdrop
        scrollable
        ScrollProps={{
          minHeight: '101%',
          padTop: 100,
          containerStyle: { minHeight: '101%' },
          provideNode: true,
        }}
      >
        <ApplicationView quoteId={quoteId}>

        </ApplicationView>
        <ModalBackdrop open={open} onPress={closeModal} zIndex="-2" />
      </Modal>
    </>
  )
}

const QuotePremium = ({ adjusting, netPremiumTotal }) => {
  const { preview = {}, lastPreview = {} } = useAdjustmentPreview() || {};
  let premium = netPremiumTotal;
  if (adjusting) {
    if (!isNull(preview) && !isNull(preview.netPremiumTotal)) {
      premium = preview.netPremiumTotal;
    } else if (!isNull(lastPreview) && !isNull(lastPreview.netPremiumTotal)) {
      premium = lastPreview.netPremiumTotal;
    }
  }

  if (isNull(premium) || typeof premium !== 'number') {
    return <Text large dim>No pricing to show</Text>;
  }

  return (
    <Text style={{ fontWeight: 600, fontSize: 28 }} color="black">
      {formatMoney(premium, { prefix: '$', withDecimals: true })}
    </Text>
  );
}

const QuoteGrossPremium = ({ adjusting, netPremiumTotal }) => {
  const { preview = {}, lastPreview = {} } = useAdjustmentPreview() || {};
  let premium = netPremiumTotal;
  if (adjusting) {
    if (!isNull(preview) && !isNull(preview.netPremiumTotal)) {
      premium = preview.netPremiumTotal +
        preview.fees.map(f => f.amount).reduce((a,b) => a+b, 0);
    } else if (!isNull(lastPreview) && !isNull(lastPreview.netPremiumTotal)) {
      premium = lastPreview.netPremiumTotal +
        lastPreview.fees.map(f => f.amount).reduce((a,b) => a+b, 0);
    }
  }

  if (isNull(premium) || typeof premium !== 'number') {
    return <Text large dim>No pricing to show</Text>;
  }

  return (
    <Text style={{ fontWeight: 600, fontSize: 28 }} color="black">
      {formatMoney(premium, { prefix: '$', withDecimals: true})}
    </Text>
  );
}

const Fees = (props) => {
  const { fees, ...rest } = props;
  const { adjusting } = useAdjustmentState();
  return (
    <FeeData adjusting={adjusting} fees={fees} name={"quote.fees"}>
      {({fees, append, prepend, remove}) => {
        return (
        <Box pt={adjusting ? 0 : "$2"}>
          <Text>Fees</Text>
          {fees && fees.length ? fees.map((fee, i) => (
            <FeeItem fee={fee} fieldName={`quote.fees.${i}`} index={i} key={fee.id} remove={remove} />
          )) : <Text size={'small'}>No Fees Added</Text>}
          {adjusting ? (
            <Button
              style={{ marginBottom: '1em' }}
              variant="contained"
              color="$primary"
              size="small"
              onPress={() => {
                append({id: uuid(), name: "New Fee", rateOfNet: null, rateOfGross: null, amount: 0});
              }}
            >
              +  Add Fee
            </Button>
          ) : <TextData2 />}
        </Box>
        );
      }}
    </FeeData>
  );
}

const FeeData = ({adjusting, fees, name, children}) => {
  const form = useAdjustmentForm();
  if (adjusting && form) {
    return <AdjustableFeesData name={name} renderChildren={children} />;
  }
  return children({ fees });
};

const AdjustableFeesData = ({name, renderChildren}) => {
  const { fields, append, prepend, remove } = useFieldArray({ name });
  return renderChildren({ fees: fields, append, prepend, remove });
}

const FeeItem = (props) => {
  const { fee, fieldName, index, remove, ...rest } = props;
  const { adjusting } = useAdjustmentState();
  const { setValue, getValues } = useAdjustmentForm();
  return (
    <LayoutBox pb="$0.5" row layout="top-left" gap={24}>
      {adjusting ? (
          <Controller
            name={`${fieldName}.name`}
            render={({
              field: { value, name, ref, onChange, onBlur },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField label="Fee" variant="filled" LabelProps={{ weight: '$bold' }} width={140}
                value={value} placeholder="0" hideHelperText={error ? false : true}
                error={error} helperText={error ? error.message : null} onChangeValue={v => onChange(v)}
                onBlur={(e, valueError) => {
                  onBlur(e);
                }}
                inputRef={ref}
              />
            )}
          />
        ) : (
          <TextData2 label="Fee" data={fee.name} textProps={{ size: 'small' }} style={{ width: '10em' }} />
        )}
      {adjusting ? (
          <Controller
            name={`${fieldName}.rateOfNet`}
            render={({
              field: { value, name, ref, onChange, onBlur },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField label="Rate of Net" variant="filled" LabelProps={{ weight: '$bold' }} width={140}
                value={value} placeholder="0" type="number" hideHelperText={error ? false : true}
                error={error} helperText={error ? error.message : null} onChangeValue={v => onChange(v)}
                onBlur={(e, valueError) => {
                  if (parseFloat(e.target.value) && !valueError) {
                    setValue(`${fieldName}.rateOfNet`, parseFloat(e.target.value));
                    setValue(`${fieldName}.rateOfGross`, null);

                    /*
                     * The following logic is copied for rateOfGross, amount, and remove.
                     * Why not just make it a function? Well...React has hooks getValues and setValue,
                     * and therefore, I can't call a function from this callback function and
                     * I don't feel like refactoring it all, so you get code copied four times.
                     */
                    const allFees = getValues('quote.fees');
                    const netPremium = getValues('quote.netPremiumTotal');

                    if (allFees) {
                      allFees.forEach(f => {
                        f.amount = f.rateOfNet ? f.rateOfNet * netPremium : f.amount;
                      });
                      const grossRate = allFees.map(f => f.rateOfGross ? f.rateOfGross : 0).reduce((a, b) => a+b,0);
                      const totalNetPremium = netPremium + allFees.map(f => f.rateOfNet || !f.rateOfGross ? f.amount : 0).reduce((a,b) => a+b,0);
                      const grossPremium = totalNetPremium / (1-grossRate);

                      allFees.forEach((f, i) => {
                        if (f.rateOfGross) {
                          setValue(`quote.fees.${i}.amount`, Math.round(f.rateOfGross * grossPremium));
                        }
                      });
                      setValue(`${fieldName}.amount`, Math.round(netPremium * parseFloat(e.target.value)));
                    }
                  }
                  onBlur(e);
                }}
                inputRef={ref}
              />
            )}
          />
        ) : (
          <TextData2
            label="Rate of Net"
            data={fee.rateOfNet ? Number(fee.rateOfNet).toLocaleString(undefined,{style: 'percent', minimumFractionDigits: 2}) : ""}
            textProps={{ size: 'small' }} />
        )}
      {adjusting ? (
          <Controller
            name={`${fieldName}.rateOfGross`}
            render={({
              field: { value, name, ref, onChange, onBlur },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField label="Rate of Gross" variant="filled" LabelProps={{ weight: '$bold' }} width={140}
                value={value} placeholder="0" type="number" hideHelperText={error ? false : true}
                error={error} helperText={error ? error.message : null} onChangeValue={v => onChange(v)}
                onBlur={(e, valueError) => {
                  if (parseFloat(e.target.value) && !valueError) {
                    setValue(`${fieldName}.rateOfNet`, null);
                    setValue(`${fieldName}.rateOfGross`, parseFloat(e.target.value));

                    const allFees = getValues('quote.fees');
                    const netPremium = getValues('quote.netPremiumTotal');

                    if (allFees) {
                      allFees.forEach(f => {
                        f.amount = f.rateOfNet ? f.rateOfNet * netPremium : f.amount;
                      });
                      const grossRate = allFees.map(f => f.rateOfGross ? f.rateOfGross : 0).reduce((a, b) => a+b,0);
                      const totalNetPremium = netPremium + allFees.map(f => f.rateOfNet || !f.rateOfGross ? f.amount : 0).reduce((a,b) => a+b,0);
                      const grossPremium = totalNetPremium / (1-grossRate);

                      allFees.forEach((f, i) => {
                        if (f.rateOfGross) {
                          setValue(`quote.fees.${i}.amount`, Math.round(f.rateOfGross * grossPremium));
                        }
                      });
                    }
                  }
                  onBlur(e);
                }}
                inputRef={ref}
              />
            )}
          />
        ) : (
          <TextData2
            label="Rate of Gross"
            data={fee.rateOfGross ? Number(fee.rateOfGross).toLocaleString(undefined,{style: 'percent', minimumFractionDigits: 2}) : ""}
            textProps={{ size: 'small' }} />
        )}
      {adjusting ? (
          <Controller
            name={`${fieldName}.amount`}
            render={({
              field: { value, name, ref, onChange, onBlur },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField label="Amount" variant="filled" LabelProps={{ weight: '$bold' }} width={140}
                value={value} placeholder="0" type="number" hideHelperText={error ? false : true}
                error={error} helperText={error ? error.message : null} onChangeValue={v => onChange(v)}
                onBlur={(e, valueError) => {
                  if (parseFloat(e.target.value) && !valueError) {
                    const thisFee = getValues(fieldName);
                    if (!thisFee.rateOfNet && !thisFee.rateOfGross && thisFee.amount) {
                      const allFees = getValues('quote.fees');
                      const netPremium = getValues('quote.netPremiumTotal');

                      if (allFees) {
                        allFees.forEach(f => {
                          f.amount = f.rateOfNet ? f.rateOfNet * netPremium : f.amount;
                        });
                        const grossRate = allFees.map(f => f.rateOfGross ? f.rateOfGross : 0).reduce((a, b) => a+b,0);
                        const totalNetPremium = netPremium + allFees.map(f => f.rateOfNet || !f.rateOfGross ? f.amount : 0).reduce((a,b) => a+b,0);
                        const grossPremium = totalNetPremium / (1-grossRate);

                        allFees.forEach((f, i) => {
                          if (f.rateOfGross) {
                            setValue(`quote.fees.${i}.amount`, Math.round(f.rateOfGross * grossPremium));
                          }
                        });
                      }
                    }
                  }
                  onBlur(e);
                }}
                inputRef={ref}
              />
            )}
          />
        ) : (
          <TextData2
            label="Amount"
            data={formatMoney(fee.amount, { prefix: '$' })}
            textProps={{ size: 'small' }} />
        )}
      {adjusting ? (
        <Button
          style={{ marginBottom: '1em' }}
          variant="contained"
          color="$primary"
          size="small"
          onPress={() => {
            const allFees = getValues('quote.fees');
            const netPremium = getValues('quote.netPremiumTotal');

            if (allFees) {
              allFees.forEach((f, i) => {
                if (i == index) {
                  f.rateOfNet = null;
                  f.rateOfGross = null;
                  f.amount = null;
                }

                f.amount = f.rateOfNet ? f.rateOfNet * netPremium : f.amount;
              });
              const grossRate = allFees.map(f => f.rateOfGross ? f.rateOfGross : 0).reduce((a, b) => a+b,0);
              const totalNetPremium = netPremium + allFees.map(f => f.rateOfNet || !f.rateOfGross ? f.amount : 0).reduce((a,b) => a+b,0);
              const grossPremium = totalNetPremium / (1-grossRate);

              allFees.forEach((f, i) => {
                if (f.rateOfGross) {
                  setValue(`quote.fees.${i}.amount`, Math.round(f.rateOfGross * grossPremium));
                }
              });
            }

            remove(index);
          }}
        >
          -  Remove Fee
        </Button>
      ) : null}
    </LayoutBox>
  );
};

const AddQuoteDescription = memo(function AddQuoteDescription(props) {
  const { quoteId, currentDescription = "", ...rest } = props;
  const [description, setDescription] = useState('');
  const [open, setOpen] = useState(false);
  const { addDescriptionForQuoteWithId } = useApi();
  const handleAddDescription = useCallback(async (val) => {
    console.log(quoteId, val);
    const result = await addDescriptionForQuoteWithId(quoteId, val);
    console.log(result);
    setOpen(false);
  }, [addDescriptionForQuoteWithId, quoteId]);
  const { execute, status, error } = useAsync(handleAddDescription, { immediate: false });

  useEffect(() => {
    if (open) {
      setDescription(currentDescription || "");
    }
  }, [open, currentDescription]);

  const loading = status === 'pending';
  return (
    <>
      <Button
        label="Add Quote Description"
        size="small"
        onPress={() => setOpen(true)}
        {...rest}
      />
      <Dialog
        open={open}
        heading="Add Quote Description"
        onClose={() => !loading && setOpen(false)}
        actions={(
          <>
            <Button
              label="Cancel"
              variant="text"
              color="$primary"
              onPress={() => setOpen(false)}
              disabled={loading}
            />
            <Button
              label="Submit"
              onPress={() => execute(description)}
              disabled={loading || !description.trim()}
              loading={loading}
              color={!description ? "$gray.500" : "$primary"}
            />
          </>
        )}
      >
        <TextField
          InputProps={{  multiline: true }}
          multiline
          height={200}
          width="100%"
          maxWidth="100%"
          disabled={loading}
          value={description}
          onChangeValue={v => setDescription(v)}
        />
      </Dialog>
    </>
  );
})
