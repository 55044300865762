import React, { useCallback, useEffect, useState } from 'react';
import { useApi, useAsync, useAuth } from '../../hooks';
import { AppLoadingOverlay, Dialog } from '../../components';
import { Spacing, Button, Text } from '../../ui';
import { useEventCallback } from '../../ui/hooks';


export const GenerateQuoteForApplicationButton = ({ applicationId: id, onSuccess }) => {
  const { user } = useAuth();
  const { createQuoteForApplication } = useApi();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const handleCreateQuote = useCallback(async() => {
      try {
        const quote = await createQuoteForApplication({ id });
        return quote;
      } catch(error) {
        console.log('quoting error', error);
        throw "Failed to create quote. Please try again or contact an administrator."
      }
  }, [createQuoteForApplication, id]);
  
  const {
    value: generatedQuote,
    execute: createQuote,
    status,
    error,
    setStatus
  } = useAsync(handleCreateQuote, { immediate: false });

  const handleOnSuccess = useEventCallback((quote) => {
    setStatus('idle');
    if (onSuccess) {
      onSuccess(quote);
    }
  });

  useEffect(() => {
    if (generatedQuote && status === 'success') {
      handleOnSuccess(generatedQuote)
    }
  }, [status, handleOnSuccess, generatedQuote])

  if (!(user.isUnderwriter || user.isPolicyAnalyst) || !id) {
    return null;
  }

  return (
    <>
      <Button onPress={() => setConfirmOpen(true)} label="+  New Quote" />
      <Dialog
        prompt={<Text large>{"Please confirm you would like to generate a quote for this application"}</Text>}
        actions={(
          <>
            <Button variant="text" label="Cancel" color="$primary" onPress={() => setConfirmOpen(false)} />
            <Spacing horizontal={2} />
            <Button
              label="Generate Quote"
              onPress={() => {
                setConfirmOpen(false);
                createQuote();
              }}
            />
          </>
        )}
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
      />
      <Dialog
        heading="Error"
        open={status === 'error'}
        prompt={error ? error : 'There was an error. Please try again or contact an administrator.'}
        onClose={() => setStatus('idle')}
      />
      <AppLoadingOverlay loading={status === 'pending'} />
    </>
  )
}