import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Box, Text, Heading, Button, TextField, Menu, MenuItem, IconButton } from '../../../../ui';
import { CloseIcon } from '../../../../components/icons';
import { withStyles } from '../../../../ui/styling';
import { apiHelpers, formatMoney, getAddressLines } from '../../../../utils';
import { useAppState } from '../../../../hooks';
import { TextData } from '../../../../components';
import { CoverageTypes } from '../../../../constants';
import { useAdjustmentForm } from '../../context/QuoteAdjustment/AdjustmentFormContext';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';

export const QuoteCoverages = withStyles(({ theme }) => ({
  root: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    flex: 1,
    padTop: '$3',
    padBottom: theme.spacing(6),
    marginBottom: '$1',
    border: {
      bottom: { width: 1, color: '$gray.200' }
    }
  },
  coverageList: {
    props: { gap: theme.spacing(4) },
  }
}))((props) => {
  const { quote, productCoverages, styles, ...rest } = props;
  const { locations } = quote;
  return (
    <Box {...rest}>
      <Box style={styles.coverageList} {...styles.props.coverageList}>
        {locations && locations.length ? locations.map((location, i) => (
          <LocationItem location={location} index={i} key={`locationItem-${location.id}`} productCoverages={productCoverages} />
        )) : null}
      </Box>
    </Box>
  )
});


const LocationItem = withStyles(({ theme }) => ({
  root: {
    maxWidth: theme.breakpoints({ sm: 620, md: 790 }),
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: theme.breakpoints({ xs: 'flex-start', sm: 'center' }),
    padBottom: '$3',
    padTop: '$3',
    width: '100%',
    border: {
      top: {
        width: 1,
        color: '$gray.200',
      }
    }
  },
  headerContextItems: {
    flexDirection: theme.breakpoints({ xs: 'column', sm: 'row' }),
    justifyContent: 'flex-start',
    alignItems: theme.breakpoints({ xs: 'flex-start', sm: 'flex-start' }),
    props: { gap: theme.breakpoints({ xs: 0, sm: theme.spacing(2) }) },
  },
  contentContainer: {
    marginLeft: theme.spacing(1),
    padLeft: theme.spacing(2.5),
    width: '100%',
    // border: {
    //   left: {
    //     width: 1,
    //     color: '$gray.200',
    //   }
    // }
  },
  listContainer: {
    width: '100%',
    props: {
      gap: theme.spacing(3),
    }
  },
}))(React.forwardRef(function LocationCoverages(props, ref) {
  const { location, styles, index, productCoverages, ...rest } = props;
  const { coverages, name: locationName, riskId, address, region, postcode, csr, coveredValue, id: locationId  } = location;
  const addressLines = getAddressLines(address, region, postcode);
  const name = `quote.locations.${index}.coverages`;
  const { adjusting } = useAdjustmentState();
  return (
    <Box ref={ref} {...rest}>
      <CoveragesData adjusting={adjusting} name={name} coverages={coverages}>
        {({ coverages, append, prepend, remove }) => {
          let availableCoverageTypes = null;
          if (coverages && coverages.length && productCoverages && productCoverages.length) {
            availableCoverageTypes = [];
            for (const productCoverage of productCoverages) {
              const exists = coverages.find(c => c.coverageType === productCoverage.coverageType);
              if (!exists) {
                availableCoverageTypes.push(productCoverage.coverageType);
              }
            }
          } else {
            availableCoverageTypes = [];
          }

          return (
            <>
              <Box style={styles.header} {...styles.props.header}>
                <Box style={styles.headerContextItems} {...styles.props.headerContextItems}>
                  <Box>
                    <Heading level={4} small>
                      {riskId}. {locationName}
                    </Heading>
                    <Text maxLines={1} small>
                      {addressLines[0]}
                    </Text>
                    <Text maxLines={1} small>
                      {addressLines[1]}
                    </Text>
                  </Box>

                </Box>
                {adjusting && availableCoverageTypes.length ? (
                  <AddCoverageButton availableCoverageTypes={availableCoverageTypes} add={prepend} locationId={locationId} />
                ) : null}
              </Box>
              <Box style={styles.contentContainer} {...styles.props.contentContainer}>
                {coverages.length ? (
                  <Box style={styles.listContainer} {...styles.props.listContainer}>
                    {coverages.map((coverage, i) => {
                      return (
                        <CoverageItem
                          coverage={coverage}
                          index={i}
                          name={name}
                          key={coverage.coverageType}
                          remove={remove}
                          showCSR={coverage.coverageType === CoverageTypes.hail}
                          csr={csr}
                          lastIndex={i === coverages.length - 1}
                          coveredValue={coveredValue}
                        />
                      );
                    })}
                    <CoverageTotalItem
                      netPremium={coverages.reduce((net, cov) => {net += cov.netPremium; return net;}, 0)}
                      key={'total'}
                    />
                  </Box>
                ) : (
                  <Text bold dim>No locations covered</Text>
                )}
              </Box>
            </>
          )
        }}
      </CoveragesData>
    </Box>
  )
}))

const CoveragesData = ({ adjusting, name, coverages: cov, children }) => {
  const form = useAdjustmentForm();
  const coverages = Array.isArray(cov) ? cov : [];
  if (adjusting && form) {
    return <AdjustableCoveragesData name={name} renderChildren={children} />;
  }
  return children({ coverages });
}


const AdjustableCoveragesData = ({ name, renderChildren }) => {
  const { fields, append, prepend, remove } = useFieldArray({ name });
  return renderChildren({ coverages: fields, append, prepend, remove });
}

const AddCoverageButton = ({ availableCoverageTypes, add, locationId }) => {
  const nodeRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button
        variant="contained"
        color="$primary"
        onPress={() => {
          setOpen(true);
        }}
        ref={nodeRef}
      >
        +  Add Coverage
      </Button>
      <Menu
        anchorNode={nodeRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        {availableCoverageTypes.map((coverageType) => {
          return (
            <MenuItem
              key={`${coverageType}-${locationId}-menuitem`}
              onPress={() => {
                setOpen(false);
                add({ coverageType, locationId, deductible: 0, netPremium: 0, payoutLimit: 0 });
              }}
            >
              {apiHelpers.resolveCoverageTypeName(coverageType)}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

const CoverageItem = withStyles(({ theme, lastIndex }) => {
  const leftPadding = theme.spacing(3);
  return {
    root: {
      flexDirection: theme.breakpoints({ sm: 'column', md: 'row' }),
      flex: 1,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padBottom: theme.spacing(4),
    },
    seperator: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '100%',
      height: 1,
      justifyContent: 'flex-end',
      alignItems: 'stretch',
      padLeft: leftPadding,
    },
    hairline: {
      display: lastIndex ? 'none' : 'flex',
      height: 1,
      maxHeight: 1,
      flex: 1,
      backgroundColor: theme.colors.gray[200],
    },
    headerCell: {
      flexGrow: theme.breakpoints({ sm: 0, md: 1.5 }),
      flexShrink: theme.breakpoints({ sm: 0, md: 1 }),
      flexBasis: theme.breakpoints({ sm: 'auto', md: '0%' }),
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padRight: theme.spacing(1.5),
      padBottom: theme.breakpoints({ sm: theme.spacing(1.5), md: 0 }),
    },
    number: {
      width: leftPadding,
      maxWidth: leftPadding,
    },
    heading: {
      props: {
        // weight: '$regular',
        level: 5,
        maxLines: 2,
        size: 'small',
      },
    },
    subheading: {
      props: { maxLines: 1, size: 'small' }
    },
    contentRow: {
      padLeft: theme.breakpoints({ sm: leftPadding, md: 0 }),
      flex: theme.breakpoints({ sm: 1, md: 3 }),
      width: theme.breakpoints({ sm: '100%', md: 'auto' }),
      flexDirection: theme.breakpoints({ xs: 'column', sm: 'row' }),
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    adjustableItems: {
      flex: theme.breakpoints({ xs: 1, sm: 2 }),
      width: theme.breakpoints({ xs: '100%', sm: 'auto' }),
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padTop: theme.breakpoints({ xs: theme.spacing(1.5), sm: 0 }),
      flexWrap: theme.breakpoints({ xs: 'wrap', sm: 'nowrap' })
    },
    contentCell: {
      flex: 1,
      minWidth: 140,
    },
    contentCellSmall: {
      flex: theme.breakpoints({ xs: 0, sm: 0.6, md: 0.8 }),
      flexBasis: theme.breakpoints({ xs: 'auto', sm: '0%' }),
    },
    inputAdjustment: {
      marginTop: theme.spacing(-1),
      marginLeft: theme.breakpoints({ xs: theme.spacing(-2), sm: 0 })
    },
    removeButton: {
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(-3),
      props: {
        color: '$coral',
      },
    },
  };
})((props) => {
  const { coverage, coveredValue, index, name: fieldName, remove, showCSR, csr, styles, lastIndex, ...rest } = props;
  const { coverageType, payoutLimit, deductible, aggregate, netPremium, isIncluded } = coverage;
  const { adjusting } = useAdjustmentState();
  const { setValue } = useAdjustmentForm();
  const [{ showCoveragePremium }] = useAppState();
  return (
    <RenderControllerIfAdjusting
      adjusting={adjusting}
      name={`${fieldName}.${index}`}
      defaultValue={coverage}
      render={({
        field: { ref },
      }) => (
        <Box
          ref={ref}
          {...rest}
        >
          {/* {adjusting ? (
            <Controller
              name={`${fieldName}.${index}.coverageType`}
              render={({
                field: { value, name, ref, onChange, onBlur },
              }) => (
                <TextField
                  value={value}
                  inputRef={ref}
                  disabled
                  readOnly
                />
              )}
            />
          ) : null} */}
          <Box style={styles.headerCell} {...styles.props.headerCell}>
            <Box style={styles.number} {...styles.props.number}>
              {adjusting ? (
                <IconButton
                  onPress={() => remove(index)}
                  style={styles.removeButton}
                  {...styles.props.removeButton}
                ><CloseIcon size="20" /></IconButton>
              ) : null}

            </Box>
            <Box flex={1}>
              <Heading style={styles.heading} {...styles.props.heading}>
                {apiHelpers.resolveCoverageTypeName(coverageType)}
              </Heading>
              {showCSR && csr && csr.id ? (
                <Text style={styles.subheading} {...styles.props.subheading}>
                  {`CSR - ${csr.id}`}
                </Text>
              ) : null}
            </Box>
          </Box>

          <Box style={styles.contentRow} {...styles.props.contentRow}>
            {
              showCoveragePremium ? (
                <Box style={styles.contentCellSmall} {...styles.props.contentCellSmall}>
                  <TextData
                    label="Premium"
                    data={isIncluded ? 'Incl.' : formatMoney(netPremium, { prefix: '$' })}
                    invert
                  />
                </Box>
              ) : null
            }

            <Box style={[styles.adjustableItems, adjusting ? styles.inputAdjustment : null]} {...styles.props.adjustableItems}>
              <Box style={styles.contentCell} {...styles.props.contentCell}>
                {adjusting ? (
                  <Controller
                    name={`${fieldName}.${index}.deductible`}
                    rules={{ required: 'Required' }}
                    render={({
                      field: { value, name, ref, onChange, onBlur },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <TextField
                        label="DEDUCTIBLE"
                        variant="filled"
                        LabelProps={{ weight: '$bold' }}
                        width={140}
                        value={value}
                        placeholder="0"
                        type="number"
                        hideHelperText={error ? false : true}
                        error={error}
                        helperText={error ? error.message : null}
                        onChangeValue={v => onChange(v)}
                        onBlur={(e, valueError) => {
                          if (valueError && valueError.fixed) {
                            setValue(`${fieldName}.${index}.deductible`, valueError.fixed, { shouldValidate: true });
                          } else if (value === null) {
                            setValue(`${fieldName}.${index}.deductible`, 0, { shouldValidate: true });
                          }
                          onBlur(e);
                        }}
                        inputRef={ref}
                        readOnly={isIncluded}
                        disabled={isIncluded}
                      />
                    )}
                  />
                ) : (
                  <TextData
                    label="Deductible"
                    data={formatMoney(deductible, { prefix: '$' })}
                    invert
                  />
                )}
              </Box>
              <Box style={styles.contentCell} {...styles.props.contentCell}>
                {adjusting ? (
                  <Controller
                    name={`${fieldName}.${index}.aggregate`}
                    rules={{ required: 'Required' }}
                    render={({
                      field: { value, name, ref, onChange, onBlur },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <TextField
                        label="AGGREGATE"
                        variant="filled"
                        LabelProps={{ weight: '$bold' }}
                        width={140}
                        value={value}
                        placeholder="0"
                        type="number"
                        hideHelperText={error ? false : true}
                        error={error}
                        helperText={error ? error.message : null}
                        onChangeValue={v => onChange(v)}
                        onBlur={(e, valueError) => {
                          if (valueError && valueError.fixed) {
                            setValue(`${fieldName}.${index}.aggregate`, valueError.fixed, { shouldValidate: true });
                          } else if (value === null) {
                            setValue(`${fieldName}.${index}.aggregate`, 0, { shouldValidate: true });
                          }
                          onBlur(e);
                        }}
                        inputRef={ref}
                        readOnly={isIncluded}
                        disabled={isIncluded}
                      />
                    )}
                  />
                ) : (
                  <TextData
                    label="Aggregate"
                    data={formatMoney(aggregate, { prefix: '$' })}
                    invert
                  />
                )}
              </Box>
              <Box style={styles.contentCell} {...styles.props.contentCell}>
                {adjusting ? (
                  <Controller
                    name={`${fieldName}.${index}.payoutLimit`}
                    rules={{
                      required: 'Required',
                      ...(coveredValue && typeof coveredValue === 'number' && coveredValue > 0
                        ? {
                            max: {
                              value: coveredValue,
                              message: 'Must not exceed the covered value at this location.'
                            }
                          }
                        : null)
                    }}
                    render={({
                      field: { value, name, ref, onChange, onBlur },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <TextField
                        label="PAYOUT LIMIT"
                        variant="filled"
                        LabelProps={{ weight: '$bold' }}
                        width={140}
                        value={value}
                        placeholder="0"
                        type="number"
                        hideHelperText={error ? false : true}
                        error={error}
                        helperText={error ? error.message : null}
                        onChangeValue={v => onChange(v)}
                        onBlur={(e, valueError) => {
                          if (valueError && valueError.fixed) {
                            setValue(`${fieldName}.${index}.payoutLimit`, valueError.fixed, { shouldValidate: true });
                          } else if (value === null) {
                            setValue(`${fieldName}.${index}.payoutLimit`, 0, { shouldValidate: true });
                          }
                          onBlur(e);
                        }}
                        inputRef={ref}
                      />
                    )}
                  />
                ) : (
                  <TextData
                    label="Payout Limit"
                    data={formatMoney(payoutLimit, { prefix: '$' })}
                    invert
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box style={styles.seperator}>
            <Box style={styles.hairline} />
          </Box>
        </Box>
      )}
    />
  )
})

const CoverageTotalItem = withStyles(({ theme, lastIndex }) => {
  const leftPadding = theme.spacing(3);
  return {
    root: {
      flexDirection: theme.breakpoints({ sm: 'column', md: 'row' }),
      flex: 1,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padBottom: theme.spacing(4),
    },
    headerCell: {
      flexGrow: theme.breakpoints({ sm: 0, md: 1.5 }),
      flexShrink: theme.breakpoints({ sm: 0, md: 1 }),
      flexBasis: theme.breakpoints({ sm: 'auto', md: '0%' }),
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padRight: theme.spacing(1.5),
      padBottom: theme.breakpoints({ sm: theme.spacing(1.5), md: 0 }),
    },
    number: {
      width: leftPadding,
      maxWidth: leftPadding,
    },
    heading: {
      props: {
        // weight: '$regular',
        level: 5,
        maxLines: 2,
        size: 'small',
      },
    },
    contentRow: {
      padLeft: theme.breakpoints({ sm: leftPadding, md: 0 }),
      flex: theme.breakpoints({ sm: 1, md: 3 }),
      width: theme.breakpoints({ sm: '100%', md: 'auto' }),
      flexDirection: theme.breakpoints({ xs: 'column', sm: 'row' }),
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  };
})((props) => {
  const { netPremium, styles, lastIndex, ...rest } = props;
  const { adjusting } = useAdjustmentState();
  const { setValue } = useAdjustmentForm();
  return (
    <RenderControllerIfAdjusting
      adjusting={adjusting}
      name='total'
      render={({
        field: { ref },
      }) => (
        <Box
          ref={ref}
          {...rest}
        >
          <Box style={styles.headerCell} {...styles.props.headerCell}>
            <Box style={styles.number} {...styles.props.number}/>
            <Box flex={1}>
              <Heading style={styles.heading} {...styles.props.heading}>
                Total
              </Heading>
            </Box>
          </Box>

          <Box style={styles.contentRow} {...styles.props.contentRow}>
            <TextData
              label="Premium"
              data={formatMoney(netPremium, { prefix: '$'})}
              invert
            />
          </Box>
        </Box>
      )}
    />
  )
})
const RenderControllerIfAdjusting = ({ adjusting, render, ...controllerProps }) => {
  if (adjusting) {
    return (
      <Controller
        {...controllerProps}
        render={render}
      />
    )
  }
  return render({ field: { ref: null }});
}
