/* eslint-disable import/prefer-default-export */
import {
  Dimensions,
  StatusBar,
  Platform,
} from 'react-native';

// See https://mydevice.io/devices/ for device dimensions
const X_WIDTH = 375;
const X_HEIGHT = 812;
const XSMAX_WIDTH = 414;
const XSMAX_HEIGHT = 896;
const PAD_WIDTH = 768;
// const PADs_HEIGHT = 1024;
const IPADPRO11_WIDTH = 834;
const IPADPRO11_HEIGHT = 1194;
const IPADPRO129_HEIGHT = 1024;
const IPADPRO129_WIDTH = 1366;

export const isIPhoneX = (dimensions) => {
  if (Platform.OS === 'web') return false;
  const deviceDimensions = dimensions || Dimensions.get('window');
  const { width: deviceWidth, height: deviceHeight } = deviceDimensions;

  return (
    (
      Platform.OS === 'ios' && (
        (deviceHeight === X_HEIGHT && deviceWidth === X_WIDTH)
        || (deviceHeight === X_WIDTH && deviceWidth === X_HEIGHT)
      )
    ) || (
      (deviceHeight === XSMAX_HEIGHT && deviceWidth === XSMAX_WIDTH)
      || (deviceHeight === XSMAX_WIDTH && deviceWidth === XSMAX_HEIGHT)
    )
  );
};

export const isNewIPadPro = (dimensions) => {
  if (Platform.OS !== 'ios') return false;
  const deviceDimensions = dimensions || Dimensions.get('window');
  const { width: deviceWidth, height: deviceHeight } = deviceDimensions;

  return (
    (deviceHeight === IPADPRO11_HEIGHT && deviceWidth === IPADPRO11_WIDTH)
    || (deviceHeight === IPADPRO11_WIDTH && deviceWidth === IPADPRO11_HEIGHT)
    || (
      (deviceHeight === IPADPRO129_HEIGHT && deviceWidth === IPADPRO129_WIDTH)
      || (deviceHeight === IPADPRO129_WIDTH && deviceWidth === IPADPRO129_HEIGHT)
    )
  );
};

export const isIPad = (dimensions) => {
  if (Platform.OS !== 'ios' || isIPhoneX(dimensions)) return false;

  const deviceDimensions = dimensions || Dimensions.get('window');
  const { width: deviceWidth, height: deviceHeight } = deviceDimensions;

  // if portrait and width is smaller than iPad width
  if (deviceHeight > deviceWidth && deviceWidth < PAD_WIDTH) {
    return false;
  }

  // if landscape and height is smaller that iPad height
  if (deviceWidth > deviceHeight && deviceHeight < PAD_WIDTH) {
    return false;
  }

  return true;
};

export const getStatusBarHeight = (isLandscape = false, dimensions) => {
  /**
   * This is a temporary workaround because we don't have a way to detect
   * if the status bar is translucent or opaque. If opaque, we don't need to
   * factor in the height here; if translucent (content renders under it) then
   * we do.
   */
  if (Platform.OS === 'web') {
    return 0;
  }

  if (Platform.OS === 'android') {
    return StatusBar.currentHeight;
  }

  const deviceDimensions = dimensions || Dimensions.get('window');

  if (isIPhoneX(deviceDimensions)) {
    return isLandscape ? 0 : 44;
  }

  if (isNewIPadPro(deviceDimensions)) {
    return 24;
  }

  if (isIPad(deviceDimensions)) {
    return 20;
  }

  return isLandscape ? 0 : 20;
};

function isOrientationLandscape(dimensions) {
  const d = dimensions || Dimensions.get('window');
  return d.width > d.height;
}

const cachedInsets = {};
export const getDeviceInsets = (landscape = null, dimensions) => {
  const deviceDimensions = dimensions || Dimensions.get('window');
  const isLandscape = landscape !== null || isOrientationLandscape(deviceDimensions);
  const key = `${deviceDimensions.width}${deviceDimensions.height}${isLandscape}`;
  if (cachedInsets[key]) {
    return cachedInsets[key];
  }
  const left = isLandscape && isIPhoneX(deviceDimensions) ? 44 : 0;
  const top = getStatusBarHeight(isLandscape, deviceDimensions);
  let bottom = 0;
  if (isIPhoneX(deviceDimensions)) {
    if (isLandscape) {
      bottom = 24;
    } else {
      bottom = 34;
    }
  } else if (isNewIPadPro(deviceDimensions)) {
    bottom = 20;
  }
  const insets = {
    left,
    top,
    bottom,
    right: 0,
  };
  cachedInsets[key] = insets;
  return insets;
};

const isBrowser = Platform.OS === 'web' && typeof window !== `undefined`;

export function isWindowOverflowing() {
  if (!isBrowser) return false;

  return window.innerWidth > document.documentElement.clientWidth;
}

export function getScrollbarWidth() {
  const scrollDiv = document.createElement('div');
  scrollDiv.style.width = '99px';
  scrollDiv.style.height = '99px';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  scrollDiv.style.overflow = 'scroll';

  document.body.appendChild(scrollDiv);
  const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);

  return scrollbarSize;
}
