import React, { useState, useContext, useEffect } from 'react';
import { useMemoCompare } from '../ui/hooks';
import { omit } from '../ui/utils';

const AppStateContext = React.createContext([{}, () => null]);

const useAppState = () => useContext(AppStateContext);



const ProvideAppState = (props) => {
  const value = useMemoCompare(omit(props, ['children']));
  const [parentState] = useAppState();
  const [appState, setAppState] = useState({ ...parentState, ...value });

  useEffect(() => {
    setAppState(current => ({ ...current, ...parentState, ...value }));
  }, [value, parentState]);

  const memoizedState = useMemoCompare([appState, setAppState]);

  return (
    <AppStateContext.Provider value={memoizedState}>
      {props.children}
    </AppStateContext.Provider>
  );
}

export {
  AppStateContext,
  useAppState,
  ProvideAppState,
}
