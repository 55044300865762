import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { ContentCard, LayoutBox } from '../../../../components';
import { Button, Spacing, Text } from '../../../../ui';
import { QuoteDetails } from './QuoteDetails';


export const QuoteView = ({ data, productCoverages, macavity, concentrations }) => {
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  return (
    <ContentCard
      mt="$8"
      padTop="$2.5"
      padBottom="$3.5"
      bg="$white"
      alignItems="stretch"
    >
      <LayoutBox row layout="center-left" justifyContent="space-between">
        <Text small bold dim>{`Quote No. ${data.quoteNumber}`}</Text>
        <Button
          label="Print Quote"
          onPress={handlePrint}
          variant="text"
          color="$primary"
        />
      </LayoutBox>
      
      <Spacing vertical={1} />
      <QuoteDetails ref={printRef} quote={data} productCoverages={productCoverages} macavity={macavity} concentrations={concentrations} />
    </ContentCard>
  );
}


