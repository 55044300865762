import React, { useCallback, useState, useMemo } from 'react';
import { ActivityIndicator, Box, Text, ScrollView } from '../../../ui';
import { PageContent, Main, LayoutBox } from '../../../components';
import { useApi, useAsync, useNavigate } from '../../../hooks';
import { QuoteList } from '../../QuotesListView';
import { GenerateQuoteForApplicationButton } from '../GenerateQuoteForApplicationButton';
import { ViewTabs } from '../../../components/ViewTabs';
import { ApplicationDetails } from './ApplicationDetails';
import { ApplicationDocuments } from './ApplicationDocuments';
import { ApplicationLossHistory } from './ApplicationLossHistory';

export const ApplicationViewTabs = {
  APPLICATION: 'APPLICATION',
  QUOTES: 'QUOTES',
  DOCUMENTS: 'DOCUMENTS',
  LOSS_HISTORY: 'LOSS_HISTORY',
}

export const ApplicationView = React.forwardRef(function ApplicationView(props, ref) {
  const {
    data = undefined,
    status: externalStatus,
    quoteId,
    applicationId,
    initialTab,
    children,
    scrollView = false,
    enableControls = false, // true when rendered from the actual application page
    // ^^ false for instances like viewing the application as just a modal in the quote view and want to keep editing limitedt o the application page itself
    ...rest
  } = props;
  const [viewTab, setViewTab] = useState(initialTab || ApplicationViewTabs.APPLICATION);
  const navigate = useNavigate();
  const { getApplicationWithId, getApplicationWithQuoteId } = useApi();
  const getApplication = useCallback(async (refresh = false) => {
    if (refresh !== true && data !== undefined) {
      return data;
    }
    if (quoteId) {
      return await getApplicationWithQuoteId(quoteId);
    } else if (applicationId) {
      return await getApplicationWithId(applicationId);
    } else if (data !== undefined) {
      return await getApplicationWithId(data.id);
    }
  }, [data, quoteId, applicationId]);
  const { value: application, status: localStatus, execute: updateApplication } = useAsync(getApplication);
  const status = data !== undefined && externalStatus ? externalStatus : localStatus;

  const containerProps = {
    bg: 'white',
    ref,
    ...(status === 'pending' || !application ? { justifyContent: 'center', alignItems: 'center' } : null),
    padTop: '$3',
    ...rest
  };
  const contents = (
    <Main pointerEvents="box-none" padBottom={164}>
        {(application && application.id) ? (
          <LayoutBox
            padX="$3"
            pb="$1.5"
            flexDirection={({ theme }) => theme.breakpoints({ xs: 'column-reverse', sm: 'row' })}
            layout="bottom-left"
            justifyContent={enableControls ? 'space-between' : undefined}
            width="100%"
            gap={8}
          >
            {enableControls ? (
              <GenerateQuoteForApplicationButton
                applicationId={application.id}
                onSuccess={({ id: quoteId } = {}) => {
                  if (quoteId) {
                    navigate.to(`/quotes/${quoteId}`)
                  }
                }}
              />
            ) : null}
          </LayoutBox>
        ) : null}
        <PageContent {...containerProps}>
          {
            status === 'pending' ?
              <ActivityIndicator />
            : !application && status === 'error' ?
              <Text>Error: Could not retrieve application data</Text>
            : !application ?
              <Text>No application data available</Text>
            : (
              <>
                <ApplicationTabs data={application} initialTab={initialTab} onTabChanged={setViewTab} />
                <ApplicationTabView tab={viewTab} data={application} status={status} scrollNode update={updateApplication} enableControls={enableControls} />
              </>
            )
          }
        </PageContent>
      </Main>
  );
  if (!scrollView) {
    return contents;
  }
  return (
    <ScrollView
      width="100%"
      flex={1}
      minHeight="101%"
      padTop="100"
      containerStyle={{ minHeight: '101%' }}
      provideNode
      {...rest}
    >
      {contents}
      {children}
    </ScrollView>
  )
});

const applicationTabsKeys = Object.keys(ApplicationViewTabs);
const ApplicationTabs = ({ data, initialTab = ApplicationViewTabs.APPLICATION, ...props }) => {
  const numQuotes = useMemo(() => {
    if (data) {
      if (data.quotes && Array.isArray(data.quotes) && data.quotes.length) {
        return data.quotes.length;
      }
    }
    return 0;
  }, [data]);

  const getLabel = useCallback((name, label) => {
    if (name === ApplicationViewTabs.APPLICATION) {
      return 'Application Details';
    }
    if (name === ApplicationViewTabs.QUOTES) {
      return `Quotes (${numQuotes})`;
    }
    if (name === ApplicationViewTabs.DOCUMENTS) {
      return `Documents`;
    }
    if (name === ApplicationViewTabs.LOSS_HISTORY) {
      return `Loss History`;
    }
    return label;
  }, [numQuotes]);

  return (
    <Box width="100%" alignItems="flex-start">
      <ViewTabs
        initialTab={initialTab}
        getLabel={getLabel}
        tabs={applicationTabsKeys}
        {...props}
      />
    </Box>
  )
}

const ApplicationTabView = ({ tab, data, status, scrollNode, update, enableControls }) => {
  switch(tab) {
    case ApplicationViewTabs.APPLICATION:
      return <ApplicationDetails data={data} refreshApplicationData={update} enableControls={enableControls} refreshing={status === 'pending'} />;
    case ApplicationViewTabs.QUOTES:
      return <ApplicationQuotes quotes={data ? data.quotes : []} status={status} scrollNode={scrollNode} />;
    case ApplicationViewTabs.DOCUMENTS:
      return <ApplicationDocuments data={data} update={update} />;
    case ApplicationViewTabs.LOSS_HISTORY:
      return <ApplicationLossHistory application={data} refreshApplicationData={update} enableControls={enableControls} refreshing={status === 'pending'} />
    default:
      return <ApplicationDetails data={data} />;
  }
}

const ApplicationQuotes = React.forwardRef(({ quotes, status, scrollNode }, ref) => {
  return (
    <QuoteList
      ref={ref}
      data={quotes && Array.isArray(quotes) && quotes.length ? quotes : []}
      loading={status === 'pending'}
      scrollNode
    />
  )
});
