import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isObject } from '../ui/utils';

const NavigateContext = React.createContext({ to: () => null, back: () => null, canGoBack: false, location: { current: null } });

const navActionTypes = {
  push: 'PUSH',
  replace: 'REPLACE',
  back: 'BACK',
}

const ProvideNavigateContext = ({ children, logLocation = false }) => {
  const history = useHistory();
  const location = useLocation();
  if (logLocation) {
    console.log('location', location);
  }
  
  const pathHistory = useRef([]);
  const navAction = useRef(null);
  const currentLocation = useRef(location ? location : null);

  useEffect(() => {
    if (location && location.pathname) {
      if (currentLocation.current && currentLocation.current.pathname !== location.pathname) {
        if (pathHistory.current.length) {
          if (
            navAction.current !== navActionTypes.back &&
            location.pathname !== pathHistory.current[pathHistory.current.length - 1]
          ) {
            pathHistory.current.push(currentLocation.current.pathname);
          } else if (!navAction.current) {
            pathHistory.current.pop();
          }
        } else {
          pathHistory.current.push(currentLocation.current.pathname);
        }
      }
      currentLocation.current = { ...location };
      navAction.current = null;
    }
    if (pathHistory.current.length >= 100) {
      pathHistory.current = pathHistory.current.slice(50);
    }
  }, [location]);
  
  const canGoBack = useMemo(() => {
    if (!pathHistory.current.length) {
      return false;
    }
    return true;
  }, [location]);

  const navigate = useMemo(() => {
    const to = (pathnameOrLocation) => {
      let next = null;
      let replace = false;
      if (typeof pathnameOrLocation === 'string') {
        next = { pathname: pathnameOrLocation };
      } else if (isObject(pathnameOrLocation) && pathnameOrLocation.pathname) {
        const { replace: r, ...rest } = pathnameOrLocation;
        next = rest;
        replace = r;
      } else {
        return;
      }
      if (replace) {
        navAction.current = navActionTypes.replace;
        history.replace(next)
      } else {
        navAction.current = navActionTypes.push;
        history.push(next);
      }
    }
    const back = (pathnameOrLocation) => {
      const backProps = isObject(pathnameOrLocation) ? pathnameOrLocation : typeof pathnameOrLocation === 'string' ? { pathname: pathnameOrLocation } : null;
      if (backProps && backProps.pathname) {
        const { exact = true, replace = false, ...backLocation } = backProps;
        if (
          !pathHistory.current.length ||
          (exact && pathHistory.current[pathHistory.current.length - 1] !== backLocation.pathname) ||
          (!exact && !pathHistory.current[pathHistory.current.length - 1].startsWith(backLocation.pathname))
        ) {
          navAction.current = navActionTypes.back;
          if (replace) {
            history.replace(backLocation);
          } else {
            history.push(backLocation);
          }
          
          return;
        }
      }
      history.goBack();
    }
    const search = (queryString, replace = true) => {
      to({ pathname: `${currentLocation.current.pathname}`, search: queryString ? `?${queryString}` : "", replace });
    }
    return { to, back, canGoBack, search, location: currentLocation };
  }, [history, canGoBack]);

  return (
    <NavigateContext.Provider value={navigate}>
      {children}
    </NavigateContext.Provider>
  );
}

function useNavigate() {
  return useContext(NavigateContext);
}

export { ProvideNavigateContext, useNavigate };