import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { useApi, useAsync } from '../../hooks';
import { ApplicationView } from './ApplicationView';
import { Page, NavRow, NavGroup, NavBackButton, NavBar, AppBarSpacer } from '../../components';
import { Spacing } from '../../ui';

const ApplicationPage = () => {
  const { id } = useParams();
  const { getApplicationWithId } = useApi();
  const getApplicationData = useCallback(async () => {
    return await getApplicationWithId(id);
  }, [id, getApplicationWithId]);
  const { value: data, status, lastUpdated } = useAsync(getApplicationData);

  return (
    <Page layout={!data && status === 'pending' ? 'center' : 'top-center'} padTop={({ theme: { sizes, breakpoints } }) => breakpoints({ xs: 0, sm: sizes.appBarHeight }) }>
      <Nav data={data} status={status} lastUpdated={lastUpdated} />
      <Spacing vertical={3} />
      <ApplicationView data={data} status={status} applicationId={id} enableControls />
    </Page>
  )
}

const Nav = ({ data, status, lastUpdated }) => {
  return (
    <NavBar>
      <AppBarSpacer />
      <NavRow padTop="$1" maxWidth="$mainWidth" marginX="auto">
        <NavGroup start>
          <NavBackButton path="/applications" label="Back to Applications" />
        </NavGroup>
      </NavRow>
    </NavBar>
  )
}

export { ApplicationPage }