import React from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { withStyles } from '../../styling';

const stepIconBorderWidth = 3;
const stepIconWidth = 32 - stepIconBorderWidth * 2;

const StepIcon = withStyles(({
  theme,
  color: colorProp,
  activeColor,
  completedColor,
  completed,
  active,
  error,
  disabled,
}) => {
  const color = colorProp ? colorProp : completed ? completedColor : active ? activeColor : disabled ? '$gray.300' : '$gray.500';

  return {
    root: {
      size: stepIconWidth,
      borderRadius: theme.radii.circle,
      backgroundColor: completed ? color : 'transparent',
      borderColor: color,
      borderWidth: stepIconBorderWidth,
      marginTop: theme.spacing(0.25),
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      fontWeight: 600,
      props: {
        color: completed ? theme.colors.on(color) : color,
        size: 'xSmall',
        align: 'center',
      },
    },
    active: {},
    completed: {},
    error: {},
    disabled: {},
  };
}, { preserveStyleProp: true })(React.forwardRef(function StepIcon(props, ref) {
  const {
    icon: iconProp,
    color,
    activeColor,
    completedColor,
    completed,
    active,
    error,
    disabled,
    style,
    styles,
    ...rest
  } = props;

  const icon = typeof iconProp === 'function' ? iconProp(props) : iconProp;
  return (
    <Box
      style={[
        styles.root,
        completed ? styles.completed : null,
        active ? styles.active : null,
        error ? styles.error : null,
        disabled ? styles.disabled : null,
        style,
      ]}
      {...(completed ? styles.props.completed : null)}
      {...(active ? styles.props.active : null)}
      {...(error ? styles.props.error : null)}
      {...(disabled ? styles.props.disabled : null)}
      ref={ref}
      {...rest}
    >
      {typeof icon === 'number' || typeof icon === 'string' ? (
        <Text style={styles.text} {...styles.props.text}>
          {icon}
        </Text>
      ) : icon}
    </Box>
  )
}))

StepIcon.width = stepIconWidth;

export { StepIcon }
