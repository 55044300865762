import { capitalize } from "../ui/utils";
export const HOURS_QUOTE_VALID_FOR = 60 * 24;
export const ASYNC_API_CACHE_KEY_PREFIX = '@dunvegan:api:cache';
export const ASYNC_SETTINGS_KEY_PREFIX = '@dunvegan:settings';
export const ASYNC_USER_CACHE_KEY_PREFIX = '@dunvegan:user:cache';
export const AUTHORIZATION_LOCAL_STORAGE_KEY = `oidc.user:${process.env.REACT_APP_KALIDASA_BASE_URI}:${process.env.REACT_APP_OPENQUOTE_CLIENT_ID}`

/*
TODO
combined the utils folder and constants folder into "helpers" folder.
most of the constants should be relevant to a utility function anyway.
*/


export const CoverageNames = {
  collision: 'Collision',
  hail: 'Windstorm and Hail',
  wind: 'Wind',
  falsePretense: 'False Pretense',
  comprehensive: 'Comprehensive',
  terrorism: 'Certified Acts of Terrorism',
  earthquake: 'Earthquake',
  flood: 'Flood',
};

export const CoverageTypes = {
  collision: 'collision',
  hail: 'hail',
  falsePretense: 'falsePretense',
  comprehensive: 'comprehensive',
  terrorism: 'terrorism',
  earthquake: 'earthquake',
  flood: 'flood',
}

export const CoverageTypeSubTypes = {
  comprehensive: {
    fire: 'fire',
    theft: 'theft',
    vandalism: 'vandalism',
  }
}

export const QuoteStatuses = {
  pending: 'pending',
  released: 'released',
  prebound: 'prebound',
  bound: 'bound',
  rejected: 'rejected',
  expired: 'expired',
};



export const UserRoles = {
  admin: 'admin',
  broker: 'dunvegan:broker',
  underwriter: 'dunvegan:underwriter',
  internal: 'dunvegan:internal',
  ops: 'dunvegan:internal', // <- TODO: verify ops user role...is it admin?
  policyAnalyst: 'dunvegan:policy_analyst'
}

export const ProductNames = {
  ahs: 'Auto Hail Safe',
  orca: 'Dealer Captive Open Lot',
}

export const ProductTypes = {
  auto: 'Auto',
  ag: 'Agriculture'
}

export const QUOTE_REQUEST = 'request';
export const QUOTE_ADJUSTMENT = 'adjustment';

export const MessageTypes = {
  request: 'request',
  adjustment: 'adjustment',
}

export const MessageTypeName = {
  request: 'Request',
  adjustment: 'Adjustment',
}

export const MessageTypeUserRole = {
  request: 'Broker',
  adjustment: 'Underwriter',
}

export const Colors = {
  quoteStatus: {
    pending: '$brandOrange',
    released: '$sky.light',
    prebound: '$coral.main',
    bound: '$secondary',
    rejected: '$gray.600',
    expired: '$gray.500',
  }
}
export const quoteStatusOptions = {}
for (const key in QuoteStatuses) {
  quoteStatusOptions[key] = {
    name: QuoteStatuses[key],
    value: QuoteStatuses[key],
    color: Colors.quoteStatus[key] ? Colors.quoteStatus[key] : '$gray.200',
    label: capitalize(QuoteStatuses[key])
  }
}
export const BindingFormTypes = {
  siteChecklist: 'site-checklist',
  leaseAgreement: 'lease-agreement',
}

export const bindFormTypeOptions = {
  'site-checklist': {
    value: 'site-checklist',
    name: 'site-checklist',
    label: 'Site Checklist',
  },
  'lease-agreement': {
    value: 'lease-agreement',
    name: 'lease-agreement',
    label: 'Lease Agreement',
  }
};

export const ClaimStatusTypes = {
  open: 'open',
  adjusting: 'adjusting',
  denied: 'denied',
  accepted: 'accepted',
};

export const claimStatusOptions = {};
for (const key in ClaimStatusTypes) {
  claimStatusOptions[key] = {
    name: ClaimStatusTypes[key],
    value: ClaimStatusTypes[key],
    label: capitalize(ClaimStatusTypes[key]),
  }
};

export const ListDisplayTypes = {
  TABLE: 'table',
  CARDS: 'card',
}

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export const sortByOptions = {
  ASC: {
    name: SORT_ASC,
    label: 'Ascending',
  },
  DESC: {
    name: SORT_DESC,
    label: 'Descending',
  }
}

export const filterOperators = {
  equals: "equals",
  lte: 'lte',
  lt: 'lt',
  gte: 'gte',
  gt: 'gt',
  like: 'like',
  ilike: 'ilike',
  notequals: 'notequals',
};

export const applicationSortOptions = {
  insured: {
    name: 'insured',
    label: 'Insured Name',
  },
  address: {
    name: 'address',
    label: 'Address',
  },
  region: {
    name: 'region',
    label: 'Region',
  },
  country: {
    name: 'country',
    label: 'Country',
  },
  applied_at: {
    name: 'applied_at',
    label: 'Applied At',
  },
}

export const applicationFilterOptions = {
  broker_id: {
    name: 'broker_id',
    label: 'Broker ID',
  },
  quote_id: {
    name: 'quote_id',
    label: 'Quote ID',
  },
  insured: {
    name: 'insured',
    label: 'Insured',
  },
  product_name: {
    name: 'product_name',
    label: 'Product',
  },
  agent_id: {
    name: 'agent_id',
    label: 'Agent ID',
  },
  underwriter_id: {
    name: 'underwriter_id',
    label: 'Underwriter ID',
  },
  application_id: {
    name: 'application_id',
    label: 'Application ID',
  },
  agent_name: {
    name: 'agent_name',
    label: 'Agent Name',
  },
};

export const quoteSortOptions = {
  prebound_at: {
    name: 'prebound_at',
    label: 'Prebound At',
  },
  insured: {
    name: 'insured',
    label: 'Insured',
  },
  status: {
    name: 'status',
    label: 'Status',
  },
  bind_target_at: {
    name: 'bind_target_at',
    label: 'Bind Target At',
  },
  coverage_at: {
    name: 'coverage_at',
    label: 'Coverage At',
  },
  coverage_until: {
    name: 'coverage_until',
    label: 'Coverage Until',
  },
  last_change_at: {
    name: 'last_change_at',
    label: 'Last Change At',
  }
};

export const quoteFilterOptions = {
  broker_id: {
    name: 'broker_id',
    label: 'Broker ID',
  },
  quote_id: {
    name: 'quote_id',
    label: 'Quote ID',
  },
  insured: {
    name: 'insured',
    label: 'Insured',
  },
  product_name: {
    name: 'product_name',
    label: 'Product',
  },
  agent_id: {
    name: 'agent_id',
    label: 'Agent ID',
  },
  underwriter_id: {
    name: 'underwriter_id',
    label: 'Underwriter ID',
  },
  status: {
    name: 'status',
    label: 'Status',
  },
  application_id: {
    name: 'application_id',
    label: 'Application ID',
  },
  agent_name: {
    name: 'agent_name',
    label: 'Agent Name',
  },
};

export const policySortOptions = {
  broker_id: {
    name: 'broker_id',
    label: 'Broker ID',
  },
  insured: {
    name: 'insured',
    label: 'Insured',
  },
  agent_id: {
    name: 'agent_id',
    label: 'Agent ID',
  },
  policy_id: {
    name: 'policy_id',
    label: 'Policy ID',
  },
  product_id: {
    name: 'product_id',
    label: 'Product ID',
  },
  is_active: {
    name: 'is_active',
    label: 'Is Active',
  },
  agent_name: {
    name: 'agent_name',
    label: 'Agent Name',
  },
};

export const policyFilterOptions = {
  broker_id: {
    name: 'broker_id',
    label: 'Broker ID',
  },
  insured: {
    name: 'insured',
    label: 'Insured',
  },
  agent_id: {
    name: 'agent_id',
    label: 'Agent ID',
  },
  policy_id: {
    name: 'policy_id',
    label: 'Policy ID',
  },
  product_id: {
    name: 'product_id',
    label: 'Product ID',
  },
  is_active: {
    name: 'is_active',
    label: 'Is Active',
  },
  agent_name: {
    name: 'agent_name',
    label: 'Agent Name',
  },
};

export const bindingFormSortOptions = {
  form_type: {
    name: 'form_type',
    label: 'Form Type',
  },
  requested_at: {
    name: 'requested_at',
    label: 'Requested At',
  },
  received_at: {
    name: 'received_at',
    label: 'Received At',
  },
  approved_at: {
    name: 'approved_at',
    label: 'Approved At',
  },
  rejected_at: {
    name: 'rejected_at',
    label: 'Rejected At',
  },
};

export const bindingFormFilterOptions = {
  insured: {
    name: 'insured',
    label: 'Insured',
  },
  address: {
    name: 'address',
    label: 'Address',
  },
  postcode: {
    name: 'postcode',
    label: 'Postcode',
  },
  region: {
    name: 'region',
    label: 'Region',
  },
  binding_form_id: {
    name: 'binding_form_id',
    label: 'Binding Form ID',
  },
  form_type: {
    name: 'form_type',
    label: 'Form Type',
  },
  covered_value: {
    name: 'covered_value',
    label: 'Covered Value',
  },
  quote_id: {
    name: 'quote_id',
    label: 'Quote ID',
  },
  requested_at: {
    name: 'requested_at',
    label: 'Requested At',
  },
  received_at: {
    name: 'received_at',
    label: 'Received At',
  },
  approved_at: {
    name: 'approved_at',
    label: 'Approved At',
  },
  rejected_at: {
    name: 'rejected_at',
    label: 'Rejected At',
  },
}

export const claimSortOptions = {
  status: {
    name: 'status',
    label: 'Status',
  },
  coverage: {
    name: 'coverage',
    label: 'Coverage',
  },
  insured: {
    name: 'insured',
    label: 'Insured',
  },
  location_name: {
    name: 'location_name',
    label: 'Location Name',
  },
  address: {
    name: 'address',
    label: 'Address',
  },
  region: {
    name: 'region',
    label: 'Region',
  },
  postcode: {
    name: 'postcode',
    label: 'Postcode',
  },
  product: {
    name: 'product',
    lable: 'Product',
  }
};

export const claimFilterOptions = {
  status: {
    name: 'status',
    label: 'Status',
  },
  coverage: {
    name: 'coverage',
    label: 'Coverage',
  },
  insured: {
    name: 'insured',
    label: 'Insured',
  },
  location_name: {
    name: 'location_name',
    label: 'Location Name',
  },
  address: {
    name: 'address',
    label: 'Address',
  },
  region: {
    name: 'region',
    label: 'Region',
  },
  postcode: {
    name: 'postcode',
    label: 'Postcode',
  },
  product: {
    name: 'product',
    label: 'Product',
  },
  claim_id: {
    name: 'claim_id',
    label: 'Claim ID',
  },
  agent_id: {
    name: 'agent_id',
    label: 'Agent ID',
  },
  adjuster_id: {
    name: 'adjuster_id',
    label: 'Adjuster ID',
  },
  policy_id: {
    name: 'policy_id',
    label: 'Policy ID',
  },
  location_id: {
    name: 'location_id',
    label: 'Location ID',
  },
  storm_id: {
    name: 'storm_id',
    label: 'Storm ID',
  }
}


export const DEFAULT_APP_SETTINGS = {
  listDisplayType: ListDisplayTypes.CARDS,
  // QuotesListView: {
  //   searchConfig: {
  //     filters: [{ field: quoteFilterOptions.status.name, value: 'open' }],
  //     search: [{ field: quoteFilterOptions.insured.name, operator: filterOperators.ilike, value: '' }],
  //     sort: { field: quoteSortOptions.last_change_at.name, by: SORT_DESC },
  //   },
  // },
  // ApplicationsListView: {
  //   searchConfig: {
  //     search: [{ field: applicationFilterOptions.insured.name, operator: filterOperators.ilike, value: '' }],
  //     // sort: {
  //     //   field: applicationSortOptions.applied_at.name,
  //     //   by: SORT_DESC
  //     // },
  //   }
  // },
  // BindFormsListView: {
  //   searchConfig: {
  //     search: [{ field: bindingFormFilterOptions.insured.name, operator: filterOperators.ilike, value: '' }],
  //     // sort: {
  //     //   field: bindingFormSortOptions.requested_at.name,
  //     //   by: SORT_DESC,
  //     // }
  //   }
  // },
  // PoliciesListView: {
  //   searchConfig: {
  //     search: [{ field: policyFilterOptions.insured.name, operator: filterOperators.ilike, value: '' }],
  //   },
  // },
  // ClaimsListView: {
  //   searchConfig: {
  //     search: [{ field: claimFilterOptions.insured.name, operator: filterOperators.ilike, value: '' }],
  //   },
  // },
}


