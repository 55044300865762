import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Platform } from 'react-native';
import { Text, TextProps } from '../Text';
import { Box } from '../Box';
import { Hoverable } from '../Hoverable';
import { BaseInput } from './BaseInput';
import { NumericInput } from './NumericInput';
import { TextCount } from './TextCount';
import { withStyles } from '../../styling';
import {
  flexBox,
  margin,
  position,
  transforms,
  padding,
  sizes,
  appearance,
  typography,
  createStylePropsFunction,
} from '../../system/props';
import { useForkRef, useEventCallback } from '../../hooks';

const textProps = createStylePropsFunction(TextProps);
const filterProps = [
  ...typography.filterProps,
  ...appearance.filterProps,
  ...textProps.filterProps,
  ...flexBox.filterProps,
  ...margin.filterProps,
  ...position.filterProps,
  ...transforms.filterProps,
  ...sizes.filterProps,
  ...padding.filterProps,
  'includeLabelSpacing',
];

export const TextInput = withStyles(
  ({ size = 'large', variant, variantPrefix, color, onColor, multiline, textAlignVertical, ...props }) => {
    // ^^ dont want to parse this into root (size, variants, and colors are only thing possibly shared...)
    
    return {
      root: {
        ...flexBox({ flexDirection: 'column', ...props }),
        ...margin(props),
        ...position(props),
        ...transforms(props),
      },
      textBox: {
        overflow: 'hidden',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        ...appearance(props, { omit: transforms.filterProps }),
        ...sizes(props),
        ...padding({ padY: '$2', ...props }),
        cursor: 'text',
        animations: {
          initial: false,
        },
      },
      focused: {},
      hovered: {},
      disabled: { opacity: 0.75 },
      error: {},
      input: {
        flex: 1,
        borderColor: 'transparent',
        maxHeight: '100%',
        alignSelf: 'stretch',
        maxWidth: '100%',
        borderWidth: 0,
        props: {
          ...typography({
            textAlignVertical: textAlignVertical ? textAlignVertical : multiline ? 'top' : null,
            ...props,
          }),
          ...textProps({
            align: 'left',
            center: false,
            size,
            // large: true, // TODO: stop using the shortcut booleans. when text is refactored this change this to size="large"
            maxLines: multiline ? props.maxLines ? props.maxLines : null : 1,
            variant,
            variantPrefix,
            color,
            onColor,
            ...props,
          }),
          ...(Platform.OS === 'web' ? null : { lineHeight: 0 }),
        },
      },
      multilineInputClone: {
        position: 'absolute',
        opacity: 0,
        width: '100%',
        zIndex: -999,
        overflow: 'visible',
        minHeight: 0,
        flexShrink: 0,
      },
      inputFocused: {},
      inputDisabled: {},
      inputFilled: {},
    };
  },
  {
    name: 'TextInput',
    filterProps,
    preserveStyleProp: true,
  }
)(
  React.forwardRef(function TextInput(props, ref) {
    const {
      value,
      placeholder,
      onFocus: onFocusProp,
      onBlur: onBlurProp,
      onChange,
      onChangeText,
      onChangeValue,
      onInputLayout,
      textAlignVertical,
      multiline = false,
      error = false,
      type = 'text', // dont pass
      InputProps = {},
      TextBoxProps = {},
      textBoxRef,
      inputRef,
      disabled = false,
      ignoreValue = true,
      accessibility,
      id = props.nativeID,
      nativeID,
      focusable = true,
      InputComponent,
      children,
      startAdornment,
      endAdornment,
      renderBefore,
      renderAfter,
      renderAbove,
      renderBelow,
      style: styleProp,
      styles,
      animate: animateProp,
      showTextCount = false,
      renderCount,
      // number input props
      onChangeNumber,
      parse,
      format,
      precision,
      addZeroes = false,
      min,
      max,
      step,
      disableAnimate,
      onColor,
      filled: filledProp = false,
      onPress,
      onHoverIn,
      onHoverOut,
      onHoverChange,
      onResponderGrant,
      onResponderRelease,
      autoComplete = undefined,
      autoCapitalize = undefined,
      autoCorrect = undefined,
      spellCheck = undefined,
      disableAutoComplete = false,
      disableAutoCapitalize = false,
      disableAutoCorrect = false,
      disableSpellCheck = false,
      autoFocus,
      keyboardType,
      onKeyPress, // prefer this over onKeyDown
      onKeyDown,
      onKeyUp,
      onKeyDownCapture,
      onKeyUpCapture,
      onSubmitEditing,
      returnKeyType,
      blurOnSubmit,
      dataSet,
      dir,
      editable,
      onContentSizeChange,
      onSelectionChange,
      secureTextEntry,
      selectTextOnFocus,
      clearTextOnFocus,
      focused: focusedProp = undefined,
      ...rest
    } = props;

    const [inputFocused, setFocused] = useState(false);
    const focused = focusedProp !== undefined ? focusedProp : inputFocused;
    const component = InputComponent
      ? InputComponent
      : type === 'number'
      ? NumericInput
      : BaseInput;

    
    const handlers = {
      onFocus: useEventCallback((...args) => {
        setFocused(true);
        if (onFocusProp) {
          onFocusProp(...args);
        }
      }),
      onBlur: useEventCallback((...args) => {
        setFocused(false);
        if (onBlurProp) {
          onBlurProp(...args);
        }
      }),
    };
    const handleOnChange = useEventCallback((...args) => onChange && onChange(...args));
    const handleOnChangeValue = useEventCallback((...args) => {
      if (onChangeValue) {
        onChangeValue(...args);
      }
      if (onChangeText) {
        onChangeText(...args);
      }
    });

    if (onChange) {
      handlers.onChange = handleOnChange;
    }
    if (onChangeText || onChangeValue) {
      if (component === BaseInput) {
        handlers.onChangeText = handleOnChangeValue;
      } else {
        handlers.onChangeValue = handleOnChangeValue;
      }
    }
    
    const input = useRef();
    const handleInputRef = useForkRef(input, inputRef);
    const filled = filledProp || (props.value !== null && props.value !== undefined && props.value !== '') || (input.current && input.current.value ? true : false);

    const inputPropsStyle = InputProps.style;
    const textInputStyle = useMemo(() => {
      const inputStyle = [styles.input];
      if (disabled) {
        inputStyle.push(styles.inputDisabled);
      } else if (focused) {
        inputStyle.push(styles.inputFocused);
      }
      if (filled) {
        inputStyle.push(styles.inputFilled);
      }
      if (inputPropsStyle) {
        inputStyle.push(inputPropsStyle);
      }
      return inputStyle;
    }, [styles, disabled, focused, filled, inputPropsStyle]);

    const { id: propsId, nativeID: propsNativeID, ...otherInputProps } = InputProps || {};

    const textInputProps = {
      autoFocus,
      keyboardType,
      onKeyPress, // prefer this over onKeyDown
      onKeyDown,
      onKeyUp,
      onKeyDownCapture,
      onKeyUpCapture,
      onSubmitEditing,
      returnKeyType,
      blurOnSubmit,
      dataSet,
      dir,
      editable,
      onContentSizeChange,
      onSelectionChange,
      secureTextEntry,
      selectTextOnFocus,
      clearTextOnFocus,
      ...styles.props.input,
      ...(type === 'number' ? {
            onChangeNumber,
            parse,
            format,
            precision,
            addZeroes,
            min,
            max,
            step,
          }
        : null),
      ...(focused && !disabled ? styles.props.inputFocused : null),
      ...(filled ? styles.props.inputFilled : null),
      ...otherInputProps,
      ...handlers,
      style: textInputStyle,
    };

    const declaredID = propsId || propsNativeID || id;
    if (declaredID) {
      textInputProps.id = declaredID;
    }

    const renderProps = { onColor, error, styles, focused, disabled, filled };
    
    

    const focusOnPressTextBox = useEventCallback(() => {
      if (disabled) {
        return;
      }
      if (input.current && input.current.focus && !focused) {
        input.current.focus();
      }
      if (onPress) {
        onPress();
      }
    });

    const cloneRef = useRef(null);
    const [contentHeight, setContentHeight] = useState(null);
    useEffect(() => {
      const cloneHeight = cloneRef.current && cloneRef.current.scrollHeight ? cloneRef.current.scrollHeight : 0;
      if (multiline && cloneHeight) {
        setContentHeight(cloneHeight);
      }
    }, [value, multiline])

    const [inputWidth, setInputWidth] = useState(null);
    const handleInputLayout = useEventCallback((e) => {
      if (!e || !e.nativeEvent || !e.nativeEvent.layout) {
        return;
      }
      if (onInputLayout) {
        onInputLayout(e);
      } else if (textInputProps.onLayout) {
        textInputProps.onLayout(e);
      }
      setInputWidth(e.nativeEvent.layout.width);
    });

    const autosize = multiline && !textInputProps.maxLines;
    
    const multilineInputCloneProps = autosize ? {
      placeholder,
      ignoreValue,
      multiline,
      value,
      ...styles.props.input,
      ...(focused && !disabled ? styles.props.inputFocused : null),
      ...(filled ? styles.props.inputFilled : null),
      accessibility: { accessibilityHidden: true },
      style: [...textInputStyle, styles.multilineInputClone],
      component: BaseInput,
      focusable: false,
      disabled: true,
      pointerEvents: 'none',
      ref: cloneRef,
      maxLines: 1,
      maxWidth: inputWidth ? inputWidth : '100%',
    } : null;

    return (
      <Box
        disableAnimationDefaults
        disabled={disabled}
        style={[styles.root, styleProp]}
        onPress={disabled ? undefined : focusOnPressTextBox}
        ref={ref}
        pointerEvents={disabled ? 'none' : undefined}
        {...rest}
      >
        {typeof renderAbove === 'function' ? renderAbove(renderProps) : renderAbove}
        <Hoverable
          onHoverChange={onHoverChange}
          onHoverIn={onHoverIn}
          onHoverOut={onHoverOut}
          onResponderGrant={onResponderGrant}
          onResponderRelease={onResponderRelease}
        >
          {({ hovered }) => {

            // TODO: NATIVE only issue: animation seems to not happen even tho the animate prop is updated
            let animate = ['default'];
            if (!disabled) {
              if (focused) {
                animate.push('focused');
              } else if (hovered) {
                animate.push('hovered');
              }
              if (error) {
                animate.push('error');
              }
            }
            if (animateProp) {
              animate.push(animateProp);
            }

            const textBoxStyle = TextBoxProps.style;
            let style = error ? [styles.error, textBoxStyle] : [textBoxStyle];
            if (disabled) {
              style = [styles.textBox, styles.disabled, ...style];
            } else if (focused) {
              style = [styles.textBox, styles.focused, ...style];
            } else if (hovered) {
              style = [styles.textBox, styles.hovered, ...style];
            } else {
              style = [styles.textBox, ...style];
            }
            return (
              <Box
                disableAnimationDefaults
                disabled={disabled}
                ref={textBoxRef}
                {...styles.props.textBox}
                {...TextBoxProps}
                animate={animate}
                style={style}
              >
                {renderBefore ? renderBefore({ startAdornment, textBoxStyle: style, ...renderProps }) : null}
                {startAdornment}
                <Text
                  value={value}
                  placeholder={placeholder}
                  ignoreValue={ignoreValue}
                  disabled={disabled}
                  component={component}
                  multiline={multiline}
                  ref={handleInputRef}
                  focusable={focusable}
                  autoComplete={autoComplete ? autoComplete : disableAutoComplete ? 'new-password' : undefined}
                  autoCapitalize={autoCapitalize ? autoCapitalize : disableAutoCapitalize ? 'off' : undefined}
                  autoCorrect={autoCorrect ? autoCorrect : disableAutoCorrect ? 'off' : undefined}
                  spellCheck={spellCheck === true ? true : disableSpellCheck || spellCheck === false ? false : undefined}
                  accessibility={{
                    accessibilityRole: undefined, // if component is Native TextInput then role is handled automatically by RN/RNW
                    accessibilityMultiline: multiline ? true : null,
                    ...accessibility,
                  }}
                  height={autosize && contentHeight ? contentHeight : undefined}
                  {...textInputProps}
                  onLayout={handleInputLayout}
                  maxLines={autosize ? 1 : textInputProps.maxLines}
                />
                {autosize ? <Text {...multilineInputCloneProps} /> : null}
                {children}
                {endAdornment}
                {showTextCount ? (
                  <TextCount
                    value={props.value}
                    maxLength={props.maxLength}
                    renderCount={renderCount}
                    onColor={onColor}
                    styles={styles}
                  />
                ) : null}
                {renderAfter ? renderAfter({ endAdornment,  textBoxStyle: style, ...renderProps }) : null}
              </Box>
            );
          }}
        </Hoverable>
        {typeof renderBelow === 'function' ? renderBelow(renderProps) : renderBelow}
      </Box>
    );
  })
);
