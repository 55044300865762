import React, { useCallback } from 'react';
import { Select } from '../Select';
import { withStyles } from '../../styling';
import { inputComponentVariants } from '../TextInput';
import { Label } from './Label';
import { HelperText } from './HelperText';
import { useEventCallback, useId } from '../../hooks';
import { ListInput } from '../ListInput';

const TextField = React.memo(withStyles(
  ({ onColor: onColorProp = '$background', label, theme } = {}) => {
    const onColor = theme.colors.on(onColorProp) || 'white';
    const placeholderTextColor = theme.colors.alpha(onColor, 0.5);
    return {
      root: {
        marginY: '$1',
      },
      textInput: {},
      textBox: {},
      input: {
        props: {
          placeholderTextColor: label ? theme.colors.alpha(onColor, 0) : placeholderTextColor,
        },
      },
      inputFocused: {
        props: {
          placeholderTextColor: placeholderTextColor,
        },
      },
      inputFilled: {
        props: {
          placeholderTextColor: placeholderTextColor,
        },
      },
      label: {},
      helperText: {},
    };
  },
  {
    name: 'TextField',
  }
)(
  React.forwardRef(function TextField(props, ref) {
    const {
      label,
      LabelProps,
      required = false,
      HelperTextProps,
      helperText,
      hideHelperText = false,
      InputProps,
      inputRef,
      styles,
      variant = 'filled',
      type,
      color,
      onColor,
      // TextInput props
      ...rest
    } = props;
    let Component = inputComponentVariants[variant];

    
    const labelId = useId(LabelProps && LabelProps.id ? LabelProps.id : null, 'zuiFieldLabel');
    const helperId = useId(HelperTextProps && HelperTextProps.id ? HelperTextProps.id : null, 'zuiFieldHelper');

    const inputProps = {
      ...InputProps,
      type,
      ...rest,
    };

    if (type === 'select') {
      inputProps.variant = variant;
      Component = Select;
    } else if (type === 'list') {
      inputProps.variant = variant;
      Component = ListInput;
    }
    

    const renderLabel = useEventCallback(({ styles: s, ...p }) => (
      <Label
        id={labelId}
        style={s.label}
        required={required}
        size={s.props.input.size}
        textBoxStyle={s.textBox}
        {...s.props.label}
        {...LabelProps}
        color={color}
        inputVariant={variant}
        {...p}
      >
        {label}
      </Label>
    ));

    const renderHelperText = useCallback(({ styles: s, ...p }) => (
      !hideHelperText ? (
        <HelperText
          id={helperId}
          style={s.helperText}
          {...s.props.helperText}
          {...HelperTextProps}
          inputVariant={variant}
          {...p}
        >
          {helperText || " "}
        </HelperText>
      ) : null
    ), [hideHelperText, helperId, HelperTextProps, variant, helperText]);

    return (
      <Component
        ref={ref}
        styles={styles}
        inputRef={inputRef}
        includeLabelSpacing={label ? true : false}
        {...inputProps}
        accessibility={{
          ...inputProps.accessibility,
          accessibilityLabelledBy: label ? labelId : undefined,
          accessibilityDescribedBy: helperText ? helperId : undefined,
        }}
        onColor={onColor}
        color={color}
        renderBefore={label ? renderLabel : null}
        renderBelow={renderHelperText}
      />
    );
  })
));

TextField.Label = Label;
TextField.HelperText = HelperText;

export { TextField };
