import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { Box, Text, Heading, Button, Spacing, DatePicker, TextField, Autocomplete } from '../../ui';
import { useApi, useAsync, useAuth, useNavigate } from '../../hooks';
import {
  NavBar,
  Page,
  Main,
  NavBackButton,
  NavRow,
  NavGroup,
  Dialog,
  AppLoadingOverlay,
} from '../../components';
import { useEventCallback } from '../../ui/hooks';
import { isNull } from '../../ui/utils';
import { AddCircleIcon, UploadCloudIcon } from '../../components/icons';
import { validateApplicationData } from './validateApplicationData';
import { RowLayout, SectionSeperator } from './components';
import { LocationItem } from './LocationItem';
import { AddressAutocomplete } from './AddressAutocomplete';
import { LocationDialog } from './LocationDialog';
import { ProductNames } from '../../constants';


export const SubmitApplicationPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { submitApplication, getProducts, getAgents } = useApi();
  const [ productId, setProductId ] = useState(null);
  const [ agentId, setAgentId ] = useState(null);
  const [ insured, setInsured ] = useState('');
  const [ primaryEmail, setPrimaryEmail ] = useState('');
  const [ billingEmail, setBillingEmail ] = useState('');
  const [ namedInsureds, setNamedInsureds ] = useState([]);
  const [ federalEIN, setFederalEIN ] = useState('');
  const [ naicsCode, setNaicsCode ] = useState(null);
  const [ numberEmployees, setNumberEmployees ] = useState(0);
  const [ notes, setNotes ] = useState('');
  const [ placeId, setPlaceId ] = useState(null);
  const [ address, setAddress ] = useState('');
  const [ address1, setAddress1 ] = useState('');
  const [ address2, setAddress2 ] = useState(null);
  const [ city, setCity ] = useState('');
  const [ addressDetailsLoading, setAddressDetailsLoading ] = useState(false);
  const [ regionCode, setRegionCode ] = useState('');
  const [ postcode, setPostcode ] = useState('');
  const [ countryCode, setCountryCode ] = useState('');
  const [ timezone, setTimezone ] = useState(null);
  const [ lat, setLat ] = useState(null);
  const [ lon, setLon ] = useState(null);
  const [ targetEffectiveAt, setTargetEffectiveAt ] = useState(null);
  const [ locations, setLocations ] = useState([]);
  const [ locationDialogOpen, setLocationDialogOpen ] = useState(false);
  const [ editLocationIndex, setEditLocationIndex ] = useState(-1);
  const [ deleteLocationIndex, setDeleteLocationIndex ] = useState(false);

  const handleSubmit = useEventCallback(async () => {
    const application = {
      productId: productId,
      insured: insured,
      namedInsureds,
      federalEIN,
      naicsCode,
      primaryEmail,
      billingEmail,
      placeId: placeId,
      address: address,
      address1: address1,
      address2: address2,
      city: city,
      regionCode: regionCode,
      postcode: postcode,
      countryCode: countryCode == "US" ? "USA" : countryCode,
      timezone: timezone,
      lat: lat,
      lon: lon,
      locations: locations,
      details: {
        employees: numberEmployees,
        loss_history_download_link: "",
      },
      notes: notes,
      targetEffectiveAt: targetEffectiveAt,
      agent: { id: agentId },
    }
    validateApplicationData(application);
    return await submitApplication(application);
  }, [productId, agentId, naicsCode, insured, namedInsureds, placeId, address, address1, address2, city, regionCode, postcode, countryCode, timezone, lat, lon, numberEmployees, notes, targetEffectiveAt, locations, submitApplication])

  const { value: newApplication, execute: submit, status, error } = useAsync(handleSubmit, { immediate: false });

  const errors = useMemo(() => {
    if (error && error.validationError) {
      return error;
    }
    return {};
  }, [error]);

  const unhandledError = status === 'error' && (!error || !error.validationError);

  const { value: products, status: productsStatus } = useAsync(getProducts);
  const { value: agents, status: agentsStatus } = useAsync(getAgents);

  const handleSubmitLocation = (loc) => {
    if (editLocationIndex >= 0 && editLocationIndex < locations.length) {
      setLocations(locs => {
        const next = [...locs];
        next[editLocationIndex] = loc;
        return next;
      });
    } else {
      setLocations(locs => [...locs, loc]);
    }
    if (locationDialogOpen) {
      setLocationDialogOpen(false);
    }
  };

  const deleteLocationAtIndex = (index) => {
    setDeleteLocationIndex(false);
    if (locationDialogOpen) {
      setLocationDialogOpen(false);
    }
    if (editLocationIndex !== -1) {
      setEditLocationIndex(-1);
    }
    if (index >= 0 && index < locations.length) {
      setLocations(locs => {
        const next = [...locs];
        next.splice(index, 1);
        return next;
      });
    }
  }


  useEffect(() => {
    if (newApplication && typeof newApplication === 'object' && newApplication.id) {
      navigate.to({ pathname: `/applications/${newApplication.id}`, replace: true });
    }
  }, [newApplication, navigate])

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const startDate = new moment();
  startDate.add(90, 'days');
  startDate.startOf('month');
  const [d, setD] = useState(targetEffectiveAt ? moment(targetEffectiveAt) : startDate);
  useEffect(() => {
    if (d) {
      setTargetEffectiveAt(d.valueOf());
    }
  }, [d, setTargetEffectiveAt])

  useEffect(() => {
    if (agents) {
      agents.sort((a,b) => {
        if (a.broker.name > b.broker.name) {
          return 1;
        } else if (a.broker.name < b.broker.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else if (a.name < b.name) {
          return -1;
        } else {
          return 0;
        }
      });

      agents.forEach(a => a.display_name = `${a.broker.name} - ${a.name}`);
      const userAgents = user && user.userId ? agents.filter(a => a.userId == user.userId) : agents;
      if (userAgents && userAgents.length > 0) {
        setAgentId((currId) => {
          if (!currId) {
            return userAgents[0].id
          }
          return currId;
        });
      }
    }
  }, [agents, user])

  const naicsOptions = [
    {label: 'Auto Dealer - New/New and Used', value: '441110'},
    {label: 'Auto Dealer - Used', value: '441120'}
  ];
  const productOptions = products && products !== null ? products : [];
  const agentOptions = agents && agents !== null ? agents : [];
  const currentProduct = (products || []).find(p => p.id === productId);
  const locationCards = locations.map((loc, i) => (
    <LocationItem
      loc={loc}
      key={`${loc.name}${i}`}
      product={currentProduct}
      onDelete={() => {
        setDeleteLocationIndex(i)
      }}
      onEdit={() => {
        setEditLocationIndex(i);
        setLocationDialogOpen(true);
      }}
    />
  ));

  return (
    <Page>
      <AppLoadingOverlay loading={status === 'pending'} />
      <NavBar>
        <NavRow>
          <NavGroup start>
            <NavBackButton path="/applications" />
          </NavGroup>
        </NavRow>
        {unhandledError || (error && error.validationError) ? (
          <NavRow bg={unhandledError ? '$error' : '$white'} minHeight="50" justifyContent="center" padX="$1" border={{ bottom: { width: 1, color: '$error' }}}>
            <Text my="$1.75" bold color={unhandledError ? '$white' : '$error'} lineHeight={20}>
              {unhandledError ? (
                "An error occured and the application may have not been submitted."
              ) : (
                "There are some errors in the form. Please correct these and try again."
              )}
            </Text>
          </NavRow>
        ) : null}
      </NavBar>
      <Main
        maxWidth={({ theme }) => theme.breakpoints({ sm: 640, md: '$mainWidth' })}
        padBottom={364}
        alignItems="flex-start"
        padTop={({ theme }) => theme.breakpoints({ sm: '$9', md: '$13' })}
        padX={({ theme }) => theme.breakpoints({ xs: theme.spacing(2), sm: theme.spacing(4), md: theme.spacing(6) })}
      >
        <Box alignItems="flex-start" width="100%">
        <Heading level={2} spaceAfter>
          New Application
        </Heading>
        <Text large>All fields are required unless noted as optional</Text>
        <Spacing vertical={({ theme }) => theme.breakpoints({ sm: 3.5, md: 7 })} />
        <Box flex={1} alignSelf="stretch" gap={0}>
        <SectionSeperator label="Policy Details" />
          <RowLayout zIndex="999999999">
            <Autocomplete
              options={productOptions}
              autoComplete
              autoHighlight
              loading={productsStatus === 'pending'}
              getOptionLabel={(option) => {
                if (typeof option === 'string' || typeof option === 'number') {
                  const productOption = productOptions.find(p => p.id === option);
                  if (productOption) {
                    return ProductNames[productOption.name] || productOption.name;
                  }
                } else if (option) {
                  return ProductNames[option.name] || option.name;
                } else {
                  return '';
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value || option === value}
              value={productId}
              onChangeValue={(v) => setProductId(v && v.id ? v.id : null)}
              renderInput={(iProps) => (
                <TextField
                  {...iProps}
                  label="Product"
                  helperText={errors.productId ? errors.productId : 'The insurance product this application is applying for.'}
                  error={errors.productId}
                  placeholder="-- Select a product --"
                  filled
                />
              )}
              flex="1"
            />
            <Autocomplete
              options={agentOptions}
              autoComplete
              autoHighlight
              loading={agentsStatus === 'pending'}
              getOptionLabel={(option) => {
                if (typeof option === 'string' || typeof option === 'number') {
                  const agentOption = agentOptions.find(a => a.id === option);
                  if (agentOption) {
                    return agentOption.display_name;
                  }
                } else if (option) {
                  return option.display_name;
                } else {
                  return '';
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value || option === value}
              value={agentId}
              onChangeValue={(v) => setAgentId(v && v.id ? v.id : null)}
              renderInput={(iProps) => (
                <TextField
                  {...iProps}
                  label="Agent"
                  helperText={errors.agent ? errors.agent : 'The agent submitting this application.'}
                  error={errors.agent}
                  placeholder="-- Select an agent --"
                  filled
                />
              )}
              flex="1"
            />
          </RowLayout>
          <RowLayout>
            <TextField
              label="Target Effective Date"
              value={moment(targetEffectiveAt).format('MM-DD-YYYY')}
              onPress={(date) => {
                setDatePickerOpen(true);
              }}
              flex={({ theme }) => theme.breakpoints({ sm: 1, md: 0.5 })}
              helperText={errors.targetEffectiveAt ? errors.targetEffectiveAt : undefined}
              error={errors.targetEffectiveAt}
              hideHelperText={isNull(errors.targetEffectiveAt)}
            />
          </RowLayout>
          <Dialog open={datePickerOpen} onClose={() => setDatePickerOpen(false)}>
            <DatePicker
              date={d}
              onChange={(date) => {
                setD(date);
                setDatePickerOpen(false);
              }}
            />
          </Dialog>
          <SectionSeperator label="Client Details" />
          <TextField
            label="Insured"
            value={insured}
            onChangeValue={setInsured}
            helperText={errors.insured ? errors.insured : undefined}
            error={errors.insured}
            hideHelperText={isNull(errors.insured)}
          />
          <TextField
            type="list"
            addInputValueOnBlur
            label="Additional Named Insureds (Optional)"
            value={namedInsureds}
            onChangeValue={setNamedInsureds}
            helperText="Press Enter to add another name."
            vertical
          />
          <RowLayout zIndex="99999999">
            <TextField
              label="Federal EIN"
              value={federalEIN}
              onChangeValue={setFederalEIN}
              flex="1"
              helperText={errors.federalEIN ? errors.federalEIN : undefined}
              error={errors.federalEIN}
              hideHelperText={isNull(errors.federalEIN)}
            />
            <Autocomplete
              options={naicsOptions}
              autoComplete
              autoHighlight
              getOptionLabel={option => {
                if (typeof option === 'string' || typeof option === 'number') {
                  return naicsOptions.find(o => o.value === option).label;
                } else if (option) {
                  return option.label;
                } else {
                  return '';
                }
              }}
              isOptionEqualToValue={(option, value) => option.value === value || option === value}
              value={naicsCode}
              onChangeValue={(v) => setNaicsCode(v && v.value ? v.value : null) }
              renderInput={(iProps) => (
                <TextField
                  {...iProps}
                  label="NAICS Code"
                  error={errors.naicsCode}
                  placeholder="-- Select an industry classification --"
                  filled
                />
              )}
              flex="1"
            />
          </RowLayout>
          <RowLayout>
            <TextField
              label="Primary Email"
              value={primaryEmail}
              onChangeValue={setPrimaryEmail}
              flex="1"
              helperText={errors.primaryEmail ? errors.primaryEmail : undefined}
              error={errors.primaryEmail}
              hideHelperText={isNull(errors.primaryEmail)}
            />
            <TextField
              label="Billing Email"
              value={billingEmail}
              onChangeValue={setBillingEmail}
              flex="1"
              helperText={errors.billingEmail ? errors.billingEmail : undefined}
              error={errors.billingEmail}
              hideHelperText={isNull(errors.billingEmail)}
            />
          </RowLayout>
          <AddressAutocomplete
            address={address}
            setAddress={setAddress}
            onDetailsLoading={setAddressDetailsLoading}
            onDetailsReceived={(details) => {
              setLat(details.lat);
              setLon(details.lon);
              setPostcode(details.postcode);
              setCountryCode(details.countryCode);
              setRegionCode(details.regionCode);
              setTimezone(details.timezone);
              setPlaceId(details.placeId);
              setAddress(details.address);
              setAddress1(details.address1);
              setAddress2(details.address2);
              setCity(details.city);
            }}
            helperText={errors.address ? errors.address : undefined}
            error={errors.address}
          />
          <Box flexDirection="row" gap="8" alignSelf="stretch" opacity={addressDetailsLoading ? 0.5 : 1}>
            <TextField
              label="Region Code"
              value={regionCode}
              onChangeValue={setRegionCode}
              disableAutoComplete
              disableAutoCorrect
              disableSpellCheck
              disableAutoCapitalize
              disabled={addressDetailsLoading}
              flex="1"
              helperText={errors.regionCode ? errors.regionCode : undefined}
              error={errors.regionCode}
              hideHelperText={isNull(errors.regionCode)}
            />
            <TextField
              label="Postcode"
              value={postcode}
              onChangeValue={setPostcode}
              disableAutoComplete
              disableAutoCorrect
              disableSpellCheck
              disableAutoCapitalize
              disabled={addressDetailsLoading}
              flex="1"
              helperText={errors.postcode ? errors.postcode : undefined}
              error={errors.postcode}
              hideHelperText={isNull(errors.postcode)}
            />
            <TextField
              label="Country Code"
              value={countryCode}
              onChangeValue={setCountryCode}
              disableAutoComplete
              disableAutoCorrect
              disableSpellCheck
              disableAutoCapitalize
              disabled={addressDetailsLoading}
              flex="1"
              helperText={errors.countryCode ? errors.countryCode : undefined}
              error={errors.countryCode}
              hideHelperText={isNull(errors.countryCode)}
            />
          </Box>
          <Box flexDirection="row" gap="8" flex="1" alignSelf="stretch" opacity={addressDetailsLoading ? 0.5 : 1}>
            <TextField
              label="Latitude"
              value={lat}
              type="number"
              onChangeValue={setLat}
              disableAutoComplete
              disableAutoCorrect
              disableSpellCheck
              disableAutoCapitalize
              disabled={addressDetailsLoading}
              flex="1"
              helperText={errors.lat ? errors.lat : undefined}
              error={errors.lat}
              hideHelperText={isNull(errors.lat)}
            />
            <TextField
              label="Longitude"
              value={lon}
              type="number"
              onChangeValue={setLon}
              disableAutoComplete
              disableAutoCorrect
              disableSpellCheck
              disableAutoCapitalize
              disabled={addressDetailsLoading}
              flex="1"
              helperText={errors.lon ? errors.lon : undefined}
              error={errors.lon}
              hideHelperText={isNull(errors.lon)}
            />
          </Box>
          <RowLayout>
            <TextField
              label="Number of Employees"
              value={numberEmployees}
              onChangeValue={setNumberEmployees}
              flex={({ theme }) => theme.breakpoints({ sm: 1, md: 0.5 })}
              type="number"
              precision={0}
              helperText={errors.employees ? errors.employees : undefined}
              error={errors.employees}
              hideHelperText={isNull(errors.employees)}
            />
            <TextField
              label="Notes"
              value={notes}
              onChangeValue={setNotes}
              flex={({ theme }) => theme.breakpoints({ sm: 1, md: 0.5 })}
              multiline={true}
            />
          </RowLayout>

          <SectionSeperator
            lineColor={!isNull(errors.locations) ? '$error' : undefined}
            label={(
              <Box>
                <Text bold xLarge>Locations</Text>
                <Text bold small dim={isNull(errors.locations) ? 0.4 : 1} color={!isNull(errors.locations) ? '$error' : undefined}>
                  {errors.locations ? errors.locations : locationCards.length ? `${locationCards.length} location${locationCards.length > 1 ? 's' : ''} added` : `No locations added`}
                </Text>
              </Box>
            )}
          />
          <Spacing vertical={0.75} />
          <Text regular dim={0.8} italic>Add locations to be covered under this policy</Text>
          <Spacing vertical={5.25} />
          {locationCards}
          <Spacing vertical={1} />
          <Button
            startIcon={<AddCircleIcon color="$primary" />}
            label="Add Location"
            color="$primary.light"
            outlined
            styles={{
              root: {
                padY: '$3.5',
                borderStyle: 'dashed',
              },
              Text: {
                color: '$primary',
              }
            }}
            large
            onPress={() => {
              if (editLocationIndex !== -1) {
                setEditLocationIndex(-1);
              }
              setLocationDialogOpen(true)
            }}
          />
          <Spacing vertical={3} />

          <LocationDialog
            open={locationDialogOpen}
            onClose={() => setLocationDialogOpen(false)}
            product={currentProduct}
            onSubmit={handleSubmitLocation}
            canEdit
            mode={(editLocationIndex >= 0 && editLocationIndex < locations.length) ? 'EDIT' : 'CREATE'}
            data={editLocationIndex >= 0 && editLocationIndex < locations.length ? locations[editLocationIndex] : undefined}
          />
        </Box>
        <Spacing vertical={6} />
        <SectionSeperator />
        <Box padY="$1" height={80} alignItems="center" alignSelf="stretch" pointerEvents="box-none" opacity="1">
          <Button loading={status === 'pending'} large onPress={submit} minWidth={240} height="100%" disabled={addressDetailsLoading} color={addressDetailsLoading ? '$gray.400' : '$primary'} startIcon={<UploadCloudIcon color="$white" />}>Submit Application</Button>
        </Box>
        </Box>
        <DeleteLocationPrompt
          open={deleteLocationIndex !== false}
          onDelete={() => deleteLocationAtIndex(deleteLocationIndex)}
          onCancel={() => setDeleteLocationIndex(false)}
        />
      </Main>
    </Page>
  );
}

const DeleteLocationPrompt = ({ open, onDelete, onCancel }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      heading="Delete Location"
      prompt="Please confirm you would like to delete this location"
      actions={[
        <Button
          variant="text"
          color="$primary"
          label="Cancel"
          onPress={onCancel}
          key="cancel"
        />,
        <Button
          label="Delete"
          onPress={onDelete}
          key="delete"
        />
      ]}
    />
  )
}
