import { inputTests } from "../../ui/utils";

export const validateApplicationLocationData = (application = {}) => {
  const {
    name,
    address,
    regionCode,
    postcode,
    countryCode,
    lat,
    lon,
    coveredValue,
    // details,
  } = application;
  // const { average_car_value, num_vehicles } = details || {};

  const errors = { validationError: true };
  let valid = true;

  if (!inputTests.filled(name)) {
    errors.name = 'The business name of the location is required';
    valid = false;
  }
  if (!inputTests.filled(address)) {
    errors.address = 'Required';
    valid = false;
  }
  if (!inputTests.filled(regionCode)) {
    errors.regionCode = 'Required';
    valid = false;
  }
  if (!inputTests.filled(postcode)) {
    errors.postcode = 'Required';
    valid = false;
  }
  if (!inputTests.filled(countryCode)) {
    errors.countryCode = 'Required';
    valid = false;
  }
  if (!inputTests.number(lat)) {
    errors.lat = 'Required';
    valid = false;
  }
  if (!inputTests.number(lon)) {
    errors.lon = 'Required';
    valid = false;
  }
  if (!inputTests.number(coveredValue)) {
    errors.coveredValue = 'Required';
    valid = false;
  } else if (coveredValue <= 0) {
    errors.coveredValue = 'Value cannot be less than or equal to 0';
    valid = false;
  }
  if (!valid) {
    throw errors;
  }
  return true;
}
