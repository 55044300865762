import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, TextField, Spacing, ModalBackdrop, Modal } from '../../../../ui';
import { Controller, useWatch } from 'react-hook-form';
import { withStyles } from '../../../../ui/styling';
import { useApi, useAsync, useAppState } from '../../../../hooks';
import { AppLoadingOverlay, Dialog } from '../../../../components';
import { useEventCallback } from '../../../../ui/hooks';
import { ApplicationView } from '../../../ApplicationPage';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';
import { useAdjustmentForm } from '../../context/QuoteAdjustment/AdjustmentFormContext';
import { useAdjustmentPreview } from '../../context/QuoteAdjustment/AdjustmentPreviewContext';



export const QuoteActions = withStyles(({ theme }) => ({
  // root: {
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  //   alignItems: 'flex-end',
  //   alignSelf: 'stretch',
  //   padBottom: '$2',
  //   padLeft: '$4',
  //   padRight: '$2',
  //   minHeight: 60,
  //   opacity: 0,
  // },
  // start: {
  //   flexDirection: 'row',
  //   justifyContent: 'flex-start',
  //   alignItems: 'center',
  //   props: { gap: 12 },
  // },
  // end: {
  //   flexDirection: 'row',
  //   justifyContent: 'flex-end',
  //   alignItems: 'center',
  //   props: { gap: 12 },
  // },
  root: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  primaryAction: {
    marginLeft: '$1.5',
    props: {
      color: '$primary',
      variant: 'contained',
    },
  },
  secondaryAction: {
    marginLeft: '$1.5',
    props: {
      color: '$primary',
      variant: 'text',
    }
  }
}))(React.forwardRef(function QuoteActions(props, ref) {
  const { quote, styles, refreshQuoteData, ...rest } = props;
  const { id: quoteId } = quote;

  const primaryActionProps = {
    style: styles.primaryAction,
    ...styles.props.primaryAction,
  };

  const secondaryActionProps = {
    style: styles.secondaryAction,
    ...styles.props.secondaryAction,
  };
  const [{ canAdjustQuote, canRequestChangesToQuote, canPrintQuote, canAcceptQuote, canRejectQuote }] = useAppState();
  const { adjusting, enable, disable, submitting, setCanPreview } = useAdjustmentState();
  const { hasQuoteError, trigger } = useAdjustmentForm();
  const [submitAdjustmentOpen, setSubmitAdjustmentOpen] = useState(false);
  const [publishQuoteOpen, setPublishQuoteOpen] = useState(false);
  const [acceptQuoteOpen, setAcceptQuoteOpen] = useState(false);
  const [rejectQuoteOpen, setRejectQuoteOpen] = useState(false);
  const [requestChangesOpen, setRequestChangesOpen] = useState(false);

  useEffect(() => {
    if (submitAdjustmentOpen) {
      trigger();
    }
  }, [submitAdjustmentOpen, trigger])

  useEffect(() => {
    setSubmitAdjustmentOpen(false);
  }, [hasQuoteError])

  const {
    releaseQuoteWithId,
    submitRequestOnQuoteId,
    acceptQuoteWithId,
    rejectQuoteWithId,
  } = useApi();

  /* RELEASE/PUBLISH QUOTE API/STATE */
  const {
    execute: releaseQuote,
    status: releaseStatus,
    error: releaseError,
    setStatus: setReleaseStatus,
  } = useAsync(releaseQuoteWithId, { immediate: false });

  useEffect(() => {
    if (releaseStatus === 'success') {
      setReleaseStatus('idle');
      refreshQuoteData();
    }
  }, [refreshQuoteData, releaseStatus, setReleaseStatus])

  /* REQUEST CHANGES API/STATE */
  const {
    execute: requestChanges,
    status: requestChangesStatus,
    error: requestChangesError,
    setStatus: setRequestChangesStatus,
  } = useAsync(submitRequestOnQuoteId, { immediate: false });

  useEffect(() => {
    if (requestChangesStatus === 'success') {
      setRequestChangesStatus('idle');
      refreshQuoteData();
    }
  }, [refreshQuoteData, requestChangesStatus, setRequestChangesStatus])

  /* ACCEPT QUOTE API/STATE */
  const {
    execute: acceptQuote,
    status: acceptQuoteStatus,
    error: acceptQuoteError,
    setStatus: setAcceptQuoteStatus,
  } = useAsync(acceptQuoteWithId, { immediate: false });

  useEffect(() => {
    if (acceptQuoteStatus === 'success') {
      setAcceptQuoteStatus('idle');
      refreshQuoteData();
    }
  }, [refreshQuoteData, acceptQuoteStatus, setAcceptQuoteStatus])

  /* REJECT QUOTE API/STATE */
  const {
    execute: rejectQuote,
    status: rejectQuoteStatus,
    error: rejectQuoteError,
    setStatus: setRejectQuoteStatus,
  } = useAsync(rejectQuoteWithId, { immediate: false });

  useEffect(() => {
    if (rejectQuoteStatus === 'success') {
      setRejectQuoteStatus('idle');
      refreshQuoteData();
    }
  }, [refreshQuoteData, rejectQuoteStatus, setRejectQuoteStatus])

  const errors = acceptQuoteError || rejectQuoteError || requestChangesError || releaseError;

  if (errors) {
    console.log('ERROR with an action', errors);
  }

  const printQuote = async () => {

  }

  const leftElements = [];
  const rightElements = [];
  if (canAdjustQuote) {
    if (adjusting) {
      rightElements.push(
        <Button
          key="preview"
          onPress={() => setCanPreview(true)} 
          {...secondaryActionProps}
        >
          {'Preview'}
        </Button>
      );
      rightElements.push(
        <Button
          key="endEditing"
          onPress={disable}
          {...secondaryActionProps}
        >
          {'Cancel'}
        </Button>
      );
      rightElements.push(
        <Button
          key="submitAdjustments"
          onPress={() => setSubmitAdjustmentOpen(true)}
          disabled={hasQuoteError}
          {...primaryActionProps}
          color={hasQuoteError ? '$gray.400' : '$primary'}
        >
          Submit Adjustments
        </Button>
      );
    } else {
      rightElements.push(
        <Button
          key="startEditing"
          {...secondaryActionProps}
          onPress={enable}
        >
          Edit
        </Button>
      );
      rightElements.push(
        <Button
          key="publishQuote"
          {...primaryActionProps}
          onPress={() => setPublishQuoteOpen(true)}
        >
          Publish
        </Button>
      );
    }
  }

  if (canRequestChangesToQuote) {
    rightElements.push(
      <Button
        key="requestChanges"
        {...secondaryActionProps}
        onPress={() => setRequestChangesOpen(true)}
      >
        Request Changes
      </Button>
    )
  }
  // if (canPrintQuote) {
  //   rightElements.push(
  //     <Button
  //       key="printQuote"
  //       {...secondaryActionProps}
  //       onPress={() => printQuote()}
  //       variant="text"
  //     >
  //       Print Quote
  //     </Button>
  //   );
  // }
  if (canRejectQuote) {
    rightElements.push(
      <Button
        key="rejectQuote"
        {...secondaryActionProps}
        onPress={() => setRejectQuoteOpen(true)}
      >
        Reject Quote
      </Button>
    )
  }
  if (canAcceptQuote) {
    rightElements.push(
      <Button
        key="acceptQuote"
        {...primaryActionProps}
        onPress={() => setAcceptQuoteOpen(true)}
      >
        Accept Quote
      </Button>
    )
  }

  console.log(rightElements);
  return (
    <Box ref={ref} {...rest}>
      {rightElements}
      {adjusting && (
        <SubmitAdjustmentDialog
          open={submitAdjustmentOpen}
          hasQuoteError={hasQuoteError}
          onClose={() => setSubmitAdjustmentOpen(false)}
        />
      )}
      <ConfirmDialog
        key="releaseQuote"
        onConfirm={() => releaseQuote(quoteId)}
        open={publishQuoteOpen}
        onClose={() => setPublishQuoteOpen(false)}
        heading="Publish Quote"
        prompt="Please confirm that you've completed your assessment and adjustments and you wish to release the quote to broker and client."
      />
      <SubmitMessageDialog
        key="requestChanges"
        onSubmit={(message) => requestChanges(quoteId, message)}
        open={requestChangesOpen}
        onClose={() => setRequestChangesOpen(false)}
        heading="Request Changes to Quote"
        prompt="Please describe the changes you would like the Underwriter to make."
        messageRequired
      />
      <ConfirmDialog
        key="acceptQuote"
        onConfirm={() => acceptQuote(quoteId)}
        open={acceptQuoteOpen}
        onClose={() => setAcceptQuoteOpen(false)}
        heading="Accept Quote"
        prompt="Please confirm that you and your client are accepting the quote and ready to bind."
      />
      <ConfirmDialog
        key="rejectQuote"
        onConfirm={() => rejectQuote(quoteId)}
        open={rejectQuoteOpen}
        onClose={() => setRejectQuoteOpen(false)}
        heading="Reject Quote"
        prompt="Please confirm that you would like to reject and discard this quote."
      />
      <AppLoadingOverlay
        loading={(
          submitting ||
          releaseStatus === 'pending' ||
          acceptQuoteStatus === 'pending' ||
          rejectQuoteStatus === 'pending' ||
          requestChangesStatus === 'pending'
        )}
      />
    </Box>
  );
}))

const ButtonEnabledWithWatchValue = ({ name, control, defaultValue = '', disabled = false, ...btnProps }) => {
  const value = useWatch({ control, name, defaultValue });
  return (
    <Button disabled={disabled || !value} {...btnProps} />
  )
}

const SubmitAdjustmentDialog = ({ open, onClose, hasQuoteError = false }) => {
  const { adjusting, submitting } = useAdjustmentState();
  const { handleSubmit, control, trigger } = useAdjustmentForm();
  const { setSubmitDialogOpen } = useAdjustmentPreview() || {};

  useEffect(() => {
    if (setSubmitDialogOpen && typeof setSubmitDialogOpen === 'function') {
      setSubmitDialogOpen(open);
    }
  }, [open, setSubmitDialogOpen])

  const submit = async () => {
    await trigger();
    handleSubmit();
    onClose();
  }

  const disabled = hasQuoteError || submitting;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      heading="Submit Adjustment"
      prompt="Please provide the reason for this adjustment."
      actions={(
        <Box flexDirection="row" alignItems="center" gap={18}>
          <Button
            variant="text"
            color="$primary"
            onPress={onClose}
          >Cancel</Button>
          {
            adjusting && (
              <ButtonEnabledWithWatchValue
                name="notes"
                control={control}
                defaultValue=""
                onPress={submit}
                disabled={disabled}
                color={({ disabled }) => disabled ? '$gray.400' : '$primary'}
              >Submit
              </ButtonEnabledWithWatchValue>
            )
          }
        </Box>
      )}
    >
      <Spacing vertical={1} />
      {
        adjusting ? (
          <Controller
            name="notes"
            control={control}
            rules={{ required: 'Required' }}
            render={({
              field: { value, ref, onChange, onBlur },
              fieldState: { error },
            }) => (
              <TextField
                value={value}
                label="Adjustment Reason"
                onChangeValue={v => onChange(v)}
                onBlur={onBlur}
                inputRef={ref}
                variant="filled"
                error={error}
                helperText={error ? error.message : null}
              />
            )}
          />
        ) : null
      }
    </Dialog>
  )
}

const ConfirmDialog = ({
  open,
  onConfirm,
  onClose,
  heading = "Are you sure?",
  prompt = "",
  confirmText = "Confirm",
  cancelText = "Cancel"
}) => {
  const handleConfirm = useCallback(() => {
    if (!open) {
      return;
    }
    if (onClose) {
      onClose();
    }
    if (onConfirm) {
      onConfirm();
    }
  }, [open, onConfirm, onClose])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      heading={heading}
      prompt={prompt}
      actions={(
        <Box flexDirection="row" alignItems="center" gap={18}>
          <Button
            variant="text"
            color="$primary"
            onPress={onClose}
          >{cancelText}</Button>
          <Button
            disabled={!open}
            onPress={handleConfirm}
            color="$primary"
          >{confirmText}
          </Button>
        </Box>
      )}
    >
    </Dialog>
  );
}

const SubmitMessageDialog = ({
  open,
  initialMessage = '',
  onSubmit,
  onClose,
  messageRequired = false,
  requiredMessage = 'Required',
  inputProps = {},
  heading = "Submit Message",
  prompt = "",
  submitText = "Submit",
  cancelText = "Cancel"
}) => {
  const [message, setMessage] = useState(initialMessage);
  const submitDisabled = !open || (messageRequired && (!message || !message.trim()));


  const [error, setError] = useState(null);

  const validate = () => {
    if (messageRequired) {
      if (!message || !message.trim()) {
        setError(requiredMessage);
        return false;
      }
    }
    setError(null);
    return true;
  }

  const handleSubmit = () => {
    if (!open || !validate()) {
      return;
    }
    if (onClose) {
      onClose();
    }
    if (onSubmit) {
      onSubmit(message);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      heading={heading}
      prompt={prompt}
      actions={(
        <Box flexDirection="row" alignItems="center" gap={18}>
          <Button
            variant="text"
            color="$primary"
            onPress={onClose}
          >{cancelText}</Button>
          <Button
            disabled={submitDisabled}
            onPress={handleSubmit}
            color={submitDisabled ? '$gray.400' : '$primary'}
          >{submitText}
          </Button>
        </Box>
      )}
    >
      <Spacing vertical={1} />
      <TextField
        value={message}
        onChangeValue={v => {
          setMessage(v);
          if (error) {
            validate();
          }
        }}
        onBlur={() => validate()}
        label="Message"
        variant="filled"
        error={error}
        helperText={error ? error : null}
        {...inputProps}
      />
    </Dialog>
  )
}

const ViewApplicationButton = ({ quoteId }) => {
  const [open, setOpen] = useState(false);
  const closeModal = useEventCallback(() => setOpen(false));
  return (
    <>
      <Button label="View Application" variant="text" onPress={() => setOpen(!open)} />
      <Modal
        open={open}
        onClose={closeModal}
        hideBackdrop
        scrollable
        ScrollProps={{
          minHeight: '101%',
          padTop: 100,
          containerStyle: { minHeight: '101%' },
          provideNode: true,
        }}
      >
        <ApplicationView quoteId={quoteId}>
          
        </ApplicationView>
        <ModalBackdrop open={open} onPress={closeModal} zIndex="-2" />
      </Modal>
    </>
  )
}