import React, { useRef, useCallback, useState, useEffect, useMemo } from 'react';
import { Box, Heading, Button, TextField, Menu, Text, MenuItem, ActivityIndicator, ListRenderer, IconButton, Popover } from '../ui';
import { useEventCallback, useMemoCompare } from '../ui/hooks';
import { debounce, isNull, isObject } from '../ui/utils';
import { withStyles, styled } from '../ui/styling';
import { flexBox, layout, appearance  } from '../ui/system/props';
import { ExpandArrowIcon, FilterIcon, MenuVerticalIcon, SearchIcon, SortIcon, ViewGridIcon, ViewRowsIcon } from './icons';
import { useAppState, useFilterQueryParams, useQueryParam, useSearchQueryParams, useSortQueryParams } from '../hooks';
import { filterOperators, ListDisplayTypes, quoteStatusOptions, sortByOptions } from '../constants';
import { merge } from 'merge-anything';
import { LayoutBox } from './Layout';
import equal from 'fast-deep-equal/react';



export const ListHead = styled(LayoutBox, { filterProps: [...layout.filterProps] })(props => ({
  ...layout({
    width: '100%',
    alignSelf: 'stretch',
    alignItems: 'stretch',
    padBottom: ({ theme: { spacing } }) => spacing(2),
    ...props,
  }),
  props: {
    layout: 'bottom-left',
  }
}));

export const ListHeadRow = styled(LayoutBox, { filterProps: [...layout.filterProps] })(props => ({
  ...layout({
    width: '100%',
    height: '$appBarHeight',
    ...props,
  }),
  props: { row: true, layout: 'center-left' },
}));

export const ListHeading = Heading.withProps({
  level: 3,
  padLeft: ({ theme: { spacing } }) => spacing(1),
})

const ListViewHeader = withStyles(({ theme: { spacing }, ...rest }) => ({
  root: {
    ...layout({
      flexDirection: 'column',
      width: '100%',
      maxWidth: '100%',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padBottom: spacing(3.25),
      ...rest,
    }),
  },
}), { filterProps: [...layout.filterProps] })(React.forwardRef(function ListViewHeader(props, ref) {
  const {
    heading,
    styles,
    children,
    ...rest
  } = props;

  return (
    <Box ref={ref} {...rest}>
      {typeof heading === 'string'
        ? <ListViewHeading>{heading}</ListViewHeading>
        : typeof heading === 'function' ? heading()
        : heading
      }
      {children}
    </Box>
  );
}));

export const ListDisplayTypeButton = React.forwardRef(({ displayType = ListDisplayTypes.CARDS, ...rest }, ref) => {
  return (
    <IconButton
      color="$text"
      maxSize="38"
      ref={ref}
      {...rest}
    >
      {displayType === ListDisplayTypes.CARDS ? 
        <ViewRowsIcon size="22" />
      : <ViewGridIcon size="22" />}
    </IconButton>
  )
})


const ListViewHeading = Heading.withProps({
  level: 3,
  padLeft: ({ theme: { spacing } }) => spacing(1),
  spaceAfter: true,
})


const ListViewHeadingBox = withStyles(({ theme }) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {},
}))(({ styles, children, heading, ...rest }) => {
  return (
    <Box {...rest}>
      {typeof heading === 'string'
        ? <ListViewHeading {...styles.toProps('heading')}>{heading}</ListViewHeading>
        : typeof heading === 'function' ? heading()
        : heading
      }
      {children}
    </Box>
  )
});


const Controls = styled(Box, { filterProps: [...layout.filterProps ]})
(({ theme: { breakpoints, spacing }, ...props }) => ({
  ...layout({
    flexDirection: breakpoints({ xs: 'column', sm: 'row' }),
    justifyContent: breakpoints({ xs: 'flex-start', sm: 'space-between' }),
    alignItems: breakpoints({ xs: 'stretch', sm: 'center' }),
    padX: breakpoints({ xs: spacing(1), sm: spacing(1.25) }),
    ...props
  }),
}));

const ControlGroup = styled(Box, { filterProps: [ ...flexBox.filterProps, 'gap' ]})
(({ theme: { breakpoints }, gap = 8, ...props }) => ({
  ...flexBox({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...props
  }),
  props: { gap },
}));

const ControlButton = styled(Button, { filterProps: ['borderColor', 'borderRadius', 'color', 'variant']})(({
  theme: { colors },
  breakpoint,
  variant = 'outlined',
  color = '$text',
  borderRadius = 3,
  borderColor,
  label,
}) => ({
  borderRadius,
  borderColor: borderColor ? borderColor : colors.alpha(colors.on('$background', 0.064)),
  props: {
    variant,
    color,
    label: typeof label === 'function' ? label(breakpoint) : label,
  },
}));

export const SearchControlButton = ControlButton;

export const FilterChip = React.memo(({ selected, color, onPress, badgeText, value, name, label }) => {
  const backgroundColor = selected ? color ? color : '$primary.dark' : '$gray.50';
  const handleOnPress = useEventCallback(() => onPress && onPress(value));
  return (
    <Box
      sx={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '$circle',
        padX: '$2',
        padY: '$0.75',
        borderWidth: 1,
        borderColor: (theme) => selected ? theme.colors.opacity('black', 0.11) : theme.colors.opacity('$gray.400', 0.25),
        backgroundColor,
      }}
      onPress={onPress ? handleOnPress : undefined}
    >
      <Text
        align="center"
        onColor={backgroundColor}
        size="small"
        bold
        capitalize
      >
        {label}
      </Text>
      {badgeText ? (
        <Box
          marginLeft="$1"
          size="25"
          bg={({ theme }) => theme.colors.darken('$coral', 0.12)}
          borderRadius="$circle"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            weight="$bolder"
            color="$white"
            small
          >
            {badgeText}
          </Text>
        </Box>
      ) : null}
    </Box>
  )
}, equal);

export const FilterMenu = ({ queryConfig, ...rest }) => {
  const queryArgs = useMemoCompare([queryConfig]);
  return (
    <QueryOptionsMenu
      queryArgs={queryArgs}
      useQueryHook={useFilterQueryParams}
      {...rest}
    />
  )
}

export const QueryOptionsMenu = React.memo(withStyles(({ theme, inline = false }) => ({
  root: inline ? {} : {
    backgroundColor: 'white',
    shadow: {
      color: 'rgb(49, 51, 51)',
      offset: { y: 5 },
      opacity: 0.07,
      radius: 28,
    },
    borderWidth: 1,
    borderRadius: 3,
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.04)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padX: theme.spacing(3),
    padY: theme.spacing(4),
    props: {
      gap: theme.spacing(2),
    },
  },
}))(({
  inline = false,
  hide = false,
  queryArgs,
  onSelect,
  param,
  useQueryHook = useQueryParam,
  defaultValue = undefined,
  valueType,
  selected,
  options: optionsProp,
  styles,
  anchorNode,
  open,
  onClose,
  OptionComponent = FilterChip,
  ...rest
}) => {
  const [queriedValue, setQuery] = useQueryHook(...(queryArgs ? queryArgs : [param, defaultValue, valueType]));
  const selectedValue = typeof selected === 'function' ? selected(queriedValue) : selected;
  const handleOnSelect = useEventCallback((val) => {
    if (onClose && !inline) {
      onClose();
    }
    if (onSelect) {
      onSelect(val, setQuery);
    }
  });
  const options = useMemoCompare(optionsProp);
  const children = useMemo(() => Object.keys(options).map((key, i) => {
    const item = options[key];
    const value = !isNull(item.value) ? item.value : key;
    const chipProps = {
      key: `${key}${i}`,
      selected: value === selectedValue || (isNull(selectedValue) && value === defaultValue),
      onPress: () => handleOnSelect(value),
      ...item,
    };
    return <OptionComponent {...chipProps} />;
  }), [options, handleOnSelect, selectedValue, defaultValue, OptionComponent]);

  if (inline) {
    return (
      <Box display={hide ? 'none' : 'flex'} flexDirection="row" justifyContent="flex-start" alignItems="center" gap="16" {...rest}>
        {children}
      </Box>
    )
  }
  return (
    <Popover anchorNode={anchorNode} open={open} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
      <Box {...rest}>
        {children}
      </Box>
    </Popover>
  );
}), equal);

export const QueryControlButton = React.memo(({ label = 'Options', IconComponent = FilterIcon, MenuComponent = QueryOptionsMenu, ...rest }) => {
  const btnRef = useRef(null);
  const [open, setOpen] = useState(false);
  const toggleOpen = useEventCallback(() => setOpen(c => !c));
  const startIcon = useMemo(() => IconComponent ? <IconComponent color="$text" size="20" /> : null, [IconComponent]);
  const closeMenu = useEventCallback(() => setOpen(false));
  return (
    <>
      <ControlButton ref={btnRef} label={label} onPress={toggleOpen} startIcon={startIcon} />
      <MenuComponent
        anchorNode={btnRef.current}
        open={open}
        onClose={closeMenu}
        {...rest}
      />
    </>
  )
}, equal);

export const FilterControlButton = ({ label = 'Filter', IconComponent = FilterIcon, ...rest }) => {
  return <QueryControlButton label={label} IconComponent={IconComponent} MenuComponent={FilterMenu} {...rest} />;
};

const SortButton = ({ label="Sort by", options, MenuProps, MenuItemProps, defaultSortBy = sortByOptions.ASC.name, ...rest }) => {
  const btnRef = useRef();
  const [open, setOpen] = useState(false);
  const onClose = useEventCallback(() => setOpen(false));
  return (
    <>
      <ControlButton
        ref={btnRef}
        onPress={() => setOpen(curr => !curr)}
        label={(breakpoint) => breakpoint.key === 'sm' ? '' : label}
        startIcon={<SortIcon color="$text" size="20" />}
        {...rest}
      />
      <SortMenu
        open={open}
        defaultSortBy={defaultSortBy}
        options={options}
        anchorNode={btnRef.current}
        onClose={onClose}
        MenuItemProps={MenuItemProps}
        {...MenuProps}
      />
    </>
  )
}

const SortMenu = ({ onClose, options: opts, defaultSortBy, MenuItemProps, ...rest }) => {
  const [{ field = null, by = null }, setQuery] = useSortQueryParams();
  const [currentSort, setCurrentSort] = useState({ field, by });
  const currentSortField = currentSort.field;
  const currentSortBy = currentSort.by;
  const shouldUpdate = useRef(false);
  const options = useMemoCompare(opts);
  useEffect(() => {
    if (shouldUpdate.current) {
      shouldUpdate.current = false;
      if (!options[currentSortField]) {
        setQuery();
      } else {
        setQuery({
          field: currentSortField,
          by: currentSortBy ? currentSortBy : by ? by : defaultSortBy,
        })
      }
    } else {
      if (currentSortField !== field || currentSortBy !== by) {
        setCurrentSort({ field, by });
      }
    }
  }, [field, by, currentSortField, currentSortBy, defaultSortBy, setQuery, options]);

  const { fieldKeys, byKeys } = useMemo(() => {
    return { fieldKeys: Object.keys(options), byKeys: Object.keys(sortByOptions) };
  }, [options]);

  const itemProps = useMemoCompare(MenuItemProps);

  const { fieldItems, byItems } = useMemo(() => {
    const fieldItems = fieldKeys.map((key) => {
      const value = options[key].name;
      const selected = currentSortField === value;
      const label = options[key].label;
      return (
        <MenuItem
          key={key}
          selected={selected}
          onPress={() => {
            shouldUpdate.current = true;
            onClose();
            setCurrentSort((c) => ({ field: selected ? null : value, by: c.by }));
          }}
          {...itemProps}
        >
          {label}
        </MenuItem>
      )
    });
    const byItems = byKeys.map((key, i) => {
      const value = sortByOptions[key].name;
      const selected = currentSortBy === value || (!currentSortBy && defaultSortBy === value);
      const label = sortByOptions[key].label;
      return (
        <MenuItem
          key={key}
          selected={selected}
          onPress={() => {
            shouldUpdate.current = true;
            onClose();
            setCurrentSort(c => ({ field: c.field, by: value }));
          }}
          borderBottomWidth={i === byKeys.length - 1 ? 1 : 0}
          borderBottomColor="$gray.300"
          {...itemProps}
        >
          {label}
        </MenuItem>
      )
    });
    return { fieldItems, byItems };
  }, [currentSortField, currentSortBy, defaultSortBy, options, fieldKeys, byKeys, onClose, itemProps]);
  return (
    <Menu onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} {...rest}>
      {byItems}
      {fieldItems}
    </Menu>
  );
}

const SearchInput = React.memo(withStyles(({ theme: { spacing, breakpoints } }) => {
  const p = {
    InputProps: {
      size: 'medium',
    },
    variant: 'outlined',
    hideHelperText: true,
    opacity: 0.9,
    styles: { textBox: { padY: spacing(1.75) }},
  };
  return {
    root: {
      props: breakpoints({
        xs: {
          width: '100%',
          ...p,
        },
        sm: {
          width: 400,
          ...p,
        },
        md: {
          width: 456,
          ...p,
        }
      }),
    }
  }
})(React.forwardRef(function SearchInput(props, ref) {
  const { onChangeValue, styles, initialValue, ...rest } = props;
  const mounted = useRef(true);
  useEffect(() => { return () => mounted.current = false }, []);
  
  const [input, setInput] = useState(initialValue ? initialValue : '');

  useEffect(() => {
    if (!onChangeValue) {
      return;
    }
    const debounceOnChangeValue = debounce(() => {
      if (mounted.current && onChangeValue) {
        onChangeValue(input ? input : '');
      }
    }, 500);

    debounceOnChangeValue();

    return () => {
      debounceOnChangeValue.clear();
    }
  }, [input, onChangeValue]);
  
  return (
    <TextField
      value={input}
      onChangeValue={(v) => setInput(v)}
      ref={ref}
      startAdornment={(
        <Box width={26} ml="$-0.5" height={24} pt="$0.25" overflow="visible">
          <SearchIcon color="$gray.400" />
        </Box>
      )}
      {...rest}
    />
  )
})), equal);

export const SearchFilterInput = React.forwardRef((props, ref) => {
  const { filterOptions, initialOption: initialOptionProp, initialValue: initialValueProp, onChangeField, onChangeValue: onChangeValueProp, ...rest } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const btnRef = useRef();
  const lastField = useRef(null);
  const [cf, setFilter] = useSearchQueryParams();
  const initialField = useRef('notset');
  if (initialField.current === 'notset') {
    if (cf.length && cf[0].field) {
      initialField.current = cf[0].field;
    } else if (initialOptionProp) {
      initialField.current = initialOptionProp;
    }
    if (initialField.current === 'notset') {
      throw new Error('No initial field option for search filter');
    }
  }
  const [field, setField] = useState(initialField.current);

  const initialValue = useRef('notset');
  if (initialValue.current === 'notset') {
    if (cf.length && field) {
      for (const f of cf) {
        if (f.field === field && f.value) {
          let val = `${f.value}`;
          initialValue.current = val.replace(/%/g, '');
          break;
        }
      }
    }
    if ((initialValue.current === 'notset' || !initialValue.current) && initialValueProp) {
      let val = `${initialValueProp}`;
      initialValue.current = val.replace(/%/g, '');
    }
    if (initialValue.current === 'notset') {
      initialValue.current = '';
    }
  }
  
  const onSelect = useCallback((f) => {
    lastField.current = f !== field ? field : lastField.current;
    setField(f);
    setMenuOpen(false);
    if (onChangeField) {
      onChangeField(f);
    }
  }, [field, onChangeField]);

  const onChangeProp = useEventCallback((v) => {
    if (onChangeValueProp) {
      onChangeValueProp(v);
    }
  });

  const onChangeValue = useCallback((nextValue) => {
    const value = nextValue && typeof nextValue === 'string' && nextValue.trim() ? `%${nextValue.trim()}%` : null;
    onChangeProp(value);
    if (filterOptions[field]) {
      setFilter((currentFilters) => {
        let filters = [];
        if (lastField.current) {
          filters = currentFilters.filter(f => f.field !== lastField.current && f.field !== field);
        } else {
          filters = currentFilters.filter(f => f.field !== field);
        }
        filters.push({ field: filterOptions[field].name, value, operator: filterOperators.ilike });
        return filters;
      });
    }
  }, [field, setFilter, onChangeProp])

  const toggleMenuOpen = useEventCallback(() => setMenuOpen(c => !c));
  const labelForCurrentFilter = filterOptions[field].label;
  const endAdornment = useMemo(() => (
    <Box ref={btnRef} justifyContent="center" alignItems="flex-end" overflow="visible" padLeft="$1.5" onPress={toggleMenuOpen}>
      <Box flexDirection="row" justifyContent="center" alignItems="center" >
        <Text small color="$gray.400" maxWidth={112} maxLines={1}>{labelForCurrentFilter}</Text>
      </Box>
      <Box width="100%" height="165%" borderLeftWidth="1" borderLeftColor="$gray.300" position="absolute" />
    </Box>
  ), [toggleMenuOpen, labelForCurrentFilter]);
  
  const closeMenu = useEventCallback(() => setMenuOpen(false));

  return (
    <>
      <SearchInput
        ref={ref}
        {...rest}
        onChangeValue={onChangeValue}
        initialValue={initialValue.current}
        endAdornment={endAdornment}
      />
      <SearchFilterInputMenu
        filterOptions={filterOptions}
        selected={field}
        onSelect={onSelect}
        anchorNode={btnRef.current}
        open={menuOpen}
        onClose={closeMenu}
      />
    </>
  )
});

const SearchFilterInputMenu = React.memo((props) => {
  const { filterOptions, selected, onSelect, ...menuProps } = props;

  return (
    <Menu anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} {...menuProps}>
      {Object.keys(filterOptions).map((key) => {
        return (
          <MenuItem
            key={key}
            selected={key === selected}
            onPress={() => onSelect(key)}
          >
            {filterOptions[key].label}
          </MenuItem>
        )
      })}
    </Menu>
  )
}, equal);

ListViewHeader.Heading = ListViewHeading;
ListViewHeader.HeadingBox = ListViewHeadingBox;
ListViewHeader.Controls = Controls;
ListViewHeader.ControlGroup = ControlGroup;
ListViewHeader.ControlButton = ControlButton;
ListViewHeader.SearchInput = SearchInput;
ListViewHeader.SortButton = SortButton;

const StyledListView = styled(Box, { filterProps: [...layout.filterProps, ...appearance.filterProps] })
(({ theme: { colors, breakpoints, spacing }, ...props }) => ({
  ...layout({
    width: '100%',
    flex: 1,
    padX: breakpoints({ xs: spacing(2), sm: spacing(4), md: 0 }),
    ...props,
  }),
  ...appearance({
    borderWidth: 0,
    borderColor: colors.gray['200'],
    ...props,
  })
}));

const ListViewLoading = React.forwardRef((props, ref) => {
  const { LoadingComponent = ActivityIndicator, LoadingComponentProps, children, ...rest } = props;
  return (
    <Box ref={ref} alignSelf="stretch" justifyContent="center" minHeight={200} alignItems="center" {...rest}>
      {children ? children : <LoadingComponent {...LoadingComponentProps} />}
    </Box>
  );
})

export const ListLoadingIndicator = React.memo(({ loading = false, ...rest }) => {
  return (
    <Box
      pointerEvents="none"
      sx={{
        zIndex: -1,
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: ({ spacing }) => spacing(16),
      }}
      {...rest}
    >
      <ActivityIndicator animating={loading} />
    </Box>
  )
})

const ListView = React.forwardRef(function ListView(props, ref) {
  const { children, renderLoading = false, ...rest } = props;
  const loading = renderLoading ? typeof renderLoading === 'function' ? renderLoading() : <ListViewLoading /> : null;
  return (
    <StyledListView ref={ref} {...rest}>
      {children}
      {loading}
    </StyledListView>
  )
});

ListView.Loading = ListViewLoading;




export const ITEM_ROW_HEIGHT = 68;

export const ItemRow = React.memo(React.forwardRef((props, ref) => {
  const { item, RowProps = null, children, head = false, cellProps = null, onPress, ...rest } = props;
  const asHeaderProps = useMemo(() => {
    if (head) {
      return {
        bold: true, dim: 0.65, size: 'xSmall', uppercase: true,
      }
    }
    return null;
  }, [head]);

  const columns = useColumnsConfig(item, head);

  const handleOnPress = useEventCallback(() => {
    if (onPress) {
      onPress(item);
    }
  });
  return (
    <LayoutBox
      ref={ref}
      width="100%"
      alignSelf="stretch"
      layout="center"
      px="$2"
      bg="$white"
      border={{
        width: head ? 0 : 0,
        color: ({ theme }) => theme.colors.opacity('$gray.300', 0.3),
        bottom: {
          width: head ? 1 : 1,
        }
      }}
      borderRadius={0}
      onPress={onPress ? handleOnPress : undefined}
      focusable={onPress ? true : undefined}
      {...rest}
    >
      <LayoutBox row width="100%" alignSelf="stretch" height={ITEM_ROW_HEIGHT} gap={12} layout={head ? 'bottom-center' : 'center'}
        padTop={head ? '$3' : '$2'}
        padBottom={head ? '$1' : '$2'}
        {...RowProps}
      >
        {Array.isArray(columns) && columns.length ? columns.map(({ props: p, ...c } = {}, i) => {
          return <ItemRowCell key={`rowCell${i}`} index={i} {...cellProps} head={head} asHeaderProps={asHeaderProps} {...p} {...c} />
        }) : null}
      </LayoutBox>
      {children}
    </LayoutBox>
  )
}));

export const ItemRowCell = React.memo(props => {
  const {
    children,
    label,
    value,
    index: columnIndex,
    TextProps = {},
    head = false,
    asHeaderProps = {},
    option,
    optionIndex,
    optionLabels,
    options = [],
    setColumnOption,
    onPress,
    onSelect,
    ...rest
  } = props;

  let child = null;
  const item = head ? label : value; 
  const validOptions = Array.isArray(options) && options.length;
  if (typeof item === 'string' || typeof item === 'number') {
    const textProps = { ...asHeaderProps, ...TextProps };
    child = <Text small maxLines={1} maxWidth="100%" value={item} {...textProps} />;
  } else if (React.isValidElement(item) || Array.isArray(item)) {
    child = item;
  }

  const [open, setOpen] = useState(false);
  const handleOnClose = useEventCallback(() => setOpen(false));
  const handleOnPress = useEventCallback(() => {
    setOpen((c) => !c);
    if (onPress) {
      onPress();
    }
  });
  const handleOnSelect = useEventCallback((optIndex) => {
    setOpen(false);
    if (optIndex === optionIndex) {
      return;
    }
    if (onSelect) {
      onSelect(columnIndex, optIndex);
    } else if (setColumnOption && head && validOptions) {
      setColumnOption(columnIndex, optIndex);
    }
  });
  const cellRef = useRef();
  const { layout="center-left", row = false, flexDirection, contentMaxWidth = '100%', ...rootProps } = rest;
  return (
    <>
      <LayoutBox ref={cellRef} flex={1} onPress={head && validOptions ? handleOnPress : undefined} {...rootProps}>
        <LayoutBox minHeight={24} row width="100%" height="100%" layout={layout}>
          <LayoutBox height="100%" row={row} flexDirection={flexDirection} maxWidth={contentMaxWidth}>
            {child}
          </LayoutBox>
          {head && validOptions ? <ExpandArrowIcon color="$gray.400" size="20" pb="2" pl="2" /> : null}
        </LayoutBox>
        {children}
      </LayoutBox>
      {head && validOptions ? (
        <Menu anchorNode={cellRef.current} open={open} onClose={handleOnClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          {(Array.isArray(optionLabels) && optionLabels.length === options.length ? optionLabels : options).map((optLabel, optIndex) => {
            return (
              <MenuItem
                key={`${optLabel}${optIndex}`}
                selected={optIndex === optionIndex}
                onPress={() => handleOnSelect(optIndex)}
                borderTopWidth={optIndex === 0 ? 0 : 1}
                borderTopColor="$gray.100"
              >
                {optLabel}
              </MenuItem>
            )
          })}
        </Menu>
      ) : null}
    </>
  )
});

const columnsConfigContext = React.createContext([]);
const columnsOptionsContext = React.createContext([]);
export const ColumnsAndSettingsProvider = ({ viewKey, onChange, ...rest }) => {
  const [{ settings, saveSettings }] = useAppState();
  let initialColumnsDisplayRef = useRef(null);
  let hasSet = useRef(false);
  if (!hasSet.current && viewKey && settings && settings.current && settings.current[viewKey] && settings.current[viewKey].columnsOptions) {
    if (Array.isArray(settings.current[viewKey].columnsOptions) && Array.length) {
      hasSet.current = true;
      initialColumnsDisplayRef.current = settings.current[viewKey].columnsOptions;
    }
  }
  const handleOnChange = useEventCallback((next) => {
    if (viewKey && Array.isArray(next) && next.length) {
      const settingsNext = {};
      settingsNext[viewKey] = { columnsOptions: next };
      saveSettings(settingsNext);
    }
    if (onChange) {
      onChange(next);
    }
  });
  return <ColumnsProvider onChange={handleOnChange} initialColumnsDisplay={initialColumnsDisplayRef.current} {...rest} />;
}
export const ColumnsProvider = ({ children, config, initialColumnsDisplay, onChange }) => {
  const [columnsOptions, setOptions] = useState(() => {
    if (Array.isArray(initialColumnsDisplay)) {
      return [...initialColumnsDisplay];
    } else if (Array.isArray(config) && config.length) {
      return config.map((column) => {
        if (isObject(column)) {
          const { options, defaultOption } = column;
          if (Array.isArray(options) && options.length) {
            if (defaultOption && options.includes(defaultOption)) {
              return options.indexOf(defaultOption);
            }
            return 0;
          }
        }
        return null;
      });
    }
    return [];
  });
  const setColumnOption = useEventCallback((columnIndex, valueIndex) => {
    const next = [...columnsOptions];
    next[columnIndex] = valueIndex;
    setOptions(next);
    if (onChange) {
      onChange(next);
    }
  });
  const contextValue = useMemoCompare({ config: config || [], setColumnOption });
  return (
    <columnsConfigContext.Provider value={contextValue}>
      <columnsOptionsContext.Provider value={columnsOptions}>
        {children}
      </columnsOptionsContext.Provider>
    </columnsConfigContext.Provider>
  )
}

export const useColumnsConfig = (data, skipValue = false) => {
  const { config, setColumnOption } = React.useContext(columnsConfigContext);
  const columnsOptions = React.useContext(columnsOptionsContext);
  
  const result = useMemo(() => {
    let columns = [];
    if (Array.isArray(config) && config.length > 0) {
      const validColumnOptions = Array.isArray(columnsOptions) && columnsOptions.length > 0;
      columns = config.map((column, columnIndex) => {
        let optionIndex = 0;
        let option = null;
        if (validColumnOptions && columnIndex < columnsOptions.length) {
          const cOption = columnsOptions[columnIndex];
          if (typeof cOption === 'number' && cOption !== -1) {
            optionIndex = cOption;
          } else if (typeof cOption === 'string') {
            option = cOption;
          }
        }
        let options = [];
        let optionLabels = [];
        let label = '';
        let props = null;
        let value = null;
        if (!isObject(column)) {
          return { label, props, options, value, option, optionIndex, optionLabels };
        }
        if (Array.isArray(column.options) && column.options.length) {
          options = column.options;
          if (optionIndex !== null && optionIndex < options.length) {
            option = options[optionIndex];
          } else if (option !== null && options.includes(option)) {
            optionIndex = options.indexOf(option);
          } else if (column.defaultOption && options.includes(column.defaultOption)) {
            option = column.defaultOption;
            optionIndex = options.indexOf(option.defaultOption);
          } else {
            optionIndex = 0;
            option = options[0];
          }
        }
        if (Array.isArray(column.optionLabels)) {
          optionLabels = column.optionLabels;
        } else if (Array.isArray(column.label)) {
          optionLabels = column.label;
        } else if (options.length) {
          optionLabels = options;
        }
        if (typeof column.label === 'function') {
          label = column.label(data, option, optionIndex);
        } else if (optionIndex < optionLabels.length && optionLabels[optionIndex] !== null) {
          label = optionLabels[optionIndex];
        } else if (!isNull(column.label)) {
          label = column.label;
        }
        if (isObject(column.props)) {
          props = column.props;
        } else if (typeof column.props === 'function') {
          props = column.props(data, option, optionIndex);
        } else if (Array.isArray(column.props) && optionIndex < column.props.length) {
          props = column.props[optionIndex];
        }
        if (!skipValue) {
          if (typeof column.value === 'function') {
            value = column.value(data, option, optionIndex);
          } else if (Array.isArray(column.value) && optionIndex < column.value.length) {
            value = column.value[optionIndex];
          }
          if (isNull(value)) {
            if (!isNull(column.value)) {
              value = column.value;
            } else if (!isNull(column.defaultValue)) {
              value = column.defaultValue;
            }
          }
        }
        
        return { label, props, options, value, option, optionIndex, optionLabels, setColumnOption };
      })
    }
    return columns
  }, [data, config, setColumnOption, columnsOptions, skipValue])
  
  return useMemoCompare(result);
}


/*
const byItems = byKeys.map((key, i) => {
      const value = sortByOptions[key].name;
      const selected = currentSortBy === value || (!currentSortBy && defaultSortBy === value);
      const label = sortByOptions[key].label;
      return (
        <MenuItem
          key={key}
          selected={selected}
          onPress={() => {
            shouldUpdate.current = true;
            onClose();
            setCurrentSort(c => ({ field: c.field, by: value }));
          }}
          borderTopWidth={i === 0 ? 1 : 0}
          borderTopColor="$gray.300"
          {...itemProps}
        >
          {label}
        </MenuItem>
      )
    });
    return { fieldItems, byItems };
  }, [currentSortField, currentSortBy, defaultSortBy, options, fieldKeys, byKeys, onClose, itemProps]);
  return (
    <Menu onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} {...rest}>
      {fieldItems}
      {byItems}
    </Menu>
*/
ItemRow.Cell = ItemRowCell;

export const DataListRenderer = React.memo(React.forwardRef((props, ref) => {
  const {
    data,
    loading,
    itemComponent: Item,
    displayType = ListDisplayTypes.CARDS,
    renderItem,
    keyExtractor,
    renderHeader,
    requestMoreData,
    onSelect,
    requestingMore = false,
    emptyMessage = 'Nothing to show',
    getHrefForItem,
    styles: stylesProp,
    onEndReachedThreshold = 1.5,
    itemPath,
    ...rest
  } = props;

  const listStyles = {
    root: {
      minHeight: 400,
      padX: ({ theme }) => theme.breakpoints({ xs: theme.spacing(2), sm: theme.spacing(4), md: 0 }),
      padBottom: '$16',
    },
    item: {
      height: ({ theme }) => theme.breakpoints({ xs: 222, sm: 183 }),
      mb: '$3'
    },
    listLoading: {
      justifyContent: 'center',
    },
    header: {
      height: ({ theme }) => theme.breakpoints({ xs: 186, sm: 154 }),
    }
  };

  let styles = listStyles;

  if (stylesProp && isObject(stylesProp)) {
    styles = merge(listStyles, stylesProp);
  } else if (Array.isArray(stylesProp) && stylesProp.length) {
    for (const s of stylesProp) {
      if (isObject(s)) {
        styles = merge(styles, s);
      }
    }
  } else if (typeof stylesProp === 'function') {
    styles = [listStyles, stylesProp]
  }
  
  const getHrefFor = useEventCallback((item) => {
    if (getHrefForItem !== undefined) {
      if (typeof getHrefForItem === 'function') {
        return getHrefForItem(item);
      }
      return null;
    }
    return item && itemPath ? `${itemPath}/${item.id}` : null;
  });
  
  return (
    <ListRenderer
      ref={ref}
      data={data}
      loading={loading}
      keyExtractor={keyExtractor ? keyExtractor : (item, uniqueKey) => item ? item.id : uniqueKey}
      onEndReached={requestMoreData}
      onEndReachedThreshold={onEndReachedThreshold}
      styles={styles}
      renderItem={renderItem ? renderItem : !Item ? null : ({ key, style, item, height }) => (
        <Item
          key={key}
          item={item}
          style={style}
          height={height}
          onPress={onSelect ? onSelect : undefined}
          href={getHrefFor(item)}
          // displayType={displayType}
        />
      )}
      renderEmpty={({ key, style }) => <EmptyView message={emptyMessage} style={style} key={key} />}
      renderLoading={({ key, style }) => <ListViewLoading key={key} style={style} />}
      renderAccessory={({ style }) => <ListLoadingIndicator loading={requestingMore} style={style} />}
      renderHeader={renderHeader}
      {...rest}
    />
  )
}), equal);

const EmptyView = ({ message = 'Nothing to show!' }) => {
  return (
    <Box flex="1" justifyContent="center" alignItems="center" padding="$2.5">
      <Text dim size="large">{message}</Text>
    </Box>
  );
}


export { ListViewHeader, ListView };