export function getAddressLines(address, region, postcode) {
  if (!address) {
    return ['', ''];
  }
  const addressParts = address.split(',');
  let street = '';
  let city = '';
  let state = region || '';
  let zip = postcode || '';
  if (addressParts.length) {
    street = addressParts[0].trim();
    if (addressParts.length > 1) {
      city = addressParts[1].trim();
    }
    if (addressParts.length > 2) {
      if (!state && addressParts[2]) {
        state = addressParts[2].trim();
      }
    }
    if (addressParts.length > 3) {
      if (!zip && addressParts[3]) {
        zip = addressParts[3].trim();
      }
    }
  }

  const line1 = `${street}`;
  const line2 = `${city ? city + ', ' : ''}${state} ${zip}`;
  return [line1, line2];
}