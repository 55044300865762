import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { Box } from '../Box';
import { withStyles } from '../../styling';
import { StepIcon } from './StepIcon';
import { useStepperContext, useStepContext } from './StepperContext';


const StepConnector = withStyles(({ theme: { spacing, colors } }) => ({
  root: {
    flex: 1,
  },
  horizontal: {},
  vertical: {
    width: StepIcon.width,
    padLeft: StepIcon.width / 2,
  },
  verticalContent: {
    position: 'absolute',
    width: '100%',
    height: StepIcon.width,
    justifyContent: 'center',
    alignItems: 'center',
    top: spacing(0.25),
    padX: spacing(2),
    tx: '-100%',
  },
  line: {
    borderColor: '$gray.200',
  },
  lineHorizontal: {
    borderTopWidth: 2,
  },
  lineVertical: {
    borderLeftWidth: 2,
    minHeight: spacing(3),
  },
}), { preserveStyleProp: true })(React.forwardRef(function StepConnector(props, ref) {
  const {
    style,
    styles,
    ...rest
  } = props;

  const { verticalContent, vertical } = useStepperContext();
  const { active, disabled, completed } = useStepContext();

  return (
    <Box
      ref={ref}
      style={[
        styles.root,
        vertical ? styles.vertical : styles.horizontal,
        verticalContent ? styles.verticalContent : null,
        style,
      ]}
      {...(vertical ? styles.props.vertical : styles.props.horizontal)}
      {...(verticalContent ? styles.props.verticalContent : null)}
      {...rest}
    >
      <Box
        style={[
          styles.line,
          vertical ? styles.lineVertical : styles.lineHorizontal,
        ]}
        {...(vertical ? styles.props.lineVertical : styles.props.lineHorizontal)}
      />
    </Box>
  );
}))

export { StepConnector };
