import { isNull } from "../../ui/utils";

// range start and end indicators used for building query string in MIN_MAX_RANGE and DATES_FROM_TO
export const QUERY_RANGE_START = 'f';
export const QUERY_RANGE_END = 'l';
export const BOOLEAN_TRUE_VALUES = ['true', 'yes', '1', 1, 'Yes', 'YES', 'True', 'TRUE', 'on', 'ON'];

export function getBooleanFromQueryValue(value) {
  if (typeof value !== 'boolean') {
    if (BOOLEAN_TRUE_VALUES.includes(value)) {
      value = true;
    } else {
      value = false;
    }
  }
  return value;
}

export function getRangeFromQueryString(val) {
  const rangeString = typeof val === 'string' ? val.trim() : null;
  const range = [null, null];
  if (!rangeString) {
    return range;
  }
  const startFirstIndex = rangeString.indexOf(QUERY_RANGE_START);
  const endFirstIndex = rangeString.indexOf(QUERY_RANGE_END);
  if (startFirstIndex !== -1) {
    const startLastIndex = startFirstIndex !== 0 || endFirstIndex === -1 ? rangeString.length : endFirstIndex;
    const startValueString = rangeString.slice(startFirstIndex + 1, startLastIndex);
    if (startValueString.length) {
      const startValueNumber = Number(startValueString);
      if (!isNaN(startValueNumber)) {
        range[0] = startValueNumber;
      }
    }
  }
  if (endFirstIndex !== -1) {
    const endLastIndex = endFirstIndex !== 0 || startFirstIndex === -1 ? rangeString.length : startFirstIndex;
    const endValueString = rangeString.slice(endFirstIndex + 1, endLastIndex);
    if (endValueString.length) {
      const endValueNumber = Number(endValueString);
      if (!isNaN(endValueNumber)) {
        range[1] = endValueNumber;
      }
    }
  }
  return range;
}

export function getQueryStringForRange(range) {
  if (!Array.isArray(range) || range.length !== 2) {
    return '';
  }
  const [start, end] = range;
  let startString = '';
  let endString = '';
  if (!isNull(start) && typeof start === 'number' && !isNaN(start)) {
    startString = `${QUERY_RANGE_START}${start}`;
  }
  if (!isNull(end) && typeof end === 'number' && !isNaN(end)) {
    endString = `${QUERY_RANGE_END}${end}`;
  }
  return `${startString}${endString}`;
}