import React, { useState, useCallback, useMemo, useRef } from 'react';
import {
  Radio,
  RadioGroup,
  Box,
  Map,
  Text,
  TextField,
  Button,
  Svg,
  Heading,
} from '../../../ui';
import { useBreakpoint } from '../../../ui/hooks';
import { getBounds, inputTests } from '../../../ui/utils';
import { withStyles } from '../../../ui/styling';
import { BulletList, Section } from './components';
import {
  roofPitchOptions,
  roofAccessOptions,
} from '../constants';
import { useApi } from '../../../hooks';


export const AdditionalDetailsSection = React.forwardRef((props, ref) => {
  const { siteChecklistContext } = props;
  const { data, submitRequest, bindForm, updateData, internalViewer, canInternalMakeChanges, canMakeChanges, editingEnabled, status } = siteChecklistContext;
  const { submitDetailsOnBindingForm } = useApi();

  const submit = useMemo(() => {
    return (inputs = {}) => {
      return submitRequest(async () => {
        const { additionalDetails } = inputs;
        await submitDetailsOnBindingForm({
          ...data,
          additionalDetails,
        }, bindForm, internalViewer);
        await updateData();
      })
    }
  }, [data, submitRequest, submitDetailsOnBindingForm, internalViewer])

  const { additionalDetails } = data;
  const [input, setInput] = useState(additionalDetails || '');

  return (
    <Section
      heading={internalViewer ? "Additional access information, preferences, or instructions:" : "Any additional access information, preferences, or instructions?"}
      padTop="$5.5"
      size="small"
      padX="$4"
      maxWidth={'100%'}
      width="100%"
      ref={ref}
    >
      <TextField
        InputProps={{  multiline: true }}
        multiline
        height={200}
        width="100%"
        maxWidth="100%"
        placeholder={internalViewer ? "No additional information, preferences, or instructions provided" : "Enter any additional information, preferences, or instructions here..."}
        value={input}
        disabled={status === 'pending' || !editingEnabled}
        onChangeValue={v => setInput(v)}
        onBlur={() => {
          if (!(canInternalMakeChanges || canMakeChanges)) {
            return;
          }
          if (input.trim() === (additionalDetails || '').trim()) {
            return;
          }
          submit({ additionalDetails: input })
        }}
      />
    </Section>
  )
});