import { styled } from '../../ui/styling';
import { layout as layoutProps, backgroundColor as bgProps } from '../../ui/system/props';
import { getFlexLayout } from './utils';
import { LayoutBox } from './LayoutBox';

export const Main = styled(LayoutBox)(props => {
  const { SectionProps } = props;
  const flexLayout = getFlexLayout(props, {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  });

  const directionBasedProps = flexLayout.flexDirection === 'row' ? {
    minHeight: '100%',
  } : {
    maxWidth: '$mainWidth',
    marginX: 'auto',
  };
  
  return {
    ...layoutProps({
      flex: 1,
      width: '100%',
      ...flexLayout,
      ...directionBasedProps,
      ...props,
    }),
    ...bgProps({ backgroundColor: 'transparent', ...props }),
    props: {
      include: SectionProps ? { SectionProps } : null,
      defaultLayout: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
      disableAnimationDefaults: true,
    },
  };
});