export const deploymentTypes = {
  roof: 'roof',
  ground: 'ground',
};

export const roofPitches = {
  flat: 'flat',
  lessThan5Degrees: 'lessThan5Degrees',
  sloped: 'sloped',
};

export const roofPitchOptions = [
  {
    label: 'Less than 5 degree pitch',
    value: false,
  },
  {
    label: 'Steeper than 5 degrees',
    value: true,
  }
]

export const roofAccessOptions = [
  {
    label: 'Interior access to the rooftop',
    value: 'interior',
  },
  {
    label: 'Fixed external ladder',
    value: 'fixedExternal',
  },
  {
    label: 'Freestanding external ladder',
    value: 'freestandingExternal',
  }
];

