import React from "react";
import { merge } from 'merge-anything'
import { ThemeProvider, PortalProvider, Mapbox } from './ui';
import { createTheme, theme as baseTheme } from './ui/system';
import { AppRoutes } from './routing';
import { useAuthService, ApiProvider } from './hooks';
import { ProvideAppState } from './hooks/useAppState';
import { Site } from './components';
import './App.css';

Mapbox.setAccessToken(process.env.REACT_APP_MAPBOX_TOKEN);
Mapbox.addStyleURL({
  Street: 'mapbox://styles/hewitte/cjwaq462118se1cpas6fme42m',
  Satellite: 'mapbox://styles/hewitte/ckekkjxwt0bdk19phn1lb6nnf',
});
Mapbox.setDefaultImages({
  defaultMarker: require('./res/mapMarker.png'),
})

const theme = createTheme(merge(baseTheme, {
  sizes: {
    appBarHeight: 56,
    sidebarWidth: 272,
    sidebarWidthSm: 74,
    mainWidth: 920,
  },
  layout: {
    main: {
      maxWidth: '$mainWidth',
      marginX: 'auto',
      width: '100%',
    },
    centeredOverlay: {
      marginLeft: ({ theme: t }) => t.breakpoints({
        xs: 0,
        sm: t.sizes.sidebarWidthSm,
        md: t.sizes.sidebarWidth,
      }),
    },
    centeredOverlayLargeWindow: {
      marginLeft: ({ theme: t }) => t.breakpoints({
        sm: 0,
        md: t.sizes.sidebarWidth,
      }),
    },
  }
}));

const App = () => {
  const auth = useAuthService();

  return (
    <PortalProvider>
      <ThemeProvider theme={theme}>
        <ApiProvider auth={auth}>
          <ProvideAppState>
            <Site>
              <AppRoutes auth={auth} />
            </Site>
          </ProvideAppState>
        </ApiProvider>
      </ThemeProvider>
    </PortalProvider>
  );
};

export default App;