import { isObject, uniqueId } from "../../utils";

export class ScrollEventManager {
  onScrollHandlers = {}
  onLayoutHandlers = {}
  eventsDisabled = false;
  latest = { onLayout: null, onScroll: null }

  

  disableEvents() {
    this.eventsDisabled = true;
  }

  enableEvents() {
    this.eventsDisabled = false;
  }
  
  handleOnScroll = (event) => {
    if (this.eventsDisabled || !event) {
      return;
    }
    if (event) {

    }
    this.latest.onScroll = event;
    Object.keys(this.onScrollHandlers).forEach((key) => {
      const handler = this.onScrollHandlers[key];
      if (handler && typeof handler === 'function') {
        handler(event);
      } else if (isObject(handler) && handler.current && typeof handler.current === 'function') {
        handler.current(event);
      }
    });
  }

  handleOnLayout = (event) => {
    if (this.eventsDisabled || !event) {
      return;
    }
    this.latest.onLayout = event;
    Object.keys(this.onLayoutHandlers).forEach((key) => {
      const handler = this.onLayoutHandlers[key];
      if (handler && typeof handler === 'function') {
        handler(event);
      } else if (isObject(handler) && handler.current && typeof handler.current === 'function') {
        handler.current(event);
      }
    });
  }

  callOnLayout() {
    this.handleOnLayout(this.latest.onLayout);
  }

  attachOnScrollHandler = (handler) => {
    if (typeof handler !== 'function' && !isObject(handler)) {
      return () => null;
    }
    const id = uniqueId('osh');
    this.onScrollHandlers[id] = handler;
    return () => {
      this.onScrollHandlers[id] = null;
      delete this.onScrollHandlers[id];
    }
  }

  attachOnLayoutHandler = (handler) => {
    if (typeof handler !== 'function' && !isObject(handler)) {
      return () => null;
    }
    const id = uniqueId('olh');
    this.onLayoutHandlers[id] = handler;
    return () => {
      this.onLayoutHandlers[id] = null;
      delete this.onLayoutHandlers[id];
    }
  }

  clear = () => {
    this.onScrollHandlers = {};
    this.onLayoutHandlers = {};
  }
}