import { isNull, isObject, getFunctionName } from '../utils';

const breakpointsStore = { };

// if you change this value update the return func name below under Breakpoints.match
export const MATCH_BREAKPOINTS = '_$bp';

export const isBreakpointFunction = value => {
  if (!typeof value == 'function') {
    return false;
  }
  return getFunctionName(value) === MATCH_BREAKPOINTS || value.isBreakpointFunction === true;
}

export const breakpointKeysList = ['xs', 'sm', 'md', 'lg', 'xl'];

export function createBreakpoints(definedBreakpoints) {
  if (typeof definedBreakpoints === 'function' && definedBreakpoints.id && breakpointsStore[definedBreakpoints.id]) {
    return definedBreakpoints;
  }
  const id = JSON.stringify(definedBreakpoints);
  const keys = [];
  const breakpoints = { };
  for (const key in definedBreakpoints) {
    breakpoints[key] = definedBreakpoints[key];
    keys.push(key);
  }
  keys.sort((a, b) => breakpoints[a] - breakpoints[b]);
  const breakpointsFunc = value => {
    if (!isObject(value)) {
      throw new Error(`breakpoints requires an object with one or more size properties set: ${breakpointKeysList}`);
    }
    
    // match this name to the value of MATCH_BREAKPOINTS
    const _$bp = props => {
      return getBreakpointValue(value, { ...props, breakpoints })
    }

    _$bp.isBreakpointFunction = true;

    for (const key in value) {
      _$bp[key] = value[key];
    }


    // TODO: make this cleaner
    const minMax = breakpointKeysList.reduce((curr, key) => {
      let min = curr.min;
      let max = curr.max;
      if (value.hasOwnProperty(key)) {
        if (!min) {
          min = key;
        }
        max = key;
      }
      return { min, max };
    }, { min: null, max: null });

    _$bp.maxSize = minMax.max;
    _$bp.minSize = minMax.min;

    return _$bp;
  }

  const up = (currKey) => {
    const currKeyIndex = keys.indexOf(currKey);
    if (currKeyIndex !== -1) {
      if (currKeyIndex !== keys.length - 1) {
        return keys[currKeyIndex + 1];
      }
    }
    return keys[keys.length - 1];
  }

  const down = (currKey) => {
    const currKeyIndex = keys.indexOf(currKey);
    if (currKeyIndex !== -1) {
      if (currKeyIndex !== 0) {
        return keys[currKeyIndex - 1];
      }
    }
    return keys[0];
  }

  breakpointsFunc.id = id;
  breakpointsFunc.keys = keys;
  breakpointsFunc.values = breakpoints;
  breakpointsFunc.match = breakpointsFunc;
  breakpointsFunc.up = up;
  breakpointsFunc.down = down;

  // breakpointsFunc.cache = { };
  breakpointsStore[id] = breakpointsFunc;
  return breakpointsFunc;
}

export function getCurrentBreakpoint(props) {
  const { breakpoints, breakpoint } = props;
  if (!breakpoints || !breakpoint ) return null;
  if (typeof breakpoint === 'string') {
    const size = breakpoint;
    const index = breakpoints.keys.indexOf(size);
    if (index === -1) return null;
    return { size, index };
  }
  if (typeof breakpoint === 'object') {
    const { key: size, index } = breakpoint;
    if (!size) return null;
    return { size, index }
  }
  return null;
}

export function getBreakpointValue(value, props) {
  let { breakpoints, theme } = props;
  if (!breakpoints.keys && theme) {
    breakpoints = theme.breakpoints;
  }

  const curr = getCurrentBreakpoint({ ...props, breakpoints });
  if (curr) {
    if (!isNull(value[curr.size])) return value[curr.size];

    for (let i = curr.index; i >= 0; i -= 1) {
      const key = breakpoints.keys[i];
      if (!isNull(value[key])) {
        return value[key];
      }
    }

    if (curr.index !== breakpoints.keys.length - 1) {
      for (let i = curr.index; i < breakpoints.keys.length; i += 1) {
        const key = breakpoints.keys[i];
        if (!isNull(value[key])) {
          return value[key];
        }
      }
    }
  }
  return value;
}
