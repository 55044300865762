import React, { memo, useMemo } from "react";
import { QuoteStatuses } from "../../../constants";
import { ProvideAppState } from "../../../hooks/useAppState";
import { useAuth } from "../../../hooks/useAuth";
import { ProvideQuoteAdjustment } from "./QuoteAdjustment/AdjustmentContext";
import { useQuoteData } from "./QuoteDataContext";

const ProvideQuotePageContext = memo(function QuotePageState(props) {
  const { children } = props;
  const { data, update, updating, lastUpdated } = useQuoteData();
  const { user } = useAuth();

  const { status } = data || {};
  const userStatePermissions = useMemo(() => {
    let showPremium = false;
    let showCoveragePremium = false;
    let canAdjustQuote = false;
    let showAdjustmentFactor = false;
    let canRequestChangesToQuote = false;
    let canPrintQuote = false;
    let canAcceptQuote = false;
    let canRejectQuote = false;
    let canAddQuoteDescription = false;
    let canViewWorkbook = false;
    if (user && status) {
      if (status === QuoteStatuses.pending) {
        canAdjustQuote = user.isUnderwriter || user.isInternal;
      } else if (status === QuoteStatuses.released || status === QuoteStatuses.prebound || status === QuoteStatuses.bound) {
        showPremium = user.isBroker;
        canPrintQuote = true;
      }
      if (status === QuoteStatuses.released && user.isBroker) {
        canRequestChangesToQuote = true;
        canAcceptQuote = true;
        canRejectQuote = true;
      }
      if (user.isInternal || user.isUnderwriter) {
        showPremium = true;
        showAdjustmentFactor = true;
        canViewWorkbook = true;
        showCoveragePremium = true;
      }
    }
    if (user) {
      canAddQuoteDescription = user.isUnderwriter;
    }
    return {
      showPremium,
      showCoveragePremium,
      canAdjustQuote,
      showAdjustmentFactor,
      canPrintQuote,
      canAcceptQuote,
      canRejectQuote,
      canRequestChangesToQuote,
      canAddQuoteDescription,
      canViewWorkbook
    };
  }, [user, status]);

  return (
    <ProvideAppState  {...userStatePermissions}>
      <ProvideQuoteAdjustment
        quoteData={data}
        refreshQuoteData={update}
        refreshing={updating}
        lastUpdated={lastUpdated}
      >
        {children}
      </ProvideQuoteAdjustment>
    </ProvideAppState>
  )
})

export { ProvideQuotePageContext }
