import { getStyles } from '../../styling';
import { ListItemTypes, getNumberFromStyle, getRenderInsets } from './utils';
import { ListItemRecycler } from './ListItemRecycler';

class ListProcessor {
  constructor({
    sections,
    styles,
    itemHeight: iHeight = 0,
    sectionHeaderHeight: shHeight = 0,
    sectionFooterHeight: sfHeight = 0,
    numColumns = 1,
  }) {
    const {
      renderInsets,
      item,
      header,
      footer,
      sectionHeader,
      sectionFooter,
      between
    } = getStyles(styles);
    this.renderInsets = getRenderInsets(styles, renderInsets);
    this.itemHeight = getTotalHeightFromStyle(item, iHeight);
    this.marginHeights = {
      item: getMarginHeightFromStyle(item),
      header: getMarginHeightFromStyle(header),
      footer: getMarginHeightFromStyle(footer),
      sectionHeader: getMarginHeightFromStyle(sectionHeader),
      sectionFooter: getMarginHeightFromStyle(sectionFooter),
      between: getMarginHeightFromStyle(between),
    }
    this.headerHeight = getTotalHeightFromStyle(header);
    this.footerHeight = getTotalHeightFromStyle(footer);
    this.sectionHeaderHeight = getTotalHeightFromStyle(sectionHeader, shHeight);
    this.sectionFooterHeight = getTotalHeightFromStyle(sectionFooter, sfHeight);
    this.betweenHeight = getTotalHeightFromStyle(between);
    this.numColumns = numColumns >= 1 ? numColumns : 1;
    this.sections = sections;
    this.itemHeightMayVary = typeof this.itemHeight === 'function' ? true : false;
  }

  getComputedHeight(height, marginKey) {
    return {
      height,
      computedHeight: height === 0 ? height : height + this.marginHeights[marginKey],
    }
  }

  getItemHeight(section, index) {
    const { itemHeight, itemHeightMayVary } = this;
    return this.getComputedHeight(itemHeightMayVary ? itemHeight(section, index) : itemHeight, 'item');
  }
  getSectionHeaderHeight(section) {
    const { sectionHeaderHeight } = this;
    return this.getComputedHeight(typeof sectionHeaderHeight === 'function' ? sectionHeaderHeight(section) : sectionHeaderHeight, 'sectionHeader');
  }
  getSectionFooterHeight(section) {
    const { sectionFooterHeight } = this;
    return this.getComputedHeight(typeof sectionFooterHeight === 'function' ? sectionFooterHeight(section) : sectionFooterHeight, 'sectionFooter');
  }
  getHeaderHeight() {
    const { headerHeight } = this;
    return this.getComputedHeight(headerHeight, 'header');
  }
  getFooterHeight() {
    const { footerHeight } = this;
    return this.getComputedHeight(footerHeight, 'footer');
  }
  getBetweenHeight() {
    const { betweenHeight } = this;
    return this.getComputedHeight(betweenHeight, 'between');
  }


  process(top, bottom, prevItems) {
    const { sections } = this;
    const items = [];
    const recycler = new ListItemRecycler(prevItems);

    let position;
    let counter = -1;
    let height = this.renderInsets.top;
    let spacerHeight = height;

    const isFullRow = (item) => {
      return item.type !== ListItemTypes.ITEM;
    }

    const isVisibleBelow = (item) => {
      const { computedHeight } = item;
      counter = -1;
      if (height > bottom) {
        spacerHeight += computedHeight;
        return false;
      } else {
        return true;
      }
    }

    const isVisible = (item, force = false) => {
      if (item.type === ListItemTypes.SECTION_HEADER) {
        return isVisibleBelow(item);
      }
      const { computedHeight } = item;
      const fullRow = isFullRow(item);
      const prevHeight = height;
      counter = fullRow ? -1 : counter + 1;
      if (fullRow || counter % this.numColumns === 0) {
        height += computedHeight;
      }
      if (force || (height > top && prevHeight < bottom)) {
        return true;
      } else {
        if (fullRow || counter % this.numColumns === 0) {
          spacerHeight += computedHeight;
        }
        return false;
      }
    }

    const push = (...itemsArray) => {
      itemsArray.forEach((item) => {
        items.push(recycler.get(item));
      });
    };

    const pushSpacer = (item) => {
      if (spacerHeight > 0) {
        push({
          type: ListItemTypes.SPACER,
          position: item.position - spacerHeight,
          height: spacerHeight,
          computedHeight: spacerHeight,
          section: item.section,
          index: item.index,
        });
        spacerHeight = 0;
      }
    };

    const pushItem = (item, force = false) => {
      if (isVisible(item, force)) {
        pushSpacer(item);
        push(item);
      }
    };

    const getSpacerHeight = () => {
      let itemsCounter = -1;
      return items.reduce((totalHeight, item, i) => {
        if (i !== items.length - 1) {
          const fullRow = isFullRow(item);
          itemsCounter = fullRow ? 0 : itemsCounter + 1;
          if (fullRow || itemsCounter % this.numColumns === 0) {
            return totalHeight + item.computedHeight;
          }
        }
        return totalHeight;
      }, 0);
    }

    const headerHeight = this.getHeaderHeight();
    if (headerHeight.computedHeight > 0) {
      position = height;
      pushItem(
        {
          type: ListItemTypes.HEADER,
          position: position,
          height: headerHeight.height,
          computedHeight: headerHeight.computedHeight,
        },
        true,
      );
    }

    for (let section = 0; section < sections.length; section++) {
      const rows = sections[section];
      if (rows === 0) {
        continue;
      }
      const sectionHeaderHeight = this.getSectionHeaderHeight(section);
      position = height;
      height += sectionHeaderHeight.computedHeight;
      if (
        section > 1 &&
        items.length > 0 &&
        items[items.length - 1].type === ListItemTypes.SECTION_HEADER
      ) {
        const initialSpacerHeight = getSpacerHeight();
        const prevSection = items[items.length - 1];
        items.splice(0, items.length);
        push(
          {
            type: ListItemTypes.HEADER,
            position: position,
            height: headerHeight.height,
            computedHeight: headerHeight.computedHeight,
          },
          {
            type: ListItemTypes.SPACER,
            position: 0,
            height: initialSpacerHeight - headerHeight.computedHeight,
            computedHeight: initialSpacerHeight - headerHeight.computedHeight,
            section: prevSection.section,
            index: 0,
          },
          prevSection,
        );
      }
      pushItem({
        type: ListItemTypes.SECTION_HEADER,
        position: position,
        height: sectionHeaderHeight.height,
        computedHeight: sectionHeaderHeight.computedHeight,
        section: section,
      });

      let itemHeight = this.getItemHeight(section);
      for (let index = 0; index < rows; index++) {
        if (this.itemHeightMayVary) {
          itemHeight = this.getItemHeight(section, index);
        }
        position = height;
        pushItem({
          type: ListItemTypes.ITEM,
          position: position,
          height: itemHeight.height,
          computedHeight: itemHeight.computedHeight,
          section: section,
          index: index,
        });
      }

      const sectionFooterHeight = this.getSectionFooterHeight(section);
      if (sectionFooterHeight.computedHeight > 0) {
        position = height;
        pushItem({
          type: ListItemTypes.SECTION_FOOTER,
          position: position,
          height: sectionFooterHeight.height,
          computedHeight: sectionFooterHeight.computedHeight,
          section: section,
        });
      }
    }

    const footerHeight = this.getFooterHeight();
    if (footerHeight.computedHeight > 0) {
      position = height;
      pushItem(
        {
          type: ListItemTypes.FOOTER,
          position: position,
          height: footerHeight.height,
          computedHeight: footerHeight.computedHeight,
        },
        true,
      );
    }

    height += this.renderInsets.bottom;
    spacerHeight += this.renderInsets.top;

    if (spacerHeight > 0) {
      push({
        type: ListItemTypes.SPACER,
        position: height - spacerHeight,
        height: spacerHeight,
        computedHeight: spacerHeight,
        section: sections.length,
      });
    }

    recycler.fill();
  
    return {
      height,
      items,
    };
  }
}

function getTotalHeightFromStyle(s = {}, overrideHeight) {
  if (overrideHeight) {
    if (typeof overrideHeight === 'function') {
      return overrideHeight;
    }
    const override = getNumberFromStyle(overrideHeight);
    if (override) {
      return override;
    }
  }
  let height = getNumberFromStyle(s.height);
  if (!height) {
    height = getNumberFromStyle(s.maxHeight);
  }
  if (!height) {
    height = getNumberFromStyle(s.maxHeight);
  }
  if (!height) {
    const borderTop = getNumberFromStyle(s.borderTopWidth);
    const borderBottom = getNumberFromStyle(s.borderBottomWidth);
    const paddingTop = getNumberFromStyle(s.paddingTop);
    const paddingBottom = getNumberFromStyle(s.paddingBottom);
    height = borderTop + borderBottom + paddingTop + paddingBottom;
  }
  
  return height;
}

function getMarginHeightFromStyle(s = {}) {
  const marginTop = getNumberFromStyle(s.marginTop);
  const marginBottom = getNumberFromStyle(s.marginBottom);
  return marginTop + marginBottom;
}


export { ListProcessor };