import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../ui";
import { useEventCallback } from "../ui/hooks";
import { isObject } from "../ui/utils";
import { LayoutBox } from "./Layout";
/*
  props

  tabs: array of strings used for label/onchange or array of objects with { label: string, name: string }
  onTabChanged: called with new tab when internal tab state has changed
  initialTab: the initial tab for internal state to start with
  getLabel: function to calculate label on render
*/
function ViewTabs(props) {
  const { tabs: tabsProp, initialTab, getLabel, onTabChanged, TabButtonProps, ...rest } = props;

  const tabs = useMemo(() => {
    if (Array.isArray(tabsProp)) {
      return tabsProp.map((t, i) => {
        if (isObject(t)) {
          return t;
        }
        const label = `${t}`;
        return { label, name: label };
      });
    }
    return [];
  }, [tabsProp]);

  const [tab, setTab] = useState(() => {
    if (initialTab) {
      if (isObject(initialTab)) {
        return initialTab.name;
      }
      return initialTab;
    }
    if (tabs.length) {
      return tabs[0].name;
    }
    return null;
  });

  const updateViewTab = useEventCallback((t) => onTabChanged && onTabChanged(t));

  useEffect(() => {
    updateViewTab(tab);
  }, [updateViewTab, tab]);

  if (!tabs.length) {
    return null;
  }
  return (
    <LayoutBox row alignItems="flex-end" ml="$-2" gap={8} {...rest}>
      {tabs.map((t) => {
        const label = resolveLabel(t, getLabel);
        if (!label) {
          return null;
        }
        return (
          <Button
            key={`ViewTab - ${t.name}`}
            onPress={() => setTab(t.name)}
            label={label}
            variant="text"
            color="$primary"
            size="large"
            border={{ bottom: {
              width: 3,
              color: tab === t.name ? '$primary' : 'transparent',
            }}}
            borderBottomRadius={0}
            {...TabButtonProps}
          />
        );
      })}
    </LayoutBox>
  )
}

function resolveLabel(tab, getLabel) {
  let label = tab.label;
  if (getLabel && typeof getLabel === 'function') {
    label = getLabel(tab.name, tab.label);
  }
  return label;
}

export { ViewTabs };