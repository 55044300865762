import React, { useMemo } from 'react';
import { ActivityIndicator, ScrollView } from '../../ui';
import { useTransition } from '../../ui/styling';
import { useAppState, useNavigate } from '../../hooks';
import {
  Page,
  AppBar,
  NavRow,
  NavGroup,
  NavBackButton,
} from '../../components';
import { AccessDeniedPage } from '../AccessDeniedPage';
import { Page404 } from '../Page404';
import { ProvideSiteChecklistContext, useSiteChecklistContext } from './context';
import { Nav } from './Nav';
import { useLocation } from 'react-router-dom';
import { WelcomePage } from './WelcomePage';
import { FormPage } from './FormPage';

export const SiteChecklistPage = (props) => {
  return (
    <ScrollView width="100%" height="100%">
      <ProvideSiteChecklistContext >
        <PageContents />
      </ProvideSiteChecklistContext>
    </ScrollView>
  )
}

const PageContents = () => {
  const { status, data, errors, internalViewer } = useSiteChecklistContext();
  const [{ canCompletePSCL, canReviewPSCL }] = useAppState();

  if (!canCompletePSCL && !canReviewPSCL) {
    return <AccessDeniedPage />;
  }

  if (status === 'error' && errors.data) {
    return <Page404 />;
  }
  const loading = status === 'pending' && !data;
  return (
    <Page layout={loading ? 'center' : null}>
      {loading ? <ActivityIndicator /> : (
        <>
          <Nav />
          {!internalViewer ? <ClientViews /> : <FormPage />}
        </>
      )}
    </Page>
  )
}

const ClientViews = () => {
  const { basePath } = useSiteChecklistContext();
  const location = useLocation();
  const currPage = useMemo(() => {
    if (location && location.pathname === basePath) {
      return 'home';
    }
    return 'form';
  }, [location, basePath]);
  const transition = useTransition(currPage, {
    from: { opacity: 0, tx: 100 },
    enter: { opacity: 1, tx: 0, position: 'relative' },
    leave: { opacity: 0, tx: -100, position: 'absolute' },
    config: {
      clamp: true,
      tension: 500,
      friction: 50,
    },
  });

  return transition((animation, item) => {
    return item === 'form' ? <FormPage animation={animation} /> : <WelcomePage animation={animation} />;
  })
  // const transition = useTransition(displayingHome, {
  //   from: { opacity: 0.5, tx: 50, top: 0, left: 0 },
  //   enter: { opacity: 1, tx: 0, position: 'relative' },
  //   leave: { opacity: 0, tx: 350, position: 'fixed' },
  //   // config: config.molasses,
  // })
}


