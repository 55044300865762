import React, { useContext, useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { isObject, isNull, inputTests, sleep } from '../../ui/utils';
import { useApi, useAppState, useAsync, useAuth, useBindFormStatus, useNavigate, useQuery } from '../../hooks';
import {
  deploymentTypes,
  roofPitches,
} from './constants';

const initialPSCLData = {
  pocName: null,
  pocPhone: null,
  pocEmail: null,
  // location inputs (roof and ground)
  lat: null,
  lon: null,
  locationDescription: null,
  additionalDetails: null,
  // roof only inputs
  roofPitchIsOverLimit: null,
  // roof only inputs when roofIsFlat = true
  roofIsCorrugated: null,
  roofHeightIsOverLimit: null,
  roofAccessibleWith: null,
  // ops only inputs
  roofDeployment: true, // when false and grounDeployment is false then ops needs to be flagged of this and resolve
  groundDeployment: false,
  internalNotes: null, // ADD for ops.
}

const SiteChecklistContext = React.createContext({});

export const ProvideSiteChecklistContext = ({ children }) => {
  const [{ canCompletePSCL, canReviewPSCL }] = useAppState();
  const { user } = useAuth();
  const currentRoute = useLocation();
  const { quoteId, formId } = useParams();
  // const testData = useRef(null);
  const { getBindingFormWithIdForQuoteId } = useApi();
  // const submitDetailsOnBindingForm = useCallback((newData) => {
  //   testData.current = newData;
  // }, [quoteId]);
  
  const getPSCLData = useCallback(async () => {
    const bindForm = await getBindingFormWithIdForQuoteId(quoteId, formId);
    if (bindForm) {
      bindForm.lat = bindForm.lat ? bindForm.lat : bindForm.latitude;
      bindForm.lon = bindForm.lon ? bindForm.lon : bindForm.longitude;
      if (!bindForm.lat || !bindForm.lon) {
        if (bindForm.location && bindForm.location.lat) {
          bindForm.lat = bindForm.location.lat;
          bindForm.lon = bindForm.location.lon;
        }
      }
      bindForm.latitude = bindForm.lat;
      bindForm.longitude = bindForm.lon;
      const value = { bindForm };
      value.data = bindForm.details 
        ? {
            ...initialPSCLData,
            ...bindForm.details,
            // TODO: remove the checks below before final release.
            // some properties names changed while in development (never released)
            groundDeployment: !isNull(bindForm.details.groundDeployment)
              ? bindForm.details.groundDeployment
              : bindForm.details.deploymentType === deploymentTypes.ground
                ? true
                : initialPSCLData.groundDeployment,
            roofDeployment: !isNull(bindForm.details.roofDeployment)
              ? bindForm.details.roofDeployment
              : bindForm.details.deploymentType === deploymentTypes.roof
                ? true
                : initialPSCLData.roofDeployment,
            locationDescription: bindForm.details.locationDescription
              ? bindForm.details.locationDescription
              : bindForm.details.stationPlacementDescription
                ? bindForm.details.stationPlacementDescription 
                : initialPSCLData.locationDescription,
            additionalDetails: bindForm.details.additionalDetails
              ? bindForm.details.additionalDetails
              : bindForm.details.accessInstructions
                ? bindForm.details.accessInstructions 
                : initialPSCLData.additionalDetails,
            roofHeightIsOverLimit: !isNull(bindForm.details.roofHeightIsOverLimit)
              ? bindForm.details.roofHeightIsOverLimit
              : !isNull(bindForm.details.roofHeight)
                ? bindForm.details.roofHeight 
                : initialPSCLData.roofHeightIsOverLimit,
            roofPitchIsOverLimit: !isNull(bindForm.details.roofPitchIsOverLimit)
              ? bindForm.details.roofPitchIsOverLimit
              : !isNull(bindForm.details.roofPitch)
                ? bindForm.details.roofPitch === roofPitches.sloped
                  ? true
                  : false
                : initialPSCLData.roofPitchIsOverLimit,
          }
        : { ...initialPSCLData };
      // remove this too for same reason as remove above
      value.data.deploymentType = null;
      value.data.stationPlacementDescription = null;
      value.data.accessInstructions = null;
      value.data.roofHeight = null;
      value.data.roofPitch = null;
      // value.data.groundDeployment = false;
      // value.data.roofDeployment = false;
      // value.data = initialPSCLData;
      return value;
    } else {
      throw 'NO_DATA';
    }
  }, [quoteId, formId, getBindingFormWithIdForQuoteId]);

  
  const { execute: updateData, value: psclData, status: dataStatus, error: dataError } = useAsync(getPSCLData);
  const { data, bindForm } = psclData || {};
  const { isUpdatable, approvedAt } = bindForm || {};
  const isSubmitted = (!isUpdatable || approvedAt) ? true : false;
  const canMakeChanges = isUpdatable && !isSubmitted && canCompletePSCL;
  const canApproveReject = canReviewPSCL && !isUpdatable && !approvedAt;
  const isAccepted = approvedAt ? true : false;
  const internalViewer = user && user.isInternal ? true : false;
  const canInternalMakeChanges = !isAccepted;
  console.log(psclData, initialPSCLData);
  
  const [editingInternal, setEditingInternal] = useState(false);

  const navigate = useNavigate();
  const basePath = `/site-checklist/${quoteId}/${formId}`;
  const formPath = `${basePath}/form`;

  useEffect(() => {
    if (dataStatus !== 'pending' && dataStatus !== 'idle' && currentRoute && currentRoute.pathname && currentRoute.pathname.includes(basePath)) {
      if (currentRoute.pathname !== basePath && currentRoute.pathname !== formPath) {
        navigate.to({ pathname: canReviewPSCL ? formPath : basePath, replace: true });
      } else if (canReviewPSCL && currentRoute.pathname === basePath) {
        navigate.to({ pathname: formPath, replace: true });
      }
    }
  }, [currentRoute, dataStatus, navigate, canReviewPSCL, formPath, basePath])

  const makeRequest = useCallback(async (asyncCall) => {
    if (asyncCall) {
      return await asyncCall();
    }
    return true;
  }, []);
  const { execute: submitRequest, status: submitStatus, error: submitError } = useAsync(
    makeRequest,
    { immediate: false }
  );
  
  const status = useMemo(() => {
    if (dataStatus !== 'idle' && dataStatus !== 'success') {
      return dataStatus;
    }
    return submitStatus;
  }, [dataStatus, submitStatus]);

  const errors = useMemo(() => {
    if (dataError || submitError) {
      const err = submitError ? isObject(submitError) ? submitError : { submit: submitError } : {};
      if (dataError) {
        err.data = dataError;
      }
      return err;
    }
    return null;
  }, [dataError, submitError]);

  const formStatus = useBindFormStatus(bindForm);
  const editingEnabled = (!internalViewer && canMakeChanges) || (internalViewer && canInternalMakeChanges && editingInternal)

  const context = {
    data,
    bindForm,
    submitRequest,
    status,
    errors,
    canMakeChanges,
    isSubmitted,
    canApproveReject,
    isAccepted,
    isUpdatable,
    basePath,
    formPath,
    formStatus,
    updateData,
    internalViewer,
    canInternalMakeChanges,
    editingInternal,
    setEditingInternal,
    editingEnabled,
  };

  console.log('PSCL context', context);
  return (
    <SiteChecklistContext.Provider value={context}>
      {status === 'pending' && !data ? null : children}
    </SiteChecklistContext.Provider>
  );
}


export const useSiteChecklistContext = () => useContext(SiteChecklistContext);



