import React from 'react';
import { Text, Heading } from '../ui';
import { AppBar, Page, Main, NavRow, LogoNavGroup, ContentCard } from '../components';

const AccessDeniedPage = React.forwardRef((props, ref) => {
  return (
    <Page {...props} ref={ref}>
      <AppBar>
        <NavRow>
          <LogoNavGroup title="Understory" />
        </NavRow>
      </AppBar>
      <Main pb="$13" layout="center">
        <ContentCard gap="12">
          <Heading level={3} spaceAfter>Access Denied</Heading>
          <Text>You do not have permission to view this page.</Text>
          <Text small>If this is a mistake, please contact us.</Text>
        </ContentCard>
      </Main>
    </Page>
  )
})


export { AccessDeniedPage };
