import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { ActivityIndicator, Spacing, Text } from '../../ui';
import { useQuoteExpiration } from '../../hooks';
import {
  Page,
  Main,
  NavBar,
  NavRow,
  NavBackButton,
  NavGroup,
  AppBarSpacer,
  ErrorBoundary,
  Chip,
} from '../../components';
import { Colors } from '../../constants';
import moment from 'moment-timezone';
import { ProvideQuoteData, useQuoteData } from './context/QuoteDataContext';
import { ProvideQuotePageContext } from './context/QuotePageContext';
import { QuoteView } from './content/QuoteView';
import { QuoteActions } from './content/QuoteView/QuoteActions';
import { BindFormsView } from './content/BindFormsView';
import { ViewQuoteHistoryButton } from './content/QuoteView/QuoteHistory';
import { useAdjustmentState } from './context/QuoteAdjustment/AdjustmentContext';
import { Prompt } from 'react-router-dom';


export function QuotePage() {
  const { id } = useParams();
  const renderLoading = useCallback(() => <ActivityIndicator />, []);
  const renderEmpty = useCallback(() => <Text>Error getting quote</Text>, []);

  return (
    <Page padTop={({ theme }) => theme.breakpoints({ xs: 0, sm: theme.sizes.appBarHeight })}>
      <ErrorBoundary>
        <ProvideQuoteData
          id={id}
          renderLoading={renderLoading}
          renderEmpty={renderEmpty}
        >
          <ProvideQuotePageContext>
            <QuotePageContent />
          </ProvideQuotePageContext>
        </ProvideQuoteData>
      </ErrorBoundary>
    </Page>
  );
}

const QuotePageContent = memo(function QuotePageContent() {
  const { data, productCoverages, macavity, concentrations, update, updating, lastUpdated } = useQuoteData();
  
  return (
    <>
      <Nav data={data} update={update} updating={updating} lastUpdated={lastUpdated}  />
      <Main
        sx={({ spacing }) => ({
          padBottom: 164,
          padTop: 0,
          alignItems: 'stretch',
          padX: spacing(3),
        })}
        layout={'top-left'}
      >
        <QuoteView
          data={data}
          update={update}
          updating={updating}
          lastUpdated={lastUpdated}
          productCoverages={productCoverages}
          macavity={macavity}
          concentrations={concentrations}
        />
        <BindFormsView
          data={data}
          update={update}
          updating={updating}
          lastUpdated={lastUpdated}
        />
        <RenderExitPromptIfEditing />
      </Main>
    </>
  )
});


const Nav = memo(function Nav({ data, updating, update, lastUpdated }) {
  const { application } = data;
  const historyDisplayText = useMemo(() => {
    if (data) {
      // const lastTimestamp = moment().subtract(339, 'days');
      const lastTimestamp = data.lastChangeAt ? moment(data.lastChangeAt) : data.createdAt ? moment(data.createdAt) : null;
      if (lastTimestamp) {
        const now = moment();
        const dayDiff = now.diff(lastTimestamp, 'days');
        if (dayDiff < 2) {
          const hourDiff = now.diff(lastTimestamp, 'hours');
          if (hourDiff < 1) {
            const minuteDiff = now.diff(lastTimestamp, 'minutes');
            if (minuteDiff <= 1) {
              return `Updated < 1 minute ago`;
            }
            return `Updated ${minuteDiff} minutes ago`;
          }
          return `Updated ${hourDiff} hour${hourDiff > 1 ? 's' : ''} ago`;
        } else if (dayDiff >= 9) {
          const monthDiff = now.diff(lastTimestamp, 'months');
          if (monthDiff >= 6) {
            return `Last updated ${lastTimestamp.format('M-D-YYYY')}`;
          }
          return `Last updated ${lastTimestamp.format('MMM Do')}`;
        }
        return `Updated ${now.diff(lastTimestamp, 'days')} days ago`;
      }
    }
    
    return 'Update History';
  }, [data]);

  const expiration = useQuoteExpiration(data ? data : {});

  return (
    <NavBar padX={({ theme }) => theme.breakpoints({ sm: '$2', md: 0 })} padTop="$1">
      <AppBarSpacer />
      <NavRow maxWidth="$mainWidth" marginX="auto" xsHeight={48}>
        <NavGroup start>
          <NavBackButton
            path="/quotes"
            color="$gray.300"
            label={
              <Text>
                Quotes /
                <Text color="$gray.700">
                  {` # ${data.quoteNumber}`}
                </Text>
                
              </Text>
            }
          />
        </NavGroup>
        <NavGroup end>
          <Text xSmall bold uppercase color="$gray.400">
            {expiration.text}
          </Text>
        </NavGroup>
      </NavRow>
      {data ? (
        <NavRow maxWidth="$mainWidth" marginX="auto" padY="0" height="50">
          <NavGroup start>
            <Spacing horizontal="2" />
            <Chip color={Colors.quoteStatus[data.status] || '$gray.100'}>
              {data.status}
            </Chip>
            <Spacing horizontal="2" />
            <ViewQuoteHistoryButton
              data={data}
              lastUpdated={lastUpdated}
              label={historyDisplayText}
              color="$gray.500"
              marginLeft="$-1.75"
              TextProps={{ style: { fontWeight: 300, textDecoration: 'underline' } }}
            />
          </NavGroup>
          <NavGroup end>
            <QuoteActions quote={data} refreshQuoteData={update} meow />
          </NavGroup>
        </NavRow>
      ) : null}
    </NavBar>
  )
});

const RenderExitPromptIfEditing = memo(function RenderExitPromptIfEditing() {
  const { adjusting, disable } = useAdjustmentState();

  useEffect(() => {
    return () => {
      if (adjusting) {
        disable();
      }
    }
  }, [adjusting, disable]);

  return (
    <Prompt
      when={adjusting}
      message="If you leave all your edits will be lost"
    />
  )
})

