
export const colors = {
  primary: {
    light: '#8561B2',
    main: "#5F2994",
    dark: '#421D6E',
    get default() {
      return this.main;
    },
    contrast: {
      main: "$gray.50",
      get default() {
        return this.main;
      }
    },
  },
  secondary: {
    light: '#42B2AD',
    main: "#078880",
    get default() {
      return this.main;
    },
  },
  error: {
    text: 'rgba(223, 70, 97, 1)',
    get default() {
      return this.text;
    },
    contrast: {
      main: '$gray.50',
      get default() {
        return this.main;
      },
    }
  },
  gray: {
    50: "#f2fafa",
    100: "#E8EDEC",
    200: "#C6CED0",
    300: "#97A0A6",
    400: "#6A737D",
    500: "#5f676f",
    600: "#494d56",
    700: "#3e4149",
    800: "#2e303b",
    900: "#212126",
    get main() {
      return this['500'];
    },
    get light() {
      return this['300'];
    },
    get dark() {
      return this['700'];
    },
    get default() {
      return this['500'];
    }
  },
  white: "#fcffff",
  get black() {
    return this.gray['900'];
  },
  get text() {
    return {
      primary: this.gray['700'],
      secondary: this.gray['500'],
      // disabled: this.gray['400'], not sure disabled text makes sense. action like buttons and anchors will have their own styling...
      default: this.gray['700'],
    }
  },
  get background() {
    return {
      site: this.gray['50'],
      page: this.gray['50'],
      surface: '#ffffff',
      default: this.white,
    }
  },
  sky: {
    main: '#398FC0',
    light: '#6BB1D3',
    bright: '#66CEE3',
    get default() {
      return this.main;
    }
  },
  mist: "#ADD2DB",
  leaf: "#CCE2C3",
  navy: "#436CBA",
  azure: {
    light: '#84A5EA',
    main: '#436CBA',
    get default() {
      return this.main;
    },
  },
  coral: {
    light: '#F6ADB1',
    main: '#F97473',
    dark: '#DB4F60',
    get default() {
      return this.main;
    }
  },
  orange: {
    main: '#E2794C',
    light: '#F8997B',
    get default() {
      return this.main;
    }
  },
  brandOrange: "#f6ba5e"
}
