import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { ActivityIndicator, Box, Modal, Spacing, Text } from '../ui';
import { useLayoutEffect } from '../ui/hooks';

const defaultMessages = [
  'Please wait...',
  'One moment please...',
  'Working on that...',
  'Processing...',
];

const AppLoadingOverlay = ({
  loading = false,
  message = undefined,
  messageOptions = undefined,
  promptMessage = "The server is processing your application. Any changes may be lost if you leave the page. Please do not click the Back button or attempt to resubmit unless you see an error message."
}) => {
  const [open, setOpen] = useState(false);
  const messages = useRef(Array.isArray(messageOptions) && messageOptions.length ? messageOptions : defaultMessages);
  const [randomMessageIndex, setRandomMessageIndex] = useState(Math.floor(Math.random() * messages.current.length));
  useLayoutEffect(() => {
    if (loading && !open) {
      setRandomMessageIndex(Math.floor(Math.random() * messages.current.length));
      setOpen(true);
    } else if (!loading && open) {
      setOpen(false);
    }
  }, [open, loading]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          if (!loading) {
            setOpen(false);
          }
        }}
        BackdropProps={{
          bg: ({ theme }) => theme.colors.alpha('$white', 0.7),
        }}
      >
        <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator color="$secondary" size="large" />
          <Spacing vertical={2} />
          <Text bold xLarge>{message !== undefined ? message : messages.current[randomMessageIndex]}</Text>
        </Box>
        

      </Modal>
      <Prompt
        message={promptMessage}
        when={loading ? true : false}
      />
    </>
  )
}

export { AppLoadingOverlay };