import moment from "moment-timezone";
import { inputTests, isNull } from "../../ui/utils";

export const validateApplicationData = (application = {}) => {
  const {
    productId,
    insured,
    // namedInsureds,
    primaryEmail,
    billingEmail,
    address,
    regionCode,
    postcode,
    countryCode,
    lat,
    lon,
    locations = [],
    details = {},
    targetEffectiveAt,
    agent = {},
  } = application;
  const { employees } = details || {};

  const errors = { validationError: true };
  let valid = true;

  if (!inputTests.filled(productId)) {
    errors.productId = 'A product must be selected';
    valid = false;
  }
  if (!agent || isNull(agent.id) || agent.id === -1 || !inputTests.filled(`${agent.id}`)) {
    errors.agent = 'An agent must be selected';
    valid = false;
  }
  if (!inputTests.number(targetEffectiveAt)) {
    errors.targetEffectiveAt = 'Required';
    valid = false;
  } else if (!moment(targetEffectiveAt).isValid()) {
    errors.targetEffectiveAt = 'Invalid date';
    valid = false;
  } else if (moment().valueOf() - moment(targetEffectiveAt).valueOf() > 0) {
    errors.targetEffectiveAt = 'Target effective date cannot be before now';
    valid = false;
  }
  if (!inputTests.filled(insured)) {
    errors.insured = 'The name of the primary insured is required';
    valid = false;
  }
  if (!inputTests.filled(primaryEmail)) {
    errors.primaryEmail = 'Required';
    valid = false;
  } else if (!inputTests.email(primaryEmail)) {
    errors.primaryEmail = 'Please enter a valid email address';
    valid = false;
  }
  if (!inputTests.filled(billingEmail)) {
    errors.billingEmail = 'Required';
    valid = false;
  } else if (!inputTests.email(billingEmail)) {
    errors.billingEmail = 'Please enter a valid email address';
    valid = false;
  }
  if (!inputTests.filled(address)) {
    errors.address = 'Required';
    valid = false;
  }
  if (!inputTests.filled(regionCode)) {
    errors.regionCode = 'Required';
    valid = false;
  }
  if (!inputTests.filled(postcode)) {
    errors.postcode = 'Required';
    valid = false;
  }
  if (!inputTests.filled(countryCode)) {
    errors.countryCode = 'Required';
    valid = false;
  }
  if (!inputTests.number(lat)) {
    errors.lat = 'Required';
    valid = false;
  }
  if (!inputTests.number(lon)) {
    errors.lon = 'Required';
    valid = false;
  }
  if (!Array.isArray(locations) || !locations.length) {
    errors.locations = 'At least one location to be covered by this policy is required';
    valid = false;
  }
  if (!inputTests.number(employees)) {
    errors.employees = 'Required';
    valid = false;
  } else if (employees === 0) {
    errors.employees = 'Please enter a valid number of employees';
    valid = false;
  }
  if (!valid) {
    throw errors;
  }
  return true;
}