/* eslint-disable */
import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import equal from 'fast-deep-equal/react';
import { withMap, SourceContext } from './Contexts';

const ShapeSource = withMap(React.forwardRef(function ShapeSource(props, ref) {
  const { map, id, shape, children, onPress, onMouseOver, onMouseOut } = props;
  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  const propHandlers = useRef(null);
  propHandlers.current = {
    onPress,
    onMouseOver,
    onMouseOut
  };
  const handlers = useRef({});
  const listeners = useRef({});
  const layerIds = useRef([]);
  useEffect(() => {
    if (!mounted.current || !map || !map.mounted || !map.mounted.current) {
      return;
    }
    let needsUpdate = false;
    if (onPress && !handlers.current.click) {
      handlers.current.click = () => {
        if (
          mounted.current &&
          map &&
          map.mounted &&
          map.mounted.current &&
          propHandlers.current.onPress
        ) {
          propHandlers.current.onPress();
        }
      }
      needsUpdate = true;
    } else if (!onPress && handlers.current.click) {
      handlers.current.click = null;
      needsUpdate = true;
    }
    if (onMouseOver && !handlers.current.mouseenter) {
      handlers.current.mouseenter = () => {
        if (
          mounted.current &&
          map &&
          map.mounted &&
          map.mounted.current &&
          propHandlers.current.onMouseOver
        ) {
          propHandlers.current.onMouseOver();
        }
      }
      needsUpdate = true;
    } else if (!onMouseOver && handlers.current.mouseenter) {
      handlers.current.mouseenter = null;
      needsUpdate = true;
    }
    if (onMouseOut && !handlers.current.mouseleave) {
      handlers.current.mouseleave = () => {
        if (
          mounted.current &&
          map &&
          map.mounted &&
          map.mounted.current &&
          propHandlers.current.onMouseOut
        ) {
          propHandlers.current.onMouseOut();
        }
      }
      needsUpdate = true;
    } else if (!onMouseOut && handlers.current.mouseleave) {
      handlers.current.mouseleave = null;
      needsUpdate = true;
    }
    if (needsUpdate) {
      for (const layerId of layerIds.current) {
        for (const key in handlers.current) {
          if (!listeners.current[layerId][key] && handlers.current[key]) {
            listeners.current[layerId][key] = () => {
              if (
                handlers.current &&
                handlers.current[key]
              ) {
                handlers.current[key]();
              }
            }
            map.on(key, layerId, listeners.current[layerId][key]);
          } else if (listeners.current[layerId][key] && !handlers.current[key]) {
            map.off(key, layerId, listeners.current[layerId][key]);
          }
        }
      }
    }
  }, [onPress, onMouseOver, onMouseOut, map]);

  const addLayer = useCallback((layer) => {
    if (!(map && map.mounted && map.mounted.current)) {
      return;
    }
    if (!layer || !layer.id) {
      return;
    }
    if (map && map.mounted && map.mounted.current) {
      map.addLayer(layer);
      layerIds.current.push(layer.id);
      listeners.current[layer.id] = { };
      for (const key in handlers.current) {
        if (!handlers.current[key]) {
          continue;
        }
        listeners.current[layer.id][key] = () => {
          if (
            handlers.current &&
            handlers.current[key]
          ) {
            handlers.current[key]();
          }
        }
        map.on(key, layer.id, listeners.current[layer.id][key]);
      }
    }
  }, [map]);

  const removeLayer = useCallback((id) => {
    if (!(map && map.mounted && map.mounted.current)) {
      return;
    }
    if (id && map.getLayer(id)) {
      if (listeners.current[id]) {
        for (const key in listeners.current[id]) {
          if (listeners.current[id][key]) {
            map.off(key, id, listeners.current[id][key]);
          }
        }
        listeners.current[id] = null;
      }
      map.removeLayer(id);
    }
    const index = layerIds.current.indexOf(id);
    if (index !== -1) {
      layerIds.current.splice(index, 1);
    }
  }, [map]);

  const [sourceId, setSourceId] = useState(null);
  const last = useRef({});
  last.current.id = sourceId;
  const nextId = id === null || id === undefined ? null : `${id}`;

  useEffect(() => {
    if (!(map && map.mounted && map.mounted.current) || !mounted.current) {
      return;
    }
    if (last.current.id !== nextId) {
      if (last.current.id && map.getSource(last.current.id)) {
        if (layerIds.current.length) {
          const layers = [...layerIds.current];
          for (const layerId of layers) {
            removeLayer(layerId);
          }
        }
        map.removeSource(last.current.id);
      }
      if (!nextId && last.current.id) {
        setSourceId(null);
      } else if (nextId && shape) {
        map.addSource(nextId, { type: 'geojson', data: shape });
        last.current.shape = shape;
        setSourceId(nextId);
      }
    } else if (!equal(last.current.shape, shape)) {
      const source = map.getSource(last.current.id);
      if (source) {
        source.setData(shape);
        last.current.shape = shape;
      }
    }
  }, [nextId, shape, removeLayer, map]);

  useEffect(() => {
    return () => {
      if (!mounted.current && sourceId) {
        if (map && map.mounted && map.mounted.current) {
          if (map.getSource(sourceId)) {
            if (layerIds.current.length) {
              const layers = [...layerIds.current];
              for (const layerId of layers) {
                removeLayer(layerId);
              }
            }
            map.removeSource(sourceId);
          }
        }
      }
    };
  }, [sourceId, removeLayer, map]);

  const source = useMemo(() => {
    return {
      id: sourceId,
      addLayer,
      removeLayer,
    }
  }, [sourceId, addLayer, removeLayer, map]);

  return (
    <SourceContext.Provider value={source}>
      {children}
    </SourceContext.Provider>
  );
}));

export { ShapeSource };
