import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { inputComponentVariants } from '../TextInput';
import { Text } from '../Text';
import { Menu } from '../Menu';
import { useEventCallback, useForkRef } from '../../hooks';
import { useLayout } from '../../hooks';
import { withStyles } from '../../styling';
import { ModalEvents } from '../Modal';

const DropdownArrow = () => null;

const Select = withStyles({ textInput: {}, textBox: {}, input: {}, menu: {} }, { name: 'Select' })(
  React.forwardRef(function Select(props, ref) {
    const {
      // Select props
      autoWidth = false,
      IconComponent = DropdownArrow,
      children, // MenuItems
      multiple,
      renderValue,
      // Menu props
      onOpen,
      open: openProp,
      MenuProps,
      openOnFocus = false,
      onKeyDown,
      onFocus,
      onClose,
      // shared input props,
      onChange, // prevent passing
      onChangeText, // prevent passing
      onChangeValue,
      value,
      placeholder,
      InputComponent = Text,
      TextInputComponent,
      variant = 'filled',
      onLayout: onLayoutProp,
      textBoxRef,
      inputRef: inputRefProp,
      disabled,
      accessibility,
      styles,
      style,
      ...rest
    } = props;
    const textBox = useRef();
    const inputRef = useRef();
    const handleInputRef = useForkRef(inputRef, inputRefProp);
    const handleTextBoxRef = useForkRef(textBox, textBoxRef);

    const { current: isOpenControlled } = React.useRef(openProp != null);
    const [openState, setOpenState] = useState(false);
    const updateMenuState = (open, event) => {
      if (open) {
        if (onOpen) {
          onOpen(event);
        }
      } else if (onClose) {
        onClose(event);
      }

      if (!isOpenControlled) {
        setOpenState(open);
      }
    }

    
    const open = isOpenControlled ? openProp : openState;
    const refocusInput = useRef(false);

    useEffect(() => {
      if (!open && refocusInput.current) {
        if (inputRef.current && inputRef.current.focus) {
          inputRef.current.focus();
        }
      }
    }, [open])

    const handleClose = (e, reason) => {
      if (reason === 'selectItem' || reason === 'tabKeyDown' || reason === ModalEvents.escapeKeyDown || reason === ModalEvents.backPress) {
        refocusInput.current = true;
      }
      updateMenuState(false, e);
    }

    const handleItemPress = useEventCallback((event, child) => {
      let next;

      if (multiple) {
        next = Array.isArray(value) ? value.slice() : [];
        const itemIndex = value.indexOf(child.props.value);
        if (itemIndex === -1) {
          next.push(child.props.value);
        } else {
          next.splice(itemIndex, 1);
        }
      } else {
        next = child.props.value;
      }

      if (child.props.onPress) {
        child.props.onPress(event);
      }

      if (value !== next) {
        if (onChangeValue) {
          onChangeValue(next);
        } else if (onChangeText) {
          onChangeText(next); // TODO: fix this...
        }
      }

      if (!multiple) {
        handleClose(event, 'selectItem');
      }
    });

    const childrenArray = useMemo(() => React.Children.toArray(children), [children]);

    let valueText = renderValue ? (renderValue(value) || '') : '';
    

    const [items, selectedValues] = useMemo(() => {
      const selectedValues = [];
      const items = childrenArray.map((child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        let selected;

        if (multiple) {
          if (!Array.isArray(value)) {
            console.warn('Select with multiple prop true expects value prop to be an array');
            return null;
          }

          selected = value.some((v) => areEqualValues(v, child.props.value));
          if (selected && !renderValue) {
            selectedValues.push(child.props.children)
          }

        } else {
          selected = areEqualValues(value, child.props.value);
          if (selected && !renderValue) {
            selectedValues.push(child.props.children);
          }
        }

        const noStringChild = typeof child.props.children === 'string' && isEmpty(child.props.children);
        return React.cloneElement(child, {
          onPress: (e) => handleItemPress(e, child),
          selected: !child.props.value ? false : selected,
          value: undefined,
          children: noStringChild ? (placeholder ? placeholder : '----') : child.props.children,
          TextProps: noStringChild ? (placeholder ? { dim: true } : { color: 'transparent' }) : null,
        });
      })
      return [items, selectedValues];
    }, [childrenArray, multiple, value, handleItemPress, placeholder, renderValue]);

    if (!renderValue) {
      valueText = multiple ? selectedValues.join(', ') :
        (selectedValues.length ? selectedValues[0] : '');
    }

    const textInputProps = { };
    if (InputComponent === Text) {
      if (isEmpty(valueText)) {
        if (placeholder) {
          textInputProps.value = placeholder;
          textInputProps.dim = true;
        } else {
          textInputProps.value = '--';
          textInputProps.color = 'transparent';
        }
      } else {
        textInputProps.value = valueText;
      }
    } else {
      textInputProps.value = valueText;
      textInputProps.placeholder = placeholder;
    }
    if (isEmpty(valueText)) {
      valueText = '';
    }

    
    const { onLayout, width: inputWidth } = useLayout();
    const handleOnLayout = useCallback((e) => {
      onLayout(e);
      if (onLayoutProp) {
        onLayoutProp(e);
      }
    }, [onLayout, onLayoutProp])
    const menuListStyleProps = useMemo(() => {
      if (!autoWidth && inputWidth && open) {
        return { width: inputWidth, maxWidth: inputWidth };
      }
      return null;
    }, [inputWidth, autoWidth, open])

    const handleSelectInputPress = (event) => {
      if (disabled) return;
      updateMenuState(true, event);
    }

    const handleKeyDown = useEventCallback((e) => {
      if (e && e.key) {
        if (!e.nativeEvent) {
          e.nativeEvent = { key: e.key };
        } else if (!e.nativeEvent.key) {
          e.nativeEvent.key = e.key;
        }
      }
      if (onKeyDown) {
        onKeyDown(e);
      }
      if (!e || !e.nativeEvent || !e.nativeEvent.key || e.preventHandlerDefault || disabled) {
        return;
      }
      if (e.nativeEvent.key === 'ArrowDown' || e.nativeEvent.key === 'ArrowUp') {
        if (e.preventDefault) {
          e.preventDefault();
        }
        if (!open) {
          updateMenuState(true, e);
        }
      }
    })

    const handleOnFocus = useEventCallback((e) => {
      if (openOnFocus && !refocusInput.current) {
        updateMenuState(true, e);
      }
      if (onFocus) {
        onFocus(e);
      }
      refocusInput.current = false;
    })
    const Component = TextInputComponent || inputComponentVariants[variant];

    return (
      <>
        <Component
          textBoxRef={handleTextBoxRef}
          inputRef={handleInputRef}
          ref={ref}
          InputComponent={InputComponent}
          ignoreValue={false}
          {...textInputProps}
          onKeyDown={handleKeyDown}
          onLayout={handleOnLayout}
          disabled={disabled}
          onPress={disabled ? undefined : handleSelectInputPress}
          accessibility={{
            accessibilityRole: 'button',
            ...accessibility,
          }}
          onFocus={handleOnFocus}
          styles={styles}
          {...rest}
        >
          { IconComponent ? <IconComponent /> : null }
        </Component>
        <Menu
          open={open}
          anchorNode={textBox.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          anchorOffset={{ vertical: -4, horizontal: 0 }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          onClose={handleClose}
          {...MenuProps}
          style={styles.menu}
          {...styles.props.menu}
          MenuListProps={{
            variant: 'select',
            ...menuListStyleProps,
            ...(MenuProps && MenuProps.MenuListProps ? MenuProps.MenuListProps : null)
          }}
        >
          {items}
        </Menu>
      </>
    )
  })
);

function areEqualValues(a, b) {
  if (typeof b === 'object' && b !== null) {
    return a === b;
  }

  return String(a) === String(b);
}

function isEmpty(display) {
  return display == null || (typeof display === 'string' && !display.trim());
}

export { Select }