import { forwardRef, useEffect, useRef } from 'react';
import equal from 'fast-deep-equal/react';
import { withMap } from './Contexts';

const imagesLoading = [];
const imagesLoaded = [];
export function loadImage(imageName, imageSrc, map, onImageMissing) {
  if (!map || !map.mounted || !map.mounted.current) {
    return;
  }
  const name = imageName ? imageName : imageSrc;
  if (
    imageSrc &&
    typeof imageSrc === 'string' &&
    (imageSrc.startsWith('data:image') || imageSrc.startsWith('http')) &&
    !map.hasImage(name)
  ) {
    for (const images of imagesLoaded) {
      if (images[0] === imageSrc) {
        map.addImage(name, images[1]);
        return;
      }
    }
    if (imagesLoading.indexOf(imageSrc) !== -1) {
      return;
    }
    imagesLoading.push(imageSrc);
    map.loadImage(imageSrc, (error, image) => {
      if (!error && image) {
        imagesLoaded.push([imageSrc, image]);
      }
      if (error) {
        console.error('Error loading image into mapbox', error);
        if (onImageMissing && typeof onImageMissing === 'function') {
          onImageMissing(error, imageName, imageSrc, map);
        }
      } else if (map && map.mounted && map.mounted.current) {
        if (!map.hasImage(name)) {
          map.addImage(name, image);
        }
      }
      const index = imagesLoading.indexOf(imageSrc);
      if (index !== -1) {
        imagesLoading.splice(index, 1);
      }
    });
  }
}

const Images = withMap(forwardRef(function Images(props, ref) {
  const {
    map,
    images, // object of key value pairs, keys are the name to use, value is the string http or data source
    nativeAssetImages, // not used on web
    onImageMissing, // todo
  } = props;

  const lastImages = useRef(null);
  useEffect(() => {
    if (map && map.mounted && map.mounted.current && images) {
      if (!equal(lastImages.current, images)) {
        lastImages.current = images;
        for (const key in images) {
          const src = images[key];
          loadImage(key, src, map, onImageMissing);
        }
      }
    }
  }, [images, onImageMissing, map]);

  return null;
}));

export { Images };
