import React, { useMemo, useState } from 'react';
import { withStyles } from '../../ui/styling';
import { resolveLayoutStyleProp } from './utils';
import { LayoutBox } from './LayoutBox';
import { withContainerContext } from './context';


export const Sidebar = withStyles(props => {
  const { hide = false, theme, width = '$sidebarWidth', ...rest } = props;
  return {
    root: {
      props: {
        width,
        minHeight: '100%',
        shouldHide: resolveLayoutStyleProp(hide, props, { breakpointUp: true }),
        styleKeys: ['width', 'position'],
      }
    }
  }
}, { filterProps: ['hide'] })(React.forwardRef(function Sidebar(props, ref) {
  const { shouldHide, withPlaceholder, ...rest } = props;
  return shouldHide === true ? null : (
    <LayoutBox ref={ref} {...rest} afterLayoutComponent={withPlaceholder ? SidebarPlaceholder : null} />
  );
}));

export const SidebarPlaceholder = withContainerContext(React.forwardRef(function SidebarSpacer(props, ref) {
  const { parentContainer, width: widthProp, ...rest } = props;
  const { position, width } = parentContainer || {};
  // if (position !== 'fixed' && position !== 'absolute') {
  //   return null;
  // }
  return <LayoutBox width={widthProp !== undefined ? widthProp : width} height="100%" bg="transparent" pointerEvents="none" />
}))

const sidebarContext = React.createContext({ open: true, setOpen: () => null });

export function useSidebarState() {
  return React.useContext(sidebarContext);
}

export const ProvideSidebarState = ({ open: initial = true, children }) => {
  const [open, setOpen] = useState(initial);
  const value = useMemo(() => [open, setOpen], [open]);
  return (
    <sidebarContext.Provider value={value}>
      {children}
    </sidebarContext.Provider>
  )
}

