import React, {useRef, useState, useEffect, useCallback } from 'react';
import { Heading, Box, Spacing, Button, ActivityIndicator } from '../ui';
import { useAuth } from '../hooks';
import { Page, Main, Logo, LayoutBox } from '../components';
import { AUTHORIZATION_LOCAL_STORAGE_KEY } from '../constants';


const LoginPage = React.forwardRef((props, ref) => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(true);
  const [cancelIframeAuth, setcancelIframeAUth] = useState(false);
  const authIframe = useRef(null);

  // Wait 5 seconds max for auto-auth, if not allow user to login manually
  setTimeout(() => {
    setcancelIframeAUth(true);
    setLoading(false);
  }, 5000);

  const sendMessage = (iframeRef, message) => {
      iframeRef.current.contentWindow.postMessage(message, `${process.env.REACT_APP_KALIDASA_BASE_URI}`);
  }

  const receiveMessage = useCallback((message) => {
    if (
        !cancelIframeAuth && 
        message.origin === process.env.REACT_APP_KALIDASA_BASE_URI.replace(/\/$/, "") &&
        message.data.access_token
    ) {
      window.localStorage.setItem(
        AUTHORIZATION_LOCAL_STORAGE_KEY,
        JSON.stringify(message.data)
      )
      window.location.reload()
      return;
    }

    setLoading(false);
  }, [cancelIframeAuth]);

  const iframeLoaded = () => {
    sendMessage(authIframe, {type: 'request_token', clientId: process.env.REACT_APP_OPENQUOTE_CLIENT_ID});
  }

  useEffect(() => {
    window.addEventListener('message', receiveMessage);
    return () => window.removeEventListener('message', receiveMessage);
  }, [receiveMessage]);

  return (
    <Page {...props} ref={ref}>
      <Main pb="$13" layout="center">
        <Box justifyContent="center" alignItems="center">
          <LayoutBox layout="bottom-center" row>
            <Logo width={90} height={80} mr="$-1" />
            <Heading pb="$1.5" level={3} weight="$regular" letterSpacing={1} uppercase>Understory</Heading>
          </LayoutBox>
          <Spacing vertical={8} />
          {
            loading === true && !cancelIframeAuth ?
            <ActivityIndicator/> :
            <Button width="150" large onPress={login}>Login</Button>
          }
          <div style={{display: 'none'}}>
            <iframe src={`${process.env.REACT_APP_KALIDASA_BASE_URI}/authorization-iframe`}
              ref = {authIframe}
              onLoad={iframeLoaded}
            />
          </div>
        </Box>
      </Main>
    </Page>
  )
})


export { LoginPage };
