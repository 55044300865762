// import React from 'react';
import { Text } from '../Text';
import { Platform } from 'react-native';

// const Heading = props => {
//   const { level, variant, ...rest } = props;
//   let headingVariant = `h${level}`;
//   if (variant) {
//     headingVariant = `${headingVariant}.${variant}`;
//   }
//   return <Text variant={headingVariant} {...rest} />
// }

// const Heading = styled((props) => {
//   const { level, variant, ...rest } = props;
//   let headingVariant = `h${level}`;
//   if (variant) {
//     headingVariant = `${headingVariant}.${variant}`;
//   }
//   return <Text variant={headingVariant} {...rest} />;
// }, { name: 'Heading' })({});

const Heading = Text.withProps((props) => {
  const {
    level = 1,
    variant,
    accessibility: accessibilityProp,
    disableHeadingAccessibility,
    href,
    ...rest
  } = props;
  let headingVariant = `h${level}`;
  if (variant) {
    headingVariant = `${headingVariant}.${variant}`;
  }
  let a = null;
  if (!disableHeadingAccessibility) {
    a = Platform.OS === 'web' ? {
      accessibilityRole: href ? 'link' : 'heading',
      'aria-level': level,
    } : { accessibilityRole: 'header' };
  }
  return {
    variant: headingVariant,
    accessibility: { ...a, ...accessibilityProp },
    href,
    ...rest,
  };
}, { name: 'Heading' });

const Subheading = Heading.withProps({ variant: 'subheading', disableHeadingAccessibility: true }, { name: 'Subheading' });
// const Subheading = props => <Heading variant="subheading" {...props} />;

const HeadingBody = Heading.withProps({ variant: 'body', disableHeadingAccessibility: true }, { name: 'HeadingBody' });
// const HeadingBody = props => <Heading variant="body" {...props}  />;

const H1 = Heading.withProps({ level: 1 }, { name: 'H1' });
// const H1 = props => <Heading {...props} level="1" />;

H1.Subheading = Subheading.withProps({ level: 1 }, { name: 'H1.Subheading' });
// H1.Subheading = props => <Subheading {...props} level="1" />;
H1.Body = HeadingBody.withProps({ level: 1 }, { name: 'H1.Body' });
// H1.Body = props => <HeadingBody {...props} level="1" />;

const H2 = Heading.withProps({ level: 2 }, { name: 'H2' });
H2.Subheading = Subheading.withProps({ level: 2 }, { name: 'H2.Subheading' });
H2.Body = HeadingBody.withProps({ level: 2 }, { name: 'H2.Body' });

const H3 = Heading.withProps({ level: 3 }, { name: 'H3' });
const H4 = Heading.withProps({ level: 4 }, { name: 'H4' });
const H5 = Heading.withProps({ level: 5 }, { name: 'H5' });
const H6 = Heading.withProps({ level: 6 }, { name: 'H6' });

// const H2 = props => <Heading {...props} level="2" />;
// H2.Subheading = props => <Subheading {...props} level="2" />;
// H2.Body = props => <HeadingBody {...props} level="2" />;

// const H3 = props => <Heading {...props} level="3" />;
// const H4 = props => <Heading {...props} level="4" />;
// const H5 = props => <Heading {...props} level="5" />;
// const H6 = props => <Heading {...props} level="6" />;


export {
  // prefer these
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  // prefer not to use these
  Heading,
  Subheading,
  HeadingBody
}
