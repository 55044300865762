import React, { useState, useCallback, useMemo, useRef } from 'react';
import {
  Radio,
  RadioGroup,
  Box,
  Map,
  Text,
  TextField,
  Button,
  Svg,
  Heading,
} from '../../../ui';
import { useBreakpoint } from '../../../ui/hooks';
import { getBounds, inputTests } from '../../../ui/utils';
import { withStyles } from '../../../ui/styling';
import { BulletList, Section } from './components';
import {
  roofPitchOptions,
  roofAccessOptions,
} from '../constants';
import { useApi } from '../../../hooks';


export const ThankYouSection = React.forwardRef((props, ref) => {
  return (
    <Section>
      <Heading level={3}>Thank you!</Heading>
    </Section>
  )
});

export const HelpSection = React.forwardRef((props, ref) => {
  const { data, submit, status, errors, goBack } = props.siteChecklistContext;
  return (
    <Section
      heading="We're here to help"
      ref={ref}
      actions={{
        onPressLeft: goBack,
        right: [],
      }}
      {...props}
    >
    </Section>
  )
});


