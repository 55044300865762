import React, { memo, useCallback, useState } from 'react';
import moment from 'moment-timezone';
import { Box, Button, Heading, Text } from '../../../ui';
import { capitalize } from '../../../ui/utils';
import { getAddressLines, formatMoney, apiHelpers } from '../../../utils';
import { TextData } from '../../../components';
import { useEventCallback } from '../../../ui/hooks';
import { LocationDialog } from '../../SubmitApplicationPage/LocationDialog';
import { useApi, useAsync, useAuth } from '../../../hooks';
import { withStyles } from '../../../ui/styling';


const ApplicationDetails = (props) => {
  const {
    data: application,
    enableControls = false,
    refreshApplicationData,
    refreshing = false,
  } = props;

  const {
    product: productData,
    insured,
    namedInsureds = [],
    address,
    postcode,
    region,
    appliedAt,
    targetEffectiveAt,
    details,
    broker,
    agent,
    locations,
    customer,
    notes,
  } = application || {};

  const primaryEmail = customer && customer.primaryContact ? customer.primaryContact.email : '--';
  const billingEmail = customer && customer.billingContact ? customer.billingContact.email : '--';

  const product = apiHelpers.resolveProduct(productData);

  const {
    employees,
    lossHistoryURI,
  } = details || {};

  const { name: brokerName } = broker || {};
  const {
    name: agentName,
    email: agentEmail,
  } = agent || {};

  const allNamedInsureds = [...namedInsureds];
  const insuredNameIndex = allNamedInsureds.indexOf(insured);
  if (insuredNameIndex === -1) {
    allNamedInsureds.unshift(insured);
  }

  const auth = useAuth();
  const canEdit = enableControls && auth && (auth.user.isUnderwriter || auth.user.isPolicyAnalyst || auth.user.isAdmin);
  const [locationDialog, setLocationDialog] = useState({ open: false, data: undefined  });
  const { updateApplication } = useApi();
  const handleUpdateApplication = useCallback(async (data) => {
    await updateApplication(data);
    await refreshApplicationData(true);
    setLocationDialog({ open: false });
  }, [updateApplication, refreshApplicationData]);
  const { execute: executeUpdate, status: updateStatus, error: updateError } = useAsync(handleUpdateApplication, { immediate: false });

  const handleLocationEditSubmit = useEventCallback((data) => {
    const updatedApplication = { ...application };
    updatedApplication.locations = application.locations.map((l) => {
      if (l.id === data.id) {
        return data;
      }
      return l;
    });
    executeUpdate(updatedApplication);
  });


  const onLocationDialogClose = useEventCallback(() => setLocationDialog({ open: false }));
  const handlePressLocationItem = useEventCallback((locationId) => {
    if (!locationId) {
      return;
    }
    const locationData = locations.find((l) => l.id === locationId);
    if (locationData) {
      setLocationDialog({ open: true, data: locationData });
    }
  })


  return (
    <Box
      width="100%"
      alignItems="stretch"
      alignSelf="stretch"
      pb="$10"
      pt="$4"
    >
      <Heading level={3} pt="$1" pb="$3">Application Details</Heading>
      <Box flexDirection="column" gap={16}>
        <Box alignItems="stretch" gap={12} gapBorder={{ width: 1, color: '$gray.100' }} maxWidth={600} overflow="visible">
          <TextData
            label="Applied at"
            data={moment.utc(appliedAt).format('MM-DD-YYYY')}
            asRow={true}
            invert
          />
          <TextData
            label="Brokerage"
            data={brokerName}
            asRow={true}
            invert
          />
          <TextData
            label="Agent"
            data={agentName}
            asRow={true}
            invert
          />
          <TextData
            label="Agent Email"
            data={agentEmail}
            asRow={true}
            invert
          />
          <SectionHeading>Policy Info</SectionHeading>
          <TextData
            label="Product"
            data={product.labels.name}
            asRow
            invert
          />
          <TextData
            label="Line of Business"
            data={product.labels.type}
            asRow
            invert
          />
          <TextData
            label="Target Effective"
            data={moment.utc(targetEffectiveAt).format('MM-DD-YYYY')}
            asRow={true}
            textProps={{
              style: { fontWeight: 600 },
              color: '$primary'
            }}
            invert
          />
          <SectionHeading>Client Info</SectionHeading>
          <TextData
            label="Insured Name"
            data={insured}
            asRow
            invert
          />
          <TextData
            label="Named Insured(s)"
            data={allNamedInsureds}
            asRow={true}
            invert
          />
          <TextData
            label="Address"
            data={getAddressLines(address, region, postcode)}
            asRow={true}
            invert
          />
          <TextData
            label="Primary Email"
            data={primaryEmail}
            asRow
            invert
          />
          <TextData
            label="Billing Email"
            data={billingEmail}
            asRow
            invert
          />
          <SectionHeading>Additional Info</SectionHeading>
          <TextData
            label="Employees"
            data={employees}
            asRow={true}
            invert
          />
          <TextData
            label="Notes"
            data={notes}
            asRow={true}
            invert
          />
        </Box>
      </Box>
      <Box marginTop="$8" flex={1}>
        <Heading level={3} spaceAfter>Locations</Heading>
        <Box justifyContent="flex-start" gap={12} alignItems="stretch" flex={1} width="100%">
          {locations.map((location, i) => {
            return (
              <LocationItem
                key={location.id}
                product={productData}
                loc={location}
                onPress={handlePressLocationItem}
              />
            )
          })}
        </Box>
      </Box>
      <LocationDialog
        product={productData}
        onSubmit={handleLocationEditSubmit}
        onClose={onLocationDialogClose}
        canEdit={canEdit}
        mode={canEdit ? "EDIT" : undefined}
        processing={updateStatus === 'pending' || refreshing}
        {...locationDialog}
      />
    </Box>
  )
}

const SectionHeading = memo(function SectionHeading(props) {
  return <Heading level={6} pt="$2" {...props} />
})

const LocationItem = withStyles(({ theme }) => {
  return {
    root: {
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padBottom: theme.spacing(6),
    },
    layout: {
      flexDirection: theme.breakpoints({ sm: 'column', md: 'row' }),
      flex: 1,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padBottom: theme.spacing(2),
    },
    hairlineLayout: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      props: {
        gap: theme.spacing(0.75),
      }
    },
    hairline: {
      height: 1,
      maxHeight: 1,
      backgroundColor: theme.colors.gray[200],
    },
    headerCell: {
      flexGrow: theme.breakpoints({ sm: 0, md: 1 }),
      flexShrink: theme.breakpoints({ sm: 0, md: 1 }),
      flexBasis: theme.breakpoints({ sm: 'auto', md: '0%' }),
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padRight: theme.spacing(1.5),
      padBottom: theme.breakpoints({ sm: theme.spacing(1), md: 0 }),
    },
    heading: {
      props: {
        // weight: '$regular',
        level: 4,
        maxLines: 2,
        size: 'xSmall',
      },
    },
    addressLine: {
      props: { maxLines: 2, size: 'small' }
    },
    contentRow: {
      flex: theme.breakpoints({ sm: 1, md: 3 }),
      width: theme.breakpoints({ sm: '100%', md: 'auto' }),
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      flexWrap: theme.breakpoints({ xs: 'wrap', sm: 'nowrap' }),
    },
    contentCell: {
      flex: 1,
      minWidth: 140,
      marginRight: '$1'
    }
  }
})((props) => {
  const { styles, loc, product, onPress, ...rest } = props;
  const {
    name,
    address,
    coveredValue,
    details,
    id,
  } = loc || {};
  const {
    num_vehicles: numVehicles = 0,
    average_car_value: averageCarValue = 0,
  } = details || {};

  const renderAutoFields = product && apiHelpers.isProductAuto(product);
  return (
    <Box
      onPress={onPress ? () => onPress(id) : null}
      {...rest}
    >
      <Box {...styles.toProps('layout')}>
        <Box {...styles.toProps('headerCell')}>
          <Box flex={1}>
            <Heading {...styles.toProps('heading')}>
              {name}
            </Heading>
            <Text {...styles.toProps('addressLine')}>
              {address}
            </Text>
          </Box>
        </Box>
        <Box {...styles.toProps('contentRow')}>
          {renderAutoFields && (
            <Box {...styles.toProps('contentCell')}>
              <TextData
                label="Number of Vehicles"
                data={numVehicles}
                invert
                labelProps={{ size: 'small' }}
              />
            </Box>
          )}
          {renderAutoFields && (
            <Box {...styles.toProps('contentCell')}>
              <TextData
                label="Average Car Value"
                data={numVehicles && numVehicles > 0 ? formatMoney(averageCarValue, { prefix: '$', withDecimals: true }) : 'N/A'}
                invert
                labelProps={{ size: 'small' }}
              />
            </Box>
          )}
          <Box {...styles.toProps('contentCell')}>
            <TextData
              label="Covered Value"
              data={formatMoney(coveredValue, { prefix: '$', withDecimals: true })}
              invert
              labelProps={{ size: 'small' }}
            />
          </Box>
          <Button
            label="View Details"
            variant="outlined"
            color="$primary"
            onPress={onPress ? () => onPress(id) : null}
          />
        </Box>

      </Box>
      <Box {...styles.toProps('hairlineLayout')}>
        <Box style={styles.hairline} flex={1} />
      </Box>
    </Box>
  );
})

export { ApplicationDetails }
