import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useScrollNode } from '../ui';

const win = typeof window !== 'undefined' ? window : null;
export function ScrollToTop({ onWindow = false, y = 0, x = 0, animated = false, trigger }) {
  const scrollNode = useScrollNode();
  const { pathname } = useLocation();
  const lastTrigger = useRef(pathname);
  let triggerscroll = pathname;
  if (trigger && typeof trigger === 'function') {
    triggerscroll = trigger(pathname, lastTrigger.current);
    lastTrigger.current = triggerscroll;
  } else {
    lastTrigger.current = pathname;
  }
  useEffect(() => {
    if (onWindow && win) {
      window.scrollTo(0, 0);
    } else if (scrollNode && scrollNode.current && scrollNode.current.scrollTo) {
      scrollNode.current.scrollTo({ x, y, animated });
    }
  }, [triggerscroll, onWindow]);

  return null;
}