import React, {
  createContext,
  useRef,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { Platform } from 'react-native';
import { merge } from 'merge-anything';
import {
  useTheme,
  useLayoutEffect,
  useOnKeyPress,
  useBackHandler,
} from '../../hooks';
import { uniqueId, isNull } from '../../utils';
import { PortalDestination, Portal } from '../Portal';
import { Box } from '../Box';
import { ModalManager } from './ModalManager';
import { ModalFocusTrap } from './ModalFocusTrap';
import { ModalBackdrop } from './ModalBackdrop';
import { Transition, withStyles } from '../../styling';
import { sizes, position, flexBox } from '../../system/props';
import { ScrollView } from '../ScrollView';
import { parseSystemStyle } from '../../system';
const defaultManager = new ModalManager();

const filterProps = [
  ...sizes.filterProps,
  ...position.filterProps,
  ...flexBox.filterProps,
];

const ModalEvents = {
  backdropPress: 'backdropPress',
  escapeKeyDown: 'escapeKeyDown',
  backPress: 'backPress',
};

const Modal = withStyles((props) => {
  return {
    root: props.renderInline && props.disablePortal ? {
      ...flexBox({
        justifyContent: 'center',
        alignItems: 'center',
        ...props,
      }),
      overflow: props.overflow ? props.overflow : 'visible',
    } : {
      ...position({
        top: 0,
        left: 0,
        ...props,
        position: Platform.OS === 'web' ? 'fixed' : 'absolute',
      }),
      ...sizes({
        width: '100%',
        height: '100%',
        ...props,
      }),
      ...flexBox({
        justifyContent: 'center',
        alignItems: 'center',
        ...props,
      }),
    },
  };
}, { name: 'Modal', filterProps, asProp: { zIndex: true } })(
  React.forwardRef(function Modal(props, ref) {
    const {
      portalName, // uses name from modalContainerContext if null/undefined (preferred)
      disablePortal = false,
      manager = defaultManager,
      BackdropComponent = ModalBackdrop,
      BackdropProps,
      hideBackdrop = false,
      disableBackdropPress = false,
      disableEscapeKeyDown = false,
      disableBackPress = false,
      disableBackPressPropagation = false,
      disableScrollLock = false,
      disableAnimate = false,
      disableTrapFocus = false,
      keepMounted = false,
      renderInline = false,
      onKeyDown,
      onPressBack,
      onPressBackdrop,
      onClose,
      onEntered,
      onExited,
      open,
      children: childrenProp,
      animate, // dont pass
      animations, // used for <Transition>
      accessibility,
      zIndex: z = 'modal', // theme zIndex key string or number. Important for layering
      onEnter,
      onLeave,
      styles,
      scrollable = false,
      ScrollComponent = ScrollView,
      ScrollProps,
      ScrollRef,
      focusable, // dont pass
      ...rest
    } = props;

    const modalId = useRef(uniqueId('__modal__'));
    const mounted = useRef(true);
    const hasOpened = useRef(false);
    const transitionState = useRef(null);
    const lastTransitionState = useRef(null);
    const [isOpen, setIsOpen] = useState(() => open);
    const [isTop, setIsTop] = useState(false); // is this modal the active modal/should have highest zIndex and focus (if multiple modals)
    const [isTransitioning, setTransitioning] = useState(() => open && !disableAnimate);

    useEffect(() => { return () => { mounted.current = false; } }, []);

    useEffect(() => {
      const id = modalId.current;
      return () => {
        manager.remove(id);
      }
    }, [manager])

    useLayoutEffect(() => {
      if (open && mounted.current) {
        // add modal to manager so it can be a source of truth for multiple modals on which one is on top and should be the "active" one
        // updateTop func is so manager can update this modals state of being the currently focused/top modal.
        // ^^ ModalFocusTrap will disable trapping focus if it's not the top modal and re-trap focus when it becomes the top modal again
        manager.add({
          id: modalId.current,
          updateTop: (modalIsTop) => {
            if (mounted.current) {
              setIsTop(modalIsTop);
            }
          }
        }, () => {
          if (mounted.current) {
            setTransitioning(() => !disableAnimate);
            setIsOpen(() => true);
            transitionState.current = 'enter';
            hasOpened.current = true;
          }
        }, { disableScrollLock });
      } else {
        setIsTop(false);
        manager.remove(modalId.current, () => {
          if (mounted.current) {
            setIsOpen(() => false);
            if (hasOpened.current) {
              transitionState.current = 'leave';
              setTransitioning(() => !disableAnimate);
            }
          }
        });
      }
    }, [open, manager, disableScrollLock, disableAnimate]);

    useOnKeyPress('Escape', (event) => {
      if (!manager.isTopModal(modalId.current)) {
        return;
      }
      if (onKeyDown) {
        onKeyDown(event);
      }
      if (!disableEscapeKeyDown) {
        if (event.stopPropagation) {
          event.stopPropagation();
        }
        if (onClose) {
          onClose(event, ModalEvents.escapeKeyDown);
        }
      }
    });

    useBackHandler((event) => {
      if (!manager.isTopModal(modalId.current)) {
        return;
      }
      if (onPressBack) {
        onPressBack(event);
      }
      if (!disableBackPress) {
        if (onClose) {
          onClose(event, ModalEvents.backPress);
        }
        // prevent bubbling by returning true
        return true;
      }
      // allow event to bubble up by setting this to false (default)
      return disableBackPressPropagation;
    });

    const handlePressBackdrop = (event) => {
      if (event && event.key && event.key === 'Enter') {
        return;
      }
      if (onPressBackdrop) {
        onPressBackdrop(event);
      }
      if (!disableBackdropPress && onClose) {
        onClose(event, ModalEvents.backdropPress);
      }
    }

    const theme = useTheme();
    const zIndex = useMemo(() => {
      let zIndex = parseSystemStyle(z);
      if (typeof zIndex === 'string') {
        if (theme && theme.zIndex && !isNull(theme.zIndex[z])) {
          zIndex = theme.zIndex[z] * 1;
        } else {
          zIndex = zIndex * 1;
        }
      }
      if (typeof zIndex !== 'number' || isNaN(zIndex)) {
        zIndex = 99999999;
      }
      return zIndex;
    }, [z, theme]);

    const transitionAnimations = useMemo(() => {
      const base = {
        from: { opacity: 0 },
        enter: {
          opacity: keepMounted ? (isOpen ? 1 : 0) : 1,
          onStart: (...args) => {
            if (transitionState.current && lastTransitionState.current !== transitionState.current) {
              lastTransitionState.current = transitionState.current;
              if (onEnter) {
                onEnter(...args)
              }
            }
          },
          onRest: (...args) => {
            setTransitioning(false);
            if (onEntered) {
              onEntered(...args);
            }
          }
        },
        leave: {
          opacity: 0,
          onStart: (...args) => {
            if (transitionState.current && lastTransitionState.current !== transitionState.current) {
              lastTransitionState.current = transitionState.current;
              if (onLeave) {
                onLeave(...args)
              }
            }
          },
          onRest: (...args) => {
            setTransitioning(false);
            if (onExited) {
              onExited(...args);
            }
          }
        },
        config: {
          clamp: true,
          tension: 500,
          friction: 50,
        },
        ...(keepMounted ? { update: { opacity: isOpen ? 1 : 0 } } : null),
      };
      return animations ? merge(base, animations) : base;
    }, [keepMounted, isOpen, animations, onEntered, onExited, onEnter, onLeave]);


    
    let content = null;
    const zedPlus = useRef(null);
    if (keepMounted || isTransitioning || isOpen) {
      if (isOpen || !zedPlus.current) {
        // increase the provided zIndex so it's on layered on the top of other modals when opened.
        // dont update the zIndex when it's closing so that it can finish the close animation while on top of other modals
        zedPlus.current = zIndex + manager.getIndex(modalId.current);
      }

      let children = childrenProp;
      const withScroll = scrollable && ScrollComponent;
      if (withScroll) {
        const baseScrollProps = {
          ref: ScrollRef,
          width: '100%',
          minHeight: '100%',
          containerStyle: { minHeight: '100%', justifyContent: 'center', alignItems: 'center' },
          scrollEnabled: isOpen,
        };
        const scrollProps = ScrollProps ? merge(baseScrollProps, ScrollProps) : baseScrollProps;
        children = (
          <ScrollComponent {...scrollProps}>
            {!hideBackdrop ? (
              <BackdropComponent
                open={isOpen}
                onPress={handlePressBackdrop}
                disableAnimate={disableAnimate}
                {...BackdropProps}
              />
            ) : null}
            {childrenProp}
          </ScrollComponent>
        );
      }
      
      content = (
        <ModalFocusTrap
          manager={manager}
          modalId={modalId}
          active={isOpen && isTop}
          zIndex={zedPlus.current}
          disableTrapFocus={disableTrapFocus}
        >
          <Box
            pointerEvents={!isOpen ? 'none' : 'box-none'}
            disableAnimate
            zIndex={zedPlus.current}
            ref={ref}
            focusable={isTop} // Made true when opened so ModalFocusTrap could focus at something initially otherwise causes scroll issues on web. If not top modal then prevent focusing
            accessibility={{
              accessibilityRole: Platform.OS === 'web' ? 'dialog' : null,
              accessibilityModal: true,
              accessibilityViewIsModal: isOpen, // ios
              accessibilityElementsHidden: !isOpen, // ios - similar to android no-hide-descendants
              accessibilityHidden: !isOpen,
              importantForAccessibility: isOpen ? 'yes' : 'no-hide-descendants', // android
              ...accessibility,
            }}
            aria-modal
            {...rest}
          >
            {!hideBackdrop && !withScroll ? (
              <BackdropComponent
                open={isOpen}
                onPress={handlePressBackdrop}
                disableAnimate={disableAnimate}
                {...BackdropProps}
              />
            ) : null}
            {!disableAnimate ? (
              <Transition
                items={isOpen || keepMounted ? true : false}
                showHide
                key={modalId.current}
                animations={transitionAnimations}
              >
                {children}
              </Transition>
            ) : (
              children
            )}
          </Box>
        </ModalFocusTrap>
      );
    }

    return (
      <ModalPortal
        portalName={portalName}
        disablePortal={disablePortal}
      >
        {content}
      </ModalPortal>
    );
  })
);

const ModalPortal = ({
  portalName, // uses name from modalContainerContext if null/undefined (preferred)
  disablePortal = false,
  children,
}) => {
  const containerName = useModalContainerName(portalName);

  return disablePortal ? (
    children
  ) : (
    <Portal name={containerName}>{children}</Portal>
  );
};

const modalContainerContext = createContext('_modal_portal_');

function useModalContainerName(portalName) {
  const name = useContext(modalContainerContext);
  return portalName || name;
}

function ModalContainer({ children, ...props }) {
  const name = useRef(uniqueId('_modal_portal_'));
  return (
    <modalContainerContext.Provider value={name.current}>
      {children}
      <PortalDestination name={name.current} {...props} />
    </modalContainerContext.Provider>
  );
}

ModalContainer.displayName = 'ModalContainer';

export {
  modalContainerContext,
  useModalContainerName,
  ModalContainer,
  ModalEvents,
  ModalBackdrop,
  Modal,
  defaultManager as ModalManager,
};