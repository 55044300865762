import { useCallback } from 'react';
import { useAsync } from '../ui/hooks';
import { useAuth } from './useAuth';
import { ASYNC_USER_CACHE_KEY_PREFIX } from '../constants';
import { isObject, LocalStorage } from '../ui/utils';

const useAsyncInternal = (asyncFunction, asyncConfig) => {
  const {
    immediate = true,
    resetValueOnExecute = false,
    cache = false,
    cacheKey = null,
    comparer,
    compareWithout,
    isCanceled,
    cancel,
  } = isObject(asyncConfig) ? asyncConfig : {};

  if (asyncConfig !== undefined && !isObject(asyncConfig)) {
    throw `Second argument provided to useAsync should be an object. useAsync(asyncFunc, asyncConfig = { immediate, resetValueOnExecute, cache, cacheKey, comparer, compareWithout })`
  }
  
  const { user } = useAuth();
  const getCacheKey = useCallback((...args) => {
    if (cacheKey) {
      const key = typeof cacheKey === 'function' ? cacheKey(...args) : cacheKey;
      if (user && user.storageKey) {
        return `${user.storageKey}:${key}`;
      } else {
        return `${ASYNC_USER_CACHE_KEY_PREFIX}:public:${key}`;
      }
    }
    return null;
  }, [cacheKey, user]);

  const onCacheFull = useCallback(async (cacheKey, valueToStore) => {
    try {
      const storageKeys = await LocalStorage.getAllKeys();
      if (storageKeys && Array.isArray(storageKeys)) {
        const cacheKeysForUsers = storageKeys.filter((k) => k.startsWith(ASYNC_USER_CACHE_KEY_PREFIX));
        if (cacheKeysForUsers.length) {
          await LocalStorage.multiRemove(cacheKeysForUsers);
          await LocalStorage.setItem(cacheKey, valueToStore);
        }
      }
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(error);
      }
    }
  }, []);

  return useAsync(asyncFunction, {
    immediate,
    resetValueOnExecute,
    cache,
    cacheKey: getCacheKey,
    comparer,
    compareWithout,
    onCacheFull,
    cancel,
    isCanceled,
  });
}

export { useAsyncInternal as useAsync };
