import React, { useRef, useCallback, useState, useEffect, useMemo, useImperativeHandle } from 'react';
import { Animated, Dimensions, Platform } from 'react-native';
import { useEventCallback } from '../../hooks';
import { isNull, isObject, resolveNode } from '../../utils';
import { getStyle, withStyles } from '../../styling';
import { layout  } from '../../system/props';
import { ListItemTypes, getNumberFromStyle, getRenderInsets, getPaddingInsets } from './utils';
import { ListProcessor } from './ListProcessor';
import { WindowScrollView } from './WindowScrollView';
import { ScrollView, useScrollNode } from '../ScrollView';
import { ListSpacerItem } from './ListSpacerItem';
import { Box } from '../Box';
import equal from 'fast-deep-equal';

/*
  Performant recycler list view for use with window scrolling or ScrollView
  Based off of react-native-big-list v1.4.2 implementation (only ScrollView there though)
  https://github.com/marcocesarato/react-native-big-list/blob/d3166bbbffaa1f72f8e3c0c88fc1e82d133ff254/lib/BigList.jsx
*/

const ListRenderer = React.memo(withStyles(({ itemHeight, renderInsets, numColumns = 1, containerStyle, stickyHeaderEnabled, theme, ...rest }) => {
  return {
    // style for root ScrollView or WindowScrollView (Box) if useWindow=true
    root: {
      ...layout({
        width: '100%',
        alignSelf: 'stretch',
        flexDirection: 'row',
        flexWrap: 'wrap',
        ...rest,
      }),
    },
    // style for ScrollView contentContainer or WindowScrollView (Box) (combined with root style [root, contentContainer])
    contentContainer: {
      maxWidth: '100%',
      ...getStyle(containerStyle)
    },
    // style for root ScrollView/WindowScrollView applied when data or sections array is empty
    listEmpty: {},
    // style for root ScrollView/WindowScrollView applied when loading = true
    listLoading: {},
    // style passed to list item (renderItem). prefer to define itemHeight and any additional root item styles here to reduce style computation per list item
    item: {
      height: typeof itemHeight === 'function' ? null : (getNumberFromStyle(itemHeight) || 100),
      width: `${100 / numColumns}%`,
      maxWidth: `${100 / numColumns}%`,
    },
    // style combined with item when numColumns > 1 [item, column]
    column: {},
    // style passed to ListSpacerItem (wrapper which ensures height) and either passes it to renderPlaceholder if defined or the <Image> (placeholderImage) component otherwise
    placeholder: {},
    // style passed to renderEmpty (only rendered when empty)
    empty: { height: 0 },
    // style passed to renderLoading (only rendered when loading = true)
    loading: { height: 0 },
    // style passed to renderAccessory
    accessory: { position: 'absolute' }, // NOTE: accessories should be absolutely positioned to prevent list onLayout cycles
    // style passed to renderHeader. define height here if rendering header
    header: {
      height: 0,
      width: '100%',
    },
    sticky: {
      position: 'sticky',
      top: 0,
    },
    // style passed to renderFooter. defined height here if rendering footer
    footer: { height: 0, width: '100%' },
    // style passed to renderSectionHeader. define height here if rendering a section header using sections prop
    sectionHeader: { height: 0, width: '100%' },
    // style passed to renderSectionFooter. define height here if rendering a section footer using sections prop
    sectionFooter: { height: 0, width: '100%' },
    // TODO - not implemented. style passed to renderBetween component which renders between items
    between: { height: 0 },
    // style combined with sectionHeader and sectionFooter when empty or loading = true.
    // keep height at 0 or face the consequences
    hidden: { height: 0, maxHeight: 0, display: 'none' },
    // can ignore. renderInsets prop is passed here to enable getting theme.spacing shorthands or style functions calculated
    renderInsets: { ...(!isNull(renderInsets) && isObject(renderInsets) ? renderInsets : { top: 0, bottom: 0 }) },
    // styled for ListSpacerItem. only style this if you know what you're doing otherwise use renderPlaceholder
    listSpacer: {},
  };
}, { filterProps: [...layout.filterProps, 'containerStyle'], name: 'ListRenderer' })
(React.forwardRef(function ListRenderer(props, ref) {
  const {
    data, // array of objects used for single section use
    sections, // array of nested arrays. use this for multiple sections. if defined then sections will be used over data
    keyExtractor, // option function to use to get a specific id for data/section items (item, optionalUniqueFallbackId) => return id from item or optional
    numColumns = 1, // use to render multiple column list. Must be >= 1 and defaults to 1
    renderInsets, // object { top, bottom } for where the visible rendering area should be calculated from the scroll position (useful for absolute/fixed overlayed items outside of this that cover the list)
    loading = false, // indicate the list is receiving new data and render loading if renderLoading prop is defined
    renderItem, // * function to render the item. Make sure to pass key and style -> ({ item, section, index, key, style }) => component
    renderPlaceholder, // function to render the placeholder spacer between where the container/window and rendering starts and where rendering ends and container/window ends
    placeholderImage, // use default renderPlaceholder and include the image to repeat
    renderEmpty, // return what to render when list is empty (data = [] or sections = []/[[],[], etc...])
    renderLoading, // return what to render when loading
    renderAccessory = null, // return and render an accessory component after the list of items
    renderHeader, // return and render a component for header (rendered first in list)
    renderFooter, // return and render a component for footer (rendered last in list)
    renderSectionHeader, // return and render a component for header of section
    renderSectionFooter, // return and render a component for footer of a section
    itemHeight, // func or style num/string for item height. can be specified in styles instead. if function then rendering can be slower as list assumes heights may differ
    sectionHeaderHeight, // ^^
    sectionFooterHeight, // ^^
    renderBetween, // TODO (not implemented) return and render a componenet to render between items (does not render before or after a section/header/footer/placeholder)
    hideHeaderOnEmpty, // hide header when empty
    hideHeaderOnLoading, // hide header when loading
    hideFooterOnEmpty, // ^^
    hideFooterOnLoading, // ^^
    showSectionHeaderOnEmpty,
    showSectionHeaderOnLoading,
    styles, // comes from withStyles. can use this to define the heights of applicable render props (items, headers, footers, etc) and this is useful for different heights per theme breakpoint
    style, // withStyles prop can ignore
    batchSizeThreshold = 1, // greater means more items rendered outside of visible area. minimum is 0.5 or 50% of the visible area
    scrollEventThrottle = 5, // throttle how often checking of scroll position occurs in order to update the components to render
    onScroll,
    onLayout,
    onEndReached,
    onEndReachedThreshold = 0, // percentage of the containerHeight from the bottom in which onEndReached should be called
    useWindow = false, // use window scroll instead of a scrollview
    ScrollComponent = null, // use a custom scroll component. must have similar onScroll and onLayout event props
    scrollNode = false, // use parent scroll node via useScrollNode if true or the node if set to the node and use just a box container - no scrollview or window scroll. Use this only if you are rendering this within a scrollview and will update this component from parent scrollview updates
    // ScrollView props
    removeClippedSubviews = false,
    keyboardShouldPersistTaps = true,
    keyboardDismissMode = 'interactive',
    contentContainerStyle, // dont use this. Here to preserve ScrollView implementation...
    containerStyle, // dont use this. Here to preserve ScrollView implementation...
    // props to-do
    forceStickyPosition = false,
    stickyHeaderEnabled = false,
    stickySectionHeadersEnabled = false, // TODO: <- using animated.value need to implement this with WindowScrollView and interpolate the absolute position of a section header in the list
    ...rest
  } = props;

  const mounted = useRef(true);
  useEffect(() => { return () => { mounted.current = false; } }, []);
  const boxOnly = scrollNode !== false;
  let scrollViewNode = useScrollNode().current;
  if (scrollNode && typeof scrollNode !== 'boolean') {
    scrollViewNode = scrollNode;
  }
  const paddingInsets = useMemo(() => getPaddingInsets(styles.root), [styles.root]);
  const refStates = useRef({ containerHeight: 0, scrollTop: 0, endReached: false });
  const nodeRef = useRef(null);
  const animatedScrollTopValue = useRef(new Animated.Value(0));
  const [stickyPosition, setStickyPosition] = useState(null);
  const [state, setState] = useState(() => {
    let initialContainerHeight = scrollViewNode
      && scrollViewNode.scrollEventManager
      && scrollViewNode.scrollEventManager.latest
      && scrollViewNode.scrollEventManager.latest.onLayout
      ? scrollViewNode.scrollEventManager.latest.onLayout.nativeEvent.layout.height
      : 0;

    if (!initialContainerHeight) {
      initialContainerHeight = getNumberFromStyle(styles.root.height);
      if (!initialContainerHeight && useWindow) {
        initialContainerHeight = Dimensions.get('window').height;
      } else {
        initialContainerHeight = getNumberFromStyle(styles.root.minHeight);
      }
    }
    return getListState(
      {
        data,
        sections,
        itemHeight,
        styles,
        sectionHeaderHeight,
        sectionFooterHeight,
        batchSizeThreshold,
        numColumns,
      },
      processBlock({
        containerHeight: initialContainerHeight,
        scrollTop: refStates.current.scrollTop,
        batchSizeThreshold,
      }),
    )
  });


  const updateStickyPosition = useEventCallback(() => {
    if (!mounted.current || !forceStickyPosition || !(stickySectionHeadersEnabled || stickyHeaderEnabled)) {
      return;
    }
    const container = resolveNode(nodeRef.current);
    if (container && container.measure) {
      container.measure((x, y, width, height, pageX, pageY) => {
        if (mounted.current) {
          const nextPosition = { left: ~~pageX, top: ~~y };
          if (!equal(stickyPosition, nextPosition)) {
            setStickyPosition(nextPosition);
          }
        }
      });
    }
  })

  useEffect(() => {
    if (forceStickyPosition && (stickyHeaderEnabled || stickySectionHeadersEnabled)) {
      Dimensions.addEventListener('change', updateStickyPosition);
    }
    return () => {
      Dimensions.removeEventListener('change', updateStickyPosition);
    }
  }, [updateStickyPosition, stickyHeaderEnabled, stickySectionHeadersEnabled])


  const handleScroll = useCallback((event) => {
    const { nativeEvent } = event;
    const containerHeight = refStates.current.containerHeight ? refStates.current.containerHeight : ~~nativeEvent.layoutMeasurement.height;
    const scrollTop = ~~Math.min(
      Math.max(0, nativeEvent.contentOffset.y - paddingInsets.top), // padding of the container affects the blockStart value so need to subtract it from the offset
      nativeEvent.contentSize.height - containerHeight
    );
    refStates.current.scrollTop = scrollTop;

    const nextState = processBlock({
      containerHeight,
      scrollTop,
      batchSizeThreshold,
    });

    if (
      nextState.batchSize !== state.batchSize ||
      nextState.blockStart !== state.blockStart ||
      nextState.blockEnd !== state.blockEnd
    ) {
      setState((prevState) => getListState({
        data,
        sections,
        styles,
        itemHeight,
        sectionHeaderHeight,
        sectionFooterHeight,
        batchSizeThreshold,
        numColumns,
      }, { ...prevState, ...nextState }));
    }

    if (onScroll) {
      onScroll(event)
    }

    if (onEndReached) {
      const { layoutMeasurement, contentOffset, contentSize, containerOffset = 0 } = nativeEvent;
      const distanceFromEnd = contentSize.height - (layoutMeasurement.height + contentOffset.y) - containerOffset;
      if (distanceFromEnd <= layoutMeasurement.height * onEndReachedThreshold) {
        if (!refStates.current.endReached) {
          refStates.current.endReached = true;
          onEndReached({ distanceFromEnd });
        }
      } else {
        refStates.current.endReached = false;
      }
    }
  }, [data, sections, itemHeight, sectionHeaderHeight, sectionFooterHeight, styles, batchSizeThreshold, state, onScroll, paddingInsets, numColumns, onEndReached, onEndReachedThreshold]);

  const handleLayout = useEventCallback((event) => {
    const { nativeEvent } = event;
    const containerHeight = ~~nativeEvent.layout.height;
    if (refStates.current.containerHeight !== containerHeight) {
      refStates.current.containerHeight = containerHeight;
      const nextState = processBlock({
        containerHeight,
        scrollTop: refStates.current.scrollTop,
        batchSizeThreshold,
      });
      if (
        nextState.batchSize !== state.batchSize ||
        nextState.blockStart !== state.blockStart ||
        nextState.blockEnd !== state.blockEnd
      ) {
        setState((prevState) => getListState({
          data,
          sections,
          styles,
          itemHeight,
          sectionHeaderHeight,
          sectionFooterHeight,
          batchSizeThreshold,
          numColumns,
        }, { ...prevState, ...nextState }));
      }
    }

    if (onLayout) {
      onLayout(event);
    }

    if (forceStickyPosition && (stickyHeaderEnabled || stickySectionHeadersEnabled)) {
      updateStickyPosition();
    }
  }, [data, sections, itemHeight, sectionHeaderHeight, sectionFooterHeight, styles, batchSizeThreshold, onLayout, state, numColumns, stickyHeaderEnabled, stickySectionHeadersEnabled, updateStickyPosition, forceStickyPosition]);


  const detachScrollNodeEvents = useRef({ onScroll: null, onLayout: null });
  const handleScrollRef = useRef();
  handleScrollRef.current = handleScroll;
  const handleLayoutRef = useRef();
  handleLayoutRef.current = handleLayout;
  useEffect(() => {
    if (boxOnly && scrollViewNode && scrollViewNode.scrollEventManager) {
      detachScrollNodeEvents.current.onScroll = scrollViewNode.scrollEventManager.attachOnScrollHandler(handleScrollRef);
      detachScrollNodeEvents.current.onLayout = scrollViewNode.scrollEventManager.attachOnLayoutHandler(handleLayoutRef);
    }
    return () => {
      if (detachScrollNodeEvents.current.onScroll) {
        detachScrollNodeEvents.current.onScroll();
      }
      if (detachScrollNodeEvents.current.onLayout) {
        detachScrollNodeEvents.current.onLayout();
      }
    }
  }, [scrollViewNode, boxOnly])


  // TODO useImperativeHandle and include some things like isEmpty, etc...
  const isEmpty = useCallback(() => {
    const sectionLengths = getSectionLengths(sections, data);
      const length = sectionLengths.reduce((total, sectionLength) => {
        return total + sectionLength;
      }, 0);
      return length === 0;
  }, [sections, data])
  
  const isEmptyList = useMemo(() => isEmpty(), [isEmpty, data, sections]);

  useEffect(() => {
    setState((prevState) => {
      return getListState({
        data,
        sections,
        styles,
        itemHeight,
        sectionHeaderHeight,
        sectionFooterHeight,
        batchSizeThreshold,
        numColumns
      }, prevState);
    });
    
  }, [data, sections, itemHeight, sectionHeaderHeight, sectionFooterHeight, styles, batchSizeThreshold, numColumns, isEmptyList]);

  const renderItems = useCallback(() => {
    const { items: _items = [] } = state;
    const items = [..._items];
    const hasSections = !isNull(sections);
    const getItem = ({ index, section = 0 }) => {
      if (hasSections) {
        return sections[section][index];
      }
      return data[index];
    }

    const emptyItem = renderEmpty ? renderEmpty({ style: styles.empty, key: 'empty', styles }) : null;
    const loadingItem = renderLoading ? renderLoading({ style: styles.loading, key: 'loading', styles }) : null;

    // if loading = true or list is empty
    if (loading && loadingItem) {
      if (hideFooterOnLoading && hideHeaderOnLoading && !showSectionHeaderOnLoading) {
        return loadingItem;
      }
      const sectionHeaderIndex = items.findIndex(
        (item) => item.type === ListItemTypes.SECTION_HEADER,
      )
      const headerIndex = items.findIndex(
        (item) => item.type === ListItemTypes.HEADER,
      );
      const headerSectionIndex = sectionHeaderIndex >= 0 ? sectionHeaderIndex : headerIndex;
      items.splice(headerSectionIndex + 1, 0, {
        type: ListItemTypes.LOADING,
        key: 'loading',
      });
      if (hideHeaderOnLoading) {
        items.splice(headerIndex, 1);
      }
      if (hideFooterOnLoading) {
        const footerIndex = items.findIndex(
          (item) => item.type === ListItemTypes.FOOTER,
        );
        items.splice(footerIndex, 1);
      }
    } else if (isEmptyList && emptyItem) {
      if (hideHeaderOnEmpty && hideFooterOnEmpty && !showSectionHeaderOnEmpty) {
        return emptyItem;
      }
      const sectionHeaderIndex = items.findIndex(
        (item) => item.type === ListItemTypes.SECTION_HEADER,
      )
      const headerIndex = items.findIndex(
        (item) => item.type === ListItemTypes.HEADER,
      );
      const headerSectionIndex = sectionHeaderIndex >= 0 ? sectionHeaderIndex : headerIndex;
      items.splice(headerSectionIndex + 1, 0, {
        type: ListItemTypes.EMPTY,
        key: 'empty',
      });
      if (hideHeaderOnEmpty) {
        items.splice(headerIndex, 1);
      }
      if (hideFooterOnEmpty ) {
        const footerIndex = items.findIndex(
          (item) => item.type === ListItemTypes.FOOTER,
        );
        items.splice(footerIndex, 1);
      }
    }

    const sectionPositions = [];
    items.forEach(({ type, position }) => {
      if (type === ListItemTypes.SECTION_HEADER) {
        sectionPositions.push(position);
      }
    });
    
    const children = [];
    items.forEach(({ type, key, position, height, section, index }) => {
      const itemKey = key || position; // fallback;
      let uniqueKey = String((section + 1) * index);
      let child;
      switch (type) {
        case ListItemTypes.HEADER:
          child = renderHeader ? stickyHeaderEnabled ? (
            renderHeader({
              style: [{ zIndex: items.length + position, ...stickyPosition }, styles.sticky, styles.header],
              sticky: true,
              key: uniqueKey,
              height,
              styles,
            })
          ) : renderHeader({
            style: styles.header,
            key: uniqueKey,
            height,
            styles,
          }) : null;
        // falls through
        case ListItemTypes.FOOTER:
          if (type === ListItemTypes.FOOTER) {
            child = renderFooter ? renderFooter({
              style: styles.footer,
              key: uniqueKey,
              height,
              styles,
            }) : null;
          }
        // falls through
        case ListItemTypes.SECTION_FOOTER:
          if (type === ListItemTypes.SECTION_FOOTER && !loading) {
            height = isEmptyList ? 0 : height;
            child = renderSectionFooter ? renderSectionFooter({
              section,
              height,
              style: isEmptyList ? [styles.sectionFooter, styles.hidden] : styles.sectionFooter,
              key: uniqueKey,
              styles,
            }) : null;
          }
        // falls through
        case ListItemTypes.ITEM:
          if (type === ListItemTypes.ITEM && !loading) {
            const item = getItem({ section, index });
            uniqueKey = keyExtractor
              ? keyExtractor(item, uniqueKey)
              : uniqueKey;
            const itemStyle = numColumns > 1 ? [styles.item, styles.column] : styles.item;
            child = renderItem && !isNull(item) ? renderItem({
              item,
              index,
              key: uniqueKey,
              style: itemStyle,
              height,
              styles,
              ...(hasSections ? { section } : null)
            }) : null;
          }
          if (child != null) {
            children.push(child)
          }
          break;
        case ListItemTypes.LOADING:
          children.push(loadingItem);
          break;
        case ListItemTypes.EMPTY:
          children.push(emptyItem);
          break;
        case ListItemTypes.SPACER:
          if (!loading) {
            children.push(
              <ListSpacerItem
                key={itemKey}
                height={height}
                renderPlaceholder={renderPlaceholder}
                placeholderImage={placeholderImage}
                placeholderStyle={styles.placeholder}
                listStyles={styles}
                style={styles.listSpacer}
              />
            );
          }
          break;
        case ListItemTypes.SECTION_HEADER:
          height = isEmptyList && !showSectionHeaderOnEmpty ? 0 : height;
          sectionPositions.shift();
          child = renderSectionHeader && !(loading && !showSectionHeaderOnLoading) ? renderSectionHeader({
            section,
            height,
            key: itemKey,
            style: isEmptyList && !showSectionHeaderOnEmpty ? [styles.sectionHeader, styles.hidden] : styles.sectionHeader,
            styles,
          }) : null;
          if (child != null) {
            // TODO push wrapper or HOC for child to receive animated props for sticky headers
            children.push(child);
          }
          break;
      }
    });
    return children;
  }, [state, state.items, data, sections, styles, numColumns, isEmptyList, loading, stickyHeaderEnabled, stickyPosition]) // may need to add other related props. right nwo these need to be included in ensure the re-render is done properly with updated blockstart/end etc
  
  const rootStyle = useMemo(() => {
    if (loading) {
      return [style, styles.listLoading];
    }
    if (isEmptyList) {
      return [style, styles.listEmpty];
    }
    return style;
  }, [styles, style, isEmptyList, loading]);

  const Scroll = ScrollComponent ? ScrollComponent : boxOnly ? BoxOnlyContainer : useWindow ? WindowScrollView : ScrollView;
  const scrollHandler = stickySectionHeadersEnabled && Platform.OS === 'web' && !boxOnly
    ? Animated.event(
        [{ nativeEvent: { contentOffset: { y: animatedScrollTopValue.current } } }],
        {
          listener: (...args) => handleScroll(...args),
          useNativeDriver: true,
        },
      )
    : handleScroll;
  const renderedItems = renderItems();

  
  useImperativeHandle(
    ref,
    () => ({
      scrollTo: (...args) => {
        // TODO: the scroll component could be the nodeRef
        if (scrollViewNode && scrollViewNode.scrollTo) {
          scrollViewNode.scrollTo(...args);
        } else if (nodeRef.current && nodeRef.current.scrollTo) {
          nodeRef.current.scrollTo(...args);
        }
      },
      ...nodeRef.current
    }),
    [handleLayout, handleScroll, scrollViewNode]
  );

  return (
    <Scroll
      ref={nodeRef}
      {...rest}
      style={rootStyle}
      onScroll={scrollHandler}
      onLayout={handleLayout}
      scrollEventThrottle={scrollEventThrottle}
      removeClippedSubviews={removeClippedSubviews}
      keyboardDismissMode={keyboardDismissMode}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      containerStyle={styles.contentContainer}
      contentContainerStyle={contentContainerStyle}
    >
      {renderedItems}
      {renderAccessory != null ? renderAccessory({ styles, style: styles.accessory }) : null}
      {/* <Box position="absolute" left="0" top="0" width="400" height="200" bg="red" animated style={{ transform: [{ translateY: animatedScrollTopValue.current }] }}  /> */}
      {/* <Animated.View style={{ position: 'absolute', left: 0, top: 0, width: 400, height: 200, backgroundColor: 'red', transform: [{ translateY: animatedScrollTopValue.current }] }} /> */}
    </Scroll>
  )
})));

const BoxOnlyContainer = React.forwardRef(function BoxOnlyContainer(props, ref) {
  const {
    style: styleProp,
    containerStyle,
    contentContainerStyle,
    // dont pass
    onScroll, // need to be tracked on parent scroll and called imperatively to ListRenderer.onScroll
    onLayout, // need to be tracked on parent scroll and called imperatively to ListRenderer.onLayout
    scrollEventThrottle,
    scrollEnabled,
    removeClippedSubviews,
    contentInset,
    keyboardShouldPersistTaps,
    keyboardDismissMode,
    ...rest
  } = props;

  const style = useMemo(() => [styleProp, containerStyle], [styleProp, containerStyle, contentContainerStyle]);

  return <Box ref={ref} style={style} {...rest} />;
});
// TODO: come back to looking at what should be optimized here.
// renderItems currently doesnt include the render functions in it's dependency array and data is not compared to see if anything changed
// when all these things are memoized then updates within a render func do always not get rendered (i.e. renderHeader(...hide ActivityInidicator) <- ActivityInidicator will still show)
// const Optimized = React.forwardRef(({
//   // data: d,
//   // keyExtractor: ke,
//   // renderItem: ri,
//   // renderEmpty: re,
//   // renderHeader: rh,
//   // renderLoading: rl,
//   ...props
// }, ref) => {
//   // const data = useMemoCompare(d);
//   // const keyExtractor = useEventCallback(ke, [ke]);
//   // // const renderItem = useEventCallback(ri, [ri]);
//   // const renderEmpty = useEventCallback(re, [re]);
//   // // const renderHeader = useEventCallback(rh, [rh]);
//   // const renderLoading = useEventCallback(rl, [rl]);
//   const p = {
//     // data,
//     // keyExtractor,
//     // renderItem,
//     // renderEmpty,
//     // renderHeader, 
//     // renderLoading,
//     ...props,
//   };
//   return <ListRenderer ref={ref} {...p} />
// });

const getListState = (props, prevState) => {
  const {data, sections, itemHeight, sectionHeaderHeight, sectionFooterHeight, styles, numColumns } = props;
  const { batchSize, blockStart, blockEnd, items: prevItems } = prevState;
  const renderInsets = getRenderInsets(styles);
  if (batchSize === 0) {
    return {
      batchSize,
      blockStart,
      blockEnd,
      height: renderInsets.top + renderInsets.bottom,
      items: [],
    }
  }
  const sectionLengths = getSectionLengths(sections, data, 1);
  const processor = new ListProcessor({
    sections: sectionLengths,
    itemHeight,
    sectionHeaderHeight,
    sectionFooterHeight,
    styles,
    numColumns,
  });
  return {
    ...{
      batchSize,
      blockStart,
      blockEnd,
    },
    ...processor.process(
      blockStart - batchSize,
      blockEnd + batchSize,
      prevItems || [],
    ),
  };
};

function getSectionLengths(sections = null, data = null, otherwise = 0) {
  if (sections !== null) {
    return sections.map((section) => {
      return section.length ? section.length : otherwise;
    })
  }
  return [data && data.length ? data.length : otherwise];
}

function processBlock({ containerHeight, scrollTop, batchSizeThreshold = 1, }) {
  if (containerHeight === 0) {
    return {
      batchSize: 0,
      blockStart: 0,
      blockEnd: 0,
    };
  }
  const batchSize = Math.ceil(
    containerHeight * Math.max(0.5, batchSizeThreshold),
  );
  const blockNumber = Math.ceil(scrollTop / batchSize);
  const blockStart = batchSize * blockNumber;
  const blockEnd = blockStart + batchSize;
  return { batchSize, blockStart, blockEnd };
}

const fixScrollJiggle = () => {
  if (typeof window !== 'undefined') {
    if (!(window.innerWidth > document.documentElement.clientWidth)) {
      const scrollDiv = document.createElement('div');
      scrollDiv.style.width = '99px';
      scrollDiv.style.height = '99px';
      scrollDiv.style.position = 'absolute';
      scrollDiv.style.top = '-9999px';
      scrollDiv.style.overflow = 'scroll';

      document.body.appendChild(scrollDiv);
      const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
      document.body.style.paddingRight = `${scrollbarSize}px`;
    } else {
      document.body.style.paddingRight = `0px`;
    }
  }
}

export { ListRenderer }
// export { Optimized as ListRenderer };