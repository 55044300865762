import { useMemo } from 'react';
import { useEventCallback, useBreakpoint } from '../../hooks';
import { isObject } from '../../utils';


export const Breakpoint = (props) => {
  const {
    children,
    renderWhen,
  } = props;

  const [breakpoint] = useBreakpoint();
  
  const isRenderable = useEventCallback((bp) => {
    if (!bp || !bp.key) {
      return false;
    }
    if (typeof renderWhen === 'function') {
      return renderWhen(bp);
    }
    if (Array.isArray(renderWhen)) {
      return renderWhen.includes(bp.key) ? true : false;
    }
    if (isObject(renderWhen)) {
      return renderWhen[bp.key] ? true : false;
    }
    return true;
  });

  const shouldRender = useMemo(() => {
    return isRenderable(breakpoint);
  }, [breakpoint, isRenderable]);

  if (!shouldRender) {
    return null;
  }

  if (children && typeof children === 'function') {
    return children(breakpoint);
  }

  return children;
}