import { useMemo } from "react";
import { useMemoCompare } from ".";
import { getBreakpointValue, resolveStyleFunc } from "../system";
import { isObject } from "../utils";
import { useBreakpoint } from "./useBreakpoint";
import { useTheme } from "./useTheme";

const breakpointDirections = { 'up': 'up', 'down': 'down' };

const useSystemValue = (v, { asBreakpoint = false, breakpointDirection = 'down', props: p } = {}) => {
  const theme = useTheme();
  const [breakpoint, breakpoints] = useBreakpoint();
  const value = useMemoCompare(v);
  const resolved = useMemo(() => {
    const props = { theme, breakpoints, breakpoint, ...p };
    if (value === undefined) {
      return undefined;
    } else if (value === true) {
      return value;
    } else if (typeof value === 'function') {
      return resolveStyleFunc(value, props);
    } else if (asBreakpoint) {
      if (isObject(value)) {
        return getBreakpointValue(value, props)
      } else if (typeof value === 'string' && breakpoints.values[value]) {
        const dir = breakpointDirections[breakpointDirection] ? breakpointDirection : 'down';
        return getBreakpointValue({
          [breakpoints[dir](value)]: false,
          [value]: true,
        }, props);
      }
    }
    return value;
  }, [value, asBreakpoint, breakpointDirection, p, theme, breakpoints, breakpoint]);

  return resolved;
}


export { useSystemValue }