import React, { useMemo, useContext } from 'react';
import { useApiService } from './useApiService';
import { ASYNC_API_CACHE_KEY_PREFIX } from '../constants';
import { isObject, sleep } from '../ui/utils';
import { encodeSearchConfig } from './useSearchConfig';

function getSearch(search) {
  return search ? typeof search === 'string' ? `?search=${search}` : isObject(search) ? `?search=${encodeSearchConfig(search)}` : '' : '';
}

const ApiContext = React.createContext();
export const useApi = () => useContext(ApiContext);

export const ApiProvider = ({ auth, children }) => {
  const reportingV3 = useApiService({ auth, baseUri: process.env.REACT_APP_REPORTING_BASE_URI + '/api/v3', cache: true, asyncStorageKey: ASYNC_API_CACHE_KEY_PREFIX });
  const reportingV2 = useApiService({ auth, baseUri: process.env.REACT_APP_REPORTING_BASE_URI + '/api/v2', cache: true, asyncStorageKey: ASYNC_API_CACHE_KEY_PREFIX });
  const kalidasa = useApiService({ auth, baseUri: process.env.REACT_APP_KALIDASA_BASE_URI });

  const methods = useMemo(() => {
    return {
// USER DATA ---------------------------------------------------------------------------------------------
      getUserData: async () => {
        return await kalidasa.get({ path: '/users/me', errorMessage: 'Failed to get user data.' });
      },
// APPLICATION DATA ---------------------------------------------------------------------------------------------
      getApplications: async (search, unquoted = true) => {
        const s = getSearch(search);
        const q1 = s ? `${s}&` : '?';
        const query = `${q1}unquoted=${unquoted ? 'true' : 'false'}`;
        const response = await reportingV3.get({
          path: `/applications${query}`,
          errorMessage: 'Failed to get applications.',
        });
        return response;
      },
      getApplicationWithId: async (applicationId) => {
        return await reportingV3.get({
          path: `/applications/${applicationId}`,
          errorMessage: `Failed to get application with id: ${applicationId}`
        });
      },
      getApplicationWithQuoteId: async (quoteId) => {
        return await reportingV3.get({
          path: `/quotes/${quoteId}/application`,
          errorMessage: `Failed to get application from quote with id: ${quoteId}`
        });
      },
// APPLICATION ACTIONS ---------------------------------------------------------------------------------------------
      submitApplication: async (application) => {
        return await reportingV3.post({ path: '/applications', body: application, errorMessage: 'Failed to create application.' });
      },
      uploadFileForApplication: async (applicationId, appFile) => {
        // broker uploads a binding form document
        const { id, document } = appFile;
        const formData = new FormData();
        formData.append('files', document);
        return await reportingV3.postForm({
          path: `/applications/${applicationId}/files/${id}`,
          formData,
          errorMessage: `Failed to upload document with file id: ${id} and application id: ${applicationId}`
        });
      },
      uploadLossHistory: async (applicationId, appFile) => {
        const { id, document } = appFile;
        const formData = new FormData();
        formData.append('files', document);
        return await reportingV3.postForm({
          path: `/applications/${applicationId}/losses`,
          formData,
          errorMessage: `Failed to upload loss history`
        });
      },
      createQuoteForApplication: async ({ id }) => {
        return await reportingV3.post({
          path: `/applications/${id}/quotes`,
          body: {},
          errorMessage: 'Failed to create quote for application.'
        });
      },
      updateApplication: async (application) => {
        const { id } = application || {};
        return await reportingV3.put({
          path: `/applications/${id}`,
          body: application,
          errorMessage: 'Failed to update application with id' + id,
        });
      },
      createApplicationLossHistoryDetail: async (applicationId, detail) => {
        return await reportingV3.post({
          path: `/applications/${applicationId}/loss_history_detail`,
          body: detail,
          errorMessage: 'Failed to create loss history detail for application with id' + applicationId,
        });
      },
      updateApplicationLossHistoryDetail: async (detail) => {
        return await reportingV3.put({
          path: `/applications/${detail.applicationId}/loss_history_detail/${detail.id}`,
          body: detail,
          errorMessage: 'Failed to update loss history detail with id ' + detail.id + ' for application with id ' + detail.applicationId,
        });
      },
      deleteApplicationLossHistoryDetailWithId: async (applicationId, detailId) => {
        return await reportingV3.delete({
          path: `/applications/${applicationId}/loss_history_detail/${detailId}`,
          errorMessage: 'Failed to delete loss history detail with id ' + detailId + ' for application with id ' + applicationId,
        });
      },
      getLossCauses: async (productId) => {
        return await reportingV3.get({
          path: `/products/${productId}/loss_causes`,
          errorMessage: `Failed to get loss causes`
        });
      },
      createLossCause: async (lossCauseData) => {
        return await reportingV3.post({
          path: `/loss_causes`,
          body: lossCauseData,
          errorMessage: 'Failed to create loss cause',
        });
      },
      deleteLossCauseWithId: async (lossCauseId) => {
        return await reportingV3.get({
          path: `/loss_causes/${lossCauseId}`,
          errorMessage: `Failed to delete loss cause with id: ${lossCauseId}`
        });
      },
// QUOTE DATA ---------------------------------------------------------------------------------------------
      getQuotes: async (search) => {
        return await reportingV3.get({
          path: `/quotes${getSearch(search)}`,
          errorMessage: `Failed to get quotes.`
        });
      },
      getQuoteWithId: async (quoteId) => {
        return await reportingV3.get({
          path: `/quotes/${quoteId}`,
          errorMessage: `Failed to get quote with id: ${quoteId}`
        });
      },
      generateWorkbook: async (quoteId) => {
        return await reportingV3.post({
          path: `/quotes/${quoteId}/workbook/generate`,
          errorMessage: `Failed to generate workbook with id: ${quoteId}`
        });
      },
      generateQuoteDocs: async (quoteId) => {
        return await reportingV3.post({
          path: `/quotes/${quoteId}/docs`,
          errorMessage: `Failed to generate quote docs with id: ${quoteId}`
        });
      },
      getWorkbook: async (quoteId) => {
        return await reportingV3.get({
          path: `/quotes/${quoteId}/workbook`,
          errorMessage: `Failed to get workbook with id: ${quoteId}`
        });
      },
      getAdjustmentsWithQuoteId: async (quoteId) => {
        return await reportingV3.get({
          path: `/quotes/${quoteId}/adjustments`,
          errorMessage: `Failed to get adjustments from quote with id: ${quoteId}`
        });
      },
      getRequestsWithQuoteId: async (quoteId) => {
        return await reportingV3.get({
          path: `/quotes/${quoteId}/requests`,
          errorMessage: `Failed to get requests from quote with id: ${quoteId}`
        });
      },
      getPreviewOfAdjustmentOnQuoteId: async (quoteId, { requestId, notes, quote }) => {
        return await reportingV3.post({
          path: `/quotes/${quoteId}/adjustments?preview=true`,
          body: {
            ...(requestId ? { requestId } : null),
            notes,
            quote,
          },
          errorMessage: `Failed to apply and get adjustment preview on quote with id: ${quoteId}`
        });
      },
      addDescriptionForQuoteWithId: async (quoteId, description = "") => {
        return await reportingV3.post({
          path: `/quotes/${quoteId}/description`,
          body: { description },
          errorMessage: `Failed to add description, "${description}", to quote with id ${quoteId}`
        });
      },
      getMacavityForQuote: async (quoteId) => {
        return await reportingV3.get({
          path: `/macavity/quotes/${quoteId}`,
          errorMessage: `Failed to get Macavity results with id: ${quoteId}`
        });
      },
      getQuoteConcentrations: async (quoteId) => {
        return await reportingV3.get({
          path: `/quotes/${quoteId}/concentrations`,
          errorMessage: `Failed to get concentrations with quote id: ${quoteId}`
        });
      },
// QUOTE ACTIONS ---------------------------------------------------------------------------------------------
      submitAdjustmentOnQuoteId: async (quoteId, { requestId, notes, quote }) => {
        return await reportingV3.post({
          path: `/quotes/${quoteId}/adjustments`,
          body: {
            ...(requestId ? { requestId } : null),
            notes,
            quote,
          },
          errorMessage: `Failed to apply adjustment on quote with id: ${quoteId}`
        });
      },
      submitRequestOnQuoteId: async (quoteId, request) => {
        return await reportingV3.post({
          path: `/quotes/${quoteId}/requests`,
          body: { request },
          errorMessage: `Failed to make request on quote with id: ${quoteId}`
        })
      },
      runMacavityForQuote: async (quoteId) => {
        return await reportingV3.post({
          path: `/macavity/quotes/${quoteId}`,
          body: {},
          errorMessage: `Could not run Macavity for quote with id: ${quoteId}`,
        });
      },
      releaseQuoteWithId: async (quoteId) => {
        // underwriter releases/publishes quote for broker to view pricing
        return await reportingV3.post({
          path: `/quotes/${quoteId}/release`,
          body: {},
          errorMessage: `Failed to release quote with id: ${quoteId}`
        });
      },
      acceptQuoteWithId: async (quoteId) => {
        // broker accepts quote on behalf of client and quote goes to prebound
        return await reportingV3.post({
          path: `/quotes/${quoteId}/prebind`,
          body: {},
          errorMessage: `Failed to accept quote with id: ${quoteId}`
        });
      },
      rejectQuoteWithId: async (quoteId) => {
        // broker or underwriter rejects quote entirely.
        return await reportingV3.post({
          path: `/quotes/${quoteId}/reject`,
          body: {},
          errorMessage: `Failed to reject quote with id: ${quoteId}`
        });
      },
      bindQuoteWithId: async (quoteId) => {
        // underwriter binds quote officially and a policy is generated which is the response returned
        return await reportingV3.post({
          path: `/quotes/${quoteId}/bind`,
          body: {},
          errorMessage: `Could not bind quote with id: ${quoteId}`,
        });
      },
// BINDING FORMS ---------------------------------------------------------------------------------------------
      getBindingForms: async (searchConfig) => {
        return await reportingV3.get({
          path: `/binding_forms${getSearch(searchConfig)}`,
          errorMessage: `Failed to get binding forms`,
        });
      },
      getBindingFormsWithQuoteId: async (quoteId, searchConfig) => {
        return await reportingV3.get({
          path: `/public/quotes/${quoteId}/binding_forms${getSearch(searchConfig)}`,
          errorMessage: `Failed to get binding forms with quote id: ${quoteId}`,
          authenticated: false,
        })
      },
      getBindingFormWithIdForQuoteId: async (quoteId, bindingFormId) => {
        return await reportingV3.get({
          path: `/public/quotes/${quoteId}/binding_forms/${bindingFormId}`,
          errorMessage: `Failed to get binding form with id ${bindingFormId} and quote id: ${quoteId}`,
          authenticated: false,
        })
      },
      uploadBindingFormOnQuoteId: async (quoteId, bindingForm) => {
        // broker uploads a binding form document
        const { document, id } = bindingForm;
        const formData = new FormData();
        formData.append('document', document);
        return await reportingV3.postForm({
          path: `/quotes/${quoteId}/binding_forms/${id}`,
          formData,
          errorMessage: `Failed to upload document with binding form id: ${id} and quote id: ${quoteId}`
        });
      },
      submitDetailsOnBindingForm: async (detailsObj, bindingForm, admin = false) => {
        const { finalized = false, submitterEmail = undefined, ...details } = detailsObj;
        // ^^ for PSCL data. details is object to overwrite in quote.bindingForms[i].details
        // bindingForm is the bindingForm object which should have id and quoteId
        const body = {
          details: {},
          finalized,
        };
        for (const key in details) {
          if (details[key] !== null) {
            body.details[key] = details[key];
          }
        }
        if (submitterEmail) {
          body.submitterEmail = submitterEmail;
        }
        const { id: bindingFormId, quoteId } = bindingForm;
        return await reportingV3.post({
          path: `/quotes/${quoteId}/binding_forms/${bindingFormId}/details${admin ? '/override' : ''}`,
          body,
          errorMessage: `Failed to submit details for binding form with id: ${bindingFormId} and quote id: ${quoteId}`,
          authenticated: admin,
        })
      },
      approveBindingFormWithIdForQuoteId: async (bindingFormId, quoteId, ) => {
        // ops or underwriter approve uploaded binding form
        return await reportingV3.post({
          path: `/quotes/${quoteId}/binding_forms/${bindingFormId}/approve`,
          body: {},
          errorMessage: `Failed to approve binding form with id: ${bindingFormId} and quote id: ${quoteId}`
        });
      },
      rejectBindingFormWithIdForQuoteId: async (bindingFormId, quoteId, reason) => {
        // ops or underwriter approve uploaded binding form
        return await reportingV3.post({
          path: `/quotes/${quoteId}/binding_forms/${bindingFormId}/reject`,
          body: reason && typeof reason === 'string' && reason.trim().length ? { reason } : {},
          errorMessage: `Failed to reject binding form with id: ${bindingFormId} and quote id: ${quoteId}`
        });
      },
// POLICY DATA ---------------------------------------------------------------------------------------------
      getPolicies: async (searchConfig) => {
        return await reportingV3.get({ path: `/policies${getSearch(searchConfig)}`, errorMessage: 'Failed to get policies.' });
      },
      getPolicyWithId: async (policyId) => {
        return await reportingV3.get({
          path: `/policies/${policyId}`,
          errorMessage: `Failed to get policy with id: ${policyId}`,
          exclusive: true,
        });
      },
      getPolicyConcentrations: async (policyId) => {
        return await reportingV3.get({
          path: `/policies/${policyId}/concentrations`,
          errorMessage: `Failed to get concentrations with policy id: ${policyId}`
        });
      },
// POLICY ACTIONS ---------------------------------------------------------------------------------------------
      uploadPolicyAttachment: async (policyId, file) => {
        const { document } = file;
        const formData = new FormData();
        formData.append('files', document);
        return await reportingV3.postForm({
          path: `/policies/${policyId}/upload`,
          formData,
          errorMessage: `Failed to upload policy attachment`
        });
      },
      regeneratePolicy: async (policyId) => {
        return await reportingV3.post({
          path: `/policies/${policyId}/policy`,
          errorMessage: `Failed to regenerate policy`
        });
      },
// CLAIMS DATA ---------------------------------------------------------------------------------------------
      getClaims: async (searchConfig, withPolicyData = false) => {
        // TODO implement search once exists in backend
        const claims = await reportingV3.get({
          path: `/claims${getSearch(searchConfig)}`,
          errorMessage: 'Failed to get claims',
        });
        if (withPolicyData && claims && Array.isArray(claims) && claims.length) {
          for (const claim of claims) {
            const policy = await reportingV3.get({
              path: `/policies/${claim.policyId}`,
              errorMessage: `Failed to get policy with id: ${claim.policyId}`
            });
            if (policy) {
              claim.policy = policy;
              claim.location = policy.locations ? policy.locations.find(l => l.id === claim.locationId) : null;
            }
          }
        }
        return claims;
      },
      getClaimWithId: async (claimId) => {
        return await reportingV3.get({
          path: `/claims/${claimId}`,
          errorMessage: `Failed to get claim with id: ${claimId}`
        });
      },
      getClaimsWithPolicyId: async () => {

      },
      getClaimsWithLocationIdAndPolicyId: async (locationId, policyId) => {

      },
// CLAIM ACTIONS ---------------------------------------------------------------------------------------------
      submitClaimForLocationIdAndPolicyId: async (locationId, policyId, claimData) => {
        // claimData = expectedDamage, incurredAt, coverageType, stormId, adjusterId
        return await reportingV3.post({
          path: `/policies/${policyId}/locations/${locationId}/claims`,
          body: claimData,
          errorMessage: `Failed to submit claim for locationId: ${locationId} under policyId: ${policyId}`,
        });
      },
      submitAnnotationOnClaimId: async () => {

      },
      submitAdjustmentOnClaimId: async () => {

      },
      acceptClaim: async () => {

      },
      denyClaim: async () => {

      },
// PRODUCTS DATA ---------------------------------------------------------------------------------------------
      getProducts: async () => {
        return await reportingV3.get({ path: '/products', errorMessage: 'Failed to get products' });
      },
      getProductWithId: async (productId) => {
        return await reportingV3.get({ path: `/products/${productId}`, errorMessage: `Failed to get product with id: ${productId}` });
      },
      getCoveragesForProductId: async (productId) => {
        return await reportingV3.get({ path: `/products/${productId}/coverages`, errorMessage: `Failed to get coverages for product id: ${productId}` });
      },
      getContentForProductId: async (productId) => {
        return await reportingV3.get({ path: `/products/${productId}/content`, errorMessage: `Failed to get content for product with Id: ${productId}` });
      },
      getProductsWithContent: async () => {
        const products = await reportingV3.get({ path: '/products', errorMessage: 'Failed to get products' });
        if (products && Array.isArray(products) && products.length) {
          for (const product of products) {
            try {
              const content = await reportingV3.get({ path: `/products/${product.id}/content`, errorMessage: `Failed to get content for product with Id: ${product.id}` });
              product.content = content;
            } catch (err) {
              console.log(err);
            }
          }
        }
        return products;
      },
      getAllProductContent: async () => {
        const products = await reportingV3.get({ path: '/products', errorMessage: 'Failed to get products' });
        let contentItems = [];
        if (products && Array.isArray(products) && products.length) {
          for (const product of products) {
            try {
              const content = await reportingV3.get({ path: `/products/${product.id}/content`, errorMessage: `Failed to get content for product with Id: ${product.id}` });
              if (content && Array.isArray(content) && content.length) {
                for (const item of content) {
                  item.productName = product.name;
                  item.productType = product.productType;
                  contentItems.push(item);
                }
              }
            } catch (err) {
              console.log(err);
            }
          }
        }
        return contentItems;
      },
      getURLForContentItem: async (item) => {
        const { productId, id } = item;
        return await reportingV3.get({
          path: `/products/${productId}/content/${id}`,
          errorMessage: `Failed to get URL for content with id: ${id} and productId: ${productId}`
        });
      },
// PRODUCT ACTIONS ---------------------------------------------------------------------------------------------
      addContentItem: async (item) => {
        const { productId, title, description, locale = 'en', filename, file } = item;
        const content = new FormData();
        content.append('productId', productId);
        content.append('title', title);
        if (description) {
          content.append('description', description);
        }
        content.append('localeCode', locale);
        content.append('filename', filename);
        if (file) {
          content.append('file', file);
        }
        return await reportingV3.postForm({
          path: `/products/${productId}/content`,
          formData: content,
          errror: 'Failed to upload file',
        });
      },
      updateContentItem: async (item) => {
        const { id, productId, title, description, locale = 'en', filename, file } = item;
        const content = new FormData();
        content.append('productId', productId);
        content.append('title', title);
        if (description) {
          content.append('description', description);
        }
        content.append('localeCode', locale);
        content.append('filename', filename);
        if (file) {
          content.append('file', file);
        }
        return await reportingV3.putForm({
          path: `/products/${productId}/content/${id}`,
          formData: content,
          errror: 'Failed to upload file',
        });
      },
      deleteContentItem: async (item) => {
        const { id, productId } = item;
        return await reportingV3.delete({
          path: `/products/${productId}/content/${id}`,
          error: 'Failed to delete content item',
        });
      },
// EXTRA ---------------------------------------------------------------------------------------------
      getSupportedLocales: async () => {
        return await reportingV3.get({ path: '/i18n/locales', errorMessage: 'Failed to get supported locales' });
      },
      getPlacesAutocomplete: async (input, session) => {
        const sessionToken = session ? `&sessionToken=${session}` : '';
        return await reportingV2.get({ path: `/places/autocomplete?input=${input}${sessionToken}`, error: `Failed to lookup place.`});
      },
      getPlacesDetails: async (placeId, session) => {
        const sessionToken = session ? `&sessionToken=${session}` : '';
        return await reportingV2.get({ path: `/places/details?placeId=${placeId}${sessionToken}`, error: `Failed to lookup place.`});
      },
      getAgents: async () => {
        return await reportingV3.get({
          path: `/agents`,
          errorMessage: `Failed to get agents.`
        });
      },
      createAgent: async ({email, fullName, brokerId, productIds }) => {
        return await reportingV3.post({
          path: `/agents`,
          body: {
            user: {
              email,
              fullName
            },
            brokerId,
            productIds
          },
          errorMessage: `Failed to create agent.`
        });
      },
      editAgentWithId: async (agentId, { email, fullName, brokerId, productIds }) => {
        return await reportingV3.put({
          path: `/agents/${agentId}`,
          body: {
            email,
            fullName,
            brokerId,
            productIds
          },
          errorMessage: `Failed to edit agent.`
        });
      },
      getBrokers: async () => {
        return await reportingV3.get({
          path: `/brokers`,
          errorMessage: `Failed to get brokers.`
        });
      },
      createBroker: async ({ name }) => {
        return await reportingV3.post({
          path: `/brokers`,
          body: { name },
          errorMessage: `Failed to create broker.`
        });
      },
      editBrokerWithId: async (brokerId, { name }) => {
        return await reportingV3.put({
          path: `/brokers/${brokerId}`,
          body: { name },
          errorMessage: `Failed to edit broker.`
        });
      },
    };
  }, [reportingV3, reportingV2, kalidasa]);


  return <ApiContext.Provider value={methods}>{children}</ApiContext.Provider>;
}



