import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import { Box, Text, Heading, Button, Spacing, DatePicker, TextField, ActivityIndicator } from '../../ui';
import { withStyles, styled } from '../../ui/styling';
import { formatMoney, getAddressLines } from '../../utils';
import { useApi, useAsync, useNavigate } from '../../hooks';
import {
  NavBar,
  Page,
  Main,
  NavBackButton,
  ContentCard,
  NavRow,
  NavGroup,
  Dialog,
  AppActionsGroup,
} from '../../components';
import { CoverageNames, CoverageTypes } from '../../constants';
import { PoliciesListView, PolicyList, PolicyListItem } from '../PoliciesListView';

export const SubmitClaimPage = () => {
  const navigate = useNavigate();
  const { policyId: routePolicyId } = useParams();
  const { getPolicies, submitClaimForLocationIdAndPolicyId } = useApi();
  const { value: policies, status: dataStatus, error: dataError } = useAsync(getPolicies);
  const [policy, setPolicy] = useState(null);
  const [location, setLocation] = useState(null);
  const [expectedDamage, setExpectedDamage] = useState(null);
  const [incurredAt, setIncurredAt] = useState(null);
  const [coverage, setCoverage] = useState(null);
  const handleSubmit = useCallback(async () => {
    const { id: policyId } = policy;
    const { id: locationId } = location;
    const coverageType = CoverageTypes[coverage.coverageType];

    if (!incurredAt || typeof incurredAt !== 'number') {
      throw { incurredAt: 'Valid date required' };
    }
    if (expectedDamage === null || expectedDamage === 0 || typeof expectedDamage !== 'number') {
      throw { expectedDamage: "A total for value lost is required." };
    }
    return await submitClaimForLocationIdAndPolicyId(locationId, policyId, {
      coverageType,
      expectedDamage,
      incurredAt,
    });
  }, [policy, location, expectedDamage, incurredAt, coverage, submitClaimForLocationIdAndPolicyId])

  const { value: newClaim, execute: submit, status, error } = useAsync(handleSubmit, { immediate: false });
  const errors = useMemo(() => {
    if (error && typeof error === 'object' && status === 'error') {
      return error;
    }
  }, [status, error])

  const submitDisabled = useMemo(() => {
    if (status !== 'pending') {
      if (policy && location && coverage && incurredAt) {
        return false;
      }
    }
    return true;
  }, [status, policy, location, expectedDamage, incurredAt, coverage])

  const submitVisible = useMemo(() => {
    if (policy && location && coverage && incurredAt) {
      return true;
    }
    return false;
  }, [policy, location, expectedDamage, incurredAt, coverage])

  useEffect(() => {
    if (newClaim && typeof newClaim === 'object' && newClaim.id) {
      navigate.back('/claims');
    }
  }, [newClaim, navigate])

  return (
    <Page>
      <NavBar>
        <NavRow>
          <NavGroup start flex={1}>
            <NavBackButton path="/claims" />
          </NavGroup> 
          {
            // <NavGroup center flex={1}>
            //   <Text bold mr="$7" display={({ theme }) => theme.breakpoints({ xs: 'flex', sm: 'none' })}>First Notice of Loss</Text>
            // </NavGroup>
          }
        </NavRow>
      </NavBar>
      <Main padBottom={364} padTop={({ theme }) => theme.breakpoints({ sm: theme.spacing(24), md: theme.spacing(13) })}>
        <Heading level={2} $regular>
          First Notice of Loss
        </Heading>
        <Spacing vertical={8} />
        <Box  flex={1} alignSelf="stretch" gap={24}>
          <SelectPolicy
            policies={policies}
            policy={policy}
            onSelect={(p) => setPolicy(p)}
            reset={() => setPolicy(null)}
            loading={dataStatus === 'pending'}
          />
          {policy ? (
            <SelectLocation
              locations={policy.locations}
              location={location}
              onSelect={(l) => setLocation(l)}
              reset={() => setLocation(null)}
            />
          ) : null}
          {policy && location ? (
            <SelectCoverage
              coverages={location.coverages}
              coverage={coverage}
              onSelect={(c) => setCoverage(c)}
              reset={() => setCoverage(null)}
            />
          ) : null}
          {(policy && location && coverage) || incurredAt !== null ? (
            <SelectIncurredAt
              incurredAt={incurredAt}
              setIncurredAt={setIncurredAt}
            />
          ) : null}
          {(policy && location && coverage && incurredAt) || expectedDamage ? (
            <InputExpectedDamage
              errors={errors}
              expectedDamage={expectedDamage}
              setExpectedDamage={setExpectedDamage}
            />
          ) : null}
        </Box>
        <Box padY="$6" alignItems="flex-end" pointerEvents={submitVisible ? 'box-none' : 'none'} opacity={submitVisible ? 1 : 0}>
          <Button large onPress={submit} disabled={submitDisabled}>
            {status === 'pending' ? <ActivityIndicator /> : 'Submit Claim'}
          </Button>
        </Box>
      </Main>
    </Page>
  );
}

const SelectPolicy = ({ policies, policy, onSelect, loading, reset }) => {
  return (
    <ReviewCard
      heading={policy ? "Policy" : "Select Policy"}
      headerAction={
        policy ? {
          label: 'Edit',
          onPress: reset,
        } : false
      }
    >
      {policy ? (
        <PolicyListItem item={policy} />
      ) : <PolicyList
            data={policies}
            loading={loading}
            onSelect={onSelect}
            scrollNode
            styles={{ root: { padTop: 0 }}}
          />}
    </ReviewCard>
  )
}

const SelectLocation = ({ locations, location, onSelect, reset }) => {
  return (
    <ReviewCard
      heading={location ? "Location" : "Select Location"}
      headerAction={
        location ? {
          label: 'Edit',
          onPress: reset,
        } : false
      }
    >
      <Box alignSelf="stretch" flex={1}>
      {location ? (
        <LocationItem data={location} />
      ) : locations.map(loc => {
        return <LocationItem key={loc.id} data={loc} onSelect={onSelect} />
      })}
      </Box>
      
    </ReviewCard>
  )
}

const SelectCoverage = ({ coverages, coverage, onSelect, reset }) => {
  return (
    <ReviewCard
      heading={coverage ? "Loss Type" : "Select Loss Type"}
      headerAction={
        coverage ? {
          label: 'Edit',
          onPress: reset,
        } : false
      }
    >
      <Box alignSelf="stretch" flex={1}>
      {coverage ? (
        <CoverageItem data={coverage} />
      ) : coverages.map(c => {
        return <CoverageItem key={c.coverageId} data={c} onSelect={onSelect} />
      })}
      </Box>
      
    </ReviewCard>
  )
}

const SelectIncurredAt = ({ incurredAt, setIncurredAt }) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [d, setD] = useState(incurredAt ? moment(incurredAt) : moment());
  const mounting = useRef(true);
  useEffect(() => {
    if (mounting.current) {
      mounting.current = false;
    } else {
      if (d) {
        setIncurredAt(d.valueOf());
      }
    }
    
  }, [d, setIncurredAt])
  return (
    <ReviewCard
      heading={"When did the loss event happen?"}
      headerAction={
        incurredAt ? {
          label: 'Edit',
          onPress: () => setDatePickerOpen(true),
        } : false
      }
      disableSeparator
    >
      {incurredAt ? (
        <ListItem><Text xLarge>{moment(incurredAt).format('MM-DD-YYYY')}</Text></ListItem>
      ) : <Button outlined large onPress={() => setDatePickerOpen(true)} alignSelf="flex-start">Select date of loss</Button>}
      <Dialog open={datePickerOpen} onClose={() => setDatePickerOpen(false)}>
        <DatePicker
          date={d}
          onChange={(date) => {
            setD(date);
            setDatePickerOpen(false);
          }}
        />
      </Dialog>
    </ReviewCard>
  )
}

const InputExpectedDamage = ({ expectedDamage = 0, setExpectedDamage, errors }) => {
  const [input, setInput] = useState(expectedDamage);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  return (
    <ReviewCard
      heading={"What is the expected cost of this loss?"}
      headerAction={{
        label: isFocused ? 'Done' : 'Edit',
        onPress: !isFocused ? () => {
          if (inputRef.current) {
            if (!isFocused) {
              inputRef.current.focus();
            }
          }
        } : null,
      }}
    >
      <TextField
        label="Loss Value"
        value={input}
        size="xLarge"
        variant="filled"
        placeholder=""
        startAdornment={input !== null || isFocused ? <Text xLarge>$</Text> : null}
        type="number"
        error={errors && errors.expectedDamage}
        helperText={errors && errors.expectedDamage ? errors.expectedDamage : null}
        onChangeValue={v => setInput(v)}
        onFocus={() => setIsFocused(true)}
        onBlur={(e, valueError) => {
          if (valueError && valueError.fixed) {
            setInput(valueError.fixed);
            setExpectedDamage(valueError.fixed)
          } else {
            setExpectedDamage(input);
          }
          setIsFocused(false);
          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        inputRef={inputRef}
        LabelProps={{ weight: '$bold' }}
      />
    </ReviewCard>
  )
}

const LocationItem = ({ data, onSelect }) => {
  const {
    address,
    postcode,
    region,
    name,
  } = data;

  const addressLines = getAddressLines(address, region, postcode);
  return (
    <ListItem onPress={onSelect ? () => onSelect(data) : null}>
      <Text large bold>{name}</Text>
      {Array.isArray(addressLines) ?
            addressLines.map((a, i) => (
              <Text small key={`aline${i}`}>{a}</Text>
            )) : <Text small>{addressLines}</Text>}
    </ListItem>
  )
}

const CoverageItem = ({ data, onSelect }) => {
  const {
    coverageType,
    deductible,
    netPremium,
    payoutLimit,
  } = data;

  return (
    <ListItem onPress={onSelect ? () => onSelect(data) : null}>
      <Text large bold spaceAfter>{CoverageNames[coverageType]}</Text>
      <DollarItem label="Deductible" value={deductible} />
      <DollarItem label="Payout Limit" value={payoutLimit} />
    </ListItem> 
  )
}

const DollarItem = ({ label, value }) => {
  return (
    <Box flexDirection="row" alignItems="center" gap={12}>
      <Text small uppercase bold dim={0.7}>{`${label}:`}</Text>
      <Text>{`$${formatMoney(value)}`}</Text>
    </Box>
  )
}

const ListItem = styled(Box)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignSelf: 'stretch',
  borderRadius: 6,
  borderWidth: 1,
  borderColor: theme.colors.opacity('$gray.300', 0.3),
  px: theme.spacing(3),
  py: theme.spacing(2.75),
  marginBottom: theme.spacing(3),
  bg: theme.colors.white,
}));


const ReviewItem = withStyles(({ theme, action, ActionComponent = Button }) => ({
  root: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    padY: theme.spacing(1),
    bg: 'white',
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  header: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  heading: {
    props: {
      level: 6,
      size: 'small',
      weight: '$extraBold',
      uppercase: true,
      color: '$gray.300',
    },
  },
  content: {
    flex: 1,
  },
  action: {
    props: action && ActionComponent === Button ? {
      variant: 'text',
      color: '$secondary',
      size: 'large',
      // TextProps: { uppercase: true },
    } : null,
  },
  actionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    padLeft: theme.spacing(2),
  }
}))(React.forwardRef(function ReviewItem(props, ref) {
  const {
    styles,
    heading,
    action,
    ActionComponent = Button,
    children,
    ...rest
  } = props;
  
  return (
    <Box {...rest} ref={ref}>
      <Box {...styles.toProps('contentContainer')}>
          <Box {...styles.toProps('content')}>
            {heading ? (
              <Box {...styles.toProps('header')}>
                <Heading {...styles.toProps('heading')}>{heading}</Heading>
              </Box>
            ) : null}
            {children}
          </Box>
          <Box {...styles.toProps('actionContainer')}>
            {typeof action === 'object' && ActionComponent ? (
              <ActionComponent {...styles.toProps('action')} {...action} />
            ) : action}
          </Box>
        </Box>
    </Box>
  );
  // return (
  //   <Box {...rest} ref={ref}>
  //     {heading && !children ? (
  //       <Box {...styles.toProps('contentContainer')}>
  //         <Heading {...styles.toProps('heading')}>{heading}</Heading>
  //         <Box {...styles.toProps('actionContainer')}>
  //           {typeof action === 'object' && ActionComponent ? (
  //             <ActionComponent {...styles.toProps('action')} {...action} />
  //           ) : action}
  //         </Box>
  //       </Box>
  //     ) : heading ? (
  //       <Box {...styles.toProps('header')}>
  //         <Heading {...styles.toProps('heading')}>{heading}</Heading>
  //       </Box>
  //     ) : null}
  //     {children ? (
  //       <Box {...styles.toProps('contentContainer')}>
  //         <Box {...styles.toProps('content')}>
  //           {children}
  //         </Box>
  //         <Box {...styles.toProps('actionContainer')}>
  //           {typeof action === 'object' && ActionComponent ? (
  //             <ActionComponent {...styles.toProps('action')} {...action} />
  //           ) : action}
  //         </Box>
  //       </Box>
  //     ) : null}
  //   </Box>
  // );
}))

const ReviewCard = withStyles(({ theme, disableSeparator = false, children, heading, subheading, headerAction, ActionComponent = Button }) => {
  return {
    root: {
      px: theme.spacing(6),
      py: theme.spacing(4),
      bg: 'white',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padBottom: 2,
      minHeight: 50,
    },
    subHeader: {
      padTop: theme.spacing(2),
    },
    content: {
      padTop: children && (heading || subheading || headerAction) ? theme.spacing(3) : 0,
      alignSelf: 'stretch',
      props: {
        gap: theme.spacing(2),
        gapBorder: disableSeparator ? null : { width: 1, color: '$gray.200' },
      }
    },
    heading: {
      props: {
        level: 4,
      }
    },
    headingActionContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'stretch',
      padLeft: theme.spacing(2),
    },
    action: {
      props: headerAction && ActionComponent === Button ? {
        variant: 'text',
        color: '$secondary',
        size: 'large',
        // TextProps: { uppercase: true },
      } : null,
    },
  }
})(React.forwardRef(function ReviewCard(props, ref) {
  const {
    styles,
    heading,
    headerAction,
    ActionComponent = Button,
    subheading,
    children,
    ...rest
  } = props;

  return (
    <ContentCard ref={ref} {...rest}>
      {heading || headerAction ? (
        <Box {...styles.toProps('header')}>
          <Heading {...styles.toProps('heading')}>{heading}</Heading>
          <Box {...styles.toProps('headingActionContainer')}>
            {typeof headerAction === 'object' && ActionComponent ? (
              <ActionComponent {...styles.toProps('action')} {...headerAction} />
            ) : headerAction}
          </Box>
        </Box>
      ) : null}
      {subheading ? (
        <Box {...styles.toProps('subhHeader')}>
          <Text>{subheading}</Text>
        </Box>
      ) : null}
      <Box {...styles.toProps('content')}>
        {children}
      </Box>
    </ContentCard>
  )
}));
