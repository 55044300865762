import React, { useCallback, useMemo, useState, useRef } from 'react';
import moment from 'moment-timezone';
import { Box, Text, Heading, Button, useBreakpoint, ActivityIndicator, ListRenderer } from '../../ui';
import { getStyle, withStyles } from '../../ui/styling';
import { capitalize, isObject, omit } from '../../ui/utils';
import { ListViewHeader, Dialog, TextData, ListView, SearchFilterInput, DataListRenderer, ListHead, ListHeadRow, ListHeading, Main, Chip, ItemRow, ListDisplayTypeButton, FilterControlButton, FilterMenu, ColumnsAndSettingsProvider, ITEM_ROW_HEIGHT  } from '../../components';
import { useApi, useAsync, useNavigate, useAsyncSearch, useAppState } from '../../hooks';
import { Colors, CoverageNames, ClaimStatusTypes, claimFilterOptions, claimSortOptions, ListDisplayTypes, claimStatusOptions, filterOperators } from '../../constants';
import { apiHelpers, formatMoney, getAddressLines } from '../../utils';
import { NULL_STYLE } from '../../ui/system';
import { useEventCallback } from '../../ui/hooks';
const { Controls, ControlGroup, SortButton } = ListViewHeader;

/*
incurredAt
coverageType (claim type)
product
insured, location.name
location.address + location.region, location.region + location.countryCode,location.postcode
location.coveredValue, defaultPayment, expectedDamage, reserve
status, openedAt
*/
let lastSearchString = '';
const FILTERS_BAR_HEIGHT = 55;
const claimSearchOptions = omit(claimFilterOptions, ['status']);

const tableColumnsConfig = [
  {
    label: 'Incurred',
    value: ({ incurredAt }) => incurredAt ? moment(incurredAt).format('MM-DD-YYYY') : 'N/A',
    props: { flex: 0.5 },
  },
  {
    label: 'Type',
    value: ({ coverageClaimName }) => coverageClaimName ? <Chip color="$gray.100">{coverageClaimName}</Chip> : 'N/A',
    props: { flex: 0.8 },
  },
  {
    label: 'Product',
    value: ({ product }) => product.labels.name,
    defaultValue: 'N/A',
    props: { flex: 0.8 },
  },
  {
    options: ['insured', 'location.name'],
    defaultOption: 'insured',
    label: ['Insured', 'Location'],
    value: ({ insured, location = {} }, o, i) => [insured, location.name][i],
    defaultValue: 'N/A',
    props: { flex: 0.8, TextProps: { bold: true }},
  },
  {
    options: ['location.address', 'location.region', 'location.country'],
    defaultOption: 'location.region',
    label: ['Street Address', 'Region', 'Country'],
    value: ({location}, o, i) => {
      const { address = 'N/A', region, countryCode, postcode } = location || {};
      return [
        address,
        `${region || 'N/A'}${postcode && region ? ', ' : ''}${postcode || ''}`,
        `${region || 'N/A'}${countryCode && region ? ', ' : ''}${countryCode || ''}`
      ][i];
    },
    defaultValue: 'N/A',
    props: { flex: 0.8 },
  },
  {
    options: ['location.coveredValue', 'defaultPayment', 'expectedDamage', 'reserve'],
    defaultOption: 'location.coveredValue',
    label: ['Covered Val.', 'Default Payment', 'Expected Damage', 'Reserve'],
    value: ({ location = {}, defaultPayment, expectedDamage, reserve }, o, i) => [
      location.coveredValue ? `$${formatMoney(location.coveredValue)}` : 'N/A',
      defaultPayment ? `$${formatMoney(defaultPayment)}` : 'N/A',
      expectedDamage ? `$${formatMoney(expectedDamage)}` : 'N/A',
      reserve ? `$${formatMoney(reserve)}` : 'N/A',
    ][i],
    props: { flex: 0.8 },
  },
  {
    options: ['status', 'openedAt'],
    defaultOption: 'status',
    label: ['Status', 'Opened'],
    value: ({ statusChipProps, openedAt }, o, i) => [
      <Chip {...statusChipProps} />,
      openedAt ? moment(openedAt).format('MM-DD-YYYY') : 'N/A',
    ][i],
    defaultValue: 'N/A',
    props: { flex: 0.8 },
  }
]
export const ClaimsListView = () => null;
//TODO
export const ClaimsListView2 = ({ data = undefined, searchPath, onSelect = undefined, ...rest }) => {
  const listRef = useRef();
  const { getClaims } = useApi();
  const lastSearch = useRef(lastSearchString);
  const [{ settings, saveSettings }] = useAppState();
  const searchDefaults = useRef();
  if (!searchDefaults.current && settings && settings.current && settings.current.ClaimsListView && settings.current.ClaimsListView.searchConfig) {
    searchDefaults.current = {
      ...settings.current.ClaimsListView.searchConfig,
      search: settings.current.ClaimsListView.searchConfig.search && settings.current.ClaimsListView.searchConfig.search.length ?
        [{ ...settings.current.ClaimsListView.searchConfig.search[0], value: lastSearch.current }] :
        [{ field: 'insured', value: '' }]
    };
  }
  const { value, cached, status, requestingMore, requestMoreData, moreDataAvailable } = useAsyncSearch(getClaims, {
    sortOptions: claimSortOptions,
    filterOptions: claimFilterOptions,
    searchOptions: claimSearchOptions,
    limit: 30,
    offset: 0,
    overrideValue: data,
    cache: true,
    cacheKey: 'claimlist',
    searchPath,
    parseFilter: {
      'status': ({ field, value, operator }) => {
        if (!value) {
          return null;
        }
        if (value === 'all') {
          return null;
        }
        return { field, value, operator };
      }
    },
    onChange: (apiConfig, searchConfig) => {
      if (listRef.current) {
        listRef.current.scrollTo({ x: 0, y: 0, animated: false });
      }
      if (searchConfig) {
        const { filters = [], search = [], sort = {}} = searchConfig;
        saveSettings({
          ClaimsListView: {
            searchConfig: {
              filters,
              sort,
            }
          }
        })
      }
    }
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const navigateToClaim = useCallback((claim) => {
    navigate.to(`/claims/${claim.id}`);
  }, [navigate]);
  const [breakpoint] = useBreakpoint();
  const [actualDisplayType, setDisplayType] = useState(settings.current && settings.current.listDisplayType ? settings.current.listDisplayType : ListDisplayTypes.CARDS);
  const displayType = breakpoint.key === 'xs' ? ListDisplayTypes.CARDS : actualDisplayType;

  const loadingFromCache = !requestingMore && status === 'pending' && cached && value;
  const renderHeader = useMemo(() => (({ height, key, style }) => {
    return (
      <ClaimsListHeader
        key={key}
        height={height}
        style={style}
        loadingFromCache={loadingFromCache}
        displayType={displayType}
        saveSettings={saveSettings}
        setDisplayType={setDisplayType}
        actualDisplayType={actualDisplayType}
        searchDefaults={searchDefaults}
      />
    );
  }), [loadingFromCache, displayType, saveSettings, setDisplayType, actualDisplayType]);

  return (
    <Main>
      <ColumnsAndSettingsProvider viewKey="ClaimsListView" config={tableColumnsConfig}>
        <ClaimsList
          ref={listRef}
          data={value}
          displayType={displayType}
          loading={!requestingMore && status === 'pending' && !cached}
          onSelect={onSelect !== undefined ? onSelect : navigateToClaim}
          renderHeader={renderHeader}
          requestMoreData={requestMoreData}
          requestingMore={requestingMore}
          stickyHeaderEnabled
          scrollNode
          {...rest}
        />
      </ColumnsAndSettingsProvider>
      <Dialog
        open={dialogOpen} onClose={() => setDialogOpen(false)}
        heading="Claim Reporting"
        prompt="When a loss occurs, the loss information should be forwarded to the McLarens Dedicated Team. Download the instructions to continue."
        actions={<Button label="Download Instructions" href="/documents/claim_reporting_form.pdf" color="$secondary" />}
      >
      </Dialog>
    </Main>
  )
}

const ClaimsListHeader = React.memo(function ClaimListHeader(props) {
  const { loadingFromCache, displayType, actualDisplayType, saveSettings, searchDefaults, setDisplayType, height, style } = props;
  const s = getStyle(style);
  const navigate = useNavigate();
  const filterControlProps = {
    queryConfig: { fields: { status: { operator: filterOperators.equals } } },
    defaultValue: "all",
    options: {
      all: { label: 'All' },
      ...claimStatusOptions,
    },
    selected: filters => {
      const result = filters.find(f => f.field === 'status');
      if (result) {
        return result.value;
      }
      return null;
    },
    onSelect: (filterBy, setFilter) => {
      if (filterBy === 'all') {
        setFilter({ field: 'status', value: 'all' });
      } else if (!filterBy || !claimStatusOptions[filterBy]) {
        setFilter({ field: 'status', value: null });
      } else {
        setFilter({ field: 'status', value: filterBy });
      }
    },
  }
  const [breakpoint] = useBreakpoint();
  return (
    <React.Fragment>
      <ListHead
          {...s}
          bg="$white"
          height={height - FILTERS_BAR_HEIGHT - (displayType === ListDisplayTypes.TABLE ? ITEM_ROW_HEIGHT : 0)}
        >
        <ListHeadRow justifyContent="space-between">
          <ListHeading>Claims</ListHeading>
          {breakpoint.key === 'xs' ? <ActivityIndicator animating={loadingFromCache} /> : null}
          <Button
            label="+  New Claim"
            onPress={() => {
              navigate.to('/submitclaim');
            }}
          />
        </ListHeadRow>
        <Controls>
          <SearchFilterInput
            placeholder="Search claims"
            filterOptions={claimSearchOptions}
            initialOption={searchDefaults.current.search && searchDefaults.current.search.length ? searchDefaults.current.search[0].field : "insured"}
            initialValue={searchDefaults.current.search && searchDefaults.current.search.length ? searchDefaults.current.search[0].value : ""}
            onChangeField={(f) => {
              if (f && claimFilterOptions[f]) {
                saveSettings({
                  ClaimsListView: {
                    searchConfig: {
                      search: [{ field: f, operator: filterOperators.ilike, value: '' }]
                    }
                  }
                })
              }
            }}
            onChangeValue={(v) => {
              lastSearchString = typeof v === 'string' ? v : '';
            }}
          />
          {breakpoint.key !== 'xs' ? <ActivityIndicator animating={loadingFromCache} /> : null}
          <ControlGroup>
            {breakpoint.key === 'xs' ? null : (
              <ListDisplayTypeButton
                onPress={() => {
                  const nextListDisplayType = actualDisplayType === ListDisplayTypes.CARDS ? ListDisplayTypes.TABLE : ListDisplayTypes.CARDS;
                  saveSettings({
                    listDisplayType: nextListDisplayType,
                  })
                  setDisplayType(nextListDisplayType);
                }}
                displayType={displayType}
              />
            )}
            <FilterControlButton label={breakpoint.key === 'sm' ? '' : undefined} {...filterControlProps} />
            <SortButton label={breakpoint.key === 'sm' ? '' : undefined} options={claimSortOptions} />
          </ControlGroup>
        </Controls>
      </ListHead>
      <FilterMenu
          inline
          {...filterControlProps}
          height={FILTERS_BAR_HEIGHT}
          width="100%"
          alignItems="flex-start"
          padLeft="$1"
          alignSelf="flex-end"
          display={breakpoint.key === 'xs' ? 'none' : 'flex'}
        />
        {displayType === ListDisplayTypes.TABLE ? (
          <ItemRow
            head
            height={ITEM_ROW_HEIGHT}
            position="sticky"
            top={height - FILTERS_BAR_HEIGHT - ITEM_ROW_HEIGHT - 4 /* <- 4 for preventing hairline gaps */}
            zIndex='$header'
            bg="$white"
          />
        ) : null}
    </React.Fragment>
  )
})
// item xs: 326, sm: 218
const ClaimsList = React.forwardRef((props, ref) => {
  const { styles, displayType = ListDisplayTypes.CARDS, ...rest } = props;
  const Component = displayType === ListDisplayTypes.TABLE ? ClaimRowItem : ClaimListItem;
  return (
    <DataListRenderer
      ref={ref}
      itemComponent={Component}
      emptyMessage="No claims to show"
      itemPath="/claims"
      displayType={displayType}
      styles={[
        {
          root: {
            minHeight: 400,
            padTop: ({ theme }) => theme.sizes.appBarHeight + theme.spacing(1),
          },
          item: {
            height: displayType === ListDisplayTypes.CARDS ? ({ theme }) => theme.breakpoints({ xs: 326, sm: 218 }) : ITEM_ROW_HEIGHT,
            mb: displayType === ListDisplayTypes.CARDS ? '$3' : 0,
          },
          listLoading: {
            justifyContent: 'center',
          },
          header: {
            height: ({ theme }) => theme.breakpoints({
              xs: 186 + theme.sizes.appBarHeight + theme.spacing(1),
              sm: 154 + theme.sizes.appBarHeight + FILTERS_BAR_HEIGHT + (displayType === ListDisplayTypes.TABLE ? ITEM_ROW_HEIGHT : 0),
            }),
          }
        },
        styles,
      ]}
      {...rest}
    />
  )
});

const ClaimRowItem = React.forwardRef(function ClaimRowItem(props, ref) {
  const {
    item,
    styles,
    onPress,
    ...rest
  } = props;
  const claim = useResolveClaimData(item);
  const {
    statusChipProps,
    progress,
  } = claim;

  const progressBar = useMemo(() => (
    <Box
        sx={theme => ({
          ...theme.layout.absoluteFill,
          bottom: 0,
          top: null,
          height: 3,
          borderRadius: 6,
        })}
        bg={progress && statusChipProps ? statusChipProps.color : 'transparent'}
        maxWidth={progress}
      />
  ), [statusChipProps, progress])

  const handleOnPress = useEventCallback(() => {
    onPress(claim);
  });

  return (
    <ItemRow ref={ref} item={claim} onPress={onPress ? handleOnPress : undefined} {...rest}>
      {progressBar}
    </ItemRow>
  )
})

function useResolveClaimData(data) {
  const {
    id,
    coverageType,
    policyId,
    locationId,
    expectedDamage,
    status,
    incurredAt,
    openedAt,
    closedAt,
    product: p,
    location,
  } = data;

  // const { getPolicyWithId } = useApi();
  // const handleGetPolicy = useCallback(async () => {
  //   const policy = await getPolicyWithId(policyId);
  //   if (policy) {
  //     const location = policy.locations ? policy.locations.find(l => l.id === locationId) : null;
  //     return { policy, location };
  //   }
  //   throw 'Policy data could not be retrieved';
  // }, [policyId, locationId, getPolicyWithId]);
  // const { value: policyData, status: responseStatus, error: responseError } = useAsync(handleGetPolicy, { cache: true, cacheKey: `policy:${policyId}` });
  // const policy = policyData && policyData.policy ? policyData.policy : { };
  // const location = policyData && policyData.location ? policyData.location : {};

  // const { insured, product } = policy;
  const { address, region, postcode } = location || {};

  const [progress, statusChipProps] = useMemo(() => {
    let progress = '25%';
    let color = Colors.quoteStatus.pending;
    let text = 'Open';
    if (status === ClaimStatusTypes.open) {
      progress = '33%';
      color = '$coral';
      text = 'Open';
    } else if (status === ClaimStatusTypes.adjusting) {
      progress = '66%';
      color = Colors.quoteStatus.pending;
      text = 'Adjusting';
    } else if (status === ClaimStatusTypes.accepted) {
      progress = '100%';
      color = '$secondary'
      text = 'Accepted';
    } else if (status === ClaimStatusTypes.denied) {
      progress = '100%';
      color = Colors.quoteStatus.expired;
      text = 'Denied';
    }
    return [progress, { color, children: text }];
  }, [status]);

  const coverageClaimName = useMemo(() => {
    const coverageName = CoverageNames[coverageType];
    return coverageName;
  }, [coverageType]);

  const addressLines = getAddressLines(address, region, postcode);

  const product = isObject(p) ? apiHelpers.resolveProduct(p) : { labels: { name: p } };

  return {
    ...data,
    product,
    addressLines,
    coverageClaimName,
    progress,
    statusChipProps,
  }
}

const ClaimListItem = withStyles(({ theme }) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.colors.opacity('$gray.300', 0.3),
    px: theme.spacing(3),
    py: theme.spacing(2.75),
    bg: theme.colors.white,
  },
  progressOverlay: {
    ...theme.layout.absoluteFill,
    bottom: 0,
    top: null,
    height: 3,
    borderRadius: 6,
  },
  topContainer: {
    flexDirection: theme.breakpoints({ xs: 'column', sm: 'row' }),
    justifyContent: theme.breakpoints({ xs: 'flex-start', sm: 'space-between' }),
  },
  statusChips: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    padBottom: theme.spacing(1),
    padTop: theme.spacing(0.5),
    props: {
      gap: 8
    },
  },
  contentLeft: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 1.25 }),
    props: {
      gap: 0
    },
  },
  contentRight: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 0.75 }),
    justifyContent: 'flex-start',
    alignItems: theme.breakpoints({ xs: 'flex-start', sm: 'flex-end' }),
    props: {
      gap: 12
    },
    padTop: theme.spacing(1),
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    padTop: theme.spacing(1.5),
  },
  bottomSpacing: {
    flexGrow: 0.1,
    flexShrink: 0,
    flexBasis: '10%',
  },
  bottomContent: {
    flexGrow: 0.9,
    flexShrink: 0,
    flexBasis: 'auto',
  },
}))(React.forwardRef(function ClaimsListItem(props, ref) {
  const { item: data, styles, onPress, ...rest } = props;
  const {
    id,
    coverageType,
    policyId,
    locationId,
    expectedDamage,
    status,
    incurredAt,
    openedAt,
    closedAt,
    progress,
    statusChipProps,
    coverageClaimName,
    addressLines,
    insured,
  } = useResolveClaimData(data);

  const handleOnPress = useEventCallback(() => onPress(data));

  return (
    <Box ref={ref} onPress={onPress ? handleOnPress : undefined} {...rest}>
      <Box style={styles.topContainer} {...styles.props.topContainer}>
        <Box style={styles.contentLeft} {...styles.props.contentLeft}>
          <Box style={styles.statusChips} {...styles.props.statusChips}>
            <Chip {...statusChipProps} />
          </Box>
          {
            // <Text size="xSmall" bold color="$gray.400" style={{ fontWeight: 500 }} uppercase>{productDisplayName}</Text>
          }
          
          <Heading level={4}>{capitalize(insured)}</Heading>
          {Array.isArray(addressLines) ?
            addressLines.map((a, i) => (
              <Text small key={`aline${i}`}>{a}</Text>
            )) : <Text small>{addressLines}</Text>}
        </Box>
        <Box style={styles.contentRight} {...styles.props.contentRight}>
          <Chip color="$gray.100">{coverageClaimName}</Chip>
          <TextData
            label="Incurred At"
            data={moment(incurredAt).format('MM-DD-YYYY')}
            alignItems="inherit"
            size="small"
          />
          <TextData
            label={closedAt ? 'Closed At' : 'Opened At'}
            data={moment(closedAt ? closedAt : openedAt).format('MM-DD-YYYY')}
            alignItems="inherit"
            size="small"
            opacity={0.8}
          />
        </Box>
      </Box>
      <Box style={styles.bottomContainer} {...styles.props.bottomContainer}>
        <Box style={styles.bottomContent} {...styles.props.bottomContent}>
          <Text small bold>{`Policy for ${insured}`}</Text>
        </Box>
      </Box>
      <Box
        style={styles.progressOverlay}
        {...styles.props.progressOverlay}
        bg={progress ? statusChipProps.color : '$gray.300'}
        maxWidth={progress}
      />
    </Box>
  )
}))
