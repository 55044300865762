import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextField, Autocomplete } from '../../ui';
import { useApi, useAsync } from '../../hooks';
import { v4 as uuid } from 'uuid';
import { useEventCallback } from '../../ui/hooks';
import { debounce, isObject } from '../../ui/utils';


const AddressAutocomplete = ({ address, setAddress, onDetailsLoading, onDetailsReceived, helperText, ...inputProps }) => {
  const sessionToken = useRef(uuid());
  const mounted = useRef(true);
  useEffect(() => { return () => mounted.current = false }, []);
  const { getPlacesAutocomplete, getPlacesDetails } = useApi();
  const [place, setPlace] = useState(null);
  const { value: places, execute, status } = useAsync(getPlacesAutocomplete, { immediate: false });
  useEffect(() => {
    const debounceExecute = debounce(() => {
      if (mounted.current && address) {
        execute(address, sessionToken.current);
      }
    }, 500);
    debounceExecute();
    return () => {
      debounceExecute.clear();
    }
  }, [address, execute]);

  const handleLoading = useEventCallback((loading) => {
    if (onDetailsLoading) {
      onDetailsLoading(loading);
    }
  });

  const handleDetailsReceived = useEventCallback((details) => {
    if (onDetailsReceived) {
      onDetailsReceived(details);
    }
  });

  const handleGetPlaceDetails = useCallback(async () => {
    if (place && place.placeId) {
      handleLoading(true);
      const details = await getPlacesDetails(place.placeId, sessionToken.current);
      if (details && mounted.current) {
        const result = {
          placeId: place.placeId,
          address: details.formattedAddress || '',
          address1: '',
          city: '',
          postcode: '',
          regionCode: '',
          countryCode: '',
          lat: details.geometry.location.lat,
          lon: details.geometry.location.lng,
          aboveSeaLevel: details.aboveSeaLevel,
          timezone: details.timezone || '',
        };
        let address1Done = false;
        for (const d of details.addressComponents) {
          if (!result.countryCode && d.types.includes('COUNTRY')) {
            result.countryCode = d.shortName;
          } else if (!result.regionCode && d.types.includes('ADMINISTRATIVE_AREA_LEVEL_1')) {
            result.regionCode = d.shortName;
          } else if (!result.postcode && d.types.includes('POSTAL_CODE')) {
            result.postcode = d.longName;
          } else if (!result.city && d.types.includes('LOCALITY')) {
            result.city = d.longName;

            address1Done = true;
          }

          if (!address1Done) {
            // append space-separated components until we reach LOCALITY
            result.address1 += (result.address1 ? ' ' : '') + d.longName;
          }
        }
        handleDetailsReceived(result);
        handleLoading(false);
      }
    }
  }, [place, getPlacesDetails, handleLoading, handleDetailsReceived]);

  const { execute: executeDetails } = useAsync(handleGetPlaceDetails, { immediate: true });

  return (
    <Autocomplete
      options={address.trim() && Array.isArray(places) ? places : []}
      filterOptions={options => options}
      loading={status === 'pending'}
      getOptionLabel={(option) => {
        if (typeof option === 'string' || typeof option === 'number') {
          return option;
        } else if (option) {
          return option.description;
        } else {
          return '';
        }
      }}
      onSelectOption={(option) => {
        if (option === place) {
          executeDetails();
        }
      }}
      value={place}
      onChangeValue={(val) => isObject(val) && setPlace(val)}
      inputValue={address}
      onInputChangeValue={(val) => {
        setAddress(val);
      }}
      blurOnSelect
      freeInput
      renderInput={(iProps) => (
        <TextField
          {...iProps}
          disableAutoComplete
          disableAutoCorrect
          disableSpellCheck
          disableAutoCapitalize
          label="Full Address"
          helperText={helperText ? helperText : "Select a search result to autofill all address fields"}
          {...inputProps}
        />
      )}
    />
  )
}

export { AddressAutocomplete }
