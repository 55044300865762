import React, { useMemo } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import { usePressable } from '../Pressable';

// TODO: Update or remove this. Touchable is only used for BoxDiv's right now and this might not be neccessary
// after updating RNW to 0.17+
const Touchable = React.forwardRef(function Touchable(props, ref) {
  const { children, ...rest } = props;

  const {
    pressed,
    hovered,
    onPressIn,
    onPressOut,
    // ...handlers
  } = usePressable(rest);

  const child =
    typeof children === 'function' ? children({ pressed, hovered }) : children;

  const animate = useMemo(() => {
    if (pressed) {
      return 'pressed';
    }
    if (hovered) {
      return 'hovered';
    }
    return 'default';
  }, [pressed, hovered]);

  return (
    <TouchableWithoutFeedback
      {...rest}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      ref={ref}
    >
      {
        React.cloneElement(React.Children.only(child), { animate: child.props.animate || animate })
      }
    </TouchableWithoutFeedback>
  );
});

Touchable.displayName = 'Touchable';

export { Touchable };
