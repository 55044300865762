import moment from 'moment-timezone';
import { CoverageNames, ProductNames, ProductTypes } from "../constants";
import { capitalize, isObject } from "../ui/utils";
import { QuoteStatuses, HOURS_QUOTE_VALID_FOR } from '../constants';

const apiHelpers = {
  resolveProduct: (data) => {
    let name = '';
    let type = '';
    const labels = {
      name: '',
      type: '',
      combined: '',
    }
    if (data && isObject(data)) {
      name = data.name;
      type = data.productType;
      labels.name = name;
      labels.type = type;
    }
    if (name && ProductNames[name]) {
      labels.name = ProductNames[name];
      if (!type) {
        labels.combined = labels.name;
      }
    }
    if (type && ProductTypes[type]) {
      labels.type = ProductTypes[type];
      if (!name) {
        labels.combined = labels.type;
      } else {
        labels.combined = `${labels.type} - ${labels.name}`;
      }
    }
    return {
      name,
      type,
      labels,
      ...data,
    };
  },
  getQuoteExpiration: ({ createdAt, status } = {}) => {
    let expired = true;
    let daysLeft = 0;
    let hoursLeft = 0;
    let minutesLeft = 0;
    let text = '';
    let expiration = moment();
  
    if (createdAt && status) {
      expiration = moment(createdAt).add(HOURS_QUOTE_VALID_FOR, 'h');
      if (status !== QuoteStatuses.expired) {
        const now = moment();
        if (expiration.diff(now) > 0) {
          expired = false;
          daysLeft = expiration.diff(now, 'days');
          hoursLeft = expiration.diff(now, 'hours');
          minutesLeft = expiration.diff(now, 'minutes');
        }
      }
  
      if (expired) {
        text = 'Expired';
      } else if (daysLeft > 1) {
        text = `Expires in ${daysLeft} days`;
      } else if (hoursLeft > 2) {
        text = `Expires in ${hoursLeft} hours`;
      } else if (minutesLeft) {
        text = `Expires in ${minutesLeft} minutes`;
      } else {
        text = `Expiring soon`;
      }
    }
  
    return { expired, expiration, daysLeft, hoursLeft, minutesLeft, text };
  },
  isProductAuto: (product) => (
    product.productType.toLowerCase() === ProductTypes.auto.toLowerCase()
  ),
  resolveCoverageTypeName: (coverageType) => {
    if (CoverageNames[coverageType]) {
      return CoverageNames[coverageType];
    } else {
      return capitalize(coverageType);
    }
  }
}

export { apiHelpers };