import React, { useContext, createContext } from 'react';
import { useBreakpoints } from './useBreakpoints';
import { createTheme, theme as baseTheme } from '../system';

const ThemeContext = React.createContext()

const useTheme = () => useContext(ThemeContext);

const defaultTheme = createTheme(baseTheme);

function ThemeProvider({ theme, children }) {
  return (
    <ThemeContext.Provider value={theme || defaultTheme}>
      <ProvideThemeBreakpoints>
        {children}
      </ProvideThemeBreakpoints>
    </ThemeContext.Provider>
  );
}

ThemeProvider.displayName = 'ThemeProvider';
ThemeProvider.defaultProps = {
  theme: defaultTheme
}

function withTheme(WrappedComponent) {
  const ThemeComponent = React.forwardRef((props, ref) => {
    const theme = useTheme();
    return <WrappedComponent theme={theme} ref={ref} {...props} />
  });
  return ThemeComponent;
}

const themeBreakpointsContext = createContext();

function ProvideThemeBreakpoints({ children}) {
  const theme = useTheme();
  const breakpoint = useBreakpoints(theme.breakpoints);
  return <themeBreakpointsContext.Provider value={breakpoint}>{children}</themeBreakpointsContext.Provider>;
}

const useThemeBreakpoints = () => useContext(themeBreakpointsContext);

function withThemeBreakpoints(WrappedComponent) {
  const BreakpointsComponent = React.forwardRef((props, ref) => {
    const [breakpoint, setBreakpoint, breakpoints] = useThemeBreakpoints();
    return <WrappedComponent $breakpoint={breakpoint} $setBreakpoint={setBreakpoint} $breakpoints={breakpoints} ref={ref} {...props} />
  });
  return BreakpointsComponent;
}

function withBreakpoints(WrappedComponent, breakpointsDef) {
  if (!breakpointsDef) {
    return withThemeBreakpoints(WrappedComponent);
  }
  const BreakpointsComponent = React.forwardRef((props, ref) => {
    const [breakpoint, setBreakpoint, breakpoints] = useBreakpoints(breakpointsDef);
    return <WrappedComponent $breakpoint={breakpoint} $setBreakpoint={setBreakpoint} $breakpoints={breakpoints} ref={ref} {...props} />
  });
  return BreakpointsComponent;
}

export {
  useTheme,
  ThemeProvider,
  ThemeContext,
  withTheme,
  useThemeBreakpoints,
  ProvideThemeBreakpoints,
  withThemeBreakpoints,
  withBreakpoints
}
