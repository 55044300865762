import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, Heading, IconButton, Spacing, Text, TextField } from '../../ui';
import {
  Dialog, LayoutBox, TextData,
} from '../../components';
import { apiHelpers } from '../../utils';
import { useEventCallback } from '../../ui/hooks';
import { isNull } from '../../ui/utils';
import { NumericInput } from '../../ui/components/TextInput/NumericInput';
import { validateApplicationLocationData } from './validateApplicationLocationData';
import { RowLayout } from './components';
import { AddressAutocomplete } from './AddressAutocomplete';
import { TrashIcon } from '../../components/icons';
import { useAuth } from '../../hooks';


const LocationDialog = (props) => {
  const {
    open,
    onSubmit,
    canEdit,
    product,
    mode,
    onClose,
    onDelete,
    data,
    processing = false,
    ...rest
  } = props;
  const [editing, setEditing] = useState(mode === 'EDIT' ? true : false);
  const creating = mode === 'CREATE';
  const readOnly = !(editing || creating);

  const auth = useAuth();
  const showAdditionalDataEntries = auth.user && (auth.user.isUnderwriter || auth.user.isPolicyAnalyst || auth.user.isAdmin);

  useEffect(() => {
    setEditing(mode === 'EDIT' ? true : false);
  }, [mode]);

  const [id, setId] = useState(data && data.id ? data.id : '');
  const [name, setName] = useState(data && data.name ? data.name : '');
  const [coveredValue, setCoveredValue ] = useState(data && data.coveredValue ? data.coveredValue : null);
  const [numVehicles, setNumVehicles ] = useState(data && data.details && data.details.num_vehicles ? data.details.num_vehicles : 0);
  const [averageCarValue, setAverageCarValue] = useState(data && data.details && data.details.average_car_value ? data.details.average_car_value : 0);
  const [ placeId, setPlaceId ] = useState(data && data.placeId ? data.placeId : null);
  const [ address, setAddress ] = useState(data && data.address ? data.address : '');
  const [ address1, setAddress1 ] = useState(data && data.address1 ? data.address1 : '');
  const [ address2, setAddress2 ] = useState(data && data.address2 ? data.address2 : null);
  const [ city, setCity ] = useState(data && data.city ? data.city : '');
  const [ regionCode, setRegionCode ] = useState(data && data.regionCode ? data.regionCode : '');
  const [ postcode, setPostcode ] = useState(data && data.postcode ? data.postcode : '');
  const [ countryCode, setCountryCode ] = useState(data && data.countryCode ? data.countryCode : '');
  const [ lat, setLat ] = useState(data && data.lat ? data.lat : null);
  const [ lon, setLon ] = useState(data && data.lon ? data.lon : null);
  const [ aboveSeaLevel, setAboveSeaLevel ] = useState(data && data.aboveSeaLevel ? data.aboveSeaLevel : 0);
  const [ timezone, setTimezone ] = useState(data && data.timezone ? data.timezone : 0);
  const [floodFactor, setFloodFactor] = useState(data && data.details && data.details.floodFactor ? data.details.floodFactor : '');
  const [fema, setFema] = useState(data && data.details && data.details.fema ? data.details.fema : '');
  const [crimeZone, setCrimeZone] = useState(data && data.details && data.details.crimeZone ? data.details.crimeZone : '');
  const [highValueVehicles, setHighValueVehicles] = useState(data && data.details && Array.isArray(data.details.highValueVehicles) ? [...data.details.highValueVehicles] : []);
  const [ addressDetailsLoading, setAddressDetailsLoading ] = useState(false);
  const [visibleAddress, setVisibleAddress] = useState(data && data.visibleAddress ? data.visibleAddress : null);
  const [custRef, setCustRef] = useState(data && data.custRef ? data.custRef : null);
  const [errors, setErrors] = useState({});

  const resetData = useEventCallback(() => {
    setErrors({});
    setId(data && data.id ? data.id : '');
    setName(data && data.name ? data.name : '');
    setCoveredValue(data && data.coveredValue ? data.coveredValue : null);
    setNumVehicles(data && data.details && data.details.num_vehicles ? data.details.num_vehicles : 0);
    setPlaceId(data && data.placeId ? data.placeId : '');
    setAddress(data && data.address ? data.address : '');
    setAddress1(data && data.address1 ? data.address1 : '');
    setAddress2(data && data.address2 ? data.address2 : null);
    setCity(data && data.city ? data.city : '');
    setRegionCode(data && data.regionCode ? data.regionCode : '');
    setPostcode(data && data.postcode ? data.postcode : '');
    setCountryCode(data && data.countryCode ? data.countryCode : '');
    setLat(data && data.lat ? data.lat : null);
    setLon(data && data.lon ? data.lon : null);
    setAboveSeaLevel(data && data.aboveSeaLevel ? data.aboveSeaLevel : null);
    setTimezone(data && data.timezone ? data.timezone : null);
    setFloodFactor(data && data.details && data.details.floodFactor ? data.details.floodFactor : 'not available');
    setFema(data && data.details && data.details.fema ? data.details.fema : 'not available');
    setCrimeZone(data && data.details && data.details.crimeZone ? data.details.crimeZone : 'not available');
    setHighValueVehicles(data && data.details && Array.isArray(data.details.highValueVehicles) ? [...data.details.highValueVehicles] : []);
    setVisibleAddress(data && data.visibleAddress ? data.visibleAddress : null);
    setCustRef(data && data.custRef ? data.custRef : null);
  });

  useEffect(() => {
    if (open) {
      resetData();
    }
  }, [open, resetData]);

  useEffect(() => {
    if (!coveredValue || coveredValue <= 0) {
      setAverageCarValue(0);
    } else if (numVehicles < 1) {
      setAverageCarValue(0);
    } else {
      setAverageCarValue(NumericInput.parse(`${coveredValue / numVehicles}`, 2));
    }
  }, [numVehicles, coveredValue]);

  return (
    <Dialog
      heading={!readOnly ? `${editing ? 'Edit' : 'Add'} Location` : 'Location Details'}
      prompt={!readOnly ? "Input all known details for this location to be included under this policy application." : ""}
      styles={{
        dialogBox: {
          marginLeft: ({ theme }) => theme.layout.centeredOverlay.marginLeft,
          width: ({ theme }) => theme.breakpoints({ sm: 640, md: '$mainWidth' }),
          maxWidth: '100%',
          padX: ({ theme }) => theme.breakpoints({ xs: '$2', sm: '$4' })
        }
      }}
      actions={(
        <LayoutBox row layout="center-right" justifyContent="space-between" width="100%">
          <LayoutBox row>
            {canEdit && !creating && onDelete ? (
              <Button
                variant="text"
                color="$error"
                onPress={onDelete}
                alignSelf="flex-start"
                label="Delete"
                startIcon={<TrashIcon color="$error" />}
                disabled={processing}
              />
            ) : null}
          </LayoutBox>
          <LayoutBox row layout="center-right">
            <Button
              variant="text"
              color="$primary"
              onPress={onClose}
              disabled={processing}
              label={readOnly ? "Close" : "Cancel"}
            />
            {canEdit && onSubmit ? (
              <>
              <Spacing horizontal="3" />
                <Button
                  label={editing ? 'Save Changes' : 'Attach Location to Application'}
                  disabled={addressDetailsLoading || processing}
                  loading={processing}
                  color={addressDetailsLoading || processing ? '$gray.400' : '$primary'}
                  alignSelf="flex-end"
                  onPress={() => {
                    const loc = {
                      ...data,
                      name: name,
                      placeId: placeId,
                      address: address,
                      address1: address1,
                      address2: address2,
                      city: city,
                      regionCode: regionCode,
                      postcode: postcode,
                      countryCode: countryCode == "US" ? "USA" : countryCode,
                      lat: lat,
                      lon: lon,
                      aboveSeaLevel: aboveSeaLevel,
                      timezone: timezone,
                      coveredValue: coveredValue,
                      visibleAddress: visibleAddress,
                      custRef: custRef,
                      details: {
                        ...(data && data.details ? data.details : {}),
                        average_car_value: averageCarValue,
                        num_vehicles: numVehicles,
                        floodFactor,
                        fema,
                        crimeZone,
                        highValueVehicles
                      }
                    };
                    try {
                      validateApplicationLocationData(loc);
                    } catch (e) {
                      setErrors(e);
                      return;
                    }
                    onSubmit(loc);
                  }}
                />
              </>
            ) : null}
          </LayoutBox>
        </LayoutBox>
      )}
      onClose={onClose}
      open={open}
      {...rest}
    >
      <Box flex={1} alignSelf="stretch">
        <TextField
          label="Name"
          value={name}
          onChangeValue={setName}
          helperText={errors.name ? errors.name : undefined}
          error={errors.name}
          hideHelperText={isNull(errors.name)}
          readOnly={readOnly}
          disabled={readOnly}
        />
        <RowLayout>
        <TextField
          label="Covered Value"
          value={coveredValue}
          precision={2}
          addZeroes
          type="number"
          onChangeValue={setCoveredValue}
          helperText={errors.coveredValue ? errors.coveredValue : undefined}
          error={errors.coveredValue}
          hideHelperText={isNull(errors.coveredValue)}
          flex="1"
          readOnly={readOnly}
          disabled={readOnly}
        />
        {
          product && apiHelpers.isProductAuto(product) &&
          <TextField
            label="Number of Vehicles"
            type="number"
            precision={0}
            value={numVehicles}
            onChangeValue={setNumVehicles}
            hideHelperText
            flex="1"
            readOnly={readOnly}
            disabled={readOnly}
          />
        }
        {
          product && apiHelpers.isProductAuto(product) &&
          <TextField
            label="Average Car Value"
            value={averageCarValue ? NumericInput.format(averageCarValue, 2, true) : ''}
            onChangeValue={setAverageCarValue}
            helperText={averageCarValue >= 10000 ? undefined : "ACV is suspiciously low!"}
            hideHelperText={averageCarValue >= 10000}
            flex="1"
          />
        }
        </RowLayout>
        <AddressAutocomplete
          address={address}
          setAddress={setAddress}
          onDetailsLoading={setAddressDetailsLoading}
          onDetailsReceived={(details) => {
            setLat(details.lat);
            setLon(details.lon);
            setAboveSeaLevel(details.aboveSeaLevel);
            setTimezone(details.timezone);
            setPostcode(details.postcode);
            setCountryCode(details.countryCode);
            setRegionCode(details.regionCode);
            setPlaceId(details.placeId);
            setAddress(details.address);
            setAddress1(details.address1);
            setAddress2(details.address2);
            setCity(details.city);
            setVisibleAddress(details.address);
          }}
          helperText={errors.address ? errors.address : undefined}
          error={errors.address}
          readOnly={readOnly}
          disabled={readOnly}
        />
        <Box flexDirection="row" gap="8" flex="1" alignSelf="stretch" opacity={addressDetailsLoading ? 0.5 : 1}>
          <TextField
            label="Region Code"
            value={regionCode}
            onChangeValue={setRegionCode}
            disableAutoComplete
            disableAutoCorrect
            disableSpellCheck
            disableAutoCapitalize
            disabled={addressDetailsLoading || readOnly}
            flex="1"
            helperText={errors.regionCode ? errors.regionCode : undefined}
            error={errors.regionCode}
            hideHelperText={isNull(errors.regionCode)}
            readOnly={readOnly}
          />
          <TextField
            label="Postcode"
            value={postcode}
            onChangeValue={setPostcode}
            disableAutoComplete
            disableAutoCorrect
            disableSpellCheck
            disableAutoCapitalize
            disabled={addressDetailsLoading || readOnly}
            flex="1"
            helperText={errors.postcode ? errors.postcode : undefined}
            error={errors.postcode}
            hideHelperText={isNull(errors.postcode)}
            readOnly={readOnly}
          />
          <TextField
            label="Country Code"
            value={countryCode}
            onChangeValue={setCountryCode}
            disableAutoComplete
            disableAutoCorrect
            disableSpellCheck
            disableAutoCapitalize
            disabled={addressDetailsLoading || readOnly}
            flex="1"
            helperText={errors.countryCode ? errors.countryCode : undefined}
            error={errors.countryCode}
            hideHelperText={isNull(errors.countryCode)}
            readOnly={readOnly}
          />
        </Box>
        <Box flexDirection="row" gap="8" flex="1" alignSelf="stretch" opacity={addressDetailsLoading ? 0.5 : 1}>
          <TextField
            label="Latitude"
            value={lat}
            type="number"
            onChangeValue={setLat}
            disableAutoComplete
            disableAutoCorrect
            disableSpellCheck
            disableAutoCapitalize
            disabled={addressDetailsLoading || readOnly}
            flex="1"
            helperText={errors.lat ? errors.lat : undefined}
            error={errors.lat}
            hideHelperText={isNull(errors.lat)}
            readOnly={readOnly}
          />
          <TextField
            label="Longitude"
            value={lon}
            type="number"
            onChangeValue={setLon}
            disableAutoComplete
            disableAutoCorrect
            disableSpellCheck
            disableAutoCapitalize
            disabled={addressDetailsLoading || readOnly}
            flex="1"
            helperText={errors.lon ? errors.lon : undefined}
            error={errors.lon}
            hideHelperText={isNull(errors.lon)}
            readOnly={readOnly}
          />
        </Box>
        <TextField
          label="Visible Address"
          value={visibleAddress}
          onChangeValue={setVisibleAddress}
          helperText={errors.name ? errors.name : undefined}
          error={errors.name}
          hideHelperText={isNull(errors.name)}
          readOnly={readOnly}
          disabled={readOnly}
        />
        <TextField
          label="Customer Reference"
          value={custRef}
          onChangeValue={setCustRef}
          helperText={errors.name ? errors.name : undefined}
          error={errors.name}
          hideHelperText={isNull(errors.name)}
          readOnly={readOnly}
          disabled={readOnly}
        />
        {
          // only show this stuff if it's a saved location, otherwise we don't
          // have anything useful to present
          showAdditionalDataEntries && id && (
            <>
              <RowLayout>
                <TextField
                  label="Flood Factor"
                  value={floodFactor}
                  onChangeValue={setFloodFactor}
                  helperText={errors.floodFactor ? errors.floodFactor : undefined}
                  error={errors.floodFactor}
                  hideHelperText={isNull(errors.floodFactor)}
                  readOnly={readOnly}
                  disabled={readOnly}
                />
                <TextField
                  label="FEMA"
                  value={fema}
                  onChangeValue={setFema}
                  helperText={errors.fema ? errors.fema : undefined}
                  error={errors.fema}
                  hideHelperText={isNull(errors.fema)}
                  readOnly={readOnly}
                  disabled={readOnly}
                />
                <TextField
                  label="Crime Zone"
                  value={crimeZone}
                  onChangeValue={setCrimeZone}
                  helperText={errors.crimeZone ? errors.crimeZone : undefined}
                  error={errors.crimeZone}
                  hideHelperText={isNull(errors.crimeZone)}
                  readOnly={readOnly}
                  disabled={readOnly}
                />
              </RowLayout>
              <Box>
                <TextField
                  label="ID"
                  value={id}
                  readOnly={true}
                  disabled={true}
                />
              </Box>
              {product && apiHelpers.isProductAuto(product) ? (
                <HighValueVehicles value={highValueVehicles} onChangeValue={setHighValueVehicles} readOnly={readOnly} disabled={readOnly} />
              ) : null}
            </>
          )
        }
        <Spacing vertical={6} />
      </Box>
    </Dialog>
  );
}


const HighValueVehicles = ({ value: items, onChangeValue, readOnly, disabled }) => {
  const vehicles = useMemo(() => Array.isArray(items) ? items : [], [items]);
  const [openEditIndex, setOpenEditIndex] = useState(false);
  const handleDelete = (index) => {
    console.log('delete', index);
    const newItems = [...vehicles];
    const filteredItems = newItems.filter((v, i) => i !== index);
    console.log('delete 2', filteredItems);
    onChangeValue(filteredItems);
  }

  const handleEditOrSave = (index, data) => {
    console.log('editsave', index, data);
    const newItems= [...vehicles];
    if (index === true) {
      newItems.push(data);
    } else if (index >= 0 && index < vehicles.length) {
      newItems[index] = data;
    }
    console.log('editsave 2', newItems);
    onChangeValue(newItems);
    setOpenEditIndex(false);
  }

  const [yearEdit, setYearEdit] = useState(null);
  const [makeEdit, setMakeEdit] = useState('');
  const [modelEdit, setModelEdit] = useState('');
  const [valueEdit, setValueEdit] = useState(0);

  useEffect(() => {
    console.log(openEditIndex);
    if (typeof openEditIndex === 'number' && openEditIndex >= 0 && openEditIndex < vehicles.length) {

      const item = vehicles[openEditIndex];
      setYearEdit(item.year);
      setMakeEdit(item.make);
      setModelEdit(item.model);
      setValueEdit(item.value);
    } else {
      setYearEdit(null);
      setMakeEdit('');
      setModelEdit('');
      setValueEdit(0);
    }
  }, [openEditIndex, vehicles]);

  useEffect(() => {
    if (readOnly) {
      setOpenEditIndex(false);
    }
  }, [readOnly])

  if (readOnly && !vehicles.length) {
    return null;
  }
  return (
    <>
      <Heading level={5} pb="$2" pt="$4">
        High Value Vehicles
      </Heading>
      {vehicles.map((v, i) => {
        const { year = "", make = "", model = "", value = 0 } = v;
        return (
          <LayoutBox key={`hvv${i}`} row layout="center" justifyContent="space-between" padY="$1.5" border={{ top: { width: 1, color: '$gray.200' } }}>
            {!readOnly ? (
              <IconButton onPress={() => handleDelete(i)} disabled={disabled}>
                <TrashIcon />
              </IconButton>
            ) : null}
            <TextData
              label="Year"
              data={year}
              invert
              labelProps={{ size: 'small' }}
              flex="0.5"
            />
            <TextData
              label="Make"
              data={make}
              invert
              labelProps={{ size: 'small' }}
              flex="1"
            />
            <TextData
              label="Model"
              data={model}
              invert
              labelProps={{ size: 'small' }}
              flex="1"
            />
            <TextData
              label="Value"
              data={`${value ? '$' + value : ''}`}
              invert
              labelProps={{ size: 'small' }}
              flex="1"
            />
            {!readOnly ? (
              <Button
                label="Edit"
                onPress={() => setOpenEditIndex(i)}
                disabled={disabled}
                variant="outlined"
                color="$primary"
                width={55}
              />
            ) : null}
          </LayoutBox>
        )
      })}
      <Spacing vertical={2} />
      {!readOnly && (
        <Button
          label="+  High Value Vehicle"
          onPress={() => setOpenEditIndex(true)}
          disabled={disabled}
          alignSelf="flex-start"
          variant="outlined"
          color="$primary"
          size="large"
        />
      )}
      <Dialog
        heading="High Value Vehicle"
        open={openEditIndex !== false}
        onClose={() => setOpenEditIndex(false)}
        actions={(
          <>
            <Button
              variant="text"
              color="$primary"
              label="Cancel"
              onPress={() => setOpenEditIndex(false)}
              disabled={disabled}
            />
            <Button
              label="Save"
              onPress={() => handleEditOrSave(openEditIndex, { year: yearEdit, make: makeEdit, model: modelEdit, value: valueEdit })}
              disabled={disabled}
            />
          </>
        )}
      >
        <TextField
          label="Year"
          type="number"
          value={yearEdit}
          onChangeValue={setYearEdit}
          disabled={disabled}
        />
        <TextField
          label="Make"
          value={makeEdit}
          onChangeValue={setMakeEdit}
          disabled={disabled}
        />
        <TextField
          label="Model"
          value={modelEdit}
          onChangeValue={setModelEdit}
          disabled={disabled}
        />
        <TextField
          label="Value"
          value={valueEdit}
          onChangeValue={setValueEdit}
          type="number"
          disabled={disabled}
        />
      </Dialog>
    </>
  )
}


export { LocationDialog }
