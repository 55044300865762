import React from 'react';
import { Box, Text, Button } from '../../../ui';

const FileInputBase = React.forwardRef((props, ref) => {
  const {
    onChangeValue,
    children, // render your own child here as the input is absolutely positioned. So if you want a button to be whats rendered that but a Button wrapped in this as a child
    component: Component = Box, // < wrapper component for the input. Can ignore as Box is good enough
    labelProps = null,
    inputProps = null,
    ...rest
  } = props;

  return (
    <Component ref={ref} pointerEvents="box-none" {...rest}>
      {children}
      <label style={{ opacity: 0, width: '100%', height: '100%', cursor: 'pointer', position: 'absolute', left: 0, top: 0, zIndex: 1 }}>
        <input
          type="file"
          style={{
            width: 0.1,
            height: 0.1,
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            zIndex: -1,
          }}
          accept="*"
          onChange={e => {
            if (e && e.target && e.target.files) {
              if (onChangeValue) {
                onChangeValue(e.target.files);
              }
            }
          }}
        />
      </label>
    </Component>
  );
});

const FileInput = (props) => {
  const { value, url, disabled, onChangeValue, children, hideUpload = false, emptyText = 'No file added yet', containerProps } = props;
  return (
    <Box
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      borderRadius="8"
      pointerEvents={disabled ? 'none' : 'auto'}
      {...containerProps}
    >
      {!hideUpload && (
        <FileInputBase onChangeValue={onChangeValue}>
          <Button
            variant={url ? 'outlined' : 'contained'}
            color={url ? '$secondary' : '$primary'}
            disabled
          >{url ? 'Update File' : 'Add File'}</Button>
        </FileInputBase>
      )}
      <Box padLeft={hideUpload ? '$1' : '$3'} padRight={hideUpload ? '$6' : '$3'} justifyContent="center" alignItems="flex-start" height="100%">
        {
          url ? (
            <a href={url} target="_blank" rel="noopener noreferrer"><Text small color="$secondary" bold>Download  ↓</Text></a>
          ) : <Text small dim>{emptyText}</Text>
        }
      </Box>
      {children}
    </Box>
  )
}

export { FileInput };
