import React from 'react';
import { withStyles } from '../../styling';
import { Box } from '../Box';
import { EMPTY_FUNC } from '../../utils';
import { merge } from 'merge-anything';

const filterProps = ['size', 'medium', 'small', 'color', 'borderRadius'];

const IconButton = withStyles((props) => {
  const {
    theme,
    size = null,
    small = false,
    color = '$primary',
    onColor,
    borderRadius = '$circle',
    animations: propAnimations
  } = props;

  let buttonSize = size;
  if (!size || !theme.iconButton.sizes[size]) {
    if (small) {
      buttonSize = 'small';
    } else {
      buttonSize = 'medium';
    }
  }

  let c = color || '$primary';
  if (!color && onColor) {
    c = theme.colors.on(onColor) || '$primary';
  }
  let animations = {
    hovered: {
      backgroundColor: theme.colors.alpha(c, 0.10),
    },
    pressed: {
      backgroundColor: theme.colors.alpha(c, 0.18),
    },
    default: {
      backgroundColor: theme.colors.alpha(c, 0),
    },
  };

  if (propAnimations) {
    animations = merge(animations, propAnimations);
  }

  const style = {
    root: {
      ...theme.iconButton.base,
      ...theme.iconButton.sizes[buttonSize],
      color: c,
      borderRadius,
      animations,
    },
  };
  return style;
}, { name: 'IconButton', filterProps, asProp: { color: true } })
(React.forwardRef(function IconButton({
  styles,
  children,
  accessibility,
  color,
  onColor,
  disabled = false, // TODO: <- something with this style wise. propbably a disabled color on button/action colors
  onPress = EMPTY_FUNC,
  ...props
}, ref) {
  const childProps = {};
  if (color !== undefined) {
    childProps.color = color;
  }
  if (onColor !== undefined) {
    childProps.onColor = onColor;
  }
  return (
    <Box
      ref={ref}
      disableAnimationDefaults
      disabled={disabled}
      onPress={onPress}
      focusable
      accessibility={{ accessibilityRole: 'button', ...accessibility }}
      {...props}
    >
      {React.cloneElement(React.Children.only(children), childProps)}
    </Box>
  );
}));

export { IconButton };
