import {
  // bbox,
  transformTranslate,
  // area as turfArea,
  helpers,
  // pointsWithinPolygon,
  // center as turfCenter,
  distance as turfDistance,
} from '@turf/turf';

export const EARTH_RADIUS = 6378137;

export function getExpandedMaxBounds(maxBounds, mult = 0.5) {
  if (
    !maxBounds ||
    !Array.isArray(maxBounds.ne) ||
    !Array.isArray(maxBounds.sw)
  ) {
    return maxBounds;
  }
  let next = { ...maxBounds };
  try {
    const ne = helpers.point(maxBounds.ne);
    const sw = helpers.point(maxBounds.sw);
    const dist = turfDistance(ne, sw, { units: 'kilometers' });
    const distPadding = dist * mult * -1;
    const nePadded = transformTranslate(ne, distPadding, -45, {
      units: 'kilometers',
    });
    const swPadded = transformTranslate(sw, distPadding, 135, {
      units: 'kilometers',
    });
    next.ne = nePadded.geometry.coordinates;
    next.sw = swPadded.geometry.coordinates;
  } catch (err) {
    console.error('getExpandedMaxBounds ERROR: ', err);
  }

  return next;
}

export function getBounds(options) {
  if (!options || typeof options !== 'object') {
    return null;
  }
  try {
    const { center, hectares = 2 } = options;
    if (!Array.isArray(center) || center.length !== 2) {
      return null;
    }
    const feature = helpers.point(center);

    // convert hectares area to km2 and sqrt to km.
    const km2 = hectares / 100;
    const distance = Math.sqrt(km2) * -1;
    // convert feature and it's coords to NE distance away
    const neF = transformTranslate(feature, distance, -45, {
      units: 'kilometers',
    });
    // convert feature and it's coords to SW distance away
    const swF = transformTranslate(feature, distance, 135, {
      units: 'kilometers',
    });
    const ne = neF.geometry.coordinates;
    const sw = swF.geometry.coordinates;
    const bounds = { ne, sw };
    return bounds;
  } catch (err) {
    console.warn('Err getting bounds', err);
    return null;
  }

}