import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { Box, Text, FileInput } from '../../../ui';
import { useApi, useAsync } from '../../../hooks';

const ApplicationDocuments = ({ data, update }) => {
  const [inputFileId, setInputFileId] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const lastInputValue = useRef(inputValue);

  const { uploadFileForApplication } = useApi();
  const uploadDocument = useCallback(async (fileId, document) => {
    await uploadFileForApplication(data.id, {
      id: fileId,
      document,
    })
    console.log("Time to update");
    await update(true);
  }, [uploadFileForApplication]);

  const { value, status, execute, error } = useAsync(uploadDocument, { immediate: false });

  useEffect(() => {
    if (inputValue && inputValue !== lastInputValue.current) {
      lastInputValue.current = inputValue;
      execute(inputFileId, inputValue)
    }
  }, [inputValue, execute, inputFileId]);

  const documents = data.details.files ? Object.keys(data.details.files).map((fileId, i) => {
    const f = data.details.files[fileId];
    return (
      <Box key={fileId} style={{marginTop: "0.5em"}}>
        <Text>{f.filename}</Text>
        <FileInput url={f.url} />
      </Box>
    )
  }) : [];
  
  return (
    <Box marginTop="$3" minHeight="400" justifyContent={!documents.length ? "center" : 'flex-start'} alignItems={!documents.length ? 'center' : undefined}>
      {!documents.length ? <Text key="empty" center mb="$2">No documents currently attached</Text> : null}
      <Box alignSelf={!documents.length ? 'center' : undefined}>
        <FileInput
          value={inputValue}
          containerProps={!documents.length ? { paddingLeft: '$6' } : null}
          emptyText=""
          onChangeValue={(files) => {
            if (files.length) {
              const fileId = uuid();
              setInputValue(files[0]);
              setInputFileId(fileId);
            }
          }}
        />
      </Box>
      <Box style={{marginTop: "0.5em"}}>{documents}</Box>
    </Box>
  )
}

export { ApplicationDocuments }