import React from 'react';
import { animated as makeAnimatable } from '@react-spring/native';
import { Animate } from './Animate';


function animated(Component) {
  const AnimateComponent = makeAnimatable(Component);

  return React.forwardRef(function AnimatedComponent(props, ref) {
    const {
      animate, // dont pass
      animation, // dont pass
      animations, // dont pass
      disableAnimate, // dont pass
      animated = false, // dont pass <- use to force the use of <Animate></Animate>
      styles, // dont pass
      style,
      ...rest
    } = props;

    if (!animated && (disableAnimate || !animate || !animations)) {
      // TODO: ^^-- this was originally noAnimate. Check use in website/cms during update...
      if (animation && !disableAnimate) {
        return (
          <AnimateComponent ref={ref} style={[style, animation]} {...rest} />
        );
      }
      return <Component ref={ref} style={style} {...rest} />;
    }

    return (
      <Animate {...props}>
        {({ props: animStyle }) => (
          <AnimateComponent
            ref={ref}
            style={[style, animStyle, animation]}
            {...rest}
          />
        )}
      </Animate>
    );
  });
}

export { animated };
