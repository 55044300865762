import React, { useMemo, useRef } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

function useAdjustmentForm() {
  return useFormContext() || {};
}

function ProvideAdjustmentForm({ defaultData, submit, children }) {
  const defaultDataRef = useRef(defaultData);
  defaultDataRef.current = defaultData;

  const { handleSubmit, reset, formState: { errors }, ...form } = useForm({
    defaultValues: defaultData,
    mode: 'onBlur',
  });

  const hasQuoteError = errors && errors.quote ? true : false;

  const customMethods = useMemo(() => {
    return {
      reset: () => reset(defaultDataRef.current),
      handleSubmit: handleSubmit(submit),
      hasQuoteError,
    };
  }, [reset, handleSubmit, submit, hasQuoteError]);

  return (
    <FormProvider {...form} {...customMethods}>
      {children}
    </FormProvider>
  );
}

export { useAdjustmentForm, ProvideAdjustmentForm }