import { useRef } from 'react';
import { StyleSheet } from 'react-native';
import { merge } from 'merge-anything';
import equal from 'fast-deep-equal/react';
import { useTheme, useBreakpoint, useEventCallback } from '../hooks';
import { parseStyles, getStyleSystemOptions } from '../system';
import {
  flatten,
  flattenStyles,
  murmurhash,
  fromReactNativeStyles,
} from './utils';
import { isEmpty, get, isNull, isObject } from '../utils';


const generated = { };
const created = { };

const styleToProps = (styles, n, styleProp) => {
  if (!n || typeof n !== 'string') {
    return null;
  }
  if (n === 'root' && styleProp) {
    return {
      ...styles.props.root,
      style: Array.isArray(styleProp) ? [styles.root, ...styleProp] : [styles.root,  styleProp]
    }
  }
  if (styles[n] || styles.props[n]) {
    return {
      ...styles.props[n],
      style: styles[n],
    }
  }
  return null;
}

function createStyles(stylesDef, options = { }) {
  const {
    name,
    postApply,
    asProp = {},
    forward = false,
    ...systemOptions
  } = options;

  const className = typeof name === 'string' ? `${name.charAt(0).toLowerCase()}${name.slice(1)}` : name;

  if (forward) {
    const useStyles = (props) => {
      const theme = useTheme();
      let { props: overrideProps, ...overrideStyles } = theme.overrides[name] || {};
      if (props.styles) {
        if (Array.isArray(props.styles)) {
          return [stylesDef, overrideStyles, ...props.styles];
        }
        return [stylesDef, overrideStyles, props.styles];
      }
      return [stylesDef, overrideStyles];
    };
    return useStyles;
  }

  const styleSystemOptions = getStyleSystemOptions(systemOptions);

  const useStyles = (props) => {
    const lastBreakpoint = useRef();
    const lastProps = useRef();
    const lastStyles = useRef();
    const stylesRef = useRef({ props: { } });
    const theme = useTheme();
    const [breakpoint, breakpoints] = useBreakpoint();

    let styles = { props: { } };


    // TODO: is checking for useLast a performance drop?
    let useLast = false;
    if (lastStyles.current) {
      useLast = (
        lastProps.current
        && equal(lastProps.current, props)
        && lastBreakpoint.current
        && equal(lastBreakpoint.current, breakpoint)
      );
    }
    
    /*
      convenience function for destructuring styles and props into a component within render
      takes the style name or names (n) and optionally props.style (styleProp). styleProp is only relevant if only the style name is 'root'
      EX: <Component {...styles.toProps('vertical')} />
      EX: <Component {...styles.toProps(['root', 'vertical', 'primary'], props)} />
      EX: <Component {...styles.toProps({ root: true, vertical: ifVertical, primary: !secondary }, props)} />
    */
    const toProps = useEventCallback((n, styleProp) => {
      if (!n) {
        return null;
      }
      const stylesResult = useLast ? lastStyles.current : stylesRef.current;
      if (typeof n === 'string') {
        return styleToProps(stylesResult, n, styleProp);
      }
      let nStyle = [];
      let result = {};
      if (Array.isArray(n)) {
        for (const sn of n) {
          const r = styleToProps(stylesResult, sn, styleProp);
          if (r) {
            const { style: pStyle, ...rest } = r;
            if (Array.isArray(pStyle)) {
              nStyle = [...nStyle, ...pStyle];
            } else {
              nStyle.push(pStyle);
            }
            result = { ...result, ...rest };
          }
        }
      } else if (typeof n === 'object') {
        for (const sn in n) {
          if (n[sn]) {
            const r = styleToProps(stylesResult, sn, styleProp);
            if (r) {
              const { style: pStyle, ...rest } = r;
              if (Array.isArray(pStyle)) {
                nStyle = [...nStyle, ...pStyle];
              } else {
                nStyle.push(pStyle);
              }
              result = { ...result, ...rest };
            }
          }
        }
      }
      result.style = nStyle;
      return result;
    });

    styles.toProps = toProps;

    if (useLast) {
      return lastStyles.current;
    }

    lastProps.current = { ...props };
    lastBreakpoint.current = breakpoint;

    const override = theme.overrides[name] || {};
    let { props: overrideProps, ...overrideStyles } = override;
    // TODO: ^^ in order for theme override props to work. all components need to NOT use defaultProps. Thost are handled via props destrucuring in function body
    const system = props.styleSystemOptions
      ? getStyleSystemOptions(props.styleSystemOptions, styleSystemOptions)
      : styleSystemOptions;

    const propsWithSystemProps = {
      ...overrideProps,
      breakpoint,
      breakpoints,
      theme,
      styleSystemOptions: system,
      ...props,
      getStyle,
    };

    

    const { stylesToParse, stylesPassed, sxStyleDef } = getStylesToParse(
      stylesDef,
      overrideStyles,
      propsWithSystemProps
    );
    const flattened = flatten(stylesToParse, propsWithSystemProps);
    const actualDefs = {};
    const rootVariants = {};
    let hasVariants = false;
    for (const key in flattened) {
      if (typeof flattened[key] === 'function' && flattened[key].isVariantResolver) {
        rootVariants[key] = flattened[key];
        hasVariants = true;
      } else {
        actualDefs[key] = flattened[key];
      }
    }
    if (!actualDefs.root) {
      actualDefs.root = {};
    }
    for (const key in actualDefs) {
      let resolved = parseStyles(actualDefs[key], propsWithSystemProps);
      if (key === 'root') {
        if (hasVariants) {
          const resolvedVariants = parseStyles(rootVariants, propsWithSystemProps);
          resolved = merge(resolved, resolvedVariants);
        }
        if (sxStyleDef) {
          const sxStyle = parseStyles(sxStyleDef, {
            ...propsWithSystemProps,
            resolveStyleWithThemeOnly: true
          });
          resolved = merge(resolved, sxStyle);
        }
      }
      let { props: addProps, animations, ...resolvedStyles } = resolved;
      const stylesAsProps = { };
      if (key === 'root') {
        for (const styleKey in asProp) {
          const prop = get(system.aliases, styleKey, styleKey);
          if (asProp[styleKey] !== false && !isNull(resolvedStyles[prop])) {
            stylesAsProps[styleKey] = resolvedStyles[styleKey];
            delete resolvedStyles[styleKey];
          }
        }
      }
      if (!isEmpty(resolved)) {
        const generatedStyle = generateStyles(resolvedStyles);
        styles[key] = generatedStyle;
      } else {
        styles[key] = null;
      }

      if (addProps) {
        styles.props[key] = typeof addProps === 'function'
          ? { ...addProps(propsWithSystemProps, resolved), ...stylesAsProps }
          : { ...addProps, ...stylesAsProps };
      } else {
        styles.props[key] = { ...stylesAsProps };
      }
      if (animations) {
        styles.props[key].animations = animations;
      }
    }

    if (stylesPassed) {
      for (const key in stylesPassed) {
        if (key === 'root' || key === 'props') {
          continue;
        }
        if (name && (key === name || key === className)) {
          styles.root = [styles.root, stylesPassed[key]];
          styles.props.root = merge(styles.props.root, stylesPassed.props[key]);
        } else if (styles[key]) {
          styles[key] = [styles[key], stylesPassed[key]];
          styles.props[key] = merge(styles.props[key], stylesPassed.props[key]);
        } else {
          styles[key] = stylesPassed[key]
          styles.props[key] = stylesPassed.props[key];
        }
      }
    }
    if (props.animations) {
      if (!styles.props.root) styles.props.root = { animations: { } };
      if (typeof props.animations === 'function') {
        styles.props.root.animations = merge(
          styles.props.root.animations || { },
          props.animations(propsWithSystemProps, styles.root)
        )
      } else {
        styles.props.root.animations = merge(styles.props.root.animations || { }, props.animations);
      }
    }
    
    if (options.debug) console.log('DEBUG', options.name, styles, getStyles(styles));

    if (postApply) {
      let createdStyles = getStyles(styles);
      let postStyles = { };
      let postProps = { };
      if (Array.isArray(postApply)) {
        for (let i = 0; i < postApply.length; i += 1) {
          const post = postApply[i]({
            ...propsWithSystemProps,
            styles: createdStyles,
          });
          const { props: aProps, ...aStyles } = post;
          if (aStyles) {
            for (const key in aStyles) {
              aStyles[key] = parseStyles(aStyles[key], propsWithSystemProps);
            }
            postStyles = merge(postStyles, aStyles);
          }
          if (aProps) {
            postProps = merge(postProps, aProps);
          }
          createdStyles = merge(createdStyles, {
            props: postProps,
            ...postStyles,
          });
        }
      } else {
        const post = postApply({
          ...propsWithSystemProps,
          styles: createdStyles,
        });
        const { props: aProps, ...aStyles } = post;
        if (aStyles) {
          for (const key in aStyles) {
            aStyles[key] = parseStyles(aStyles[key], propsWithSystemProps);
          }
          postStyles = aStyles;
        }
        if (aProps) {
          postProps = aProps;
        }
      }
      for (const key in postStyles) {
        if (!isEmpty(postStyles[key])) {
          const generatedStyle = generateStyles(postStyles[key]);
          styles[key] = [styles[key], generatedStyle];
        }
      }
      styles.props = merge(styles.props, postProps);
    }
    styles._resolved = true;

    


    lastStyles.current = styles;
    stylesRef.current = styles;
    return styles;
  };

  return useStyles;
}


function getStylesToParse(stylesDef, overrideStyles, props) {
  let { styles, sx } = props;

  let stylesToParse = [stylesDef, overrideStyles];
  let stylesPassed = null;
  if (styles) {
    if (Array.isArray(styles)) {
      for (const style of styles) {
        if (stylesAreResolved(style)) {
          const { props: styleProps, ...namedStyles } = style;
          if (!stylesPassed) {
            stylesPassed = { ...namedStyles, props: { ...styleProps } };
          } else {
            for (const name in namedStyles) {
              const namedStyle = namedStyles[name];
              if (stylesPassed[name]) {
                if (Array.isArray(stylesPassed[name])) {
                  stylesPassed[name] = [...stylesPassed[name], namedStyle];
                } else {
                  stylesPassed[name] = [stylesPassed[name], namedStyle];
                }
              } else {
                stylesPassed[name] = namedStyle;
              }
            }
            if (styleProps) {
              stylesPassed.props = merge(stylesPassed.props, styleProps);
            }
          }
        } else {
          stylesToParse.push(style);
        }
      }
    } else if (!stylesAreResolved(styles)) {
      stylesToParse.push(styles);
    } else {
      stylesPassed = styles;
    }
  }
  let sxStyleDef = sx ? sx : null;
  if (typeof sx === 'function') {
    sxStyleDef = sx(props.theme);
  }
  return { stylesToParse, stylesPassed, sxStyleDef };
}


const createStyleSheet = (styleObject) => {
  return StyleSheet.create(styleObject);
};


function generateStyles(styles) {
  const styleObject = flattenStyles(styles);
  const styleString = JSON.stringify(styleObject);

  const hash = murmurhash(styleString);
  if (!generated[hash]) {
    const styleSheet = createStyleSheet({ generated: styleObject });
    generated[hash] = styleSheet.generated;
    created[styleSheet.generated] = styleObject;
  }

  return generated[hash];
}


function isPossibleStyle(style) {
  if (!isNull(style)) {
    if (Array.isArray(style)) {
      return true;
    }
    if (created[style]) {
      return true;
    }
    if (isObject(style)) {
      return true;
    }
  }
  return false;
}

function getStyle(style) {
  let resolved = {};
  if (!isNull(style)) {
    if (Array.isArray(style)) {
      for (let i = 0; i < style.length; i += 1) {
        resolved = { ...resolved, ...getStyle(style[i]) };
      }
    } else if (typeof style === 'object') {
      resolved = style;
    } else if (created[style]) {
      resolved = created[style];
    }
  }
  return fromReactNativeStyles(resolved);
}


function getStyles(styles) {
  let resolved = { };
  for (const key in styles) {
    resolved[key] = getStyle(styles[key]);
  }
  return resolved;
}


function stylesAreResolved(style) {
  return isObject(style) && style._resolved;
}



export { createStyles, getStyle, getStyles, isPossibleStyle };
