import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { canUseDOM } from 'fbjs/lib/ExecutionEnvironment';
import { SearchControlButton } from '../ListView';
import { Modal, Box, useBreakpoint, Popover, ScrollView, TextField, Autocomplete, ModalManager, Chip, Heading, Text } from '../../ui';
import { useEventCallback } from '../../ui/hooks';
import { FilterIcon, SearchIcon } from '../icons';
import { useFilterControls, FilterTypeComponents, useSearchFieldOptions } from './FilterControls';
import { Dialog } from '../Dialog';
import { withStyles } from '../../ui/styling';
import { debounce, isNull } from '../../ui/utils';

export const SearchBoxFilterButton = ({ searchPlaceholder }) => {
  const [filterControls, activeFiltersCount] = useFilterControls({ components: FilterTypeComponents })
  const [searchFields, activeSearches] = useSearchFieldOptions();
  const numSet = activeFiltersCount + activeSearches.length;

  const containerRef = useRef();
  const trapElementRef = useRef();
  const filterBtnRef = useRef();
  const inputRef = useRef();

  const [input, setInput] = useState('');

  // handle menu and input display/hide behavior
  const [inputFocused, setInputFocused] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // is the menu for filter/search fields open
  const [showSearchFields, setShowSearchFields] = useState(false); // should the menu show searchFields instead of filterFields
  const [hasActiveElement, setHasActiveElement] = useState(false); // track if any child elements are focused
  const activeModalCount = useRef(ModalManager.open.length);
  const hasInput = input.trim().length ? true : false;
  const handleInputFocus = useEventCallback(() => {
    if (!menuOpen) {
      if (hasInput) {
        if (!showSearchFields) {
          setShowSearchFields(true);
        }
      } else if (showSearchFields) {
        setShowSearchFields(false);
      }
      setMenuOpen(true);
    }
    setInputFocused(true)
  });
  useTrapFocusState({
    trapElementRef,
    onFocusCheck: (active) => {
      // If a filter control modal was open ModalManager.open will = 2. Modals on close result in focus going elsewhere
      // so if we can see a control modal was just open we can assume it just closed and try to focus to keep active true
      if (!active && ModalManager.open.length === 1
          && activeModalCount.current === 2
          && filterBtnRef.current && filterBtnRef.current.focus
        ) {
          activeModalCount.current = ModalManager.open.length;
          filterBtnRef.current.focus();
      } else {
        activeModalCount.current = ModalManager.open.length;
        setHasActiveElement(active || ModalManager.open.length > 1);
        // NOTE ^ the FilterMenu is registered as an open Modal within the ModalManager hence > 1
      }
    },
  });
  useEffect(() => {
    if (inputFocused) {
      if (hasInput) {
        if (!showSearchFields) {
          setShowSearchFields(true);
        }
      } else if (showSearchFields) {
        setShowSearchFields(false);
      }
    } else if (menuOpen) {
      if (!hasActiveElement) {
        setMenuOpen(false);
      }
    }
    activeModalCount.current = ModalManager.open.length;
  }, [inputFocused, hasInput, menuOpen, hasActiveElement, showSearchFields]);
  const handleOnPressFilterButton = useEventCallback(() => {
    if (menuOpen) {
      if (showSearchFields) {
        setShowSearchFields(false);
      } else {
        setMenuOpen(false);
      }
    } else {
      if (showSearchFields) {
        setShowSearchFields(false);
      }
      setMenuOpen(true);
    }
  });

  // components
  const endAdornment = useMemo(() => (
    <>
      <SearchControlButton
        label={(breakpoint) => breakpoint.key === 'sm' ? <Text dim bold small>{`(${numSet})`}</Text> : (
          <Text small bold>
            Filters
            <Text dim>{` (${numSet})`}</Text>
          </Text>
        )}
        startIcon={<FilterIcon color="$text" size="20" />}
        onPress={handleOnPressFilterButton}
        ref={filterBtnRef}
      />
    </>
  ), [handleOnPressFilterButton, showSearchFields, numSet]);
  
  const handleSearchOptionSelected = useEventCallback(() => {
    setInput('');
    if (inputRef && inputRef.current && inputRef.current.focus) {
      inputRef.current.focus();
    }
  });

  const searchFieldControls = useMemo(() => {
    return <SearchInputOptions input={input} options={searchFields} onOptionSelected={handleSearchOptionSelected} />;
  }, [input, searchFields]);

  const filterFieldControls = useMemo(() => {
    const chipProps = {
      marginX: 2,
      marginBottom: 2,
      marginTop: 4,
      styles: {
        root: {
          height: 42,
        },
        label: {
          paddingRight: '$2.25',
          paddingLeft: '$2',
        }
      }
    }
    const activeSearchFieldControls = activeSearches.map((field, i) => {
      const { setQuery, name, value, label, key } = field;
      return (
        <Chip
          index={i}
          label={(
            <Box alignItems="flex-start">
              <Text size="xxSmall" style={{ fontWeight: 600 }} uppercase>{label}</Text>
              <Text size="small" maxLines={1} maxWidth={144} marginTop="-4">{value}</Text>
            </Box>
          )}
          key={`${key}-active`}
          onDelete={(e, { index } = {}) => {
            if (filterBtnRef.current && filterBtnRef.current.focus) {
              filterBtnRef.current.focus();
            }
            setQuery({ field: name, value: '', operator: 'ilike' });
          }}
          {...chipProps}
        />
      )
    });
    const ActiveSearchFields = (
      <Box
        key={`activeSearchFieldList`}
        sx={{
          alignItems: 'stretch',
          marginBottom: '$4',
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <Heading level={5} dim={activeSearchFieldControls.length ? 0.75 : true}>Search Filters</Heading>
        <Text xSmall>Type in the search box to add search filters.</Text>
        <Text xSmall>You can view and remove added search filters below.</Text>
        <Box width="100%" height="1" bg="$gray.100" my="$1" />
        <Box
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {activeSearchFieldControls.length ? activeSearchFieldControls : (
            <Text small dim>No search filters</Text>
          )}
        </Box>
      </Box>
    )
    return [ActiveSearchFields, ...filterControls];
  }, [filterControls, activeSearches])

  const menu = useMemo(() => {
    return (
      <FilterMenu
        fieldControls={showSearchFields ? searchFieldControls : filterFieldControls}
        anchorNode={containerRef.current}
        open={menuOpen}
      />
    )
  }, [filterFieldControls, searchFieldControls, menuOpen, showSearchFields])

  return (
    <Box ref={trapElementRef} onPress={() => null} disableAnimationDefaults cursor="default" zIndex={9999999}>
      <SearchBox
        ref={containerRef}
        inputRef={inputRef}
        onChangeValue={setInput}
        value={input}
        placeholder={searchPlaceholder}
        endAdornment={endAdornment}
        onFocus={handleInputFocus}
        onBlur={() => setInputFocused(false)}
        selectTextOnFocus
      />
      {menu}
    </Box>
  );
}



const SearchInputOptions = ({ input, options: searchFieldOptions, onOptionSelected }) => {
  const handleOnPress = useEventCallback((index) => {
    if (!input.trim() || isNull(index) || index >= searchFieldOptions.length) {
      return;
    }
    const { setQuery, name, value } = searchFieldOptions[index];
    if (!value || value.trim() !== input.trim()) {
      setQuery({ field: name, value: input, operator: 'ilike' });
    }
    if (onOptionSelected) {
      onOptionSelected(name)
    }
  });
  const elements = [
    <Text medium bold pb="$1.75" dim={0.75} key={`siosubheading`}>Select a search option to apply</Text>
  ];
  searchFieldOptions.forEach((option, i) => {
    elements.push(
      <Box
        sx={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          maxWidth: '100%',
          height: 38,
          padLeft: '$0.25',
        }}
        key={option.key}
        onPress={() => handleOnPress(i)}
      >
        <Chip
          label={<Text bold>{option.label}</Text>}
          color="$gray.100"
          index={i}
        />
        <Text small maxLines={1} ml="$1.25" dim={0.75}>{`that includes "${input}"`}</Text>
      </Box>
    );
  });
  return elements;
}



const FilterMenu = (props) => {
  const { fieldControls, anchorNode, open, close } = props;
  const adjustPositionStyle = useEventCallback((styles, layoutItems) => {
    if (layoutItems && layoutItems.anchor && layoutItems.anchor.width) {
      return { ...styles, width: layoutItems.anchor.width };
    }
    return { ...styles, width: 500 };
  })
  return (
    <Popover
      open={open}
      onClose={close}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      anchorNode={anchorNode}
      ContentContainerProps={{ maxHeight: '100%' }}
      adjustPositionStyle={adjustPositionStyle}
      disableTrapFocus
      disableScrollLock
      allowPositionOffscreen
      disablePortal
      renderInline
      hideBackdrop
      anchorOffset={{ vertical: -4, horizontal: 0 }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <Box
        sx={{
          cursor: 'default',
          maxHeight: 500,
          maxWidth: '100%',
          flexGrow: 1,
          flexShrink: 0,
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'flex-start',
          marginBottom: 16,
          bg: '$white',
          borderWidth: 1,
          borderRadius: 3,
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, 0.04)',
          shadow: {
            color: 'rgb(49, 51, 51)',
            offset: { y: 5 },
            opacity: 0.07,
            radius: 28,
          },
        }}
        onPress={() => null}
        disableAnimationDefaults
      >
        <ScrollView justifyContent="flex-start" alignItems="flex-start" padX="$2" padTop="$2" padBottom="$4">
          {fieldControls}
        </ScrollView>
      </Box>
    </Popover>
  )
}



const SearchBox = withStyles(({ theme: { spacing, breakpoints } }) => {
  const p = {
    InputProps: {
      size: 'medium',
    },
    variant: 'outlined',
    hideHelperText: true,
    opacity: 0.9,
    styles: { textBox: { padY: spacing(1.75) }},
  };
  return {
    root: {
      props: breakpoints({
        xs: {
          width: '100%',
          ...p,
        },
        sm: {
          width: 500,
          ...p,
        },
        md: {
          width: 600,
          ...p,
        }
      }),
    }
  }
})(React.forwardRef(function SearchInput(props, ref) {
  const { styles, ...rest } = props;

  return (
    <TextField
      ref={ref}
      startAdornment={(
        <Box width={26} ml="$-0.5" height={24} pt="$0.25" overflow="visible">
          <SearchIcon color="$gray.400" />
        </Box>
      )}
      {...rest}
    />
  )
}));



const useTrapFocusState = (props) => {
  const { trapElementRef, onFocusCheck, disabled = false } = props;
  const mounted = useRef(true);
  useEffect(() => { return () => mounted.current = false }, []);

  const [trapFocused, setTrapFocused] = useState(false);
  const updateCallback = useEventCallback((focused) => {
    if (mounted.current) {
      if (onFocusCheck && typeof onFocusCheck === 'function') {
        onFocusCheck(focused ? true : false);
      }
      setTrapFocused(focused ? true : false);
    }
  });
  const checkFocused = useCallback(() => {
    if (!trapElementRef || trapElementRef.current == null || disabled) {
      return;
    }
    try {
      if (document.activeElement instanceof Node
        && (
          trapElementRef.current.contains(document.activeElement)
          || trapElementRef.current === document.activeElement
        )
      ) {
        updateCallback(true);
      } else {
        updateCallback(false);
      }
    } catch (e) {
      // TODO
    }
  }, [disabled, trapElementRef, updateCallback]);

  useEffect(() => {
    const debounceCheckFocused = debounce(() => {
      mounted.current && checkFocused();
    });
    if (canUseDOM && !disabled) {
      checkFocused();
      document.addEventListener('focus', checkFocused, true);
      document.addEventListener('blur', debounceCheckFocused, true);
    }
    return () => {
      document.removeEventListener('focus', checkFocused, true);
      document.addEventListener('blur', debounceCheckFocused, true);
    }
  }, [disabled, checkFocused]);

  return [trapFocused, checkFocused];
}

