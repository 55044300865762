import { useEffect, useState } from 'react'
import { AppState } from 'react-native'

export function useAppState() {
  const currentState = AppState.currentState
  const [appState, setAppState] = useState(currentState)

  function onChange(newState) {
    setAppState(newState)
  }

  useEffect(() => {
    const listener = AppState.addEventListener('change', onChange)

    return () => {
      listener.remove();
    }
  }, [])

  return appState
}

export const useAppWindowState = useAppState;

