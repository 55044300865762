import React from 'react';
import { Transition, withStyles } from '../../styling';
import { Checkbox } from '../Checkbox';
import { IconButton } from '../IconButton';
import { Box } from '../Box';
import { merge } from 'merge-anything';

const Thumb = ({ onColor, color, checked, ...rest }) => {
  return <Box width="100%" height="100%" {...rest} />;
};

const DefaultIcon = <Thumb />;
const DefaultCheckedIcon = <Thumb checked />;

const SwitchButton = withStyles((props) => {
  const { theme, size, small } = props;
  // TODO: create switchbutton size variants. right now only looks at the end padding of iconButton but nothing else changes
  let buttonSize = size;
  if (!size || !theme.iconButton.sizes[size]) {
    if (small) {
      buttonSize = 'small';
    } else {
      buttonSize = 'medium';
    }
  }
  return {
    root: {
      width: 38,
      height: 34,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      overflow: 'visible',
      padding: 0,
    },
    iconButton: {
      position: 'absolute',
      top: 0,
      left: 0,
      overflow: 'visible',
      ...theme.iconButton.sizes[buttonSize],
    },
    thumb: {
      width: 22,
      height: 22,
      borderRadius: '$circle',
      overflow: 'visible',
      shadow: theme.shadows.switch || {
        color: '$gray.800',
        offset: { y: 2 },
        opacity: 0.18,
        radius: 3,
      },
    },
    track: {
      width: '100%',
      height: 22,
      borderRadius: '$circle',
      zIndex: -1,
      opacity: 0.36,
    },
  };
}, {
  name: 'SwitchButton',
  postApply: ({ styles, color, onColor, theme }) => {
    let { width: rootWidth = 0, height: rootHeight = 0 } = styles.root;
    const { width: thumbWidth = 0, height: thumbHeight } = styles.thumb;
    const {
      padding = 0,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
    } = styles.iconButton;

    const iconHeight =
      thumbHeight + (paddingTop || padding) + (paddingBottom || padding);
    const rootPadY =
      iconHeight > rootHeight ? (iconHeight - rootHeight) / 2 : 0;
    let xOffset = 0;
    let rootPadding = { paddingLeft: 0, paddingRight: 0, padY: rootPadY };
    if (padding) {
      xOffset = padding;
      rootPadding = { paddingLeft: padding, paddingRight: padding };
    }
    if (paddingLeft) {
      xOffset = paddingLeft;
      rootPadding.paddingLeft = paddingLeft;
    }
    if (paddingRight) {
      rootPadding.paddingRight = paddingRight;
    }

    rootWidth = rootWidth + rootPadding.paddingLeft + rootPadding.paddingRight;
    const yPos = 0;
    const xPos = xOffset * -1 + rootPadding.paddingLeft;
    const xPosChecked = rootWidth - rootPadding.paddingRight - (thumbWidth + xOffset);

    return {
      root: {
        width: rootWidth,
        height: rootHeight + rootPadY * 2,
        ...rootPadding,
      },
      props: {
        root: {
          yPos,
          yPosChecked: yPos,
          xPos,
          xPosChecked,
          thumbColor: 'rgba(255,255,255, 1)',
          thumbColorChecked: theme.colors.alpha(color || 'rgba(255, 255, 255, 1)', 1),
          trackColor: theme.colors.alpha(onColor || 'rgba(0, 0, 0, 1)', 1),
          trackColorChecked: theme.colors.alpha(color || 'rgba(0, 0, 0, 1)', 1),
        },
      },
    };
  },
})(
  React.forwardRef(function SwitchButton(props, ref) {
    const {
      color,
      onColor,
      disabled,
      onPress,
      children,
      switchIcon,
      switchCheckedIcon,
      styles,
      style,
      TransitionProps,
      xPos = 0,
      xPosChecked = 0,
      yPos,
      yPosChecked,
      thumbColor,
      thumbColorChecked,
      trackColor,
      trackColorChecked,
      ...rest
    } = props;

    const checked = children === 'checked';

    let transition = {
      items: 'switchTransition',
      update: {
        translateX: checked ? xPosChecked : xPos,
        translateY: checked ? yPosChecked : yPos,
        thumbColor: checked ? thumbColorChecked : thumbColor,
        trackColor: checked ? trackColorChecked : trackColor,
      },
      config: {
        clamp: true,
        tension: 500,
        friction: 35,
      },
    };

    transition.initial = transition.update;

    if (TransitionProps) {
      transition = merge(transition, TransitionProps);
    }

    return (
      <Box disabled={disabled} style={style} disableAnimate onPress={onPress}>
        {({ hovered, pressed }) => (
          <Transition {...transition}>
            {(t) => (
              <>
                <IconButton
                  ref={ref}
                  onPress={onPress}
                  disabled={disabled}
                  color={color}
                  style={styles.iconButton}
                  onColor={onColor}
                  animation={{ transform: t.transform }}
                  {...(pressed
                    ? { animate: 'pressed' }
                    : hovered
                    ? { animate: 'hovered' }
                    : null)}
                  {...styles.props.iconButton}
                  {...rest}
                >
                  <Thumb
                    checked={checked}
                    style={styles.thumb}
                    animation={{ backgroundColor: t.thumbColor }}
                    {...styles.props.thumb}
                  />
                </IconButton>
                <Box
                  style={styles.track}
                  animation={{ backgroundColor: t.trackColor }}
                  {...styles.props.track}
                />
              </>
            )}
          </Transition>
        )}
      </Box>
    );
  })
);

const Switch = Checkbox.withProps((props) => {
  const {
    checkedIcon = DefaultCheckedIcon,
    icon = DefaultIcon,
    accessibility,
    ...rest
  } = props;
  return {
    icon: 'notchecked',
    checkedIcon: 'checked',
    ...rest,
    switchIcon: icon,
    switchCheckedIcon: checkedIcon,
    component: SwitchButton,
    accessibility: {
      accessibilityRole: 'switch',
      ...accessibility
    },
  };
}, { name: 'Switch' });

Switch.icon = DefaultIcon;
Switch.checkedIcon = DefaultCheckedIcon;

export { Switch };
