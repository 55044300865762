import React from 'react';
import {
  Box,
  Text,
  Heading,
  Button,
  BackgroundImage,
  Spacing,
  ScrollView,
} from '../../ui';
import { withStyles } from '../../ui/styling';
import { getAddressLines } from '../../utils';
import {
  Page,
  Section,
} from '../../components';
import { useSiteChecklistContext } from './context';
import { useNavigate } from '../../hooks';



export const WelcomePage = withStyles(({ theme }) => ({
  heroSection: {
    width: theme.breakpoints({ sm: '25%', md: '40%' }),
    minWidth: theme.breakpoints({ sm: 320, md: 400 }),
    maxWidth: theme.breakpoints({ sm: '25%', md: '40%' }),
    bg: theme.colors.gray['900'],
    padRight: theme.spacing(3),
    padLeft: theme.spacing(4),
    padTop: 145,
    display: theme.breakpoints({ xs: 'none', sm: 'flex' }),
    minHeight: '100vh',
  },
  contentSection: {
    flex: 1,
    padTop: theme.breakpoints({ xs: 160, sm: theme.spacing(20), md: 0 }),
    padBottom: theme.breakpoints({ xs: 160, sm: 160, md: theme.spacing(10) }),
    padX: theme.breakpoints({ xs: theme.spacing(3), sm: '4%', md: '8%' }),
    maxHeight: '100vh',
    props: {
      layout: { xs: 'top-left', sm: 'center-left' },
      containerStyle: {
        
      },
    },
  },
  heroImage: {
    zIndex: -2,
    props: {
      resizeMode: 'cover',
      src: '/images/dot_roof_cropped.jpg',
      alignImage: {
        y: 'top',
        x: 'center',
      }
    },
  },
  heroImageOverlay: {
    ...theme.layout.absoluteFill,
    bg: theme.colors.gray['900'],
    opacity: 0.15,
    zIndex: -1,
  },
  heading: {
    props: {
      level: 2,
      maxWidth: 600,
      spaceAfter: true,
    },
  },
  paragraph: {
    props: {
      size: 'medium',
      maxWidth: 600,
      spaceAfter: true,
    }
  },
}))(React.forwardRef(function WelcomePage(props, ref) {
  const { styles, ...rest } = props;
  const { bindForm, formPath } = useSiteChecklistContext();
  const navigate = useNavigate();
  const address = bindForm && bindForm.location && bindForm.location.address ? getAddressLines(bindForm.location.address, bindForm.location.region, bindForm.location.postcode) : null;

  return (
    <Page row fixed maxHeight="100vh" ref={ref} {...rest}>
      <Section {...styles.toProps('heroSection')}>
        <Text large color="white">Location</Text>
        {address ? Array.isArray(address) ? (
          address.map((line, i) => (
            <Text bold color="white" key={`a${i}`}>{line}</Text>
          ))
        ) : (
          <Text bold color="white">{address}</Text>
        ) : null}
        <BackgroundImage {...styles.toProps('heroImage')} />
        <Box {...styles.toProps('heroImageOverlay')} />
      </Section>
      <Section {...styles.toProps('contentSection')} component={ScrollView}>
        <Heading {...styles.toProps('heading')}>
          Congratulations!  
        </Heading>
        <Text {...styles.toProps('paragraph')}>Your business is taking control of the cost and hassle of inventory insurance with a game-changing new program from Understory and our trusted partners.</Text>
        <Text {...styles.toProps('paragraph')}>
        Your new property insurance policy is enhanced by Dot, Understory’s weather sensor. When a damaging hailstorm strikes your business, Understory detects it and expedites the claim process. It also alerts you when hailstorms are nearby so you can take action to protect your vehicles. Dot is maintenance-free, solar-powered and requires no drilling.
        </Text>
        <Text {...styles.toProps('paragraph')}>
          First, we just need a few bits of information so that we can schedule the installation.
        </Text>
        <Spacing vertical={3} />
        <Button size="large" minWidth={150} onPress={() => navigate.to({ pathname: formPath })}>Get Started</Button>
        <Spacing vertical={5} />
        <Text {...styles.toProps('paragraph')} italic>
          Is someone else on your team better suited to complete this form? Feel free to share this link with them.
        </Text>
        <Text {...styles.toProps('paragraph')} italic>
          Questions? Reach out to <a href="mailto:support@understoryweather.com" target="_blank">support@understoryweather.com</a> 
        </Text>
      </Section>
    </Page>
  )
}));

