import { useMemo } from "react";
import { useAsync } from "./useAsync";
import { useApi } from "./useApi";
import { capitalize, isObject } from "../ui/utils";

export const useAddProductOptionsToSearchOptions = (searchOptions) => {
  const { getProducts } = useApi();
  const { value: productsData } = useAsync(getProducts);
  
  return useMemo(() => {
    if (productsData && Array.isArray(productsData) && productsData.length) {
      const productOptions = { };
      const productTypeOptions = { };
      for (const p of productsData) {
        if (isObject(p)) {
          const { id, productType, name } = p;
          productOptions[id] = {
            productType,
            name: id,
            value: name,
            label: capitalize(name),
          };
          if (!productTypeOptions[productType]) {
            productTypeOptions[productType] = {
              name: productType,
              value: productType,
              label: capitalize(productType),
            };
          }
        }
      }
      const result = { ...searchOptions, filter: { ...searchOptions.filter } };
      result.filter.product_name.options = productOptions;
      result.filter.product_type.options = productTypeOptions;
      return result;
    }
    return searchOptions;
  }, [productsData, searchOptions]);
}