import React, { useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import { Box, Text, Heading } from '../../ui';
import { withStyles } from '../../ui/styling';
import { capitalize, isObject } from '../../ui/utils';
import {BindingFormTypes, Colors, QuoteStatuses } from '../../constants';
import { useApi, useBindFormStatus, useNavigate, useProgressPropsForBindFormStatus } from '../../hooks';
import {
  TextData,
  Main,
  ColumnsAndSettingsProvider,
  ItemRow,
  Chip,
  ListSearchControls,
  ListSearchDataRenderer,
  ProvideSearchAPIContext,
} from '../../components';
import { getAddressLines } from '../../utils';
import { NULL_STYLE } from '../../ui/system';
import { useEventCallback } from '../../ui/hooks';
import { bindFormSearchOptions } from '../../constants/searchData/searchOptions';


export const BindFormsListView = React.memo(({ data = undefined, searchPath, onSelect = undefined, ...rest }) => {
  const listRef = useRef();
  const { getBindingForms } = useApi();
  const navigate = useNavigate();

  const navigateToForm = React.useCallback((bindForm) => {
    const { quoteId } = bindForm || {};
    console.log('bindForm', bindForm);
    if (quoteId) {
      navigate.to({
        pathname: `/quotes/${quoteId}`,
      })
    }
  }, [navigate]);

  const renderHeader = useMemo(() => (({ height, key, style }) => {
    return (
      <ListSearchControls
        key={key}
        height={height}
        style={style}
        heading="Bind Forms"
        searchPlaceholder="Search bind forms"
      />
    )
  }), []);

  return (
    <Main>
      <ProvideSearchAPIContext
        data={data}
        searchPath={searchPath}
        searchOptions={bindFormSearchOptions}
        getData={getBindingForms}
        appSettingsKey="BindFormsListView"
        cacheKey="bindformslist"
        listRef={listRef}
        compareWithout="url"
      >
        <ColumnsAndSettingsProvider viewKey="BindFormsListView" config={tableColumnsConfig}>
          <ListSearchDataRenderer
            ref={listRef}
            onSelect={onSelect !== undefined ? onSelect : navigateToForm}
            emptyMessage="No forms to show"
            renderHeader={renderHeader}
            tableItemComponent={BindFormRowItem}
            cardItemComponent={BindFormCardItem}
            stickyHeaderEnabled
            scrollNode
            {...rest}
          />
        </ColumnsAndSettingsProvider>
      </ProvideSearchAPIContext>
    </Main>
  )
})

const tableColumnsConfig = [
  {
    label: 'Type',
    value: ({ formTypeLabel, formType }) => formTypeLabel ? formTypeLabel : formType,
    props: { flex: 0.5 },
  },
  {
    label: 'Name',
    value: ({ name }) => name,
    props: { flex: 1 },
  },
  {
    options: ['insured', 'agent.name', 'broker.name'],
    defaultOption: 'insured',
    label: ['Insured', 'Agent', 'Broker'],
    value: ({ insured, agent, broker }, o, index) => [insured, agent.name, broker.name][index],
    defaultValue: 'N/A',
    props: { flex: 1, TextProps: { bold: true } },
  },
  {
    options: ['address', 'region', 'country'],
    defaultOption: 'region',
    label: ['Street Address', 'Region', 'Country'],
    value: ({ address = 'N/A', region, countryCode, postcode }, o, i) => [
      address,
      `${region || 'N/A'}${postcode && region ? ', ' : ''}${postcode || ''}`,
      `${countryCode || 'N/A'}`
    ][i],
    defaultValue: 'N/A',
    props: { flex: 0.8 },
  },
  {
    options: ['requestedAt', 'receivedAt', 'rejectedAt', 'approvedAt'],
    defaultOption: 'requestedAt',
    label: ['Requested', 'Received', 'Rejected', 'Approved'],
    value: ({ requestedAt, receivedAt, rejectedAt, approvedAt }, o, i) => [
      requestedAt ? moment(requestedAt).format('MM-DD-YYYY') : null,
      receivedAt ? moment(receivedAt).format('MM-DD-YYYY') : null,
      rejectedAt ? moment(rejectedAt).format('MM-DD-YYYY') : null,
      approvedAt ? moment(approvedAt).format('MM-DD-YYYY') : null
    ][i],
    defaultValue: 'N/A',
    props: { flex: 0.5 },
  },
  {
    label: 'Status',
    value: ({ statusChipProps }) => statusChipProps ? (
      <Chip {...statusChipProps} />
    ) : null,
    props: { flex: 0.8, contentMaxWidth: null, layout: 'center-right' },
  },
]

const BindFormRowItem = React.forwardRef(function BindFormRowItem(props, ref) {
  const {
    item,
    styles,
    onPress,
    ...rest
  } = props;
  const bindForm = useFormatBindFormData(item);
  const handleOnPress = useEventCallback(() => {
    onPress(item);
  });
  return (
    <ItemRow ref={ref} item={bindForm} onPress={onPress ? handleOnPress : undefined} {...rest}>

    </ItemRow>
  )
})

function useFormatBindFormData(data = {}) {
  let {
    name,
    id,
    formType,
    insured,
    address,
    region,
    postcode,
    country,
    regionCode,
    countryCode,
    url,
    details,
    isUpdatable,
    approvedBy,
    rejectedBy,
    requestedAt,
    approvedAt,
    rejectedAt,
    receivedAt,
    location,
    broker = {},
    agent = {},
  } = data;
  

  const isSubmitted = (!isUpdatable || approvedAt) ? true : false;
  const readyForReview = !isUpdatable && !approvedAt
  const accepted = approvedAt
  const rejected = !approvedAt && rejectedAt;
  const hasRejected = rejectedAt ? true : false;
  const waitingOnClient = !isSubmitted && !readyForReview && !accepted;
  const waitingOnClientReview = waitingOnClient && hasRejected;

  const formStatus = useBindFormStatus(data);
  const [progress, statusChipProps] = useProgressPropsForBindFormStatus(formStatus);

  if (isObject(location)) {
    if (!address && location.address) {
      address = location.address;
    }
    if (!region && location.region) {
      region = location.region;
    }
    if (!postcode && location.postcode) {
      postcode = location.postcode;
    }
    if (!regionCode && location.regionCode) {
      regionCode = location.regionCode;
    }
    if (!country && location.country) {
      country = location.country;
    }
    if (!countryCode && location.countryCode) {
      countryCode = location.countryCode;
    }
  }
  let addressLines = getAddressLines(address, region, postcode);
  if (!address && !region && !postcode) {
    addressLines = ['Address Missing', 'Unknown Region'];
  }

  const [formTypeLabel, formTypeColor] = useMemo(() => {
    if (formType === BindingFormTypes.siteChecklist) {
      return ['Site Checklist', '#D0ECE7'];
    } else if (formType === BindingFormTypes.leaseAgreement) {
      return ['Lease Agreement', '#EAF2F8'];
    } else {
      return ['Other', '$gray.100'];
    }
  }, [formType]);

  const agentName = agent && agent.name ? agent.name.split('@')[0] : agent && agent.email ? agent.email.split('@')[0] : 'N/A';
  const brokerName = broker && broker.name ? broker.name : 'N/A';

  let locName = name || '';
  if (name.startsWith('Lease Agreement - ')) {
    let split = name.split('Lease Agreement - ');
    if (split.length) {
      locName = split[1];
    }
  } else if (name.startsWith('Site checklist: ')) {
    let split = name.split('Site checklist: ');
    if (split.length) {
      locName = split[1];
    }
  }
  return {
    ...data,
    address,
    region,
    postcode,
    regionCode,
    country,
    countryCode,
    name: locName,
    agent: {
      ...agent,
      name: agentName,
    },
    broker: {
      ...broker,
      name: brokerName,
    },
    formType,
    formTypeLabel,
    formTypeColor,
    progress,
    statusChipProps,
    addressLines,
    isSubmitted,
    readyForReview,
    accepted,
    rejected,
    hasRejected,
    waitingOnClient,
    waitingOnClientReview,
  };
}

const BindFormCardItem = withStyles(({ theme }) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.colors.opacity('$gray.300', 0.3),
    px: theme.spacing(3),
    py: theme.spacing(2.75),
    bg: theme.colors.white,
  },
  progressOverlay: {
    ...theme.layout.absoluteFill,
    bottom: 0,
    top: null,
    height: 3,
    borderRadius: 6,
  },
  topContainer: {
    flexDirection: theme.breakpoints({ xs: 'column', sm: 'row' }),
    justifyContent: theme.breakpoints({ xs: 'flex-start', sm: 'space-between' }),
  },
  statusChips: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    padBottom: theme.spacing(1),
    padTop: theme.spacing(0.5),
    props: {
      gap: 8
    },
  },
  contentLeft: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 1.25 }),
    props: {
      gap: 0
    },
  },
  contentRight: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 0.75 }),
    justifyContent: 'flex-start',
    alignItems: theme.breakpoints({ xs: 'flex-start', sm: 'flex-end' }),
    props: {
      gap: 12
    },
    padTop: theme.spacing(1),
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    padTop: theme.spacing(1.5),
  },
  bottomSpacing: {
    flexGrow: 0.1,
    flexShrink: 0,
    flexBasis: '10%',
  },
  bottomContent: {
    flexGrow: 0.9,
    flexShrink: 0,
    flexBasis: 'auto',
  },
}))(React.forwardRef(function BindFormItem(props, ref) {
  const { item: data, styles, onPress, ...rest } = props;
  const {
    insured,
    requestedAt,
    approvedAt,
    rejectedAt,
    receivedAt,
    formType,
    formTypeLabel,
    formTypeColor,
    statusChipProps,
    addressLines,
  } = useFormatBindFormData(data);

  const handleOnPress = useEventCallback(() => {
    onPress(data);
  });

  let addressBlock;

  if (formType !== BindingFormTypes.leaseAgreement) {
    addressBlock = Array.isArray(addressLines) ?
      addressLines.map((a, i) => (
        <Text small key={`aline${i}`} maxLines={1}>{a}</Text>
      )) : <Text small maxLines={2}>{addressLines}</Text>;
  }

  return (
    <Box ref={ref} onPress={onPress ? handleOnPress : undefined} {...rest}>
      <Box style={styles.topContainer} {...styles.props.topContainer}>
        <Box style={styles.contentLeft} {...styles.props.contentLeft}>
          <Box style={styles.statusChips} {...styles.props.statusChips}>
            <Chip color={formTypeColor}>{formTypeLabel}</Chip>
          </Box>
          <Heading level={4} maxLines={1}>{capitalize(insured)}</Heading>
          {addressBlock}
          <Box flex={1} justifyContent="flex-end">
          <Text small bold uppercase>{''}</Text>
          </Box>
        </Box>
        <Box style={styles.contentRight} {...styles.props.contentRight}>
          <Chip {...statusChipProps} />
          {requestedAt ? (
            <TextData
            label="Requested on"
            data={moment(requestedAt).format('MM-DD-YYYY')}
            alignItems="inherit"
            size="small"
          />
          ) : null}
          <TextData
            label="Last Updated"
            data={moment(
              approvedAt ? approvedAt :
              rejectedAt ? rejectedAt :
              receivedAt ? receivedAt :
              requestedAt
            ).format('MM-DD-YYYY')}
            alignItems="inherit"
            size="small"
            opacity={0.8}
          />
        </Box>
      </Box>
      {
      //   <Box style={styles.bottomContainer} {...styles.props.bottomContainer}>
      //   <Box style={styles.bottomContent} {...styles.props.bottomContent}>
      //   </Box>
      // </Box>
      }
      
    </Box>
  )
}))

