import React, { useCallback, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import { Box, Text, Heading } from '../../ui';
import { withStyles } from '../../ui/styling';
import { capitalize } from '../../ui/utils';
import {
  TextData,
  DataListRenderer,
  Main,
  Chip,
  ItemRow,
  ITEM_ROW_HEIGHT,
  ColumnsAndSettingsProvider,
  ProvideSearchAPIContext,
  ListSearchDataRenderer,
  ListSearchControls
} from '../../components';
import { useNavigate, useApi, useAddProductOptionsToSearchOptions } from '../../hooks';
import { Colors, QuoteStatuses, ListDisplayTypes } from '../../constants';
import { apiHelpers, formatMoney } from '../../utils';
import { NULL_STYLE } from '../../ui/system';
import { useEventCallback } from '../../ui/hooks';
import { quoteSearchOptions } from '../../constants/searchData/searchOptions';

const tableColumnsConfig = [
  {
    label: 'No.',
    value: ({ quoteNumber }) => `#${quoteNumber}`,
    props: { flex: 0.3, TextProps: { bold: true } },
  },
  {
    options: ['createdAt', 'lastChangeAt'],
    defaultOption: 'lastChangeAt',
    label: ['Created', 'Last Updated'],
    value: ({ createdAt, lastChangeAt }, option) => (
      option === 'createdAt' && createdAt ? moment.utc(createdAt).format('MM-DD-YYYY')
      : option === 'lastChangeAt' && lastChangeAt ? moment.utc(lastChangeAt).format('MM-DD-YYYY')
      : createdAt ? moment.utc(createdAt).format('MM-DD-YYYY') : 'N/A'
    ),
    props: { flex: 0.5 },
  },
  {
    label: 'Insured',
    value: ({ insured }) => insured ? insured : 'N/A',
    props: { flex: 1 },
  },
  {
    label: 'Product',
    value: ({ product }) => product ? product.labels.name : 'N/A',
    props: { flex: 0.8 },
  },
  {
    options: ['coverageAt', 'coverageUntil'],
    defaultOption: 'coverageAt',
    label: ['Coverage Start', 'Coverage End'],
    value: ({ coverageAt, coverageUntil }, option) => (
      option === 'coverageAt' && coverageAt ? moment.utc(coverageAt).format('MM-DD-YYYY')
      : option === 'coverageUntil' && coverageUntil ? moment.utc(coverageUntil).format('MM-DD-YYYY')
      : 'N/A'
    ),
    props: { flex: 0.5, TextProps: { bold: true } },
  },
  {
    label: 'Status',
    value: ({ statusChips }) => {
      return Array.isArray(statusChips) && statusChips.length ? (
        <Chip color={statusChips[0].color ? statusChips[0].color : undefined}>{statusChips[0].text}</Chip>
      ) : null;
    },
    props: { flex: 0.55, layout: 'center-right' },
  }
];


const QuotesListView = React.memo(({
  quotes = undefined,
  onSelect = undefined,
  searchPath,
  ...rest
}) => {
  const listRef = useRef();
  const { getQuotes } = useApi();
  const navigate = useNavigate();

  const navigateToQuote = useCallback(({ id } = {}) => {
    if (id) {
      navigate.to({
        pathname: `/quotes/${id}`,
      });
    }
  }, [navigate]);

  const renderHeader = useMemo(() => (({ height, key, style }) => {
    return (
      <ListSearchControls
        key={key}
        height={height}
        style={style}
        heading="Quotes"
        searchPlaceholder="Search for quotes"
      />
    );
  }), []);
  
  const searchOptions = useAddProductOptionsToSearchOptions(quoteSearchOptions);
  
  return (
    <Main>
      <ProvideSearchAPIContext
        data={quotes}
        searchPath={searchPath}
        searchOptions={searchOptions}
        getData={getQuotes}
        appSettingsKey="QuotesListView"
        cacheKey="quotelist"
        compareWithout="url"
        listRef={listRef}
      >
        <ColumnsAndSettingsProvider viewKey="QuotesListView" config={tableColumnsConfig}>
          <ListSearchDataRenderer
            ref={listRef}
            onSelect={onSelect !== undefined ? onSelect : navigateToQuote}
            emptyMessage="No quotes to show"
            renderHeader={renderHeader}
            tableItemComponent={QuoteRowItem}
            cardItemComponent={QuoteCardItem}
            stickyHeaderEnabled
            scrollNode
            {...rest}
          />
        </ColumnsAndSettingsProvider>
      </ProvideSearchAPIContext>
    </Main>
  )
});

const FILTERS_BAR_HEIGHT = 55;

const QuoteList = React.forwardRef((props, ref) => {
  const { styles, displayType = ListDisplayTypes.CARDS, ...rest } = props;
  const Component = displayType === ListDisplayTypes.TABLE ? QuoteRowItem : QuoteCardItem;
  return (
    <DataListRenderer
      ref={ref}
      itemComponent={Component}
      emptyMessage="No quotes to show"
      itemPath="/quotes"
      displayType={displayType}
      styles={[
        {
          root: {
            minHeight: 400,
            padTop: ({ theme }) => theme.sizes.appBarHeight + theme.spacing(1),
          },
          item: {
            height: displayType === ListDisplayTypes.CARDS ? ({ theme }) => theme.breakpoints({ xs: 290, sm: 186 }) : ITEM_ROW_HEIGHT,
            mb: displayType === ListDisplayTypes.CARDS ? '$3' : 0
          },
          listLoading: {
            justifyContent: 'center',
          },
          header: {
            height: ({ theme }) => theme.breakpoints({
              xs: 186 + theme.sizes.appBarHeight + theme.spacing(1),
              sm: 154 + theme.sizes.appBarHeight + FILTERS_BAR_HEIGHT + (displayType === ListDisplayTypes.TABLE ? ITEM_ROW_HEIGHT : 0),
            }),
          },
          
        },
        styles,
      ]}
      {...rest}
    />
  );
  
});

const stored = {};
const useFormatQuoteData = (quote = {}) => {
  const {
    id,
    status,
    product: p,
    createdAt,
    locations,
    lastChangeAt
  } = quote;
  if (id) {
    if (stored[id]) {
      if (stored[id].lastChangeAt === lastChangeAt) {
        return stored[id];
      }
    }
  }
  const product = apiHelpers.resolveProduct(p);

  const numLocations = locations.length;
  const regions = [];
  let totalValue = 0;
  for (const location of locations) {
    if (location.region && !regions.includes(location.region)) {
      regions.push(location.region);
    }
    if (location.coveredValue) {
      totalValue += location.coveredValue;
    }
  }

  const { expired, text: expirationText } = apiHelpers.getQuoteExpiration({ createdAt, status })

    // first chip defaults (main state of application)
  let chips = [];
  let progress = null;

  if (status === QuoteStatuses.pending) {
    // TODO: make progress bar more than 25% if previous releases occurred
    progress = '25%';
    chips.push({
      text: 'Pending',
      color: Colors.quoteStatus.pending,
    });
  } else if (status === QuoteStatuses.released) {
    progress = '50%';
    chips.push({
      text: 'Released',
      color: Colors.quoteStatus.released,
    });
  } else if (status === QuoteStatuses.prebound) {
    progress = '75%';
    chips.push({
      text: 'Prebound',
      color: Colors.quoteStatus.prebound,
    });
  } else if (status === QuoteStatuses.bound) {
    progress = '100%';
    chips.push({
      text: 'Bound',
      color: Colors.quoteStatus.bound,
    });
  }

  if (status !== QuoteStatuses.bound) {
    if (status === QuoteStatuses.rejected) {
      progress = "0%";
      chips = [{
        text: 'Rejected',
        color: Colors.quoteStatus.rejected,
      }];
    } else if (status === QuoteStatuses.expired) {
    // } else if (expired) { // TODO figure out who's in charge of expired. Front end or back end? it's not really a status if front end is deriving it.
      progress = "0%";
      chips = [{
        text: expirationText,
        color: Colors.quoteStatus.expired,
      }];
    } else {
      chips.push({
        text: expirationText,
        color: '$gray.100',
      })
    }
  }

  const result = {
    ...quote,
    product,
    numLocations,
    regions,
    totalValue,
    statusChips: chips,
    progress,
  }

  if (id) {
    stored[id] = result;
  }

  return result;
}

const QuoteRowItem = React.forwardRef(function QuoteRowItem(props, ref) {
  const {
    item,
    styles,
    onPress,
    ...rest
  } = props;
  const quote = useFormatQuoteData(item);
  const {
    statusChips,
    progress,
  } = quote;

  const progressBar = useMemo(() => (
    <Box
        sx={theme => ({
          ...theme.layout.absoluteFill,
          bottom: 0,
          top: null,
          height: 3,
          borderRadius: 6,
        })}
        bg={progress && statusChips.length ? statusChips[0].color : 'transparent'}
        maxWidth={progress}
      />
  ), [statusChips, progress])

  const handleOnPress = useEventCallback(() => {
    onPress(quote);
  });

  return (
    <ItemRow ref={ref} item={quote} onPress={onPress ? handleOnPress : undefined} {...rest}>
      {progressBar}
    </ItemRow>
  )
})




const QuoteCardItem = withStyles(({ theme }) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.colors.opacity('$gray.300', 0.3),
    px: theme.spacing(3),
    py: theme.spacing(2.75),
    bg: theme.colors.white,
    // shadow: theme.shadows.elevation(30, { opacity: 0.1 }),
    props: {
      gap: 0
    }
  },
  progressOverlay: {
    ...theme.layout.absoluteFill,
    bottom: 0,
    top: null,
    height: 3,
    borderRadius: 6,
  },
  topContainer: {
    flexDirection: theme.breakpoints({ xs: 'column', sm: 'row' }),
    justifyContent: theme.breakpoints({ xs: 'flex-start', sm: 'space-between' }),
  },
  statusChips: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    padBottom: theme.spacing(1),
    padTop: theme.spacing(0.5),
    props: {
      gap: 8
    },
  },
  contentLeft: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 1.25 }),
    props: {
      gap: 0
    },
  },
  contentRight: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 0.75 }),
    justifyContent: 'flex-start',
    alignItems: theme.breakpoints({ xs: 'flex-start', sm: 'flex-end' }),
    props: {
      gap: 12
    },
    padTop: theme.spacing(1),
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    padTop: theme.spacing(1.5),
  },
  bottomSpacing: {
    flexGrow: 0.1,
    flexShrink: 0,
    flexBasis: '10%',
  },
  bottomContent: {
    flexGrow: 0.9,
    flexShrink: 0,
    flexBasis: 'auto',
  },
}))(React.forwardRef(function QuoteCardItem(props, ref) {
  const {
    item,
    styles,
    onPress,
    ...rest
  } = props;
  const quote = useFormatQuoteData(item);
  const {
    id,
    quoteNumber,
    createdAt,
    lastChangeAt,
    insured,
    coverageAt,
    product,
    numLocations,
    regions,
    totalValue,
    statusChips,
    progress,
  } = quote;

  const productDisplayName = product ? product.labels.combined : '';

  const handleOnPress = useEventCallback(() => {
    onPress(quote);
  });
  
  return (
    <Box ref={ref} onPress={onPress ? handleOnPress : undefined} {...rest}>
      <Box style={styles.topContainer} {...styles.props.topContainer}>
        <Box style={styles.contentLeft} {...styles.props.contentLeft}>
          <Box style={styles.statusChips} {...styles.props.statusChips}>
            {statusChips.map(({ text, ...p }, i) => (
              <Chip key={`${id}chip${i}`}{...p}>{text}</Chip>
            ))}
          </Box>
          <Text size="xSmall" color="$gray.400" style={{ fontWeight: 500 }} uppercase>{`Quote No.  ${quoteNumber}`}</Text>
          <Heading level={4}>{capitalize(insured)}</Heading>
          <Text small bold>{productDisplayName}</Text>
        </Box>
        <Box style={styles.contentRight} {...styles.props.contentRight}>
          <TextData
            label="Target Coverage Date"
            data={moment.utc(coverageAt).format('MM-DD-YYYY')}
            alignItems="inherit"
          />
          <TextData
            label={lastChangeAt ? "Last Updated" : "Created"}
            data={moment.utc(lastChangeAt ? lastChangeAt : createdAt).format('MM-DD-YYYY')}
            alignItems="inherit"
            size="small"
            opacity={0.8}
          />
        </Box>
      </Box>
      <Box style={styles.bottomContainer} {...styles.props.bottomContainer}>
        <Box style={styles.bottomContent} {...styles.props.bottomContent}>
          <Text small>{`${numLocations} Locations`}</Text>
        </Box>
        {regions.length && (
          <>
            <Box style={styles.bottomSpacing} {...styles.props.bottomSpacing} />
            <Box style={styles.bottomContent} {...styles.props.bottomContent}>
              <Text small>{regions.map((r, i) => (
                i > 0 ? `, ${r}` : r
              ))}</Text>
            </Box>
          </>
        )}
        {
          totalValue && (
            <>
              <Box style={styles.bottomSpacing} {...styles.props.bottomSpacing} />
              <Box style={styles.bottomContent} {...styles.props.bottomContent}>
                <Text small>{`$${formatMoney(totalValue)}`}</Text>
              </Box>
            </>
          )
        }
      </Box>
      <Box
        style={styles.progressOverlay}
        {...styles.props.progressOverlay}
        bg={progress ? statusChips[0].color : '$gray.300'}
        maxWidth={progress}
      />
    </Box>
  )
}));

export { QuotesListView, QuoteList }
