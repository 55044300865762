import React, { useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { Box, Text, Heading, useBreakpoint, ActivityIndicator } from '../../ui';
import { getStyle, withStyles } from '../../ui/styling';
import { capitalize, omit } from '../../ui/utils';
import { ListViewHeader, TextData, SearchFilterInput, DataListRenderer, ListHead, ListHeadRow, ListHeading, Main, ColumnsAndSettingsProvider, ListDisplayTypeButton, FilterControlButton, FilterMenu, ItemRow, ITEM_ROW_HEIGHT, ListSearchControls, ProvideSearchAPIContext, ListSearchDataRenderer } from '../../components';
import { useApi, useNavigate, useAsyncSearch, useAppState, useAddProductOptionsToSearchOptions } from '../../hooks';
import { policySortOptions, policyFilterOptions, ProductNames, ProductTypes, ListDisplayTypes, filterOperators } from '../../constants';
import { apiHelpers, formatMoney, getAddressLines } from '../../utils';
import { NULL_STYLE } from '../../ui/system';
import { useEventCallback } from '../../ui/hooks';
import { policySearchOptions } from '../../constants/searchData/searchOptions';
const { Controls, ControlGroup, SortButton } = ListViewHeader;

/*
product, carrier.name
insured, agent.name, broker.name
address + region, region + countryCode, postcode
coveredValue, netPremium, numLocations, boundAt
effectiveAt
effectiveUntil
payoutsEnabled (active/inactive)
*/
let lastSearchString = '';
const FILTERS_BAR_HEIGHT = 55;

const tableColumnsConfig = [
  {
    options: ['product', 'carrier'],
    defaultOption: 'product',
    label: ['Product', 'Carrier'],
    value: ({ product, carrier }, o, i) => [product.labels.name, carrier.name][i],
    defaultValue: 'N/A',
    props: { flex: 0.8 },
  },
  {
    options: ['insured', 'agent.name', 'broker.name'],
    defaultOption: 'insured',
    label: ['Insured', 'Agent', 'Broker'],
    value: ({ insured, agent, broker }, o, index) => [insured, agent.name, broker.name][index],
    defaultValue: 'N/A',
    props: { flex: 1, TextProps: { bold: true } },
  },
  {
    options: ['address', 'region', 'country'],
    defaultOption: 'region',
    label: ['Street Address', 'Region', 'Country'],
    value: ({ address = 'N/A', region, countryCode, postcode }, o, i) => [
      address,
      `${region || 'N/A'}${postcode && region ? ', ' : ''}${postcode || ''}`,
      `${region || 'N/A'}${countryCode && region ? ', ' : ''}${countryCode || ''}`][i],
    defaultValue: 'N/A',
    props: { flex: 0.8 },
  },
  {
    options: ['coveredValue', 'netPremium', 'numLocations', 'boundAt'],
    defaultOption: 'coveredValue',
    label: ['Covered Val.', 'Premium', 'Num. Locations', 'Bound at'],
    value: ({ coveredValue, numLocations, netPremium, boundAt }, o, i) => [
      coveredValue ? `$${formatMoney(coveredValue)}` : 'N/A',
      netPremium ? `$${formatMoney(netPremium)}` : 'N/A',
      `${numLocations} Locations`,
      boundAt ? moment(boundAt).format('MM-DD-YYYY') : 'N/A',
    ][i],
    props: { flex: 0.8 },
  },
  {
    label: 'Start Date',
    value: ({ effectiveAt }) => effectiveAt ? moment(effectiveAt).format('MM-DD-YYYY') : 'N/A',
    props: { flex: 0.5 },
  },
  {
    label: 'End Date',
    value: ({ effectiveUntil }) => effectiveUntil ? moment(effectiveUntil).format('MM-DD-YYYY') : 'N/A',
    props: { flex: 0.5 },
  },
  {
    label: 'Active',
    value: ({ payoutsEnabled }) => <ActiveStatus payoutsEnabled={payoutsEnabled} />,
    props: { flex: 0.5 },
  }
];


export const PoliciesListView = ({ data = undefined, searchPath, onSelect = undefined, ...rest }) => {
  const listRef = useRef();
  const { getPolicies } = useApi();
  const navigate = useNavigate();

  const navigateToPolicy = React.useCallback((policy) => {
    if (policy) {
      navigate.to(`/policies/${policy.id}`);
    }
  }, [navigate]);

  const renderHeader = useMemo(() => ((renderProps) => {
    return (
      <ListSearchControls
        heading="Policies"
        searchPlaceholder="Search for policies"
        {...renderProps}
      />
    );
  }), []);

  const searchOptions = useAddProductOptionsToSearchOptions(policySearchOptions);

  return (
    <Main>
      <ProvideSearchAPIContext
        data={data}
        searchPath={searchPath}
        searchOptions={searchOptions}
        getData={getPolicies}
        appSettingsKey="PoliciesListView"
        cacheKey="policylist"
        listRef={listRef}
      >
        <ColumnsAndSettingsProvider viewKey="PoliciesListView" config={tableColumnsConfig}>
          <ListSearchDataRenderer
            ref={listRef}
            onSelect={onSelect !== undefined ? onSelect : navigateToPolicy}
            emptyMessage="No policies to show"
            renderHeader={renderHeader}
            tableItemComponent={PolicyRowItem}
            cardItemComponent={PolicyListItem}
            stickyHeaderEnabled
            scrollNode
            {...rest}
          />
        </ColumnsAndSettingsProvider>
      </ProvideSearchAPIContext>
    </Main>
  )
}


// item xs: 296 and 316, sm: 186
export const PolicyList = React.forwardRef((props, ref) => {
  const { styles, displayType = ListDisplayTypes.CARDS, ...rest } = props;
  const Component = displayType === ListDisplayTypes.TABLE ? PolicyRowItem : PolicyListItem;
  return (
    <DataListRenderer
      ref={ref}
      itemComponent={Component}
      emptyMessage="No policies to show"
      displayType={displayType}
      itemPath="/policies"
      styles={[
        {
          root: {
            minHeight: 400,
            padTop: ({ theme }) => theme.sizes.appBarHeight + theme.spacing(1),
          },
          item: {
            height: displayType === ListDisplayTypes.CARDS ? ({ theme }) => theme.breakpoints({ xs: 312, sm: 186 }) : ITEM_ROW_HEIGHT,
            mb: displayType === ListDisplayTypes.CARDS ? '$3' : 0
          },
          listLoading: {
            justifyContent: 'center',
          },
          header: {
            height: ({ theme }) => theme.breakpoints({
              xs: 186 + theme.sizes.appBarHeight + theme.spacing(1),
              sm: 154 + theme.sizes.appBarHeight + FILTERS_BAR_HEIGHT + (displayType === ListDisplayTypes.TABLE ? ITEM_ROW_HEIGHT : 0),
            }),
          }
        },
        styles,
      ]}
      {...rest}
    />
  )
});

const PolicyRowItem = React.forwardRef(function PolicyRowItem(props, ref) {
  const {
    item,
    styles,
    onPress,
    ...rest
  } = props;
  const handleOnPress = useEventCallback(() => {
    onPress(item);
  });
  const policy = useFormatPolicyData(item);

  return <ItemRow ref={ref} item={policy} onPress={onPress ? handleOnPress : undefined} {...rest} />;
})

function useFormatPolicyData(policy = {}) {
  const {
    id,
    insured,
    product: p,
    carrier,
    effectiveAt,
    effectiveUntil,
    address,
    region,
    postcode,
    locations = [],
    payoutsEnabled,
    agent,
    broker,
  } = policy;

  const product = useMemo(() => {
    return apiHelpers.resolveProduct(p);
  }, [p])

  const addressLines = getAddressLines(address, region, postcode);

  const agentName = agent && agent.name ? agent.name.split('@')[0] : agent && agent.email ? agent.email.split('@')[0] : 'N/A';
  const brokerName = broker && broker.name ? broker.name : 'N/A';
  const carrierName = carrier && carrier.name ? carrier.name : 'N/A';
  return {
    ...policy,
    agent: {
      ...agent,
      name: agentName,
    },
    broker: {
      ...broker,
      name: brokerName,
    },
    carrier: {
      ...carrier,
      name: carrierName,
    },
    product,
    addressLines,
  };
}

export const PolicyListItem = withStyles(({ theme }) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.colors.opacity('$gray.300', 0.3),
    px: theme.spacing(3),
    py: theme.spacing(2.75),
    bg: theme.colors.white,
  },
  progressOverlay: {
    ...theme.layout.absoluteFill,
    bottom: 0,
    top: null,
    height: 3,
    borderRadius: 6,
  },
  topContainer: {
    flexDirection: theme.breakpoints({ xs: 'column', sm: 'row' }),
    justifyContent: theme.breakpoints({ xs: 'flex-start', sm: 'space-between' }),
  },
  statusChips: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    padBottom: theme.spacing(1),
    padTop: theme.spacing(0.5),
    props: {
      gap: 8
    },
  },
  contentLeft: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 1.25 }),
    props: {
      gap: 0
    },
  },
  contentRight: {
    flex: theme.breakpoints({ xs: NULL_STYLE, sm: 0.75 }),
    justifyContent: 'flex-start',
    alignItems: theme.breakpoints({ xs: 'flex-start', sm: 'flex-end' }),
    props: {
      gap: 12
    },
    padTop: theme.spacing(1),
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flex: 1,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    padTop: theme.spacing(1.5),
  },
  bottomSpacing: {
    flexGrow: 0.1,
    flexShrink: 0,
    flexBasis: '10%',
  },
  bottomContent: {
    flexGrow: 0.9,
    flexShrink: 0,
    flexBasis: 'auto',
  },
}))(React.forwardRef(function PolicyListItem(props, ref) {
  const { item: data, styles, onPress, ...rest } = props;
  const {
    id,
    insured,
    product,
    addressLines,
    carrier,
    effectiveAt,
    effectiveUntil,
    address,
    region,
    postcode,
    locations = [],
    payoutsEnabled,
    agent,
    broker,
  } = useFormatPolicyData(data);

  const handleOnPress = useEventCallback(() => {
    onPress(data);
  });

  return (
    <Box ref={ref} onPress={onPress ? handleOnPress : undefined} {...rest}>
      <Box style={styles.topContainer} {...styles.props.topContainer}>
        <Box style={styles.contentLeft} {...styles.props.contentLeft}>
        <ActiveStatus payoutsEnabled={payoutsEnabled} />
          <Heading level={4} maxLines={1}>{capitalize(insured)}</Heading>
          {Array.isArray(addressLines) ?
            addressLines.map((a, i) => (
              <Text small key={`aline${i}`} maxLines={1}>{a}</Text>
            )) : <Text small maxLines={2}>{addressLines}</Text>}
          <Box flex={1} justifyContent="flex-end">
            
          
          <Text small bold uppercase>{product.labels.name}</Text>
          </Box>
        </Box>
        <Box style={styles.contentRight} {...styles.props.contentRight}>
          
        <Text large bold>{`${locations.length} Location${locations.length > 1 ? 's' : ''}`}</Text>
          <TextData
            label="Effective From"
            data={moment(effectiveAt).format('MM-DD-YYYY')}
            alignItems="inherit"
            size="small"
          />
          <TextData
            label="Effective Until"
            data={moment(effectiveUntil).format('MM-DD-YYYY')}
            alignItems="inherit"
            size="small"
            opacity={0.8}
          />
        </Box>
      </Box>
      {
      //   <Box style={styles.bottomContainer} {...styles.props.bottomContainer}>
      //   <Box style={styles.bottomContent} {...styles.props.bottomContent}>
      //   </Box>
      // </Box>
      }
      
    </Box>
  )
}))

const ActiveStatus = ({ payoutsEnabled }) => {
  return (
    <Box flexDirection="row" alignItems="center" gap={12}>
      <Box size="10" bg={payoutsEnabled ? '$secondary' : '$coral'} borderRadius="$circle" />
      <Text
        size="small"
        color={payoutsEnabled ? '$secondary' : '$coral'}
        style={{ fontWeight: 600 }}
        padTop={2}
        uppercase
      >
        {payoutsEnabled ? 'Active' : 'Inactive'}
      </Text>
    </Box>
  )
}
