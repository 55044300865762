import React from 'react';
import { Box, Text } from '../../ui';

const SectionSeperator = ({ label, lineColor = '$gray.200' }) => {
  return (
    <Box
      sx={{ width: '100%', alignSelf: 'stretch', minHeight: 8, padTop: '$6', padBottom: '$0.5', mb: '$1.5', border: { bottom: { width: 1, color: lineColor ? lineColor : '$gray.200' }}}}
    >
      {label ? <Text large bold>{label}</Text> : null}
    </Box>
  )
}

const RowLayout = (props) => {
  return (
    <Box
      sx={theme => ({
        flexDirection: theme.breakpoints({ sm: 'column', md: 'row' }),
        alignItems: theme.breakpoints({ sm: 'stretch', md: 'flex-start' }),
        alignSelf: 'stretch',
      })}
      gap={({ theme }) => theme.breakpoints({ sm: 0, md: 8 })}
      {...props}
    />
  )
}

export { SectionSeperator, RowLayout }