import equal from 'fast-deep-equal/es6';
import deepClone from 'lodash.clonedeep';
import { isNull, isObject } from '../ui/utils';

export function equalWithout(toIgnore, compareFunc = equal) {
  if (!toIgnore) {
    return compareFunc;
  }
  const ignore = Array.isArray(toIgnore) ? toIgnore : [toIgnore];
  const compareWithout = (itemA, itemB) => {
    let a = itemA;
    let b = itemB;
    if (!isNull(itemA) && typeof itemA === 'object') {
      a = deepClone(itemA);
      deepNullProps(a, ignore);
    }
    if (!isNull(itemB) && typeof itemB === 'object') {
      b = deepClone(itemB);
      deepNullProps(b, ignore);
    }
    return compareFunc(a, b);
  }
  return compareWithout;
}

function deepNullProps(item, props = []) {
  if (Array.isArray(item)) {
    for (const thing of item) {
      deepNullProps(thing, props);
    }
  } else if (isObject(item)) {
    const keys = Object.keys(item);
    for (const key of keys) {
      if (props.includes(key)) {
        item[key] = null;
      } else if (Array.isArray(item[key]) || isObject(item[key])) {
        deepNullProps(item[key], props);
      }
    }
  }
}