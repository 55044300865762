import React from 'react';
import { Box } from '../Box';
import { Image } from '../Image';
import { localeUtils } from '../../utils';

export const CountryFlag = React.forwardRef(function CountryFlag(props, ref) {
  const { countryCode, ImageProps, ...rest } = props;
  return (
    <Box
      ref={ref}
      justifyContent="center"
      alignItems="center"
      width={30}
      {...rest}
    >
      <FlagImage countryCode={countryCode} {...ImageProps} />
    </Box>
  )
})

const FlagImage = React.memo(function FlagImage(props) {
  const {
    resizeMode = 'contain',
    width = 25,
    height = 19,
    borderWidth = 1,
    borderColor = 'white',
    opacity = 0.8,
    countryCode = 'US',
    ...rest
  } = props;

  return (
    <Image
      resizeMode={resizeMode}
      sx={{
        width,
        height,
        borderWidth,
        borderColor,
        opacity,
      }}
      src={{ uri: localeUtils.getImageFlag(countryCode) }}
      {...rest}
    />
  );
})