import React, { useRef } from 'react';
import {
  ListHead,
  ITEM_ROW_HEIGHT,
  ListHeadRow,
  ListHeading,
  ListViewHeader,
  SearchFilterInput,
  ListDisplayTypeButton,
  ItemRow,
} from '../ListView';
import { ListDisplayTypes } from '../../constants';
import { ActivityIndicator, Breakpoint } from '../../ui';
import { getStyle } from '../../ui/styling';
import { useSearchApiContext, FILTERS_BAR_HEIGHT } from './contexts';
import { SearchBoxFilterButton } from './SearchBoxFilterButton';
const { Controls, ControlGroup, SortButton } = ListViewHeader;

export const ListSearchControls = React.memo(function ListSearchControls(props) {
  const {
    height,
    style: styleProp,
    heading = '',
    searchPlaceholder = '',
    headingRight = undefined
  } = props;

  const {
    loadingFromCache,
    displayType,
    initialConfig,
    toggleDisplayType,
    searchOptions,
    saveSettings,
  } = useSearchApiContext();

  const style = getStyle(styleProp);
  const isTableDisplay = displayType === ListDisplayTypes.TABLE;

  const initialProps = useRef(null);
  if (!initialProps.current) {
    initialProps.current = {};
    if (searchOptions.searchKeys.length) {
      if (initialConfig.current && initialConfig.current.search && initialConfig.current.search.length) {
        initialProps.current = initialConfig.current.search[0];
      } else {
        initialProps.current = {
          field: searchOptions.search[searchOptions.searchKeys[0]].name,
          value: '',
        }
      }
    }
  }
  return (
    <Breakpoint>
      {(breakpoint) => (
        <>
          <ListHead
            {...style}
            bg="$white"
            height={height - FILTERS_BAR_HEIGHT - (isTableDisplay ? ITEM_ROW_HEIGHT : 0)}
            zIndex={1200}
          >
            <ListHeadRow justifyContent={headingRight ? "space-between" : null}>
              <ListHeading>
                {heading}
              </ListHeading>
              {breakpoint.key === 'xs' ? <ActivityIndicator animating={loadingFromCache} /> : null}
              {headingRight}
            </ListHeadRow>
            <Controls>
              <SearchBoxFilterButton searchPlaceholder={searchPlaceholder} />
              {breakpoint.key !== 'xs' ? <ActivityIndicator animating={loadingFromCache} /> : null}
              <ControlGroup>
                {breakpoint.key !== 'xs' ? (
                  <ListDisplayTypeButton
                    onPress={toggleDisplayType}
                    displayType={displayType}
                  />
                ) : null}
                <SortButton options={searchOptions.sort} />
              </ControlGroup>
            </Controls>
          </ListHead>
          {isTableDisplay ? (
            <ItemRow
              head
              height={ITEM_ROW_HEIGHT}
              position="sticky"
              top={height - FILTERS_BAR_HEIGHT - ITEM_ROW_HEIGHT - 4 /* <- 4 for preventing hairline gaps */}
              zIndex='$header'
              bg="$white"
            />
          ) : null}
        </>
      )}
    </Breakpoint>
  )
})
