import { ListItemTypes } from './utils';

class ListItemRecycler {
  static lastKey = 0;

  constructor(items) {
    this.items = {};
    this.pendingItems = {};
    items.forEach((item) => {
      const { type, section, index } = item;
      const [itemsForType] = this.itemsForType(type);
      itemsForType[`${type}:${section}:${index}`] = item;
    })
  }

  itemsForType(type) {
    return [
      this.items[type] || (this.items[type] = {}),
      this.pendingItems[type] || (this.pendingItems[type] = []),
    ];
  }

  get({ type, position, height, computedHeight, section = 0, index = 0 }) {
    const [items, pendingItems] = this.itemsForType(type);
    const itemKey = `${type}:${section}:${index}`;
    let item = items[itemKey];
    if (item == null) {
      item = { type, key: -1, position, height, computedHeight, section, index };
      pendingItems.push(item);
    } else {
      item.position = position;
      item.height = height;
      item.computedHeight = computedHeight;
      delete items[itemKey];
    }
    return item;
  }

  fill() {
    Object.values(ListItemTypes).forEach((type) => {
      const [items, pendingItems] = this.itemsForType(type);
      let index = 0;
      Object.values(items).forEach(({ key }) => {
        const item = pendingItems[index];
        if (item == null) {
          return false;
        }
        item.key = key;
        index++;
      });

      for (; index < pendingItems.length; index++) {
        pendingItems[index].key = ++ListItemRecycler.lastKey;
      }
      pendingItems.length = 0;
    });
  }
}

export { ListItemRecycler };