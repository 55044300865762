import { Mapbox } from './Mapbox';
import { Map as BaseMap } from './Map';
import { Camera } from './Camera';
import { FillLayer } from './FillLayer';
import { Images } from './Images';
import { LineLayer } from './LineLayer';
import { Marker } from './Marker';
import { ShapeSource } from './ShapeSource';
import { SymbolLayer } from './SymbolLayer';

BaseMap.Camera = Camera;
BaseMap.FillLayer = FillLayer;
BaseMap.Images = Images;
BaseMap.LineLayer = LineLayer;
BaseMap.Marker = Marker;
BaseMap.ShapeSource = ShapeSource;
BaseMap.SymbolLayer = SymbolLayer;

export {
  Mapbox,
  Camera,
  FillLayer,
  Images,
  LineLayer,
  Marker,
  ShapeSource,
  SymbolLayer,
  BaseMap as Map,
};
