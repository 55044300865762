import React from 'react';
import { Box, Text, Heading, Button } from '../../ui';
import { formatMoney } from '../../utils';
import { TextData } from '../../components';
import { apiHelpers } from '../../utils';
import { withStyles } from '../../ui/styling';

const LocationItem = withStyles(({ theme }) => {
  return {
    root: {
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padBottom: theme.spacing(6),
    },
    layout: {
      flexDirection: theme.breakpoints({ sm: 'column', md: 'row' }),
      flex: 1,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padBottom: theme.spacing(2),
    },
    hairlineLayout: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      props: {
        gap: theme.spacing(0.75),
      }
    },
    hairline: {
      height: 1,
      maxHeight: 1,
      backgroundColor: theme.colors.gray[200],
    },
    headerCell: {
      flexGrow: theme.breakpoints({ sm: 0, md: 2 }),
      flexShrink: theme.breakpoints({ sm: 0, md: 1 }),
      flexBasis: theme.breakpoints({ sm: 'auto', md: '0%' }),
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padRight: theme.spacing(1.5),
      padBottom: theme.breakpoints({ sm: theme.spacing(1), md: 0 }),
    },
    heading: {
      props: {
        // weight: '$regular',
        level: 4,
        maxLines: 2,
        size: 'xSmall',
      },
    },
    addressLine: {
      props: { maxLines: 2, size: 'small' }
    },
    contentRow: {
      flex: theme.breakpoints({ sm: 1, md: 3 }),
      width: theme.breakpoints({ sm: '100%', md: 'auto' }),
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      flexWrap: theme.breakpoints({ xs: 'wrap', sm: 'nowrap' }),
    },
    contentCell: {
      flex: 1,
      minWidth: 140,
      marginRight: '$1'
    }
  }
})((props) => {
  const { styles, loc, product, onDelete, onEdit, ...rest } = props;
  const {
    name,
    address,
    coveredValue,
    details,
  } = loc || {};
  const {
    num_vehicles: numVehicles = 0,
    average_car_value: averageCarValue = 0,
  } = details || {};

  const renderAutoFields = product && apiHelpers.isProductAuto(product);
  return (
    <Box
      {...rest}
    >
      <Box {...styles.toProps('layout')}>
        <Box {...styles.toProps('headerCell')}>
          <Box flex={1}>
            <Heading {...styles.toProps('heading')}>
              {name}
            </Heading>
            <Text {...styles.toProps('addressLine')}>
              {address}
            </Text>
          </Box>
        </Box>
        <Box {...styles.toProps('contentRow')}>
          {renderAutoFields && (
            <Box {...styles.toProps('contentCell')}>
              <TextData
                label="Number of Vehicles"
                data={numVehicles}
                invert
                labelProps={{ size: 'small' }}
              />
            </Box>
          )}
          {renderAutoFields && (
            <Box {...styles.toProps('contentCell')}>
              <TextData
                label="Average Car Value"
                data={numVehicles && numVehicles > 0 ? formatMoney(averageCarValue, { prefix: '$', withDecimals: true }) : 'N/A'}
                invert
                labelProps={{ size: 'small' }}
              />
            </Box>
          )}
          <Box {...styles.toProps('contentCell')}>
            <TextData
              label="Covered Value"
              data={formatMoney(coveredValue, { prefix: '$', withDecimals: true })}
              invert
              labelProps={{ size: 'small' }}
            />
          </Box>
        </Box>

      </Box>
      <Box {...styles.toProps('hairlineLayout')}>
          <Button label="Edit" onPress={onEdit} small  color="$primary" />
          <Box style={styles.hairline} width={12} />
          <Button label="Delete" onPress={onDelete} small outlined color="$error" borderColor={({ theme }) => theme.colors.alpha('$error', 0.5)} />
          <Box style={styles.hairline} flex={1} />
        </Box>
    </Box>
  );
})

export { LocationItem };
