import React, { useMemo, useRef, useState } from 'react';
import { ListDisplayTypes } from '../../constants';
import { useBreakpoint } from '../../ui';
import {
  useAppState,
  useAsyncSearch,
  useFilterQueryParams,
  useSearchQueryParams,
  useSortQueryParams,
} from '../../hooks';
import { useEventCallback } from '../../ui/hooks';
import cloneDeep from 'lodash.clonedeep';

export const FILTERS_BAR_HEIGHT = 55;

const SearchQueriesContext = React.createContext({ sort: [], filter: [], search: [] });

export const useSearchQueriesContext = () => React.useContext(SearchQueriesContext);

export const ProvideSearchQueriesContext = (props) => {
  const { children } = props;
  const sort = useSortQueryParams();
  const filter = useFilterQueryParams();
  const search = useSearchQueryParams();
  const context = useMemo(() => ({ sort, filter, search }), [sort, filter, search]);
  return <SearchQueriesContext.Provider value={context}>{children}</SearchQueriesContext.Provider>
}


const SearchApiContext = React.createContext({});

export const useSearchApiContext = () => React.useContext(SearchApiContext);

export const ProvideSearchAPIContext = (props) => {
  const {
    searchPath, // override
    data: overrideValue = undefined, // override
    // required
    searchOptions, // the object that defines the expected list items and their properties, filter, search, and sort configs
    getData, // the useAPI or other callback to get the data
    appSettingsKey, // for saving search control settings to app state local storage
    cacheKey, // for data to be stored locally to improve load times
    listRef,
    children,
    ...rest
  } = props;

  const [{ settings, saveSettings: saveAppSettings }] = useAppState();
  const storedConfig = useRef();
  if (
    appSettingsKey
    && !storedConfig.current
    && settings
    && settings.current
    && settings.current[appSettingsKey]
    && settings.current[appSettingsKey].searchConfig
  ) {
    storedConfig.current = cloneDeep(settings.current[appSettingsKey].searchConfig);
  }

  const saveSettings = useEventCallback((nextSetting) => {
    if (nextSetting && appSettingsKey && typeof appSettingsKey === 'string') {
      saveAppSettings({
        [appSettingsKey]: {
          searchConfig: nextSetting,
        }
      })
    }
  })

  const {
    value: data,
    cached,
    status,
    requestingMore,
    requestMoreData,
  } = useAsyncSearch(getData, {
    options: searchOptions,
    initialConfig: storedConfig.current,
    limit: 30,
    offset: 0,
    overrideValue,
    cache: true,
    cacheKey,
    searchPath,
    onChange:  (apiConfig, searchConfig) => {
      if (listRef && listRef.current && listRef.current.scrollTo) {
        listRef.current.scrollTo({ x: 0, y: 0, animated: false });
      }
      if (searchConfig) {
        const { filters = [], search = [], sort = {}} = searchConfig;
        saveSettings({ filters, sort, search })
      }
    },
    ...rest
  });

  const [breakpoint] = useBreakpoint();
  const [actualDisplayType, setActualDisplayType] = useState(settings.current && settings.current.listDisplayType ? settings.current.listDisplayType : ListDisplayTypes.CARDS);
  const displayType = breakpoint.key === 'xs' ? ListDisplayTypes.CARDS : actualDisplayType;
  const loadingFromCache = !requestingMore && status === 'pending' && cached && data;
  const loading = !requestingMore && status === 'pending' && !cached;
  
  const toggleDisplayType = useEventCallback(() => {
    const nextListDisplayType = 
      actualDisplayType === ListDisplayTypes.CARDS
        ? ListDisplayTypes.TABLE
        : ListDisplayTypes.CARDS;

    saveAppSettings({
      listDisplayType: nextListDisplayType,
    })
    setActualDisplayType(nextListDisplayType);
  });

  const context = useMemo(() => {
    const value = {
      initialConfig: storedConfig,
      data,
      cached,
      requestingMore,
      requestMoreData,
      toggleDisplayType,
      displayType,
      loadingFromCache,
      loading,
      saveSettings,
      appSettingsKey,
      searchOptions,
    };
    console.log(`SearchApiContext for ${appSettingsKey}:`, value);
    return value;
  }, [
    data,
    cached,
    requestingMore,
    requestMoreData,
    toggleDisplayType,
    displayType,
    loadingFromCache,
    loading,
    saveSettings,
    appSettingsKey,
    searchOptions,
  ])
  
  return (
    <SearchApiContext.Provider value={context}>
      <ProvideSearchQueriesContext>
        {children}
      </ProvideSearchQueriesContext>
    </SearchApiContext.Provider>
  );
}





