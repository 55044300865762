/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import equal from 'fast-deep-equal/react';
import { withSource } from './Contexts';

const styleMappings = {
  layout: {
    // line
    lineCap: 'line-cap',
    lineJoin: 'line-join',
    lineMeterLimit: 'line-meter-limit',
    lineRoundLimit: 'line-round-limit',
    // symbol
    symbolPlacement: 'symbol-placement',
    symbolSpacing: 'symbol-spacing',
    symbolAvoidEdges: 'symbol-avoid-edges',
    symbolSortKey: 'symbol-sort-key',
    symbolZOrder: 'symbol-z-order',
    iconAllowOverlap: 'icon-allow-overlap',
    iconIgnorePlacement: 'icon-ignore-placement',
    iconOptional: 'icon-optional',
    iconRotationAlignment: 'icon-rotation-alignment',
    iconSize: 'icon-size',
    iconTextFit: 'icon-text-fit',
    iconTextFitPadding: 'icon-text-fit-padding',
    iconImage: 'icon-image',
    iconRotate: 'icon-rotate',
    iconPadding: 'icon-padding',
    iconKeepUpright: 'icon-keep-upright',
    iconOffset: 'icon-offset',
    iconAnchor: 'icon-anchor',
    iconPitchAlignment: 'icon-pitch-alignment',
    textPitchAlignment: 'text-pitch-alignment',
    textRotationAlignment: 'text-rotation-alignment',
    textField: 'text-field',
    textFont: 'text-font',
    textSize: 'text-size',
    textMaxWidth: 'text-max-width',
    textLineHeight: 'text-line-height',
    textLetterSpacing: 'text-letter-spacing',
    textJustify: 'text-justify',
    textRadialOffset: 'text-radial-offset',
    textVariableAnchor: 'text-variable-anchor',
    textAnchor: 'text-anchor',
    textMaxAngle: 'text-max-angle',
    textWritingMode: 'text-writing-mode',
    textRotate: 'text-rotate',
    textPadding: 'text-padding',
    textKeepUpright: 'text-keep-upright',
    textTransform: 'text-transform',
    textOffset: 'text-offset',
    textAllowOverlap: 'text-allow-overlap',
    textIgnorePlacement: 'text-ignore-placement',
    textOptional: 'text-optional',
    // all
    visibility: 'visibility',
  },
  paint: {
    // line
    lineOpacity: 'line-opacity',
    lineColor: 'line-color',
    lineTranslate: 'line-translate',
    lineTranslateAnchor: 'line-translate-anchor',
    lineWidth: 'line-width',
    lineGapWidth: 'line-gap-width',
    lineOffset: 'line-offset',
    lineBlur: 'line-blur',
    lineDasharray: 'line-dasharray',
    linePattern: 'line-pattern',
    lineGradient: 'line-gradient',
    // fill
    fillAntialias: 'fill-antialias',
    fillColor: 'fill-color',
    fillOpacity: 'fill-opacity',
    fillOutlineColor: 'fill-outline-color',
    fillTranslate: 'fill-translate',
    fillTranslateAnchor: 'fill-translate-anchor',
    fillPattern: 'fill-pattern',
    // symbol
    iconOpacity: 'icon-opacity',
    iconColor: 'icon-color',
    iconHaloColor: 'icon-halo-color',
    iconHaloWidth: 'icon-halo-width',
    iconHaloBlur: 'icon-halo-blur',
    iconTranslate: 'icon-translate',
    iconTranslateAnchor: 'icon-translate-anchor',
    textOpacity: 'text-opacity',
    textColor: 'text-color',
    textHaloColor: 'text-halo-color',
    textHaloWidth: 'text-halo-width',
    textHaloBlur: 'text-halo-blur',
    textTranslate: 'text-translate',
    textTranslateAnchor: 'text-translate-anchor',
  },
};

const parseLayerStyle = (styleObj) => {
  const result = {};
  for (const key in styleObj) {
    const value = styleObj[key];
    if (value === null || value === undefined) {
      continue;
    }
    if (styleMappings.layout[key]) {
      const layoutKey = styleMappings.layout[key];
      if (!result.layout) {
        result.layout = { };
      }
      result.layout[layoutKey] = value;
    } else if (styleMappings.paint[key]) {
      const paintKey = styleMappings.paint[key];
      if (!result.paint) {
        result.paint = { };
      }
      result.paint[paintKey] = value;
    }
  }
  return result;
}

const LineLayer = withSource(React.forwardRef(function LineLayer(props, ref) {
  const { map, sourceId, source, type: defaultType = 'line', id: propsId, style } = props;
  const type = React.useRef(null);
  if (!type.current) {
    type.current = defaultType || 'line';
  }
  const mounted = React.useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const [id, setId] = useState(null);
  const last = useRef({ id: null, style: null });
  last.current.id = id;
  const nextId = propsId === null || propsId === undefined || !sourceId
    ? null
    : `${sourceId}${propsId}`;

  React.useEffect(() => {
    if (!(map && map.mounted && map.mounted.current) || !mounted.current) {
      return;
    }
    if (last.current.id !== nextId) {
      if (last.current.id && source) {
        source.removeLayer(last.current.id);
      }
      if (!nextId && last.current.id) {
        setId(null);
      } else if (nextId && style && map.getSource(sourceId)) {
        const layer = {
          'id': nextId,
          'source': sourceId,
          'type': type.current,
          ...parseLayerStyle(style)
        };
        source.addLayer(layer);
        last.current.style = style;
        setId(nextId);
      }
    } else if (!equal(last.current.style, style) && map.getSource(sourceId)) {
      const layer = last.current.id ? map.getLayer(last.current.id) : null;
      if (layer) {
        const layerStyle = parseLayerStyle(style);
        if (layerStyle.layout) {
          for (const key in layerStyle.layout) {
            const value = layerStyle.layout[key];
            map.setLayoutProperty(last.current.id, key, value);
          }
        }
        if (layerStyle.paint) {
          for (const key in layerStyle.paint) {
            const value = layerStyle.paint[key];
            map.setPaintProperty(last.current.id, key, value);
          }
        }
        last.current.style = style;
      }
    }
  }, [nextId, style, map, sourceId, source]);

  useEffect(() => {
    return () => {
      if (!mounted.current && id) {
        if (source) {
          source.removeLayer(id);
        }
      }
    };
  }, [id, source]);

  return null;
}));

export { LineLayer };
