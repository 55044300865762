import Color from 'color';
import { createBreakpoints } from '../breakpoints';
import { isObject, get } from '../../utils';
import { parseSystemStyle, parseDefault } from '../utils';

const TEXT_VARIANT_SIZE_PROPS = ['xxSmall', 'xSmall', 'small', 'medium', 'large', 'xLarge', 'xxLarge'];

function generateMissingProps(obj, propsArray, callback) {
  let missingProps = [];
  let lastValue = null;
  if (typeof obj === 'object') {
    const numProps = propsArray.length;
    for (let i = 0; i < numProps; i += 1) {
      const prop = propsArray[i];
      if (obj[prop]) {
        lastValue = obj[prop];
        if (missingProps.length) {
          for (let j = 0; j < missingProps.length; j += 1) {
            obj[missingProps[j]] = obj[prop];
          }
          missingProps = [];
        }
      } else {
        if (lastValue) {
          obj[prop] = lastValue;
        } else {
          missingProps.push(prop);
        }
      }
    }
  }
  if (callback) {
    const success = !missingProps.length ? true : false;
    callback(success, obj, missingProps);
  }
}

function defineDefaultOnFinish(success, obj) {
  if (success) {
    if (!obj.default) {
      obj.default = obj.medium;
    }
  }
}

function normalizeColor(c) {
  return c === 'transparent' ? 'rgba(255,255,255,0)' : c;
}

function getThemeColor(color, theme) {
  let c = parseSystemStyle(color);
  if (theme && c) c = get(theme.colors, c, c) || c;
  return c;
}

function getColor(color, theme) {
  return parseDefault(getThemeColor(color, theme)) || color;
}

function createBreakpointFunctions(obj, breakpoints) {
  let result = { };
  if (isObject(obj)) {
    // see if it's a breakpoint object by looking for breakpoint keys (xs through xl)
    const isBreakpointObject = Object.keys(obj).reduce((is, key) => {
      if (!is) {
        if (key === 'xs' || key === 'sm' || key === 'md' || key === 'lg' || key === 'xl') {
          return true;
        }
      }
      return is;
    }, false);

    // if it is we'll apply and return the breakpoints function
    if (isBreakpointObject) {
      return breakpoints(obj);
    }

    // else investigate down the tree
    for (const key in obj) {
      const value = obj[key];
      result[key] = createBreakpointFunctions(value, breakpoints);
    }
  } else {
    result = obj;
  }
  return result;
}

export function createTheme(themeObj) {
  const { breakpoints, ...rest } = themeObj;
  const breakpointsFunc = createBreakpoints(breakpoints);
  const theme = createBreakpointFunctions(rest, breakpointsFunc);
  theme.breakpoints = breakpointsFunc;
  if (typeof theme.spacing === 'number') {
    const spacing = theme.spacing;
    theme.spacing = (multiplier = 1, add = 0) => spacing * multiplier + add;
    theme.spacing.factor = spacing;
  }
  theme.sizes = theme.sizes ? { ...theme.breakpoints.values, ...theme.sizes } : { ...theme.breakpoints.values };

  generateMissingProps(theme.text, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.h1, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.h2, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.h3, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.h4, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.h5, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.h6, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.subheading, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.overline, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.h1.subheading, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.h1.body, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.h2.subheading, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);
  generateMissingProps(theme.text.h2.body, TEXT_VARIANT_SIZE_PROPS, defineDefaultOnFinish);

  // TODO: make each color an object with these functions and have parseStyle call string() to get the color
  // so theme.colors.primary.contrast or theme.colors.primary.darken(0.5) and theme.colors.primary.string()
  theme.colors.dim = (c, amount = 0.5) => Color(normalizeColor(getColor(c, theme))).alpha(amount).rgb().string()
  theme.colors.opacity = theme.colors.dim;
  theme.colors.alpha = theme.colors.dim;
  theme.colors.grayscale = (c) => Color(normalizeColor(getColor(c, theme))).grayscale().rgb().string();
  theme.colors.darken = (c, amount = 1.5) => Color(normalizeColor(getColor(c, theme))).darken(amount).rgb().string()
  theme.colors.lighten = (c, amount = 1.5) => Color(normalizeColor(getColor(c, theme))).lighten(amount).rgb().string()
  theme.colors.rotate = (c, amount = 0) => Color(normalizeColor(getColor(c, theme))).rotate(amount).rgb().string()
  theme.colors.isDark = (c) => Color(normalizeColor(getColor(c, theme))).isDark()
  theme.colors.isLight = (c) => Color(normalizeColor(getColor(c, theme))).isLight();
  theme.colors.contrast = (c) => {
    let color = normalizeColor(getThemeColor(c, theme));
    if (isObject(color)) {
      if (color.contrast) {
        if (isObject(color.contrast)) {
          if (color.contrast[c]) {
            return color.contrast[c];
          }
          if (color.contrast.default) {
            return color.contrast.default;
          }
        }
        return color.contrast;
      } else if (color.default) {
        color = color.default;
      }
    }
    if (theme.colors.isDark(color)) {
      return theme.colors.gray['50'];
    }
    return theme.colors.gray['700'];
  }

  theme.colors.on = theme.colors.contrast;

  theme.colors.fade = (colorsOrColor, { from = 0.95, to = 0 } = { }) => {
    let color1, color2;
    if (Array.isArray(colorsOrColor)) {
      color1 = normalizeColor(getColor(colorsOrColor[0], theme));
      color2 = normalizeColor(getColor(colorsOrColor[1], theme)) || color1;
    } else {
      color1 = normalizeColor(getColor(colorsOrColor, theme));
      color2 = color1;
    }
    const start = Color(color1).alpha(from).rgb().string();
    const end = Color(color2).alpha(to).rgb().string();
    return [start, end];
  }

  theme.getColor = (c) => getColor(c, theme);

  if (process.env.NODE_ENV !== "production") console.log('THEME', theme);
  // try {
  //   console.log(theme.shadows.elevation(10));
  // } catch(error) {
  //   console.log('error', error);
  // }
  return theme;
}
