import { CoverageNames, CoverageTypes } from "../../../constants";

function getQuoteWithCoverages(quote = {}) {
  const { locations } = quote;
  let coverageTotal = 0;
  let netPremiumTotal = 0;
  if (!locations.length) {
    return { coverageTotal, netPremiumTotal, ...quote };
  }

  for (const location of locations) {
    const { name, address, details, id: locationId, coverages: cList, coveredValue, region, postcode, csr } = location;
    const { type, employees } = details || {};

    coverageTotal += coveredValue;

    for (const c of cList) {
      const { coverageType, deductible, payoutLimit, aggregate, netPremium, coverageId, quoteId } = c;
      netPremiumTotal += netPremium;
    }
  }
  const quoteWithCoverages = { coverageTotal, netPremiumTotal, ...quote };
  return quoteWithCoverages;
}

export default getQuoteWithCoverages;
