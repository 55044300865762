import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, styled } from '../../styling';
import { parseSystemStyle } from '../../system';
import { Box } from '../Box';
import { Text } from '../Text';
import { EMPTY_FUNC } from '../../utils';
import { border, padding } from '../../system/props'
import { ActivityIndicator } from '../ActivityIndicator';
import { useId } from '../../hooks';

// TODO: remove boolean shortcuts. makes overriding too difficult (buttongrup is example of this wher button group will override a boolean prop by passing size)
const propTypes = {
  children: PropTypes.node, // the text string for button name or Text component
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  size: PropTypes.oneOf(['small', 'medium', 'large']), // default is medium
  large: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  outlined: PropTypes.bool,
  contained: PropTypes.bool,
  text: PropTypes.bool,
  regular: PropTypes.bool, // make text regular and not bold
  color: PropTypes.any, // affects the background/border color and text/icon color
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  fullWidth: PropTypes.bool, // fills its container width,
  fullHeight: PropTypes.bool, // fill its container height,
  disabled: PropTypes.bool,
};

const filterProps = [
  'variant',
  'color',
  'large',
  'medium',
  'small',
  'outlined',
  'contained',
  'text',
  'regular',
  'fullWidth',
  'fullHeight',
];

const defaultProps = {
  variant: null,
  contained: true,
  outlined: false,
  text: false,
  size: null,
  medium: true,
  large: false,
  small: false,
  color: null,
  regular: false,
  startIcon: null,
  endIcon: null,
  fullWidth: false,
  fullHeight: false,
  disabled: false,
  onPress: EMPTY_FUNC,
};

const StartIcon = styled(Box, { name: 'StartIcon', filterProps: ['size', 'hasLabel'] })(
  ({ theme, size, hasLabel = true }) => ({
    marginRight: !hasLabel ? 0 : theme.spacing(1),
    marginLeft: !hasLabel ? 0 : size === 'small' ? theme.spacing(-0.25) : theme.spacing(-0.5),
  })
);

const EndIcon = styled(Box, { name: 'EndIcon', filterProps: ['size', 'hasLabel'] })(
  ({ theme, size, hasLabel }) => ({
    marginLeft: !hasLabel ? 0 : theme.spacing(1),
    marginRight: !hasLabel ? 0 : size === 'small' ? theme.spacing(-0.25) : theme.spacing(-0.5),
  })
);

const Button = withStyles(props => {
  const {
    theme,
    size,
    large,
    small,
    color: colorProp,
    variant,
    outlined,
    text,
    regular,
    fullWidth,
    fullHeight,
  } = props;
  const color = typeof colorProp === 'function' ? colorProp(props) : colorProp;
  let buttonSize = size;
  if (!size || !theme.button.sizes[size]) {
    if (large) {
      buttonSize = 'large';
    } else if (small) {
      buttonSize = 'small';
    } else {
      buttonSize = 'medium';
    }
  }

  let buttonVariant = variant;
  if (!variant || !theme.button[variant]) {
    if (outlined) {
      buttonVariant = 'outlined';
    } else if (text) {
      buttonVariant = 'text';
    } else {
      buttonVariant = 'contained';
    }
  }

  let textStyle = { };
  let buttonStyle = { };
  if (buttonVariant === 'contained') {
    const c = color || '$primary';
    buttonStyle.backgroundColor = c;
    textStyle.color = theme.colors.on(parseSystemStyle(c));
  } else if (buttonVariant === 'outlined') {
    const c = color || '$secondary';
    buttonStyle.borderColor = c;
    textStyle.color = c;
  } else {
    textStyle.color = color || '$text';
  }

  if (regular) {
    textStyle.fontWeight = '$regular';
  }

  if (fullWidth) {
    buttonStyle.width = '100%';
  }

  if (fullHeight) {
    buttonStyle.height = '100%';
  }

  const style = {
    root: {
      ...theme.button.base,
      ...theme.button.sizes[buttonSize],
      ...theme.button[buttonVariant],
      ...buttonStyle,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      ...border(props),
      ...padding(props),
    },
    [Text]: {
      ...theme.text.button[buttonSize],
      ...textStyle,
    },
    loadingIndicator: {
      zIndex: 6,
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: buttonStyle.backgroundColor,
      props: { color: textStyle.color }
    },
  }
  return style;
}, { name: 'Button', filterProps })
(React.forwardRef(function Button({
  styles,
  children: childrenProp,
  accessibility,
  startIcon: startIconProp,
  endIcon: endIconProp,
  size,
  disabled = false,
  onPress = EMPTY_FUNC,
  label,
  href,
  TextProps,
  loading = false,
  LoadingIndicatorComponent = ButtonLoadingIndicator,
  ...props
}, ref) {

  const hasLabel = label || childrenProp ? true : false;
  const startIcon = startIconProp && (
      <StartIcon size={size} hasLabel={hasLabel}>{startIconProp}</StartIcon>
  );

  const endIcon = endIconProp && <EndIcon size={size} hasLabel={hasLabel}>{endIconProp}</EndIcon>;
  const labelId = useId(TextProps && TextProps.id ? TextProps.id : null, 'zuiBtnLbl');
  const children = label  || childrenProp;
  return (
    <Box
      ref={ref}
      accessibility={{
        accessibilityRole: 'button',
        accessibilityLabelledBy: typeof children === 'string' ? labelId : undefined,
        ...accessibility
      }}
      focusable
      disabled={disabled}
      onPress={onPress}
      href={href}
      {...props}
    >
      {startIcon}
      {typeof children === 'string' ? (
        <Text {...{ styles }} selectable={false} id={labelId} {...TextProps}>
          {children}
        </Text>
      ) : (
        children
      )}
      {endIcon}
      {loading ? <LoadingIndicatorComponent {...styles.toProps('loadingIndicator')} /> : null}
    </Box>
  );
}));

const ButtonLoadingIndicator = React.forwardRef((props, ref) => {
  const { color, size, ...rest } = props;
  return (
    <Box {...rest} ref={ref}>
      <ActivityIndicator color={color || '$primary'} size={size} />
    </Box>
  );
});

Button.propTypes = propTypes;

export { Button }
