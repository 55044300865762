import React from 'react';
import { Box } from '../Box';
import { withStyles } from '../../styling';
import { isBreakpointFunction } from '../../system';

const Spacing = withStyles(({ vertical: verticalProp, horizontal: horizontalProp, theme, ...rest }) => {
  const vertical = typeof verticalProp === 'function' ? verticalProp({ theme, ...rest }) : verticalProp;
  const horizontal = typeof horizontalProp === 'function' ? horizontalProp({ theme, ...rest }) : horizontalProp;
  const root = {
    alignSelf: 'stretch',
    width: null,
    height: null,
  };
  if (vertical) {
    root.width = '100%';
    root.height = theme.spacing(isBreakpointFunction(vertical) ? vertical({ theme, ...rest }) : vertical);
  } else if (horizontal) {
    root.height = '100%';
    root.width = theme.spacing(isBreakpointFunction(horizontal) ? horizontal({ theme, ...rest }) : horizontal);
  }
  return { root };
}, { name: 'Spacing', filterProps: ['vertical', 'horizontal'] })(
  React.forwardRef(function Spacing(props, ref) {
    const { styles, ...rest } = props;
    return <Box ref={ref} {...rest} />;
  })
);

export { Spacing };
