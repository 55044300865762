import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { Box } from '../Box';
import { withStyles } from '../../styling';
import { flexBox, padding } from '../../system/props';
import { useStepperContext, StepContext } from './StepperContext';

const Step = withStyles(({ theme: { spacing } }) => ({
  root: { justifyContent: 'center', alignItems: 'center' },
  horizontal: {
    padX: spacing(1),
  },
  vertical: {},
  expanded: {},
  completed: {},
  verticalContent: {
    flex: 1,
  },
}), { filterProps: [...flexBox.filterProps], preserveStyleProp: true })
(React.forwardRef(function Step(props, ref) {
  const {
    active: activeProp,
    completed: completedProp,
    disabled: disabledProp,
    expanded = false,
    index,
    last,
    styles,
    children,
    style,
    ...rest
  } = props;

  const { activeStep, connector, vertical, verticalContent, nonLinear, hideIcon } = useStepperContext();

  let [active = false, completed = false, disabled = false] = [
    activeProp,
    completedProp,
    disabledProp,
  ];

  if (activeStep === index) {
    active = activeProp !== undefined ? activeProp : true;
  } else if (!nonLinear && activeStep > index) {
    completed = completedProp !== undefined ? completedProp : true;
  } else if (!nonLinear && activeStep < index) {
    disabled = disabledProp !== undefined ? disabledProp : true;
  }

  const stepContext = useMemo(() => ({
    active,
    completed,
    disabled,
    index,
    icon: hideIcon ? null : index + 1,
    last,
    expanded,
  }), [active, completed, disabled, index, last, expanded, hideIcon]);

  const step = (
    <Box
      ref={ref}
      style={[
        vertical ? styles.vertical : styles.horizontal,
        !vertical && verticalContent ? styles.verticalContent : null,
        expanded ? styles.expanded : null,
        completed ? styles.completed : null,
        styles.root,
        style,
      ]}
      {...rest}
    >
      {connector && verticalContent && index !== 0 ? connector : null}
      {children}
    </Box>
  );

  return (
    <StepContext.Provider value={stepContext}>
      {connector && !verticalContent && index !== 0 ? (
        <>
          {connector}
          {step}
        </>
      ) : (
        step
      )}
    </StepContext.Provider>
  )
}))

export { Step }
