import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { Box, Text, Heading, ActivityIndicator, Button, Modal } from '../../../../ui';
import { MessageTypes, MessageTypeName, MessageTypeUserRole } from '../../../../constants';
import { useApi, useAsync } from '../../../../hooks';
import { QUOTE_REQUEST, QUOTE_ADJUSTMENT } from '../../../../constants';
import { useEventCallback } from '../../../../ui/hooks';
import { Main, PageContent } from '../../../../components';

const QuoteHistory = ({ quote, forceUpdate }) => {
  const { id } = quote;
  const { getAdjustmentsWithQuoteId, getRequestsWithQuoteId, } = useApi();
  const handleGetQuoteHistory = useCallback(async () => {
    const adjustmentsData = await getAdjustmentsWithQuoteId(id);
    const requestsData = await getRequestsWithQuoteId(id);
    
    const adjustments = adjustmentsData && Array.isArray(adjustmentsData) ? adjustmentsData.sort(
      (a, b) => a.adjustedAt - b.adjustedAt
    ) : [];
    const requests = requestsData && Array.isArray(requestsData) ? requestsData.sort(
      (a, b) => a.requestedAt - b.requestedAt
    ) : [];
    let history = [...adjustments, ...requests];
    if (history.length) {
      history = history.sort((a, b) => {
        const aTime = a.requestedAt ? a.requestedAt : a.adjustedAt;
        const bTime = b.requestedAt ? b.requestedAt : b.adjustedAt;
        return aTime - bTime;
      });
      history = history.map((item) => {
        if (item.requestedAt) {
          return { ...item, type: QUOTE_REQUEST };
        }
        // can assume it's an adjustemnt
        return { ...item, type: QUOTE_ADJUSTMENT };
      });
    } else {
      history = [];
    }
    return history;
  }, [id, forceUpdate, getAdjustmentsWithQuoteId, getRequestsWithQuoteId]);
  const { value: history, status, error } = useAsync(handleGetQuoteHistory);

  return (
    <Box padX="$2" padY="$4" alignSelf="flex-start">
      <Heading level={4} spaceAfter>Update History</Heading>
      {status === 'pending' ? <ActivityIndicator /> : <MessageHistoryList history={history} />}
    </Box>
  )
}

const MessageHistoryList = ({ history }) => {
  const messages = useMemo(() => {
    if (!history || !history.length) {
      return [];
    }
    return history.map((item) => {
      const message = getMessageProps(item);
      if (!message) {
        return null;
      }
      const { id, ...messageProps } = message;
      return <MessageItem key={id} {...messageProps} />;
    });
  }, [history]);

  if (!messages.length) {
    return <Text bold dim>No adjustments or requests have been made yet</Text>
  }

  return messages;
}

const MessageItem = ({ time, message, type, author }) => {
  return (
    <Box
      flexDirection="row"
      width="100%"
      justifyContent="flex-start"
      alignItems="flex-start"
      padY="$2"
    >
      <Box padY="$2.5">
        <Text bold small>{type}</Text>
        <Text small>{time}</Text>
      </Box>
      <Box padY="$3.5" height="100%" width={32} padX={10} alignItems="center">
        <Box width="100%" height={1} bg="$gray.200" />
      </Box>
      <Box
        padY="$1.5"
        padLeft="$2"
        padRight="$3"
        bg="white"
        borderRadius={8}
        shadow={({ theme }) => theme.shadows.elevation(15, { opacity: 0.1 })}
        borderWidth={1}
        borderColor="$gray.100"
      >
        <Text bold>{author}</Text>
        <Text>{message}</Text>
      </Box>
    </Box>
  )
}

function getMessageProps(data) {
  if (!data || !data.type || !MessageTypes[data.type]) {
    return null;
  }
  const { type } = data;
  if (type === MessageTypes.adjustment) {
    const { adjustmentId, adjustedAt, notes: message, type } = data;
    if (!message) {
      return null;
    }
    return {
      id: `adjustment-${adjustmentId}`,
      type: MessageTypeName[type],
      author: MessageTypeUserRole[type],
      time: moment(adjustedAt).format('MM-DD-YYYY'),
      message,
    };
  }
  if (type === MessageTypes.request) {
    const { requestId, requestedAt, request: message } = data;
    if (!message) {
      return null;
    }
    return {
      id: `request-${requestId}`,
      type: MessageTypeName[type],
      author: MessageTypeUserRole[type],
      time: moment(requestedAt).format('MM-DD-YYYY'),
      message,
    };
  }
  return null;
}

const ViewQuoteHistoryButton = ({ data, lastUpdated, ...rest }) => {
  const [open, setOpen] = useState(false);
  const closeModal = useEventCallback(() => setOpen(false));
  return (
    <>
      <Button variant="text" color="$primary" label="Update History" onPress={() => setOpen(!open)} {...rest} />
      <Modal
        open={open}
        onClose={closeModal}
        scrollable
        ScrollProps={{ padY: '$8', containerStyle: { minHeight: '101%'} }}
      >
        <Main>
          <PageContent bg="white">
            <QuoteHistory quote={data} forceUpdate={lastUpdated} />
          </PageContent>
        </Main>
      </Modal>
    </>
  )
}

export { QuoteHistory, ViewQuoteHistoryButton };