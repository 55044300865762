import React, { useState } from 'react';
import { Button, Modal, Text, Heading, Toast } from '../ui';
import { useClipboard } from '../ui/hooks';
import { Dialog } from './Dialog';

export const ShareButtonView = ({ url = '', label = 'Share Link', disableDialog = false, disableToast = false, DialogProps = null, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [clipped, setClipped] = useClipboard(url);
  return (
    <>
      <Button
        color="$primary.light"
        variant="text"
        label={label}
        onPress={() => {
          setClipped(url);
          setOpen(c => !c);
          setToastOpen(true);
        }}
        {...rest}
      />
      {
        !disableDialog ? (
          <Dialog
            open={open} 
            onClose={() => setOpen(false)}
            heading="Link Copied!"
            prompt="The link to this item is copied to your clipboard."
            actions={
              <Button
                variant="text"
                color="$secondary"
                onPress={() => {
                  setClipped(url);
                  setOpen(false);
                }}
                label="Okay"
              />
            }
            {...DialogProps}
          />
        ) : null
      }
      {
        !disableToast ? (
          <Toast
            anchorOrigin={{ horizontal: 'center' }}
            open={toastOpen}
            onClose={() => setToastOpen(false)}
            autoHideDuration={2000}
            message="Copied to clipboard"
            hideBackdrop
            action={<Button label="Dismiss" variant="text" color="$secondary.light" onPress={() => setToastOpen(false)} />}
          />
        ) : null
      }
    </>
  );
}