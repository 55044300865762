import React, { useState, useEffect, useRef, useContext, useCallback, useMemo } from 'react';
import { Box } from '../Box';
import { Text } from '../Text';
import { withStyles } from '../../styling';
import { flexBox } from '../../system/props';
import { useStepperContext, useStepContext } from './StepperContext';
import { StepIcon } from './StepIcon';

const StepLabel = withStyles(({
  theme: { spacing, getColor },
  color: colorProp,
  activeColor = '$secondary',
  completedColor = '$secondary',
  iconColor,
  ...props
}) => {
  const colorProps = {
    activeColor: getColor(colorProp || activeColor),
    completedColor: getColor(colorProp || completedColor),
    color: colorProp,
    iconColor,
  };

  return {
    root: {
      ...flexBox({
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...props,
      }),
      ...colorProps,
    },
    horizontal: {},
    vertical: {
      padY: spacing(1),
      width: '100%',
    },
    verticalContent: {
      flexDirection: 'column',
      props: { gap: spacing(1.5) },
    },
    iconContainer: {
      padRight: spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
    },
    labelContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
    },
    labelColumn: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flex: 1,
    },
    adornmentContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      alignSelf: 'stretch',
      padLeft: spacing(2),
    },
    label: {
      props: {
        size: 'medium',
        bold: true,
        maxLines: 1,
        color: colorProp || '$gray.500',
      }
    },
    subtext: {
      props: {
        color: colorProp || '$gray.500',
      }
    },
    text: {
      props: {
        color: '$gray.500',
        size: 'xSmall'
      },
    },
    active: {
      props: {
        color: colorProp || activeColor,
      }
    },
    completed: {
      props: {
        color: colorProp || completedColor,
      }
    },
    error: {
      props: {
        color: colorProp || '$error',
      }
    },
    disabled: {
      props: {
        color: colorProp || '$gray.300',
      },
    },
  }
}, {
  filterProps: [...flexBox.filterProps, 'activeColor', 'completedColor', 'color'],
  preserveStyleProp: true,
  asProp: { activeColor: true, completedColor: true, color: true, iconColor: true, },
})
(React.forwardRef(function StepLabel(props, ref) {
  const {
    error = false,
    icon: iconProp,
    StepIconComponent: StepIconComponentProp,
    StepIconProps,
    subtext,
    adornment,
    style,
    styles,
    children,
    activeColor,
    completedColor,
    color,
    iconColor,
    hideIcon,
    ...rest
  } = props;

  const { verticalContent, vertical } = useStepperContext();
  const { active, disabled, completed, icon: iconContext } = useStepContext();
  const icon = iconProp || iconContext;

  let StepIconComponent = icon && !StepIconComponentProp ? StepIcon : StepIconComponentProp;

  const sharedTextProps = {
    style: [
      verticalContent ? styles.verticalContent : null,
      completed ? styles.completed : null,
      active ? styles.active : null,
      error ? styles.error : null,
      disabled ? styles.disabled : null,
    ],
    props: {
      ...(completed ? styles.props.completed : null),
      ...(active ? styles.props.active : null),
      ...(error ? styles.props.error : null),
      ...(disabled ? styles.props.disabled : null),
    }
  }

  return (
    <Box
      ref={ref}
      style={[
        styles.root,
        vertical ? styles.vertical : styles.horizontal,
        !vertical && verticalContent ? styles.verticalContent : null,
        style,
      ]}
      {...rest}
    >
      {(StepIconComponent && !hideIcon) && (
        <Box style={styles.iconContainer} {...styles.props.iconContainer}>
          <StepIconComponent
            icon={icon}
            activeColor={activeColor}
            completedColor={completedColor}
            color={iconColor ? iconColor : color ? color : null}
            completed={completed}
            active={active}
            error={error}
            disabled={disabled}
            {...StepIconProps}
          />
        </Box>
      )}
      <Box style={styles.labelContainer} {...styles.props.labelContainer}>
        <Box style={styles.labelColumn} {...styles.props.labelColumn}>
          {children ? (
            <Text
              style={[
                styles.label,
                ...sharedTextProps.style,
              ]}
              {...styles.props.label}
              {...sharedTextProps.props}
            >
              {children}
            </Text>
          ) : null}
          {subtext ? (
            <Text
              style={[
                styles.text,
                styles.subtext,
                ...sharedTextProps.style,
              ]}
              {...styles.props.text}
              {...styles.props.subtext}
              {...sharedTextProps.props}
            >{subtext}</Text>
          ) : null}
        </Box>
        {adornment ? (
          <Box style={styles.adornmentContainer} {...styles.props.adornmentContainer}>
            {typeof adornment === 'string' ? (
              <Text style={styles.text} {...styles.props.text}>
                {adornment}
              </Text>
            ) : adornment}
          </Box>
        ) : null}
      </Box>

    </Box>
  );
}))
/*
  <Root (StepLabel) row or column depending on verticalContent
    <IconContainer
    <LabelContainer
      {children is string and chidlren then <Text>{Children}<Text> else {children} : null}
      {adornment}
    </LabelCOntainer>
  </Root>
*/

/*
  (horizontal && !verticalcontent then {connector})
  <Step>
    else (horizontal && verticalcontent then {connector}) (connector is above step in vertical orientation)
    <StepLabel>asdfasdf</StepLabel>
    <StepContent>lksdflkajsldkfjasdf<Button</StepCOntent>
  </Step>
*/
export { StepLabel }
