import React, { createContext, memo, useCallback, useContext, useMemo } from "react";
import { useApi } from "../../../hooks/useApi";
import { useAsync } from "../../../hooks/useAsync";
import { ActivityIndicator, Text } from "../../../ui";
import getQuoteWithCoverages from "./getQuoteWithCoverages";

const QuoteDataContext = createContext({ data: null, updating: true, update: () => null });

function useQuoteData() {
  return useContext(QuoteDataContext);
}

const ProvideQuoteData = memo(function ProvideQuoteData(props) {
  const {
    id,
    renderLoading,
    renderEmpty,
    children,
  } = props;

  const {
    getQuoteWithId,
    getApplicationWithId,
    getProducts,
    getCoveragesForProductId,
    getMacavityForQuote,
    getQuoteConcentrations,
  } = useApi();
  
  const getQuoteDataAndCoverages = useCallback(async () => {
    const quote = await getQuoteWithId(id);
    const application = await getApplicationWithId(quote.applicationId);
    const productCoverages = await getCoveragesForProductId(quote.product.id);
    var macavity;
    try {
      macavity = await getMacavityForQuote(quote.id);
    } catch (error) {
      macavity = null;
    }
    var concentrations;
    try {
      concentrations = await getQuoteConcentrations(quote.id);
    } catch (error) {
      concentrations = null;
    }

    if (!quote) return null;
    return {
      data: {
        ...getQuoteWithCoverages(quote),
        application
      },
      productCoverages,
      macavity,
      concentrations,
    };
  }, [id, getQuoteWithId, getApplicationWithId, getProducts, getCoveragesForProductId, getMacavityForQuote, getQuoteConcentrations]);

  const { value, status, execute, lastUpdated } = useAsync(getQuoteDataAndCoverages);  
  const { data = null, productCoverages, macavity, concentrations } = value || {};

  const context = useMemo(() => ({
    data,
    updating: data && status === 'pending',
    update: execute,
    productCoverages,
    macavity,
    concentrations,
    lastUpdated
  }), [data, status, execute, lastUpdated]);

  if (!data && status === 'pending') {
    return renderLoading ? renderLoading() : children;
  }

  if (!data) {
    return renderEmpty ? renderEmpty() : children;
  }

  return (
    <QuoteDataContext.Provider value={context}>
      {children}
    </QuoteDataContext.Provider>
  );
});

export { QuoteDataContext, useQuoteData, ProvideQuoteData }
