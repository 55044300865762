import { useRef, useMemo, useEffect, useCallback, useState } from "react";
import { useEventCallback, useScrollPositionEffect } from "../ui/hooks";
import { debounce, sleep } from "../ui/utils";
import { useAsync } from './useAsync';
import { encodeSearchConfig, useSearchConfig } from './useSearchConfig';

function useAsyncSearch(callApi, searchConfigAsyncOptions = { }) {
  const {
    // useAsync props
    immediate = true,
    resetValueOnExecute = false,
    cache = false,
    cacheKey = null,
    comparer,
    compareWithout,
    // useAsyncSearch props
    initialOffset = 0,
    limit = 30,
    overrideValue,
    defaultValue,
    autoAdd = false,
    addDebounceMs = 0,
    autoAddEndThreshold = 1, // percentage of window height (not scrollable height)
    cacheAdditions = false,
    ...searchConfigProps
  } = searchConfigAsyncOptions;
  const mounted = useRef(true);
  useEffect(() => { return () => mounted.current = false }, []);

  const initialLimit = useRef(initialOffset ? limit + initialOffset : -1);
  const atDataLimit = useRef(false);
  const moreDataRequested = useRef(false);
  const [requestingMore, setRequestingMore] = useState(false);
  const searchConfig = useSearchConfig({ limit, ...searchConfigProps });
  
  useEffect(() => {
    setRequestingMore(false);
    moreDataRequested.current = false;
  }, [callApi, searchConfig]);

  const getCacheKey = useCallback((configOverrides = {}) => {
    if (cache) {
      if (searchConfig) {
        let scString = null;
        if (initialLimit.current !== -1) {
          scString = encodeSearchConfig({offset: 0, ...searchConfig, limit: initialLimit.current });
        } else {
          scString = encodeSearchConfig({ offset: 0, ...searchConfig, ...configOverrides });
        }
        return cacheKey && typeof cacheKey === 'string' ? `${cacheKey}:${scString}` : scString;
      }
    }
    return cacheKey;
  }, [cache, cacheKey, searchConfig]);

  const getShouldCache = useCallback((configOverrides, currValue, addingMore = false) => {
    if (cache) {
      if (addingMore) {
        return cacheAdditions ? true : false;
      }
      return true;
    }
    return false;
  }, [cacheAdditions, cache]);

  const handleApiCall = useCallback(async (configOverrides = {}, currentValue = null) => {
    if (overrideValue) {
      atDataLimit.current = true;
      return overrideValue;
    }
    if (initialLimit.current !== -1) { // only called on mount essentially
      const limitOnce = initialLimit.current;
      initialLimit.current = -1;
      return await callApi({ offset: 0, ...searchConfig, limit: limitOnce })
    }
    const result = await callApi({ offset: 0, ...searchConfig, ...configOverrides });

    if (Array.isArray(currentValue)) {
      if (Array.isArray(result)) {
        if (result.length) {
          const combinedResults = currentValue.concat(result);
          if (combinedResults.length !== searchConfig.limit && combinedResults.length % searchConfig.limit !== 0) {
            atDataLimit.current = true;
          }
          return combinedResults;
        } else {
          atDataLimit.current = true;
          return currentValue;
        }
      }
    } else if (Array.isArray(result) && !result.length) {
      atDataLimit.current = true;
    } else {
      atDataLimit.current = false;
    }
    return result;
  }, [callApi, searchConfig, overrideValue]);

  const { value, status, execute, ...apiState } = useAsync(
    handleApiCall,
    {
      immediate,
      resetValueOnExecute,
      cache: getShouldCache,
      cacheKey: getCacheKey,
      comparer,
      compareWithout
    }
  );

  useEffect(() => {
    if (status !== 'pending' && moreDataRequested.current) {
      setRequestingMore(false);
      moreDataRequested.current = false;
    }
  }, [status]);

  const moreDataAvailable = useMemo(() => {
    if (Array.isArray(value)) {
      if (atDataLimit.current) {
        return false;
      }
      return value.length === limit || value.length % limit === 0;
    }
    return false;
  }, [value, limit, status]); // status just to keep this updated

  const requestMoreData = useEventCallback((dTime = 0) => {
    const debounceTime = dTime ? dTime : addDebounceMs;
    const debounceExecute = debounce(() => {
      if (mounted.current && moreDataAvailable && moreDataRequested.current && status !== 'pending') {
        setRequestingMore(true);
      }
      return;
    }, debounceTime);
    
    
    if (moreDataAvailable && moreDataRequested.current && status !== 'pending') {
      if (addDebounceMs) {
        debounceExecute();
      } else {
        setRequestingMore(true);
        
      }
    }

    return () => {
      debounceExecute.clear();
    }
  }, [value, status, moreDataAvailable, execute, addDebounceMs]);

  const handleRequestMoreData = useEventCallback((fromAutoAdd) => {
    if (autoAdd && fromAutoAdd !== true) {
      console.warn('You requested for more data twice. Disable autoAdd by setting to false to manually request for more data.');
      return;
    }
    if (moreDataAvailable && !moreDataRequested.current) {
      moreDataRequested.current = true;
      requestMoreData();
    }
    return;
  }, [moreDataAvailable, requestMoreData, autoAdd]);

  useScrollPositionEffect(({ prevPos, currPos }) => {
    if (autoAdd && !moreDataRequested.current && currPos && currPos.scrollHeight && currPos.windowHeight && (((currPos.y + currPos.scrollHeight) - currPos.windowHeight) < (autoAddEndThreshold * currPos.windowHeight))) {
      handleRequestMoreData(true);
    }
    return;
  }, [handleRequestMoreData, autoAdd, autoAddEndThreshold], { disabled: !autoAdd });

  useEffect(() => {
    if (requestingMore && moreDataRequested.current && status !== 'pending') {
      console.log('Executing');
      execute({ offset: Array.isArray(value) ? value.length : 0 }, value, true);
    }
  }, [requestingMore, value, status])

  const finalValue = useMemo(() => {
    if (value) {
      if (!Array.isArray(value) || !value.length) {
        return value;
      }
      // TODO: have backend check for duplicates. RN for any search/sort/filter when grabbing more backend returns duplicates.
      return value.filter((item, index, self) => {
        const cIndex = self.findIndex(t => t.id && item.id ? t.id === item.id : false);
        return cIndex === index;
      })
    }
    return Array.isArray(defaultValue) ? defaultValue : [];
  }, [value, defaultValue]);

  return {
    value: finalValue,
    status,
    requestingMore,
    moreDataAvailable,
    requestMoreData: handleRequestMoreData,
    execute,
    searchConfig,
    ...apiState
  };
}

export { useAsyncSearch };