import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ScrollView as NativeScrollView, Platform } from 'react-native';
import { useForkRef } from '../../hooks';
import { withStyles, animated, getStyle } from '../../styling';
import {
  ViewStylePropTypes,
  PaddingPropTypes,
  BackgroundColorPropTypes,
} from '../../system';
import { isNull, isObject } from '../../utils';
import { ScrollEventManager } from './ScrollEventManager';

const AnimatedScrollView = animated(NativeScrollView);
AnimatedScrollView.displayName = 'animated(ScrollView)';

const ContainerFlexProps = {
  flexDirection: 'flexDirection',
  flexWrap: 'flexWrap',
  justifyContent: 'justifyContent',
  alignItems: 'alignItems',
  alignContent: 'alignContent',
}

// TODO: with sx prop now should use postApply here to double check on what styles go to contentContainer and which go to scrollview
const ScrollViewComponent = withStyles((props) => {
  const { style: s } = props;
  const propsStyle = getStyle(s);
  const stylePropPadding = {};
  const styleProp = {};

  // Explanation: Basically we want all padding and most flex properties to be forwarded to the contentContainer view within ScrollView. These properties do nothing for regular the parent ScrollView container
  if (propsStyle) {
    for (const key in propsStyle) {
      if (PaddingPropTypes[key] === undefined && ContainerFlexProps[key] === undefined) {
        styleProp[key] = propsStyle[key];
      } else {
        stylePropPadding[key] = propsStyle[key];
      }
    }
  }
  return {
    root: (props) => {
      const styles = { };
      for (const key in ViewStylePropTypes) {
        // dont include padding here
        if (props[key] !== undefined && PaddingPropTypes[key] === undefined && ContainerFlexProps[key] === undefined) {
          styles[key] = props[key];
        }
      }
      return styles;
    },
    contentContainer: (props) => {
      let styles = { };
      for (const key in PaddingPropTypes) {
        if (props[key]) {
          styles[key] = props[key];
        }
      }
      for (const key in BackgroundColorPropTypes) {
        if (props[key]) {
          styles[key] = props[key];
        }
      }
      for (const key in ContainerFlexProps) {
        if (props[key]) {
          styles[key] = props[key];
        }
      }
      if (!isNull(props.containerStyle)) {
        let containerStyle = typeof props.containerStyle === 'function' ? props.containerStyle(props) : props.containerStyle;
        if (isObject(containerStyle)) {
          for (const key in ViewStylePropTypes) {
            if (containerStyle[key] !== undefined) {
              styles[key] = containerStyle[key];
            }
          }
        } else if (typeof containerStyle === 'number') {
          containerStyle = getStyle(containerStyle);
          if (containerStyle) {
            styles = { ...styles, ...containerStyle };
          }
        }
      }
      return styles
    },
    styleProp,
    stylePropPadding,
  };
}, { name: 'ScrollView', filterProps: Object.keys(ViewStylePropTypes), preserveStyleProp: true })(
  React.forwardRef(function ScrollView(props, ref) {
    const {
      showsVerticalScrollIndicator = Platform.OS === 'web', // TODO: rename some of the default ScrollView props to match rest of UI component (enableScrollViewIndicators would be better)
      contentContainerStyle,
      containerStyle,
      onScroll,
      onLayout,
      scrollEventThrottle = undefined,
      disableEvents,
      styles,
      style,
      component: Component = AnimatedScrollView,
      ...rest
    } = props;
    const [scrollEventManager] = useState(() => new ScrollEventManager());

    
    const detachOnScroll = useRef(null);
    const detachOnLayout = useRef(null);
    useEffect(() => {
      detachOnScroll.current = scrollEventManager.attachOnScrollHandler(onScroll);
      return () => {
        if (detachOnScroll.current) {
          detachOnScroll.current();
        }
      }
    }, [onScroll, scrollEventManager]);

    useEffect(() => {
      detachOnLayout.current = scrollEventManager.attachOnLayoutHandler(onLayout);
      return () => {
        if (detachOnLayout.current) {
          detachOnLayout.current();
        }
      }
    }, [onLayout, scrollEventManager]);

    const scrollRef = useRef(null);
    useImperativeHandle(ref,
      () => ({
        scrollEventManager,
        ...scrollRef.current,
      }),
      [scrollEventManager]
    )
    return (
      <Component
        ref={scrollRef}
        onScroll={!disableEvents && scrollEventManager.handleOnScroll}
        onLayout={!disableEvents && scrollEventManager.handleOnLayout}
        scrollEventThrottle={onScroll && scrollEventThrottle !== undefined ? scrollEventThrottle : 5}
        contentContainerStyle={[styles.contentContainer, styles.stylePropPadding, contentContainerStyle]}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        style={[styles.root, styles.styleProp]}
        {...rest}
      />
    );
  })
);

const scrollNodeContext = React.createContext({ current: null });

function useScrollNode() {
  return React.useContext(scrollNodeContext);
}

const ScrollView = React.forwardRef((props, ref) => {
  const { provideNode = false, ...rest } = props;
  const scrollNodeRef = useRef(null);
  const handleRef = useForkRef(scrollNodeRef, ref);
  if (provideNode) {
    return (
      <scrollNodeContext.Provider value={scrollNodeRef}>
        <ScrollViewComponent ref={handleRef} {...rest} />
      </scrollNodeContext.Provider>
    )
  }
  return <ScrollViewComponent ref={ref} {...rest} />;
})


export { ScrollView, useScrollNode };
